import React, { Component } from 'react';
import axios from 'axios';
import ReactStars from 'react-stars';
import Moment from 'react-moment';
import notifications from '../../../utils/notifications';

class ComentariosCalificaciones extends Component {

  constructor(props) {
    super(props)
    this.state = {
      coments_rating: [],
      provider: []
    }
    this.checkprovider = this.checkprovider.bind(this);
  }

  componentDidMount() {
    this.checkprovider();
    this.props.sockets.sockets.callback = this.refreshdata
  }

  refreshdata(data) {
    notifications.buildNotification(data.type, data.message, data.accountType, data.senderid,data.profileId);
}

  checkprovider() {
    axios.get('https://serviadvisor.com/checkprovider', { withCredentials: true }).then(res => {
      const providerdata = res.data;
      if (!providerdata.error) {
        this.setState({
          provider: providerdata
        }, () => {
          axios.get('https://serviadvisor.com/coment_rating/' + this.state.provider.id).then(res => {
            const data = res.data;
            this.setState({
              coments_rating: data
            })
          })
        })
      }
    })

  }



  render() {
    return (
      <div>
        <div class="title-perfil-empresa espacio-top">
          <h4 class="azul"> Comentarios de Usuarios</h4>
        </div>

        {this.state.coments_rating.map((item,index)=>{
          return(
            <div class="caja-calif">
                <div class="caja-calif-cont">
          <div class="img-det-calificacion"><a href="#"><img src="/images/img amarillis cont.png" alt="" class="img-responsive" /></a></div>
          <div class="caja-cal-text">
          <div class="calificacion negrilla">Calificación:</div>

          <ReactStars
            count={5}
            edit={false}
            size={19}
            half={false}
            value={item.score}
            color2={'#ffd700'} />
</div>
<div class="caja-calif-cont">


          <div class="texto-det-calificacion">
            <p>{item.coment}</p>
          </div>
        </div>
        </div>
        <p class="alinear-derecha-fecha color-gris"><Moment format={"DD MMM YYYY hh:mm a"}>{item.createdAt}</Moment></p>
        <div class="limp"></div>

            </div>
          )
        })}



      


      </div>
    );
  }
}

export default ComentariosCalificaciones;