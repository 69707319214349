import React, {Component} from 'react';
import Menu from './cliente/MenuEdicionClient';
import Notifications from './cliente/clientNotifications';
import Calendario from './cliente/clientCalendar';
import Offers from './cliente/clientOffers';
import Favs from './cliente/clientFavs';
import Perfil from './cliente/perfilCliente';
import Account from './cliente/accountconfig';
import Payouts from './cliente/clientpayouts';

class ContenidoPerfilClienteEditar extends Component{

    constructor(props) {
        super(props);
        this.state = {
            tab: ""
        }

        this.rendertab = this.rendertab.bind(this);

    }

    

    rendertab() {

        let html = "";

        switch (this.props.tab) {


            case 'perfil':  html = <Perfil 
                user={this.props.user}
                sockets={this.props.sockets}></Perfil>;
            break;

            case 'calendario':  html = <Calendario
            user={this.props.user}
            sockets={this.props.sockets}></Calendario>;
            break;

            case 'favoritos':  html = <Favs
            user={this.props.user}
            sockets={this.props.sockets}></Favs>;
            break;

            case 'account':  html = <Account
            user={this.props.user}
            sockets={this.props.sockets}></Account>;
            break;

            case 'propuestas':  html = <Offers  user={this.props.user} messageType={"deals"} sockets={this.props.sockets} tab={"list"}></Offers>;
            break;

            case 'pagos':  html = <Payouts
            user={this.props.user}
            sockets={this.props.sockets}></Payouts>;
            break;

            case 'notificaciones':  html = <Notifications
            user={this.props.user}
            sockets={this.props.sockets}></Notifications>;
            break;
        }

        return (
            <div>
                {html}
            </div>
        );
    }

    render(){
        return(
        <div>
            <div class="rec-blog perfil-empresa">
                    <div class="container">
                        <div class="rec-blog-inner micuenta-empresa">
                            <div class="row">


                                <Menu onchangetab={this.props.onChangetab}></Menu>

                                <div class="col-md-9 blog-ct espacio-centro reco-client">

                                    {this.rendertab()}

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
        </div>
        );
    }
}

export default ContenidoPerfilClienteEditar;