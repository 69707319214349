import React, { Component } from 'react';
import HeaderPage from '../HeaderPage';
import Browser from '../Browser';
import Footer from '../Footer';
import Consejos from '../menu/consejos';
import Carrouseltest from '../menu/carrouseltest';
import Recomendados from '../menu/carouselpremium';
import sockets from '../../sockets/sockets';
import profileutils from '../../utils/profiledata';

class Home extends Component {

    constructor(props) {
        super(props)
        this.state = {
            islogin: false,
            user: undefined
        }
        this.logindata = this.logindata.bind(this);
        const user = profileutils.getuserprofile();
        console.log(user);
        if (user) {
            sockets.subscribeUserToSocket(user.userid)
            this.setState({
                user: user
            })
        }
    }

    logindata = (user, islogin) => {

        console.log("user login ------------------")
        console.log(user)
        this.props.loginroot(user,islogin);

        if (!islogin) {
            profileutils.deleteprofile();
            //sockets.unsubscribechat();
        }
        this.setState({
            user: user,
            islogin: islogin
        })
        profileutils.setuserprofile(user);
        if (user && user !== undefined) {
            console.log("subscripcion usuario ------------------")
            sockets.subscribeUserToSocket(user.userid)
            sockets.subscribeChat()
        }
    }

    componentDidMount() {
        console.log("component did mount en home");
    }

    render() {
        return (
            <div>
                <HeaderPage slideout={this.props.slideout} sockets={sockets} user={this.state.user} logindata={this.logindata} islogin={this.state.islogin} history={this.props.history}></HeaderPage>
                <Browser  user={this.state.user} sockets={sockets} user={this.state.user} islogin={this.state.islogin} history={this.props.history}></Browser>
               {/*<Consejos></Consejos>*/}
                <Recomendados></Recomendados>
                <Footer user={this.state.user} logindata={this.logindata} islogin={this.state.islogin}></Footer>
                
            </div>
        );
    }
}

export default Home;