import React, { Component } from 'react';
import HeaderRegistro from '../HeaderRegistro';
import Footer from '../Footer';
import axios from 'axios';
import GoogleLogin from 'react-google-login';
//import FacebookLogin from 'react-facebook-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import sockets from '../../sockets/sockets';
import profileutils from '../../utils/profiledata';
import Loader from 'react-loader-spinner';


const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
const validpassword = RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})");

class activation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            islogin: false,
            user: undefined,
            active: false,
            mensage: "Por confirmar",
            email: '',
            emailsign: '',
            passwordsign: '',
            password: '',
            secondpassword: '',
            redirect: false,
            redirecturl: '',
            modallogin: false,
            errors: {
                email: true,
                emailsign: true,
                passdis: true,
                passinvalid: true,
            },
            loadform: false,
            loadsuccess:false,
            loaderSave:false
        }
     

        this.logindata = this.logindata.bind(this);
        const user = profileutils.getuserprofile();
        console.log(user);
        if (user) {
            sockets.subscribeUserToSocket(user.userid)
            this.setState({
                user: user
            })
        }


    }

    componentDidMount() {
        console.log(this.props.match.params)
        if (this.props.match.params.token) {
            const token = this.props.match.params.token;
            this.setState({
                active: true
            }, () => {
                this.sendactivationrequest(token)
            })

        }
    }

    logindata = (user, islogin) => {

        console.log("user login ------------------")
        console.log(user)
        this.props.loginroot(user,islogin);

        if (!islogin) {
            profileutils.deleteprofile();
            //sockets.unsubscribechat();
        }
        this.setState({
            user: user,
            islogin: islogin
        })
        profileutils.setuserprofile(user);
        if (user && user !== undefined) {
            console.log("subscripcion usuario ------------------")
            sockets.subscribeUserToSocket(user.userid)
            sockets.subscribeChat()
        }
    }

    handletextChange(name, event) {


        this.setState({ [name]: event.target.value });

        if (name === "secondpasswordsign") {
            this.validateSecondPassword(event);
        }

        if (name === "username") {
            this.validateUsername(event);
        }

        if (name === "password") {
            this.validatePassword(event);
        }

        if (name === "passwordsign") {
            this.validatePassword(event);
        }

        if (name === "email") {
            this.validateemail(event, false);
        }

        if (name === "emailsign") {
            this.validateemail(event, true);
        }

    }

    validateemail(event, sign) {
        const email = event.target.value
        const checkemail = validEmailRegex.test(event.target.value)
        const errors = this.state.errors
        if (sign) {
            errors.emailsign = checkemail
        } else {
            errors.email = checkemail
        }

        this.setState({ errors: errors });
    }

    validatePassword(event) {
        const password = event.target.value;
        let checkpass = validpassword.test(password);
        const errors = this.state.errors;
        errors.passinvalid = checkpass;

        this.setState({
            errors
        })
    }

    handleSumitClicklogin(event) {
        event.preventDefault()
        const logincredentials = {
            email: this.state.email,
            password: this.state.password,
        }

        axios.post('https://serviadvisor.com/login', logincredentials, { withCredentials: true }).then(response => {
            const data = response.data.userData;

            if (!data) {
                const notify = () => toast.error("Usuario O contraseña Invalida");
                notify();
            } else {
                const userdata = {
                    userid: data.userid,
                    username: data.username,
                    photo_url: data.photo_url,
                    active: data.active,
                    accountType: data.accountType
                }
                this.logindata(userdata, true);
                if (userdata.accountType = "empresa") {
                    this.props.history.push('/registro-empresa');
                } else {
                    this.props.history.push('/');
                }

            }


        }).catch(error => {
            const notify = () => toast.error("Usuario O contraseña Invalida");
            notify();
        })



    }


    responseGooglelogin = (response) => {
        console.log(response);
        const data = {
            accounttype: "empresa",
            access_token: response.accessToken
        }

        console.log(data);
        axios.post('https://serviadvisor.com/oauth/googlelogin', data, { withCredentials: true }).then(res => {
            console.log("login desde server");

            if (res.data.error) {
                const notify = () => toast.error(res.data.error);
                notify();
            } else {
                console.log("login desde server");
                const data = res.data;
                const userdata = {
                    userid: data.userid,
                    username: data.username,
                    photo_url: data.photo_url,
                    active: '',
                    accountType: data.accountType
                }
                this.props.history.push('/');
                //this.props.logindata(userdata,true);
                //this.loginclose.click();
            }



        })

    }


    responseFacebooklogin = (response) => {
        console.log(response);
        const data = {
            accounttype: "empresa",
            access_token: response.accessToken
        }

        console.log(data);
        axios.post('https://serviadvisor.com/oauth/facebooklogin', data, { withCredentials: true }).then(res => {
            console.log("login desde server");
            console.log(res.data);
            //this.loginclose.click();
            this.props.history.push('/');
        })
    }

    resendmailvalidation(){


        this.setState({
            loaderSave:true
        })

        axios.post('https://serviadvisor.com/requestmailvalidation', null, {withCredentials:true}).then(res => {
            const data = res.data;

            if(!data.error){


                this.setState({
                    loadsuccess:true,
                    loaderSave:false
                })

                const notify = () => toast.success("Se ha enviado a tu dirección de correo un link de activación");
                notify();
            }
        })
    }



    sendactivationrequest(token) {
        axios.get('https://serviadvisor.com/confirmation/' + token, { withCredentials: true }).then(res => {
            const data = res.data;
            if (data != "error") {
                this.setState({
                    mensage: "Cuenta Confirmada",
                    loadform: true,
                    user: data
                })
            } else {
                this.setState({
                    mensage: "Error En la confirmacion"
                })
            }
        })
    }

    activation() {
        return (
            <div class="caja-activacion">
                <div>

                    <h2>Bienvenido a Serviadvisor</h2>
                    <h4>Revise su Correo ingrese al Link y active su cuenta</h4>
                    <p>Ya casi estas listo para que uses todas tus funciones que ServiAdvisor tiene para ti.</p>
                    
                    <p> Si no a recibido el correo de activacion Haga Click <a onClick={(e) => this.resendmailvalidation()} href="javascript:void(0);"> Aca</a><Loader
                  visible={this.state.loaderSave}
                  type="Oval"
                  color="#00BFFF"
                  height={25}
                  width={25}

                /> </p>
                    {this.state.loadsuccess && (<span class="alert"><i class="fas fa-check-circle"></i> Email enviado.</span>)}
                    <br></br>
                </div>
                <div class="caja-btn-bienv">
                    <div class="atras-btn"><a href="javascript: history.go(-1)"><i class="fas fa-chevron-left"></i>Ir atras</a> </div>
                    <div class="inicio-btn"><a href="/"><i class="fas fa-chevron-left"></i>Ir a inicio</a> </div>
                </div>
            </div>
        )
    }

    active() {
        return (
            <div class="conte-act-redes">
                <div class="alert alert-success"><p> {this.state.mensage}</p></div>

                {this.state.islogin && 
                <div class="modal-body"><br />



                    <FacebookLogin
                        appId="311566446451744"
                        autoLoad={false}
                        fields="name,email,picture"
                        onClick={this.componentClicked}
                        render={renderProps => (


                            <button class="btn btn-social ext-reg-btn fb-btn" data-pa-name="intro_login_fb" onClick={renderProps.onClick}>
                                <div class="text-con"> <i class="fab fa-facebook-square"></i> <span> Continuar con Facebook</span>
                                </div>
                            </button>

                        )}
                        //onClick={this.componentClicked}
                        callback={this.responseFacebooklogin}></FacebookLogin>

                    <br />
                    <GoogleLogin
                        clientId="154537549270-knt6kr4a3epb30eed3ntrcl35dtsnt1p.apps.googleusercontent.com"
                        buttonText="Login Con Google"
                        render={renderProps2 => (

                            <button class="btn btn-social ext-reg-btn gp-btn" data-pa-name="intro_login_google" id="gp-auth-btn" onClick={renderProps2.onClick} >
                                <div class="text-con"> <i class="fab fa-google-plus"></i> <span>Continuar con Google</span> </div></button>

                        )}
                        onSuccess={this.responseGooglelogin}
                        onFailure={this.responseGooglelogin}
                        cookiePolicy={'single_host_origin'}
                    ></GoogleLogin>


                    <hr />




                    <div class="con-form">
                        <div class="tit-ses">Ingresa con tu dirección de correo electronico</div>
                        <input type="text" class="form-control" id="email" placeholder="Email" onChange={(e) => this.handletextChange("email", e)} />
                        {!this.state.errors.email && (<div class="row"><i class="fas fa-times"></i><span>Por favor ingrese un correo valido</span></div>)}
                        <input type="password" class="form-control" id="contraseña" placeholder="Contraseña" onChange={(e) => this.handletextChange("password", e)} />
                        <div class="tit-olv">¿Olvidaste tu Contraseña?</div>
                    </div>

                    <button onClick={e => this.handleSumitClicklogin(e)} type="button" class="btn btn-primary">Ingresar</button>


                </div>
                }
                <br />
                <div class="caja-btn-bienv">
                    <div class="atras-btn"><a href="javascript: history.go(-1)"><i class="fas fa-chevron-left"></i>Ir atras</a> </div>
                    <div class="inicio-btn"><a href="/"><i class="fas fa-chevron-left"></i>Ir a inicio</a> </div>
                </div>
               
            </div>
        )
    }

    render() {
        return (
            <div>
                <HeaderRegistro slideout={this.props.slideout} user={this.state.user} logindata={this.logindata} islogin={this.state.islogin} history={this.props.history}></HeaderRegistro>

                {this.state.active ? this.active() : this.activation()}
                <Footer logindata={this.logindata} user={this.state.user} islogin={this.state.islogin}></Footer>
            </div>
        )
    }
}

export default activation