import React, { Component } from 'react';
import HeaderPerfilEmpresa from '../HeaderPerfilEmpresa';
import TituloBusqueda from '../TituloBusqueda';
import Footer from '../Footer';
import ContenidoBusqueda from '../ContenidoBusqueda';
import CategoriasFooter from '../CategoriasFooter';
import PublicidadFooter from '../PublicidadFooter';
import Axios from 'axios';
import profileutils from '../../utils/profiledata';
import sockets from '../../sockets/sockets';


class Busqueda extends Component {

    constructor(props) {
        super(props)


        this.state = {
            providers: [],
            category: "",
            categoryId:0,
            city: "",
            cityId: 0,
            page:0,
            islogin: false,
            user: undefined
        }

        this.logindata = this.logindata.bind(this);
        this.setcategory = this.setcategory.bind(this);
        this.setcity = this.setcity.bind(this);
        this.onparamsChange = this.onparamsChange.bind(this);

        this.logindata = this.logindata.bind(this);
        const user = profileutils.getuserprofile();
        console.log(user);
        if (user) {
            sockets.subscribeUserToSocket(user.userid)
            this.setState({
                user: user
            })
        }
    }

    logindata = (user, islogin) => {
        this.props.loginroot(user,islogin);


        if (!islogin) {
            profileutils.deleteprofile();
        }
        this.setState({
            user: user,
            islogin: islogin
        })
        profileutils.setuserprofile(user);
        if (user) {
            sockets.subscribeUserToSocket(user.userid);
            sockets.subscribeChat();
        }
    }


    componentDidMount() {
      
    }


    onparamsChange(param) {
        this.refs.child.searchproviders(param);
        console.log("search in onparams change busqueda" + this.props.location.search)
    }



    setcategory = (category) => {
        this.setState({
            category: category.name,
            categoryId: category.id
        })
    }

    setcity = (city) => {
        this.setState({
            city: city.name,
            cityId: city.id
        })
    }

    setpage = (page) => {
       
        this.setState({
            page:page
        })
    }


    render() {
        return (
            <div>
                <HeaderPerfilEmpresa
                    sockets={sockets}
                    slideout={this.props.slideout}
                    logindata={this.logindata}
                    user={this.state.user}
                    islogin={this.state.islogin}
                    history={this.props.history}>
                </HeaderPerfilEmpresa>

                <TituloBusqueda
                    sockets={sockets}
                    onSetpage={this.setpage}
                    onSetcity={this.setcity}
                    onsetCategory={this.setcategory}
                    onUrlhaschange={this.onparamsChange}
                    history={this.props.history}
                    category={this.state.category}
                    categoryId={this.state.categoryId}
                    city={this.state.city}
                    cityId={this.state.cityId}>

                </TituloBusqueda>
                <ContenidoBusqueda
                    ref="child"
                    page={this.state.page}
                    location={this.props.location}
                    history={this.props.history}
                    onUrlhaschange={this.onparamsChange}
                    onSetpage={this.setpage}
                    onSetcity={this.setcity}
                    onsetCategory={this.setcategory}
                    category={this.state.category}
                    categoryId={this.state.categoryId}
                    city={this.state.city}
                    cityId={this.state.cityId}
                    sockets={sockets}
                    user={this.state.user}>
                </ContenidoBusqueda>
                <CategoriasFooter></CategoriasFooter>
                <PublicidadFooter></PublicidadFooter>
                <Footer user={this.state.user} islogin={this.state.islogin}></Footer>
            </div>
        );
    }
}

export default Busqueda;