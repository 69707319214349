import React, {Component} from 'react';
import HeaderEditar from '../HeaderEditar';
import Footer from '../Footer';
import ContenidoEmpresaMicuentaChat from '../ContenidoEmpresaMicuentaChat';
import axios from 'axios';
import sockets from '../../sockets/sockets';
import profileutils from '../../utils/profiledata';


class PerfilEmpresaMicuentaChat extends Component{
    constructor(props){
        super(props);
        this.state={
            tab:"",
            islogin: false,
            user: undefined
        }

        this.settab= this.settab.bind(this);
        this.setactiveMenu = this.setactiveMenu.bind(this); 
        this.getproviderinfo = this.getproviderinfo.bind(this);
        this.logindata = this.logindata.bind(this);

        const user = profileutils.getuserprofile();
        console.log(user);
        if (user) {
            sockets.subscribeUserToSocket(user.userid)
            this.setState({
                user: user
            })
        }

    }


    logindata = (user, islogin) => {
        this.props.loginroot(user,islogin);
        if (!islogin) {
            profileutils.deleteprofile();
        }
        this.setState({
            user: user,
            islogin: islogin
        })
        profileutils.setuserprofile(user);
        if (user) {
            sockets.subscribeUserToSocket(user.userid)
            sockets.subscribeChat();
        }
    }


    componentDidMount(){
      


        this.setState({
            tab:this.props.match.params.tab
          });

          if(this.state.user && this.state.user.accountType==="empresa"){
            this.setactiveMenu(this.props.match.params.tab);
          }
         

         // this.getproviderinfo();
    }

    settab(name) {
        this.setState({
            tab:name
        })

        this.props.history.push('/perfil/'+ name);
        this.setactiveMenu(name);
    }

    setactiveMenu(tab){

        const tabs = ["galeria","informacion","mapa","chat", "faq","coments","propuestas","calendario","notificaciones","account","videos", "pagos", "businesshours"];

        tabs.map((item,index)=>{
            document.getElementById(item).className="";
        })
        
        if(document.getElementById(tab)){
        document.getElementById(tab).className += " activo"
       
        }
        console.log(tab);
    }

    getproviderinfo(){
        axios.get('https://serviadvisor.com/checkprovider',{withCredentials:true}).then(res =>{

        const data = res.data;
            if(data.error){

            }else{
              console.log(data);
              this.setState({
                  providerinfo:data
              })
              console.log("provider data in page")
            }
        })

      }


  

    render(){
        return(
            <div>
                <HeaderEditar slideout={this.props.slideout} sockets={sockets} user={this.state.user} logindata={this.logindata} islogin={this.state.islogin} history={this.props.history}></HeaderEditar>
                {this.state.user && this.state.user.accountType ==="empresa" ?
                 <ContenidoEmpresaMicuentaChat match={this.props.match} user={this.state.user} sockets={{sockets}} tab={this.state.tab} onChangetab={this.settab}  params={this.props.match.params}></ContenidoEmpresaMicuentaChat>
                 :
                 <h4>Logueate Con una Cuenta de empresa para poder acceder</h4>
            }
               
                <Footer></Footer>
            </div>
        );
    }
}

export default PerfilEmpresaMicuentaChat;