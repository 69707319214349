import React,{Component} from 'react';

class TituloRegistro extends Component{
    render(){
        return(
        <div> 
            <div class="regis-empre">
                 <div class="container-">
                     <div class="rec-blog">
                         <div class="row">
                             <div class="enca text-center">
                                    <h4 class="titu text-center">REGISTRA TU EMPRESA</h4>
                                    <hr/>
                                    <div class="flecha-down"></div>
                                    <div class="segm"> 
                                        <p><b>Registrate y haz que tu negocio crezca en Grande!</b></p>
                                        <p>Recibe solicitudes de Novios y consigue Clientes potenciales para tu negocio</p>
                                    </div>
                                    <div class="ti-regis-men"> 
                                        <div id="paso1" class="pas-uno">
                                            <div class="pas-uno-nu">1</div>
                                            <div class="pas-uno-text">Información General de tu empresa</div>
                                        </div>

                                        <div id="paso2" class="pas-dos">
                                            <div class="pas-dos-nu">2</div>
                                            <div class="pas-dos-text">Galería de Fotos</div>
                                        </div>
                                        <div id="paso3" class="pas-tres">
                                            <div class="pas-tres-nu">3</div>
                                            <div class="pas-tres-text">Información Adicional</div>
                                        </div>
                                    </div>
                             </div>                              
                               
                        </div>
                    </div>
                </div>   
            </div> 
        </div>
        );
    }
}

export default TituloRegistro;