import React,{Component} from 'react';
import Axios from 'axios';
import queryString from 'query-string';

class TituloPerfil extends Component{
    constructor(props){
        super(props)
        this.state={
            showbar:false,
            categoryname: ""
        }
    }

    componentDidMount(){
        const searchParams = new URLSearchParams(this.props.location.search);
   

        if(searchParams.get("category")){
          const categoryid = searchParams.get("category");
            this.setState({
                showbar:true
            })

          Axios.get("https://serviadvisor.com/activities/" + categoryid).then(res =>{
            const categoryname= res.data[0].name;
            const categoryId = res.data[0].id
            const category = {
              name:categoryname,
              id:categoryId
            }
            this.setState({
                categoryname:category.name,
            })
            
          })  
        }
    }
    
    render(){



        return(
        <div> 
            <div class="regis-empre">
                 <div class="container-">
                     <div class="rec-blog">
                         <div class="row">
                             <div class="container">
                                   {this.state.showbar && ( <div class="busca"> <b>Has Buscado:</b><span class="tit-busc">{this.state.categoryname} </span><div class="limpiar-busca"><a href="/search/">X Limpiar Búsqueda </a></div> </div>)}
                             </div>                              
                        </div>
                    </div>
                </div>   
            </div> 
        </div>
        );
    }
}

export default TituloPerfil;