import React, { Component } from 'react'
import axios from 'axios';
import Slider from "react-slick";


class Consejos extends Component {
    constructor(props) {
        super(props)
        this.state = {
            post: [],
            categoriespost: [],
            activeItemIndex: 0,
        }
        this.getcategoriespost = this.getcategoriespost.bind(this);
    }

    componentDidMount() {
        this.getadvices();
        this.getcategoriespost();
    }

    getadvices() {
        axios.get('https://serviadvisor.com/advice').then(res => {
            const data = res.data;

            this.setColumns(data)




        })
    }


    getcategoriespost() {
        axios.get("https://serviadvisor.com/categoriespost").then(res => {
            const data = res.data;
            this.setState({
                categoriespost: data
            }, () => {
                this.deletelowcount();
            })
        })
    }


    deletelowcount() {
        const items = Object.assign([], this.state.categoriespost);
        let itemspost = []

        items.map((item, index) => {
            console.log(item);
            if (item.blogPosts.length > 0) {
                itemspost.push(item);
            }
        })

        this.setState({
            categoriespost: itemspost
        })



    }


    setColumns(data) {

        console.log(data)
        let dataobj = [];
        let datarow = []
        let count = 0;
        let column = 0;
        data.forEach((item, index) => {

            datarow.push(item);
            count++;
            if (count > 2) {
                dataobj.push(datarow)
                datarow = []
                column++;
                count = 0;
            }
        })

        if (dataobj.length === 0) {
            dataobj.push(datarow)
        }

        this.setState({
            post: dataobj
        })

        console.log(dataobj)
    }

    render() {




        const settings = {
            dots: true,
            infinite: this.state.categoriespost.length > 4,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [{ breakpoint: 768, settings: { slidesToShow: 2 } },
            { breakpoint: 990, settings: { slidesToShow: 2 } },
            { breakpoint: 1024, settings: { slidesToShow: 3 } },
            { breakpoint: 1368, settings: { slidesToShow: 4 } },
            { breakpoint: 1600, settings: { slidesToShow: 5 } },
            { breakpoint: 100000, settings: { slidesToShow: 6 } }

            ]
        };

        return (


            <div class="consejos_seccion">
            <h2 class="tit-consejos"><a href="blog/"> Consejos </a></h2>
     <div class="container">
                    <Slider {...settings}


                >

                    {this.state.categoriespost.map((item, index) => {
                    return (
                    <div class="col-md-6 ">
                
                    
                    {item.blogPosts.map((item2, index2) => {
                    return (
                    <div class="caja-con">
                    <div class="con-fot"><a href={"/blog/"+ item2.id + "/post"}><img src={"https://serviadvisor.com/blogphoto/" + item2.image_url} alt="pav" /></a></div>
                    <div class="tit-con"><a href={"/blog/"+ item2.id + "/post"}>{item2.title}</a></div>
                    <div class="text-con">{item2.shorttext}</div>
                    <div class="btn-leer"><a href={"/blog/"+ item2.id + "/post"}>Leer mås <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="chevron-double-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-chevron-double-right fa-w-12"><path fill="currentColor" d="M34.5 36.5l211.1 211c4.7 4.7 4.7 12.3 0 17l-211.1 211c-4.7 4.7-12.3 4.7-17 0l-7.1-7.1c-4.7-4.7-4.7-12.3 0-17L205.9 256 10.5 60.5c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.6-4.6 12.2-4.6 16.9.1zm111 0l-7.1 7.1c-4.7 4.7-4.7 12.3 0 17L333.9 256 138.5 451.5c-4.7 4.7-4.7 12.3 0 17l7.1 7.1c4.7 4.7 12.3 4.7 17 0l211.1-211c4.7-4.7 4.7-12.3 0-17l-211.1-211c-4.8-4.8-12.4-4.8-17.1-.1z" class=""></path></svg></a></div>
                    </div>
                    )
                    })}
                    
                    </div>
                    )
                    })}

                    </Slider>

     </div>
     </div>
                )
            }
        }
        
export default Consejos;