import React, { Component } from 'react';
import Axios from 'axios';

class categoriesMenu extends Component {

    constructor(props) {
        super(props)
        this.state = {
            categories: [],
            Providers:[],
        }
        this.selectmode = this.selectmode.bind(this);
        this.providerClick = this.providerClick.bind(this);
    }

    providerClick(username){
        window.location.replace("/empresa/" + username + "/perfil");
    }

    componentDidMount() {

    }

    selectmode() {

        if (this.props.mode) {
            if (this.props.mode === "panel") {
                return (
                    <div id="categoriescontend" className="panel" style={{
                        top: "50px", marginTop: "50px", zIndex: "3", display: this.props.show ? "flex" : "none"
                    }}>

                        {this.props.categories.map((item, index) => {
                            return (

                                <div class="col-busq" >
                                    {item.map((item4,index4)=>{
                                        return(
                                        <div class="busq-c">
                                        <h5>{item4.name}</h5>
                                        <ul class="busq-col">
                                            {item4.activities.map((item2, index2) => {
                                                return (
                                                    <div class="col-busq-col">
                                                        <li>
                                                            <label class="seg-dato"><a onMouseDown={e => this.props.onhandleclick(e, item2.id, item2.name)} href="javascript:void(0);"> {item2.name}  <span>{item2.categorieslists.length}</span> </a></label><br />
                                                        </li>
                                                    </div>
                                                );
                                            })}
                                        </ul>
                                        </div>
                                        )
                                    })}
                                </div>


                            );
                        })}

                    </div>
                )
            } else if (this.props.mode === "list") {
                return (
                    <div id="categoriescontend" className="panel" style={{
                         top: "50px",
                          marginTop: "50px",
                           zIndex: "3",
                            display: this.props.show ? "block" : "none"
                    }}>
                        <ul>
                        {this.props.categories.map((item, index) => {
                            return (



                                <div class="col-busq-sol">
                                    <li>
                                        <h4>
                                    <a onMouseDown={e => this.props.onhandleclick(e, item.id, item.name)} href="javascript:void(0);"> {item.name} ({item.categorieslists.length})</a>
                                    </h4>
                                    </li>
                                    
                                </div>


                            );
                        })}
                        </ul>
                    </div>
                )
            } else if (this.props.mode === "user") {
                return (
                    <div id="categoriescontend" className="panel" style={{
                         top: "50px",
                          marginTop: "50px",
                           zIndex: "3",
                            display: this.props.show ? "block" : "none"
                    }}>
                        <ul>
                        {this.props.providerList.map((item, index) => {
                            return (



                                <div class="col-busq-sol">
                                    <li>
                                        <h4>
                                    <a onMouseDown={e => this.providerClick(item.user.username)} href="javascript:void(0);">{item.name} :  @{item.user.username}</a>
                                    </h4>
                                    </li>
                                    
                                </div>


                            );
                        })}
                        </ul>
                    </div>
                )
            }
        }
    }


    render() {


        return (

            <div>
                {this.selectmode()}
            </div>

        )
    }
}

export default categoriesMenu;