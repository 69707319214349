import React, { Component } from 'react';
import axios from 'axios';

class categoriesAdmin extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categories: [],
            activities: [],
            categoryName: "",
            categoryOrder: "",
            activityName: "",
            activityOrder: "",
            activityCategory:"",
            activityPhoto:undefined,
            other:[]
        }

        this.getactivities = this.getactivities.bind(this);
        this.getcategories = this.getcategories.bind(this);
        this.newcategory = this.newcategory.bind(this);
        this.newactivity = this.newactivity.bind(this);
        this.editcategory = this.editcategory.bind(this);
        this.editactivity = this.editactivity.bind(this);
        this.deletecategory = this.deletecategory.bind(this);
        this.deleteactivity = this.deleteactivity.bind(this);
        this.handleNewCatbutton = this.handleNewCatbutton.bind(this);
        this.handleOnchangeCategoryName = this.handleOnchangeCategoryName.bind(this);
        this.handleOnchangeCategoryOrder = this.handleOnchangeCategoryOrder.bind(this);
        this.handleOnchangeActivityName = this.handleOnchangeActivityName.bind(this);
        this.handleOnchangeActivityOrder = this.handleOnchangeActivityOrder.bind(this);
        this.handlecombochange = this.handlecombochange.bind(this);
        this.handleEditCat = this.handleEditCat.bind(this);
       
    }

    componentDidMount() {
        this.getactivities();
        this.getcategories();
        this.getotheractivities();
    }



    getactivities() {
        axios.get('https://serviadvisor.com/activities').then(res => {
            const data = res.data;
            if (data) {
                this.setState({
                    activities: data
                })
            }
        })
    }

    getotheractivities(){
        axios.get('https://serviadvisor.com/otheractivities').then(res => {
            const data = res.data;
            if (data) {
                this.setState({
                    other: data
                })
            }
        })
    }

    getcategories() {
        axios.get('https://serviadvisor.com/categoriesonly').then(res => {
            const data = res.data;
            if (data) {
                this.setState({
                    categories: data
                })
            }
        })
    }

    handleOnchangeCategoryName = (e) => {
        const name = e.target.value;
        this.setState({
            categoryName: name
        })
    }

    handleOnchangeCategoryOrder = (e) => {
        const order = e.target.value;
        this.setState({
            categoryOrder: order
        })
    }

    handleNewCatbutton = (e) => {
        if (this.state.categoryName !== "" && this.state.categoryOrder !== "") {
            const name = this.state.categoryName;
            const order = this.state.categoryOrder;

            this.newcategory(name, order);
        }
    }

    handleOnchangePhoto = (e) => {
      


        if(e.target.files[0].size > 1000000){
            alert("File is too big!");
          
         };

        this.setState({
            activityPhoto:e.target.files[0]
        },() => {
            console.log(this.state.activityPhoto)
        })
    }

    

    newcategory(name, orden) {
        const data = {
            name: name,
            orden: orden
        }
        axios.post('https://serviadvisor.com/addcategory', data, { withCredentials: true }).then(res => {
            const resdata = res.data;
            if (!resdata.error) {
                this.getcategories();
                this.setState({
                    categoryName: "",
                    categoryOrder: ""
                })
            }
        })
    }

    handleOnchangeActivityName = (e) => {
        const name = e.target.value;
        this.setState({
            activityName: name
        })
    }

    handleOnchangeActivityOrder = (e) => {
        const order = e.target.value;
        this.setState({
            activityOrder: order
        })
    }

    handleNewActbutton = (e) => {
        const name = this.state.activityName;
        const order = this.state.activityOrder;
        const categoryId = this.state.activityCategory;
        
        this.newactivity(name, order,categoryId);
    }

    newactivity(name, orden , categoryId) {


        const data = new FormData()
        const file = this.state.activityPhoto;
       
        
        if(this.state.activityPhoto){
            data.append('file', file)
        }
       
     
        data.append('orden',orden);
        data.append('name',name);
        data.append('categoryId',categoryId)

        axios.post('https://serviadvisor.com/activities', data, { withCredentials: true }).then(res => {
            const resdata = res.data;
            if (!resdata.error) {
                this.getactivities();
                this.setState({
                    activityName: "",
                    activityOrder: ""
                })
            }
        })
    }


    handleEditAct(idact){


        const name = document.getElementById("actname:" + idact).value;
        const order = document.getElementById("actorder:" + idact).value;
        const categoryId = document.getElementById("actcategory:" + idact).value;
        const publicact = document.getElementById("actpublic:"+ idact).checked
        const image = document.getElementById("actimage:" + idact).files[0];
        
        if(image){
            if(image.size > 1000000){
                alert("File is too big!");
                return
             };
        }

    

        this.editactivity(idact,name,order,categoryId,image,publicact);
    }

    editactivity(idact,name,order,categoryId,image,publicact) {

        const data = new FormData()
      
        
        if(image){
            data.append('file', image)
        }
       
        data.append('name',name)
        data.append('orden',order);
        data.append('categoryId',categoryId);
        data.append('publicat',publicact);

        axios.put('https://serviadvisor.com/activities/' + idact, data, { withCredentials: true }).then(res => {
            const resdata = res.data;
            if (!resdata.error) {
                this.setState({
                    activities:[]
                }, () => {
                    this.getactivities();
                    this.getotheractivities();
                })
               
            }
        })
    }

    handleEditCat(idcat){
        const name = document.getElementById("catname:"+ idcat).value
        const orden = document.getElementById("catorder:"+ idcat).value
       

        this.editcategory(idcat,name,orden);
    }

    editcategory(idcat, name, order) {
     
        const data = {
            name: name,
            orden: order,
          
        }
        axios.put('https://serviadvisor.com/category/' + idcat, data, { withCredentials: true }).then(res => {
            const resdata = res.data;
            if (!resdata.error) {
                this.getcategories();
            }
        })
    }

    deletecategory(catid) {
        axios.delete('https://serviadvisor.com/categories/' + catid, { withCredentials: true }).then(res => {
            const data = res.data;
            if (!data.error) {
                this.getcategories();
            }
        })
    }

    deleteactivity(actid) {
        axios.delete('https://serviadvisor.com/activities/' + actid, { withCredentials: true }).then(res => {
            const data = res.data;
            if (!data.error) {
                this.getactivities();
                this.getotheractivities();
            }
        })
    }

    handlecombochange = e => {
        console.log(e.target.value);
        this.setState({
          activityCategory: e.target.value
        })
    
      }

    getoption(item,item2,index){
        if(item.activitiesCategoryId === item2.id) {
            return(
            <option  key={index} value={item2.id} selected>{item2.name}  </option>
            )
        }else{
            return(
            <option  key={index} value={item2.id}>{item2.name}  </option>
            )
        }
    }

    render() {
        return (
            <div class="sec-caja-grupos-categorias">

              <div class="caja-grupos-categorias">
                <h4>Grupos Categorias</h4>

                {this.state.categories.map((item, index) => {

                    return (
                        <div>
                            <p>
                                <input id={"catname:" + item.id} type="text" defaultValue={item.name}></input>
                                <input id={"catorder:" + item.id} type="text" defaultValue={item.orden}></input>

                                <button onClick={e => this.handleEditCat(item.id)}  class="btn btn-primary" value="Guardar">Guardar </button>
                                <button onClick={e => this.deletecategory(item.id)}  class="btn btn-success" value="Eliminar"> Eliminar</button>

                               
                            </p>
                        </div>
                    )

                })}
</div>


                <div class="caja-crear-nueva-categoria">
                    <span>Crear Nueva Categoria</span>
                    <p>
                        <input type="text" onChange={this.handleOnchangeCategoryName} value={this.state.categoryName} placeholder="Nombre"></input>
                        <input type="text" onChange={this.handleOnchangeCategoryOrder} value={this.state.categoryOrder} placeholder="Orden"></input>
                        <button onClick={this.handleNewCatbutton}  class="btn btn-primary" value="Agregar">Agregar</button>
                     

                    </p>
                </div>

                <div class="caja-actividades-creadas">
                <h4>Actividades</h4>
                {this.state.activities.map((item, index) => {

                    return (
                        <div class="caja-actividades-creadas">
                            <p>
                                <input id={"actname:" + item.id} type="text" defaultValue={item.name}></input>
                                <input id={"actorder:"+ item.id}type="text" defaultValue={item.orden}></input>
                               
                               
                                <select id={"actcategory:" + item.id} type="text" placeholder="Producto">
                               
                                 {this.state.categories.map((item2, index) => this.getoption(item,item2,index) )
                                     
                                 }
                                 </select>
                                 <label for={"actpublic:"+ item.id}>Publico:</label>
                                 <input id={"actpublic:"+ item.id} type="checkbox" defaultChecked={item.public}></input>
                                <a target="_blank" href={"https://serviadvisor.com/" + item.image_url}>{item.image_url}</a>
                                <input id={"actimage:" + item.id}type="file"></input>


                                <button onClick={e => this.handleEditAct(item.id)}  class="btn btn-primary" value="Guardar">Guardar </button>
                                <button onClick={e => this.deleteactivity(item.id)}  class="btn btn-success" value="Eliminar"> Eliminar</button>

                            </p>
                        </div>
                    )

                })}
                </div>

                <div class="caja-crear-nueva-actividad">
                    <span>Crear Nueva Actividad</span>
                    <p>
                        <input onChange={this.handleOnchangeActivityName} value={this.state.activityName} type="text"></input>
                        <input onChange={this.handleOnchangeActivityOrder} value={this.state.activityOrder} type="text"></input>
                        <select onChange={this.handlecombochange} type="text" placeholder="Producto">
                            <option disabled selected>Selecciona Una Categoria</option>
                            {this.state.categories.map((item, index) => <option key={index} value={item.id}>{item.name} </option>)}
                        </select>
                        <input accept="image/png, image/jpeg" onChange={this.handleOnchangePhoto} type="file"></input>
                        <button onClick={this.handleNewActbutton}  class="btn btn-primary" value="Agregar">Agregar </button>
                       

                    </p>
                </div>


 <div class="caja-otras-actividades">
                <h4>Otras Actividades de ususarios</h4>
                {this.state.other.map((item, index) => {

                    return (
                        <div class="caja-crear-otras-creo">
                            <p>
                                <input id={"actname:" + item.id} type="text" defaultValue={item.name}></input>
                                <input id={"actorder:"+ item.id}type="text" defaultValue={item.orden}></input>
                               
                               
                                <select id={"actcategory:" + item.id} type="text" placeholder="Producto">
                               
                                 {this.state.categories.map((item2, index) => this.getoption(item,item2,index) )
                                     
                                 }
                                 </select>
                                 <label for={"actpublic:"+ item.id}>Publico:</label>
                                 <input id={"actpublic:"+ item.id} type="checkbox" defaultChecked={item.public}></input>
                                <a target="_blank" href={"https://serviadvisor.com/" + item.image_url}>{item.image_url}</a>
                                <input id={"actimage:" + item.id}type="file"></input>

                             

                                <button onClick={e => this.handleEditAct(item.id)}  class="btn btn-primary" value="Guardar">Guardar </button>
                                <button onClick={e => this.deleteactivity(item.id)}  class="btn btn-success" value="Eliminar"> Eliminar</button>
                            </p>
                        </div>
                    )

                })}

              
            </div>

            </div>
        )
    }
}

export default categoriesAdmin