import React,{Component} from 'react';
import axios from 'axios';
import Loader from 'react-loader-spinner';


class ofertas extends Component{
    constructor(props){
        super(props);

        this.state={
            offer:undefined,
            offerStatus:undefined,
            offerid: undefined,
            loaderSave:false,
            loaderSavepay:false
        }

        this.renderoferta= this.renderoferta.bind(this);
        this.renderpayout= this.renderpayout.bind(this);
        this.handlesearchoffer= this.handlesearchoffer.bind(this);
        this.handleeditoffer = this.handleeditoffer.bind(this);
        this.searchoffer = this.searchoffer.bind(this);
    }


    componentDidMount(){
        axios.post('https://serviadvisor.com/administrator/getofferstatus', null, {withCredentials:true}).then(res => {
            const data = res.data;

            if(!data.error){
                this.setState({
                    offerStatus:data
                })
            }
        })
    }

    handlesearchoffer(e){

        this.searchoffer();
     
    }


    searchoffer(){
        const offerId = this.state.offerid;

        const data = {
            offerId
        }

        axios.post('https://serviadvisor.com/administrator/getoffer',data, {withCredentials:true}).then(res => {
            const data = res.data;

            if(!data){
                this.setState({
                    offer:undefined
                })
                return
            }


            if(!data.error){
                this.setState({
                    offer:data
                })
            }
        })
    }   

    handleeditoffer(e){

        if(this.state.offer){

            this.setState({
                loaderSave:true
            })

            const newstatus = document.getElementById("selectofferstatus").value;

            const data={
                offerid: this.state.offer.id,
                newstatus: newstatus
            }

            axios.post('https://serviadvisor.com/administrator/editgetoffer',data, {withCredentials:true}).then(res =>{
                const data = res.data;

                if(!data.error){
                    this.setState({
                        loaderSave:false
                    })
                }
            })
    
        }

      
    }

    handlesendpayout(e){
        if(this.state.offer){

            this.setState({
                loaderSavepay:true
            })

            const data = {
                offerId: this.state.offer.id
            }

            axios.post('https://serviadvisor.com/administrator/sendpayout', data, {withCredentials:true}).then(res => {
                const resdata= res.data;

                if(!resdata.error){


                    this.setState({
                        loaderSavepay:false
                    })

                    this.searchoffer()
                }
            })
        }
    }


    onchangeofferid(e){
        const offerid = e.target.value;

        this.setState({
            offerid:offerid
        })
    }


    renderpayout(){
        if(this.state.offer.payout){
            const payout = this.state.offer.payout;
            return(
                <div>
                    <h3>Pago realizado</h3>
                    <p>id pago: {payout.id}</p>
                    <p>paypal id pago: {payout.payout_batch_id} </p>
                    <p>fecha creacion: {payout.createdAt}</p>
                    <p>fecha actualizacion: {payout.updatedAt}</p>
                </div>
            )
        }
    }

    renderoferta(){
        if(this.state.offer){
            const offer = this.state.offer;
            return(
                <div>
                    <p>Id Oferta: {offer.id}</p>
                    <p>Descripcion: {offer.description}</p>
                    <p>Precio: {offer.amount}</p>
                    <p>Id proveedor: {offer.providerId}</p>
                    <p>Id cliente: {offer.clientId}</p>
                    <p>Estado de oferta:  
                         <select id="selectofferstatus"  type="text" placeholder="Producto">
                               
                               {this.state.offerStatus.map((item2, index) => this.getoption(offer,item2,index) )
                                   
                               }
                               </select>  <button class="btn btn-primary" onClick={(e) =>  this.handleeditoffer(e)}>Guardar estado  <Loader
                  visible={this.state.loaderSave}
                  type="Oval"
                  color="#00BFFF"
                  height={25}
                  width={25}

                /></button>  </p>
                              


  
   <button class="btn btn-primary" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
  mostrar opciones de pago
  </button>
  <br></br>
  <br></br>
  <br></br>
<p>
<div class="collapse" id="collapseExample">
  <div class="card card-body">
   Realizar pago de oferta:  <button onClick={(e)=> this.handlesendpayout(e)} class="btn btn-primary">Realizar Pago a proveedor <Loader
                  visible={this.state.loaderSavepay}
                  type="Oval"
                  color="#00BFFF"
                  height={25}
                  width={25}

                /></button>
  </div>
</div>
</p>

                               {this.renderpayout()}
          
                </div>
            )
        }
    }

    getoption(item,item2,index){
        if(item.offertStatusId === item2.id) {
            return(
            <option  key={index} value={item2.id} selected>{item2.name}  </option>
            )
        }else{
            return(
            <option  key={index} value={item2.id}>{item2.name}  </option>
            )
        }
    }

    render(){
        return(
            <div>
                <h3>Modificacion directa de ofertas</h3>
                <div>
                    <input type="text" onChange={(e)=> this.onchangeofferid(e)}></input>
                    <button onClick={(e)=> this.handlesearchoffer(e) }>Buscar oferta</button>
                </div>
                <div>
                  {this.renderoferta()}

                </div>
            </div>
        )
    }
}

export default ofertas;