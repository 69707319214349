import React,{Component} from 'react';
import Axios from 'axios';
import ReactStars from 'react-stars';
import Moment from 'react-moment';

import { Modal, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import setMinutes from "date-fns/setMinutes";
import setHours from "date-fns/setHours";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import Chat from '../../chat/Chat';
import notifications from '../../../utils/notifications';

import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css'

class ContactSection extends Component{
    constructor(props){
        super(props)
        this.state={
            islogin: false,
            cropmodalshow: false,
            cropmodalshowrefuse: false,
            startDate: new Date(),
            offertId:undefined
        }
        registerLocale('es', es)
        this.openmodalDate = this.openmodalDate.bind(this);
        this.handleClientResponse = this.handleClientResponse.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.openmodalrefuse = this.openmodalrefuse.bind(this);
        this.refuseOffer = this.refuseOffer.bind(this);
    }

    componentDidMount(){
        this.setState({
            paramid: this.props.params.id,
            islogin: this.props.islogin
          })

         // this.props.sockets.callback = this.notify
    }

    notify(data) {
      console.log(this.props.user);
      notifications.buildNotification(data.type, data.message, data.accountType, data.senderid, data.profileId,data.source);
  }

 
    handleChange(date) {
        this.setState({
          startDate: date
        });
      }
    
    
      makeAssigments = () => {
    
        const data = {
          date: this.state.startDate,
          offertId: this.state.offertId
        }
    
        Axios.post('https://serviadvisor.com/assigndate/', data, { withCredentials: true }).then(response => {
          console.log(response);
          if (response.error) {
    
          } else {
            this.setState({ cropmodalshow: false });
            }
        })
      }
    
    
      refuseOffer = () => {
        const data = {
          offertId: this.state.offertId
        }
    
        Axios.post('https://serviadvisor.com/declineOffer/', data, { withCredentials: true }).then(response => {
          console.log(response);
          if (response.error) {
    
          } else {
            this.setState({ cropmodalshowrefuse: false });
            }
        })
      }
    
      openmodalDate(offertId) {
        this.setState({
          cropmodalshow: true,
          offertId:offertId
        })
      }
    
      openmodalrefuse(offertId) {
        this.setState({
          cropmodalshowrefuse: true,
          offertId:offertId
        })
      }
    
      handleClientResponse = (offertId,response) => {
        if(response){
          this.openmodalDate(offertId)
        }else{
          this.openmodalrefuse(offertId)
        }
        
      }
    
      changeaccountcheckbox(e) {
        e.preventDefault();
        const type = e.target.checked ? "1" : "2";
        const data = {
          type
        }
    
        
        Axios.post('https://serviadvisor.com/changeAccountType', data, { withCredentials: true }).then(async (res) => {
          const data = res.data;
          if (!data.error) {
            //await this.checkuserlogin();
            //this.props.history.push('./')
            window.location.reload();
          }
        })
        
      }
    
      checkboxselected(accountType) {
        if (accountType === "empresa") {
          return (
            <input onChange={(e) => this.changeaccountcheckbox(e)} type="checkbox" name="onoffswitch" class="onoffswitch-checkbox" id="myonoffswitch" checked />
          )
        } else {
          return (
            <input onChange={(e) => this.changeaccountcheckbox(e)} type="checkbox" name="onoffswitch" class="onoffswitch-checkbox" id="myonoffswitch" />
            )
        }
    
    
      }
    
    
      render() {
    
        let modalClose = () => this.setState({ cropmodalshow: false });
        let modalCloserefuse = () => this.setState({ cropmodalshowrefuse: false });
        return (
          <div>
    
                     
                      {this.props.islogin ? 
                      this.props.user.accountType==="empresa" ? <div class="alert alert-success"><p>Usa el modo Cliente para contactar proveedores.
                       haz Click en el boton para cambiar a modo Cliente
                       </p><br></br>
                      <div class="onoffswitch" data-toggle="tooltip" title=" Cambia tu tipo de cuenta">
                   {this.checkboxselected(this.props.user.accountType)}
                    <label class="onoffswitch-label" for="myonoffswitch">
                      <span class="onoffswitch-inner"></span>
                      <span class="onoffswitch-switch"></span>
                    </label>
                  </div>
                      </div> :
                        (<Chat user={this.props.user} onClientResponse={this.handleClientResponse} sockets={this.props.sockets} islogin={this.props.islogin} providerId={ this.props.providerId}></Chat>)
                        : (<div class="alert alert-success">Por favor <a href="#" data-toggle="modal" data-target="#myModal-2" data-dismiss="modal" >inicia sesión</a> o <a href="#"  data-toggle="modal" data-target="#myModal-reg" data-dismiss="modal"> crea una cuenta</a>  para acceder al el chat</div>)}
                    
    

              
              
    
    
            <Modal className="recorte pro-cal" show={this.state.cropmodalshow} onHide={modalClose}
              {...this.props}
              size="lg"
              animation={false}
    
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  Selecciona una fecha
                  <hr />
                <div className="flecha-down"></div>
              </Modal.Title>
              </Modal.Header>
              <Modal.Body>
    
              <div class="caja-calendario-popup">
                  <DatePicker
                    locale="es"
                    inline
                    selected={this.state.startDate}
                    onChange={this.handleChange}
                    showTimeSelect
                    dateFormat="MMMM d, yyyy h:mm aa"
                  />
                  <p>
                    <span>{"Fecha Seleccionada: " + this.state.startDate}</span>
                  </p>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={this.makeAssigments} className={"btn-primary"}>Aceptar</Button>
                <Button onClick={modalClose} className={"btn-success"}>Cerrar</Button>
              </Modal.Footer>
            </Modal>
    
    
    
            <Modal className="recorte pro" show={this.state.cropmodalshowrefuse} onHide={modalCloserefuse}
              {...this.props}
              size="lg"
              animation={false}
    
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  Confirmación
                  <hr />
                <div className="flecha-down"></div>
              </Modal.Title>
              </Modal.Header>
              <Modal.Body>
    
                <div >
                    Esta Seguro de rechazar la propuesta?
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={this.refuseOffer} className={"btn-primary"}>Aceptar</Button>
                <Button onClick={modalCloserefuse} className={"btn-success"}>Cerrar</Button>
              </Modal.Footer>
            </Modal>
    
    
          </div>
        );
      }
    }
    

export default ContactSection