import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import axios from 'axios';
import notifications from '../../../utils/notifications';


const getItems = count =>
    Array.from({ length: count }, (v, k) => k).map(k => ({
        id: `item-${k}`,
        content: `item ${k}`
    }));

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const deleteitem = (list, startIndex) => {
    const result = Array.from(list);

    result.splice(startIndex, 1)

    return result;
}

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "grey",

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "" : "",
    padding: grid,
    width: 300,

});





class videos extends Component {
    constructor(props) {
        super(props)
        this.state = {
            url: "http:/video.pm4",
            play: false,
            currectUrl: "",
            currentvideoType:"",
            videolist: [],
            items: [],
            providerdata: [],
            urlerror: false
        };
        this.onDragEnd = this.onDragEnd.bind(this);
        this.previewvideo = this.previewvideo.bind(this);
        this.hanldedeleteitem = this.hanldedeleteitem.bind(this);
        this.loadvideolist = this.loadvideolist.bind(this);
        this.addnewvideo = this.addnewvideo.bind(this);
        this.checkurl = this.checkurl.bind(this);
    }



    componentDidMount() {
        axios.get('https://serviadvisor.com/checkprovider', { withCredentials: true }).then(res => {

            const provider = res.data;
            if (provider.error) {

            } else {
                this.setState(
                    {
                        providerdata: provider
                    }
                )
                this.loadvideolist(provider.id)
            }
        });
        this.props.sockets.sockets.callback = this.refreshdata
    }

    refreshdata(data) {
        notifications.buildNotification(data.type, data.message, data.accountType, data.senderid, data.profileId,data.source);
    }

    onCurrectUrlChange = (evt) => {
        evt.preventDefault();
        const url = evt.target.value;
        this.setState({
            currectUrl: url
        }, () => {
            this.checkurl(url);
        })



    }

    handlesavebutton =(evt) => {
        this.updatevideolist();
    } 


    addnewvideo() {
        
        if(this.state.currectUrl=== "" || this.state.currectUrl === undefined || this.state.urlerror===true){
            return
        }
        
      

        const data = {
            url: this.state.currectUrl,
            order: this.state.items.length + 1,
            name:"",
            video_type:this.state.currentvideoType
        };

       

        axios.post('https://serviadvisor.com/addvideo', data, { withCredentials: true }).then(res => {
            const data = res.data;
            if (!data.error) {
                this.loadvideolist(this.state.providerdata.id);
            }
        })
        
    }

    loadvideolist(providerid) {

        axios.get('https://serviadvisor.com/getvideos/' + providerid, { withCredentials: true }).then(res => {
            const videodata = res.data;
            this.setState({
                items: videodata
            })
        })
    }

    datatoitems() {

    }

    handlecurrectPreviewClick = (evt) => {
        evt.preventDefault();
        
        if(this.state.currectUrl=== "" || this.state.currectUrl === undefined || this.state.urlerror===true){
            return
        }
        
        
        this.setState({
            url: this.state.currectUrl,
            play: true
        })

    }

    hanldedeleteitem(id, event) {
        event.preventDefault();
        /*
        const items = deleteitem(
            this.state.items,
            index
        );

        this.setState({
            items
        })*/
        const videoid = id;
        axios.delete('https://serviadvisor.com/videos/' + videoid, { withCredentials: true }).then(res => {
            const data = res.data;
            if (!data.error) {
                this.loadvideolist(this.state.providerdata.id)
            }

        })
    }

    clearcurrecturl= (evt) => {
        this.setState({
            currectUrl:""
        })
    }

    checkurl(url) {

        if(url ==="" || url === undefined)
        {
            this.setState({
                urlerror:false
            })
            return
        }


        var matches = url.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i);
        var m = matches && matches[1];

        if (m === "youtube" || m === "www.youtube.com" || m === "youtube.com" || m === "youtu.be") {
           this.setState({
               urlerror:false,
               currentvideoType:"youtube"
           })
         
        } else if (m === "vimeo" || m === "www.vimeo.com" || m === "vimeo.com") {
            this.setState({
                urlerror:false,
                currentvideoType:"vimeo"
            })
           
        } else {
            this.setState({
                urlerror:true,
                currentvideoType:""
            })
        }
    }


    handleaddtolist = (evt) => {
        evt.preventDefault();

        /*
 
        const list = Object.assign([], this.state.items);
 
        const item = {
            id: "item" + this.state.items.length + 1,
            url: this.state.currectUrl
        }
 
        list.push(item);
 
        this.setState({
            items: list
        })
 
        */

        this.addnewvideo();
    }

    previewvideo(videourl) {
        this.setState({
            url: videourl,
            play: true
        })
    }

    updatevideolist(){
        const items = Object.assign([], this.state.items);
        let ordernumber = items.length;
        let itemsordered= []

        console.log(items);

        items.map((item,index)=>{

            const itemunordered= {
                id:item.id,
                name:item.name,
                url:item.url,
                video_type:item.video_type,
                order:ordernumber
            }
            itemsordered.push(itemunordered);
            ordernumber--;
        })

        const data = {
            items:itemsordered
        }
        axios.post('https://serviadvisor.com/updateallvideo/',data,{withCredentials:true}).then(res => {

        })

    }

   

    handletextChange(index, event) {
        const items = Object.assign([], this.state.items);
        //this.setState({[name]: event.target.value});
    
        items[index].name = event.target.value;
    
        this.setState({
          items: items
        })
    
      
      }


    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            this.state.items,
            result.source.index,
            result.destination.index
        );

        this.setState({
            items
        }, () => {
            this.updatevideolist();
        });
    }



    render() {
        return (
            <div class="caja-lista-video">
                <div class="title-perfil-empresa espacio-top"><h4 class="azul"> Agrega Videos Para que Tus Clientes sepan mas de Ti</h4></div>
               
                <div id="player_section" class="caj-pla">
                    
                    <div class="player">                <ReactPlayer url={this.state.url}
                        controls={true}
                        playing={this.state.play} />
                    </div>
                    <div class="caja-insert-vid">
                    <input class="entra-video" type="text" placeholder="Agrega la Url de tu video" onChange={this.onCurrectUrlChange} onFocus={this.clearcurrecturl} value={this.state.currectUrl}></input>
                    {this.state.urlerror && (<i class="">X</i>)}
                    
                    <button onClick={this.handlecurrectPreviewClick} class="btn-success btn-video">Previsualizar</button>
                    <button onClick={this.handleaddtolist} class="btn-primary btn-video">Agregar a lista de videos</button>

                    
                    </div>
                </div>

                <div id="lista_videos" class="video-list">
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div class="caja-lisvid"
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={getListStyle(snapshot.isDraggingOver)}
                                >
                                    {this.state.items.map((item, index) => (
                                        <Draggable key={item.id} draggableId={"item" + item.id} index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                >

                                                    <div class="titu-vid-contenedor">
                                                        <input class="inp-tit-vid" type="text" placeholder="Titulo" onChange={evt => this.handletextChange(index,evt)} value={this.state.items[index].name} ></input>
                                                    </div>
                                                    <div class="tum-vid-contenedor">
                                                    {item.url}
                                                    </div>

                                                   
                                                   
                                                    
                                                    <div class="caja-insert-vid-btn"> 
                                                    <button onClick={(evt) => this.previewvideo(item.url)} class="btn-primary btn-video w100">Previsualizar</button>
                                                    
                                                    <button onClick={(evt) => this.hanldedeleteitem(item.id, evt)} class="btn-success btn-video w100">Eliminar</button>
                                                    <button onClick={this.handlesavebutton} className="btn-success btn-video w100">Guardar</button>
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            </div>
        )
    }
}

export default videos 