import React, { Component } from 'react';
import axios from 'axios';
import Dropzone from 'react-dropzone';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Modal, Button } from 'react-bootstrap';
import {Progress} from 'reactstrap';
import notifications from '../utils/notifications';

class ContenidoPerfilCliente extends Component {

  constructor(props) {
    super(props)
    this.state = {
      name: "",
      city: "",
      cel: "",
      email: "",
      birthdate: "",
      client: [],
      profilephotouploadprogress:0,
      profileimageloaded:false
    }
    this.handletextChange = this.handletextChange.bind(this);
    this.handleclicksubmit = this.handleclicksubmit.bind(this);
  }

  componentDidMount() {
    this.props.sockets.sockets.callback = this.refreshdata
    axios.post('https://serviadvisor.com/checkclient/', null, { withCredentials: true }).then(res => {
      console.log(res);
      const clientdata = res.data;
      this.setState({
        client: clientdata,
        name: clientdata.name,
        city: clientdata.city,
        cel: clientdata.cel,
        birthdate: clientdata.birthdate,
        email: clientdata.email
      }, () => {
        if (this.state.client.user.photo_url !== "" && this.state.client.user.photo_url !== undefined) {
          this.setState({
            croppedImageUrl: this.state.client.user.photo_url
          })

        }
      })
    })
  }

  refreshdata(data) {
    notifications.buildNotification(data.type, data.message, data.accountType, data.senderid, data.profileId);
}

  uploadprofilephoto() {
    const data = new FormData()
    const file = this.state.croppedimageblob;
    console.log(file);
    data.append('file', file)

    const config = {
      url: 'https://serviadvisor.com/addprofilephoto',
      method: 'post',
      data: data,
      withCredentials:true,
      onUploadProgress: ProgressEvent => {
        this.setState({
          profilephotouploadprogress: (ProgressEvent.loaded / ProgressEvent.total * 100)
        })
      }
    };
    axios.request(config).then(res => { // then print response status
      console.log(res.data);
      this.setState({
        profileimageloaded:true
      })
    });
  }


  handleclicksubmit = (e) => {
    const data = {
      city: this.state.city,
      cel: this.state.cel,
      birthdate: this.state.birthdate,
      name: this.state.name,
      email: this.state.email
    }

    axios.put('https://serviadvisor.com/clients', data, { withCredentials: true }).then(res => {
      const data = res.data;
      if (!data.error) {

      }
    })
  }


  handletextChange(name, event) {
    if (name === "username") {
      this.validateUsername(event);
    }


    this.setState({ [name]: event.target.value });
  }

  handleclickuploadProfilePhoto = e => {
    e.preventDefault();
    this.uploadprofilephoto();
  }

  handleOndropProfileImage = (files, rejectedfiles) => {
    if (files && files.length > 0) {
      this.setState({
        cropmodalshow: true,
        imgprofile: URL.createObjectURL(files[0])
      })
    }
  }

  handleoncrop = (crop) => {
    this.setState({ crop });
  }

  handleimageloaded = (image) => {
    this.imageRef = image;
  }

  handlecropComplete = (crop, pixelcrop) => {
    this.makeClientCrop(crop);
  }

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        this.setState({
          croppedimageblob: blob
        })
        resolve(this.fileUrl);
      }, "image/jpeg");
    });
  }






  render() {
    let modalClose = () => this.setState({ cropmodalshow: false });
    return (
      <div>




        <div class="rec-blog perfil-empresa">
          <div class="container">
            <div class="rec-blog">
              <div class="row">

                <div class="col-md-7 blog-ct ">

                  <div class="fondo-gris">
                    <div class="title-perfil-cliente espacio-top">
                    <h4 class="azul"> <span>Tu Perfil </span><hr class="naranja" /></h4>
                    </div>
                    <div class="col-cli-1">



                      <Dropzone accept='image/jpeg, image/png' maxSize={this.state.maxImagesizeGal} onDrop={this.handleOndropProfileImage}>
                        {({ getRootProps, getInputProps }) => (



                          <div {...getRootProps()}>
                            <input {...getInputProps()} />

                            {this.state.croppedImageUrl && (
                              <div class="img-det-client">
                                <img alt="Crop" style={{ height: "100%", width: "100%" }} src={this.state.croppedImageUrl} />
                              </div>
                            )}

                            {!this.state.croppedImageUrl && (
                              <div>
                                Click o Arrastra para subir un archivo!
                              <div class="text-regis">Formato PNG O JPG de un maximo de 1MB</div>
                              </div>
                            )}


                          </div>



                        )}
                      </Dropzone>
                      {this.state.profilephotouploadprogress!==0 && this.state.profileimageloaded ===false && (
                         <Progress max="100" color="success" value={this.state.profilephotouploadprogress} >{Math.round(this.state.profilephotouploadprogress,2) }%</Progress>)}
                     
                      <button onClick={this.handleclickuploadProfilePhoto} type="button" class="btn btn-success btn sel-arch">Subir Archivo</button>
                    </div>
                    <div class="col-cli-2">
                      <div class="caja-campo">
                        <h4>Tu Nombre</h4>
                        <input type="text" class="form-control perfil-cliente-ed" id="nombre" placeholder="Nombre" onChange={(e) => this.handletextChange("name", e)} value={this.state.name} autocomplete="off" /><div class="editar"></div>
                      </div>
                      <div class="caja-campo">
                        <h4>Tu Correo</h4>
                        <input type="text" class="form-control perfil-cliente-ed" id="email" placeholder="Correo electronico" onChange={(e) => this.handletextChange("email", e)} value={this.state.email} autocomplete="off" /><div class="editar"></div>
                      </div>
                      <div class="caja-campo">
                        <h4>Tu Celular</h4>
                        <input type="text" class="form-control perfil-cliente-ed" id="cel" placeholder="Numero Celular" onChange={(e) => this.handletextChange("cel", e)} value={this.state.cel} autocomplete="off" /><div class="editar"></div>
                      </div>
                      <div class="caja-campo">
                        <h4>Tu Fecha de Nacimiento</h4>
                        <input type="date" class="form-control perfil-cliente-ed" id="birthdate" placeholder="Fecha de nacimiento" onChange={(e) => this.handletextChange("birthdate", e)} value={this.state.birthdate} autocomplete="off" /><div class="editar"></div>
                      </div>
                      <div class="caja-campo">
                        <h4>Tu Ciudad</h4>
                        <input type="text" class="form-control perfil-cliente-ed" id="city" placeholder="Ciudad" onChange={(e) => this.handletextChange("city", e)} value={this.state.city} autocomplete="off" /><div class="editar"></div>
                      </div>
                      <div class="caja-campo">
                        <input onClick={this.handleclicksubmit} class="form-control btn-perfil-cliente-ed" type="button" value="Guardar"></input>
                      </div>

                    </div>
                  </div>


                </div>



                <div class="col-md-5 blog-ct let-client-sec">
                  <div class="fondo-gris">
                    <div class="title-perfil-cliente espacio-top">
                      <h4 class="azul"> <a href="#">Administra tu Cuenta</a><hr class="naranja" /></h4>
                    </div>


                    <div class="caja-campo lat-admin">
                      <h4>Tu Contraseña</h4>
                      <input type="text" class="form-control perfil-cliente-ed" id="nombre" placeholder="Contraseña" /><div class="editar"></div>
                      <input type="text" class="form-control perfil-cliente-ed" id="nombre" placeholder="Contraseña" /><div class="editar"></div>
                    </div>
                    <div class="caja-campo">
                      <h4>Cuentas Enlazadas</h4>
                      <div class="negrilla">Deseo recibir un email cuando:</div>
                      <input type="radio" name="electricidad" id="radio1" /><label for="radio1">Me envien un mensaje.</label><br />
                      <input type="radio" name="plomeria" id="radio2" /><label for="radio2">Respondan uno de mis mensajes.</label><br />
                      <hr />
                      <input type="radio" name="plomeria" id="radio2" /><label for="radio2" class="negrilla">Deseo recibir un información de novedades.</label>
                      <input type="radio" name="plomeria" id="radio2" /><label for="radio2" class="negrilla">Deseo recibir invitaciones para eventos y ofertas especiales.</label>
                    </div>
                  </div>

                </div>

                <div class="col-md-12 blog-ct">
                  <div class="enca-perfil-cli">

                    <img src="images/dib-anim.jpg" alt="" class="img-responsive" />

                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>



        <Modal className="recorte foto" show={this.state.cropmodalshow} onHide={modalClose}
          {...this.props}
          size="lg"
          animation={false}

          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Imagen de Perfil
          </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Selecciona el área a recortar</h4>
            <div>
              <ReactCrop
                onChange={this.handleoncrop}
                src={this.state.imgprofile}
                crop={this.state.crop}
                onImageLoaded={this.handleimageloaded}
                onComplete={this.handlecropComplete}>

              </ReactCrop>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={modalClose} className={"btn-primary"}>Cerrar</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default ContenidoPerfilCliente;