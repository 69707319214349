import React, { Component } from 'react';
import AdminContent from './admincontent';
import Body404 from '../../404body';
import Footer from '../../Footer';
import sockets from '../../../sockets/sockets';
import profileutils from '../../../utils/profiledata';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

class Page404 extends Component {

    constructor(props) {
        super(props)
        this.state = {
            islogin: false,
            checkuser: false,
            user: undefined,
            email: '',
            emailsign: '',
            passwordsign: '',
            password: '',
            secondpassword: '',

        }
        this.logindata = this.logindata.bind(this);
        this.handletextChange = this.handletextChange.bind(this);
        this.handleSumitClicklogin = this.handleSumitClicklogin.bind(this);
        this.checkuser = this.checkuser.bind(this);
     
    }

    logindata = (user, islogin) => {

        this.setState({
            user: user,
            islogin: islogin
        })

    }

    componentDidMount() {
        this.checkuser();
        console.log(this.props);
    }


    loginuser() {

    }




    checkuser() {
        axios.post('https://serviadvisor.com/administrador/checkuser', null, { withCredentials: true }).then(res => {
            const data = res.data;

            if (!data || data.error) {

            } else {
                const userdata = {
                    userid: data.userId,
                    username: data.username,
                    photo_url: data.photo_url,
                    role: data.role
                }
                this.logindata(userdata, true);

            }

        })
    }


    handleSumitClicklogin(event) {
        event.preventDefault()
        const logincredentials = {
            email: this.state.email,
            password: this.state.password,
        }

        axios.post('https://serviadvisor.com/administrator/login', logincredentials, { withCredentials: true }).then(response => {
            const data = response.data;

            if (!data) {
                const notify = () => toast.error("Usuario O contraseña Invalida");
                notify();
            } else {
                const userdata = {
                    userid: data.userId,
                    username: data.username,
                    photo_url: data.photo_url,
                    role: data.role
                }
                this.logindata(userdata, true);

            }


        }).catch(error => {
            const notify = () => toast.error("Usuario O contraseña Invalida");
            notify();
        })



    }

    handletextChange(name, event) {


        this.setState({ [name]: event.target.value });



    }




    render() {
        return (
            <div class="containe admin-caja">
                {this.state.islogin ? (

                    <div>
                        {this.state.user ? <AdminContent params={this.props.match.params} history={this.props.history}></AdminContent> : <div>No user Login</div>}
                    </div>
                ) :
                    <div class="login">
                        <img class="logo-login"src="/images/logo.png"/>
                        <div class="con-form">
                            <div class="titulo-log"><h4>Ingresa como administrador</h4></div>

                        
                            <div class="imput-login"><input type="text" class="form-control" id="email" placeholder="Email" onChange={(e) => this.handletextChange("email", e)} /></div>

                            <div class="imput-login"><input type="password" class="form-control" id="contraseña" placeholder="Contraseña" onChange={(e) => this.handletextChange("password", e)} /></div>
                            
                            <div class="boton-login"><button onClick={e => this.handleSumitClicklogin(e)} type="button" class="btn btn-primary">Ingresar</button></div>
                            
                        </div>

                        
                    </div>  

                }

                <ToastContainer />

            </div>
        );
    }
}

export default Page404;