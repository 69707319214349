import React, { Component } from 'react';
import Axios from 'axios';
import queryString from 'query-string';
import MenuCategories from './menu/categoriesMenu';
import MenuCities from './menu/citiesMenu';
import notifications from '../utils/notifications';

class headerpage extends Component {

    constructor(props){
        super(props)
        this.state={
            activities:[],
            categories:[],
            providerList:[],
            cities:[],
            columnCounter:0,
            categoriesisShowing: false,
            showcateMenu :false,
            catemenutype:"panel",
            showcityMenu :false,
            citymenutype:"panel"

        }
        this.showcategories= this.showcategories.bind(this);
        this.showcat = this.showcat.bind(this);
        this.hidecat = this.hidecat.bind(this);
        this.showcity = this.showcity.bind(this);
        this.hidecity = this.hidecity.bind(this);
        this.handleclickcategory = this.handleclickcategory.bind(this);
        this.handleclickcity = this.handleclickcity.bind(this);
        this.handleoOnChangeCity = this.handleoOnChangeCity.bind(this);
        this.handleOnChanceCategory = this.handleOnChanceCategory.bind(this);
        this.onMouseUpcatinput = this.onMouseUpcatinput.bind(this);
        this.setColumns = this.setColumns.bind(this);
        this.onChangetextCategory = this.onChangetextCategory.bind(this);
        this.onChangetextCity = this.onChangetextCity.bind(this);
        this.notify = this.notify.bind(this);
}



setColumns(items, type){
  
  if(type==="cat"){
    let categoriesobj=[];
    let categoriesRow=[]
    let count=0;
    let column=0;
    items.forEach((item, index) => {
  
      categoriesRow.push(item);
      count++;
      if(count>=3){
        categoriesobj.push(categoriesRow)
        categoriesRow=[]
        column++;
        count=0;
      }
    })
  
    if(categoriesRow.length>0){
      categoriesobj.push(categoriesRow)
    }

    this.setState({
      categories:categoriesobj,
      catemenutype:"panel"
    })
  }

  if(type==="city"){
    const halfnumber = items.length/2;
    let citiesobj=[];
    let citiesRow=[]
    let count=0;
 

    items.forEach(item => {
        citiesRow.push(item);
        count++;
        if(count >= halfnumber){
          citiesobj.push(citiesRow);
          citiesRow=[];
          count=0
        }
    });

    this.setState({
      cities:citiesobj,
      citymenutype:"panel"
    })
  }
  

}

    componentDidMount(){
        console.log("component did mount en browsser de home");
        Axios.get('https://serviadvisor.com/activities').then(res =>{
            const activitiesdata= res.data;
         
            this.setState({
                activities:activitiesdata
            })
        });

      
        this.props.sockets.callback=this.notify
    }

    notify(data){
          console.log(this.props.user);
          notifications.buildNotification(data.type, data.message, data.accountType, data.senderid, data.profileId, data.source);
  }


    showcategories(){
        if(this.state.categoriesisShowing){
            document.getElementById("categoriescontend").style.display = "none";
            this.setState({
                categoriesisShowing:false
            })
        }
        else{
            document.getElementById("categoriescontend").style.display = "block";
            this.setState({
                categoriesisShowing:true
            })
        }
    }

     onMouseUpcatinput = e => {
      console.log(e.target.id);
      console.log(e.target.selectionStart);
    };


    onChangetextCategory= e  => {

      let text= e.target.value;

      let firstletter = text.charAt(0);
      let textquery = text.replace("@",'');

      if(text===""){
        Axios.get('https://serviadvisor.com/categories').then(res =>{
          const categoriesdata = res.data;
       
          this.setColumns(categoriesdata,"cat");
        });
      }else if(firstletter==="@" && textquery !== ""){

  

        Axios.get('https://serviadvisor.com/GetProviders/' + textquery).then(res =>{
          const providersData = res.data;
         
         
         this.setState({
            providerList:providersData,
            catemenutype:"user"
          })
          
        });
    
      }else{
        Axios.get('https://serviadvisor.com/activitiesfilter?filter=' + text).then(res =>{
          const categoriesdata = res.data;
          
         
         this.setState({
            categories:categoriesdata,
            catemenutype:"list"
          })
          
        });
      }

    }

    showcat(){

      Axios.get('https://serviadvisor.com/categories').then(res =>{
        const categoriesdata = res.data;
      
        this.setColumns(categoriesdata,'cat');
      });

      this.setState({
        showcateMenu:true
      })


    }

    hidecat(){
      this.setState({
        showcateMenu:false
      })
    }


    
    onChangetextCity= e  => {

      let text= e.target.value;
      if(text===""){
        Axios.get('https://serviadvisor.com/cities').then(res =>{
          const citiesdata = res.data;
      
          this.setColumns(citiesdata,"city");
        });
      }else{
        Axios.get('https://serviadvisor.com/citiesfilter?filter=' + text).then(res =>{
          const citiesdata = res.data;
         
         
         this.setState({
            cities:citiesdata,
            citymenutype:"list"
          })
          
        });
      }

    }
    
    showcity(){
      Axios.get('https://serviadvisor.com/cities').then(res =>{
        const citiesdata= res.data;
        this.setColumns(citiesdata,"city");
      })

      this.setState({
        showcityMenu:true
      })
    }

    hidecity(){
      this.setState({
        showcityMenu:false
      })
    }

    handleclickcategory(event, category){
      this.props.history.push('/search/?category=' + category )
     
    }

    
  handleclickcategory(event, category,category_name){
  
 
    //this.makequeries();
    this.setState({
      category:category,
      categoryname:category_name
    }, this.sendparams);
  
    }
  
    handleclickcity(event, city, cityName){
      
     
    
      //this.makequeries();
      this.setState({
        city:city,
        cityname:cityName
      }, this.sendparams);
    }
  
  
    clearparams(){
  
      this.setState({
        city:0,
        cityname:"",
        category:0,
        categoryname:""
      }, this.sendparams);
    }
  
    sendparams(){
      const category = this.state.category;
      const city = this.state.city;
      const query = this.state.q;
      const params=[];
      if(this.state.category && this.state.category !== 0){
        params.category = this.state.category;
      }
  
      if(this.state.city && this.state.city !== 0){
        params.city = this.state.city;
      }
  
      //this.props.history.push(".?city=" + city + "&category=" + category + "&q=" + query);
      const stringified = queryString.stringify(params);
      this.props.history.push("./search/?"+ stringified);
    
  
      this.setState({
        params:params
      })
    }

    onhandleSubmitsearch= (e) =>{
      e.preventDefault();

      this.props.history.push("./search/?category");
    
    }


  handleoOnChangeCity = () =>{
    Axios.get('https://serviadvisor.com/cities').then(res =>{
      const citiesdata= res.data;
      this.setState({
        cities:citiesdata
      })
    })
  }

  handleOnChanceCategory = () => {
    Axios.get('https://serviadvisor.com/categories').then(res =>{
      const categoriesdata = res.data;

      this.setState({
        categories: categoriesdata
      });
    });
  }


  

    

  render() {
   

    return (
      <div>
        <div id="content" class="formbuscador">
          <div class="container">
            <div class="formcot">
              <form>
                <div class="form-row align-items-center">
                 {/*  <div class=" my-1">
                    <label class="sr-only" for="inlineFormInputName">Elige Tu Ciudad</label>
                    <div class="input-group">
                    <input onChange={this.onChangetextCity} onBlur={this.hidecity} onFocus={this.showcity} type="text" class="form-control" autocomplete="off" id="inlineFormInputName" placeholder="Elige Tu Ciudad"/>
                    <MenuCities onhandleclick={this.handleclickcity} mode={this.state.citymenutype} cities={this.state.cities} show={this.state.showcityMenu}></MenuCities>
                    </div></div>
                    */}
                    <div class="my-2">
                      <label class="sr-only" for="inlineFormInputGroupUsername">¿Qué Necesitas?</label>
                      <div class="caja-">
                        

                        
                        <input onChange={this.onChangetextCategory} onBlur={this.hidecat} onFocus={this.showcat} type="text" class="form-control" id="inlineFormInputGroupUsername" autocomplete="off" placeholder="¿Qué Necesitas?"/>
                        <MenuCategories providerList={this.state.providerList}  onhandleclick={this.handleclickcategory} mode={this.state.catemenutype} categories={this.state.categories} show={this.state.showcateMenu}></MenuCategories>
     </div>
                    

                      </div>

                     

                      <div class="my-3" >
                        <button onClick={this.onhandleSubmitsearch} type="submit" class="btn btn-primary busq"><a href=""><i class="fa fa-search"></i></a> Buscar</button>
                       
                      </div>


                    </div>
  </form>
                    
                </div>


            

   </div>


   
            </div>



            <div class="rec-blog">
                  <div class="container">
                      <div class="rec-blog-inner">
                          <div class="row gri-categ">


                            {this.state.activities.map((item,index)=>{
                                
                                let html;
                               
                               
                               
      
                                    html =  <div class="gri-cat blog-ct">
                                    <a href={"search/?category=" + item.id}><img src={item.image_url} alt="" class="img-responsive"/></a>
                                    <div class="blog-ct-title">
                                        <a href="">{item.name}</a>
                                    </div>
                                </div>
                               
                              

                                return(
                                    <div>
                                        {html}
                                    </div>        
                                  
                                );
                            })}



<div>
                              <div class="gri-cat blog-ct">
                                  <a href="/otrascategorias"><img src="images/Fotos Serviadvisor/30.jpg" alt="" class="img-responsive"/></a>
                                  <div class="blog-ct-title">
                                      <a href="/otrascategorias">Busco Servicios</a>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              </div>

             
          </div>
          );
      }
    }
    
export default headerpage;