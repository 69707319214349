import React, { Component } from 'react'
import axios from 'axios';
import Slider from "react-slick";


class Consejos extends Component {
    constructor(props) {
        super(props)
        this.state = {
            post: [],
            categoriespost: [],
            activeItemIndex: 0,
        }
        this.getcategoriespost = this.getcategoriespost.bind(this);
    }

    componentDidMount() {
        this.getadvices();
        this.getcategoriespost();
    }

    getadvices() {
        axios.get('https://serviadvisor.com/advice').then(res => {
            const data = res.data;

            this.setColumns(data)




        })
    }


    getcategoriespost() {
        axios.get("https://serviadvisor.com/categoriespost").then(res => {
            const data = res.data;
            this.deletelowcount(data);
        })
    }


    async deletelowcount(categoriespost) {
        const items = Object.assign([], categoriespost);
        let itemspost = []
        

        await items.map(async (item, index) => {
            console.log(item);
            if (item.blogPosts.length > 0) {
                
                let post =  await this.setColumns(item.blogPosts);
                item.blogPosts = [];
                post.forEach((p,index)=>{
                    item.blogPosts.push(p)
                })

                
               
                
               

                itemspost.push(item);
            }
        })

        this.setState({
            categoriespost: itemspost
        })

        console.log(itemspost);
    }






     async setColumns(data) {

        console.log(data)
        let dataobj = [];
        let datarow = []
        let count = 0;
        let column = 0;
        data.forEach((item, index) => {

            datarow.push(item);
            count++;
            if (count > 1) {
                dataobj.push(datarow)
                datarow = []
                column++;
                count = 0;
            }
        })

        if (dataobj.length === 0) {
            dataobj.push(datarow)
        }

        return dataobj;

        
    }

    render() {

        const settings = {
            dots: true,
            infinite: this.state.categoriespost.length > 1,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1

        };

        return (
            <div class="consejos_seccion">
                <h2 class="tit-consejos1"><a href="blog/"> Consejos </a></h2>
                <div class="container-fu">
                    <Slider {...settings}>
                        {this.state.categoriespost.map((item, index) => {
                            return (
                                <div class="col-cons ">
                                   
                                   <h4 class="tit-consejos"><a href="blog/"> {item.name} </a></h4>

                                    {item.blogPosts.map((item2, index2) => {
                                        return (
                                            <div class="col-md-12 dis-cons">
                                                
                                                {item2.map((item3,index3)=> 
                                                      <div class="caja-con">
                                                      <div class="con-fot"><a href={"/blog/" + item3.id + "/post"}><img src={"https://serviadvisor.com/blogphoto/" + item3.image_url} alt="pav" /></a></div>
                                                      <div class="tit-con"><a href={"/blog/" + item3.id + "/post"}>{item3.title}</a></div>
                                                      <div class="text-con">{item3.shorttext}</div>
                                                      <div class="btn-leer"><a href={"/blog/" + item3.id + "/post"}>Leer más <i class="fas fa-angle-double-right"></i> </a></div>
                                                  </div>
                                                
                                                )}
                                          

                                            </div>
                                        )
                                    })}

                                  
                                </div>
                            )
                        })}
                    </Slider>
                </div>
            </div>
        )
    }
}

export default Consejos;