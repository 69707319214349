import React, { Component } from 'react';
import Axios from 'axios';
import queryString from 'query-string';
import ProviderCard from './buscador/providercard';
import notifications from '../utils/notifications';
import Advertising from './menu/advertising';
import CarrouselLateral from './menu/carrousellateral';
import { param } from 'jquery';


class ContenidoBusqueda extends Component {

  constructor(props) {
    super(props)

    this.state = {
      providers: [],
      page:0,
      platinumFirst:false,
      actualorder:"",
      category:"",
      SearchNearby: false,
      SearchActive: false,
      params : [],
     
    }


    this.searchproviders = this.searchproviders.bind(this);
    this.nextpage = this.nextpage.bind(this);
    this.handlecalificationChange = this.handlecalificationChange.bind(this);
    this.handlepriceChange = this.handlepriceChange.bind(this);
    this.resetCombos= this.resetCombos.bind(this);
    this.notify = this.notify.bind(this);
    this.setPosition = this.setPosition.bind(this);
  }

  componentDidMount() {
 
    this.searchprovidersonmount();
    this.props.sockets.callback=this.notify

  
  }

  notify(data){
    console.log(this.props.user);
    notifications.buildNotification(data.type, data.message, data.accountType, data.senderid, data.profileId, data.source);
}

 

  nextpage =() => {
    const searchParams = new URLSearchParams(this.props.location.search);
    let pagenum = this.props.page;
    pagenum = pagenum + 1;

    if (searchParams) {
      Axios.get("https://serviadvisor.com/providerfilterlimit/?" + searchParams + "&page="+ pagenum).then(res => {
        let data = []
        data =  res.data;
        const providersdata = this.state.providers.slice()
        
        
      
        this.setState({
          providers: providersdata.concat(data)
        })
        this.setState({
          page:pagenum
        })
        this.props.onSetpage(pagenum);
      
      })
    } else {
      Axios.get("https://serviadvisor.com/providerfilterlimit/?page=" + pagenum).then(res => {
        const data = res.data;
       
        const providersdata = this.state.providers.slice()
        providersdata.concat(data);
      
        this.setState({
          providers: providersdata
        });
        this.setState({
          page:pagenum
        })
        this.props.onSetpage(pagenum);
     
      })
    }

  } 

  searchproviders(params) {
   
   
    if (params) {
      Axios.get("https://serviadvisor.com/providerfilterlimit/?" + params).then(res => {
        const data = res.data;
       
        this.setState({
          providers: data
        })
      })
    } else {
      Axios.get("https://serviadvisor.com/providerfilterlimit/").then(res => {
        const data = res.data;
       
        this.setState({
          providers: data
        })
      
      })
    }


  }



  searchprovidersonmount() {

    const searchParams = new URLSearchParams(this.props.location.search);
   

    if(searchParams.get("category")){
      const categoryid = searchParams.get("category");
      Axios.get("https://serviadvisor.com/activities/" + categoryid).then(res =>{
        const categoryname= res.data[0].name;
        const categoryId = res.data[0].id
        const category = {
          name:categoryname,
          id:categoryId
        }

        this.setState({
          category:category.id
        })
        this.props.onsetCategory(category);
      })  
    }

    if(searchParams.get("city")){
      const cityid = searchParams.get("city");
      Axios.get("https://serviadvisor.com/cities/" + cityid).then(res =>{
        const cityname= res.data[0].name;
        const cityId = res.data[0].id
        const city= {
          name:cityname,
          id:cityId
        }
      
        this.props.onSetcity(city);
      })  
    }
  
    if (searchParams) {
      Axios.get("https://serviadvisor.com/providerfilterlimit/?" + searchParams).then(res => {
        let data = []
        data =  res.data;
       
        this.setState({
          providers: data
        })
      })
    } else {
      Axios.get("https://serviadvisor.com/providerfilterlimit/").then(res => {
        const data = res.data;
       
        this.setState({
          providers: data
        })
      
      })
    }


  }

  handleClickProviderContact(event, id) {
    event.preventDefault();
    this.props.history.push('/empresa/' + id + '/contacto');
  }



  handlecalificationChange = (e) => {
    const value = e.target.value;
    this.setState({
      actualorder:value
    }, () => {
      this.onchargeOrder(value)
    })
   
  }

  handlepriceChange = (e) => {
    const value = e.target.value;
    this.setState({
      actualorder:value
    }, () => {
      this.onchargeOrder(value)
    })
   
  }

  handlevipChange = (e) => {
    let value = e.target.value;
    let ordervalue;
    
    if(value==="true"){
      ordervalue=true
    }else{
      ordervalue=false
    }
    this.setState({
      platinumFirst: ordervalue
    }, () => {
      this.onchargeOrder(this.state.actualorder)
    })
   
  }


  handleOnchangeNearbrSearch = (e) => {
    let value = e.target.checked;

    this.setState({
      SearchNearby: value
    }, () => {
      this.onchargeOrder(value)
    })

  }


  handleOnchangeActiveSearch = (e) => {
    let value = e.target.checked;

    this.setState({
      SearchActive: value
    }, () => {
      this.onchargeOrder(value)
    })

  }

  onchargeOrder(value){
    const params=[];
    
    if(this.props.categoryId && this.props.categoryId !== 0){
      params.category = this.props.categoryId;
    }

    if(this.props.cityId && this.props.cityId !== 0){
      params.city = this.props.cityId;
    }

    if(this.state.platinumFirst){
      params.vip = "true" 
    }

    if(this.state.SearchActive){
      params.ActiveSearch = "true" 
    }

    params.order= this.state.actualorder; 


    this.setState({
      params : params
    }, () => {
      if(this.state.SearchNearby){
        navigator.geolocation.getCurrentPosition(this.setPosition);
      }else{
        this.setparams(params);
      }
    })  
  }

  
  setPosition(position){
    let long;
    let lat;

    lat = position.coords.latitude;
    long = position.coords.longitude;

    let params = Object.assign([], this.state.params);
    

    params.NearbySearch = true;
    params.userLat = lat;
    params.userLong = long;

    
    this.setparams(params);
  
  }


  setparams(params){

    this.setState({
      page:0
    }, ()=> {
      this.props.onSetpage(0);
      const stringified = queryString.stringify(params);
      this.props.history.push(".?"+ stringified);
      this.props.onUrlhaschange(stringified);
      console.log("send params");
    })
    
  }

  resetCombos(){
    
  }





  render() {
    return (
      <div>




      <div class="rec-blog perfil-empresa">
        <div class="container">
          <div class="rec-blog">
            <div class="row">

              <div class="col-md-9 blog-ct espacio-centro">


                <div class="vestidos-ordenar">
                  <div class="tit-cate"><span>Categorías</span> <span class="flec"><i class="fas fa-sort-down"></i></span></div>
                  <div class="tit-cate-ordena">Ordenar por</div>


                  <form>
                    <div class="vestidos-nov">
                      <div class="acho my-1">
                        <label class="sr-only" for="inlineFormInputName">Recomendados Bodadvisor</label>
                        <select onChange={this.handlevipChange} type="text" class="form-control" id="recomentado" placeholder="Ciudad"> 
                                    <option   disabled selected>Recomendados serviadvisor</option>
                                      <option  value="true">Recomendado</option>
                                      <option  value="false">No Recomendado</option>
                                    </select>
                       
                            
                      </div>
                      <div class="acho my-2">
                        <label class="sr-only" for="inlineFormInputGroupUsername">Calificación Clientes</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                          <select  onChange={this.handlecalificationChange} type="text" class="form-control mitad-2" id="calificacion" placeholder="Ciudad"> 
                                    <option  disabled selected>Calificación</option>
                                      <option value="calificationASC">Menor a mayor</option>
                                      <option value="calificationDESC">Mayor a menor</option>
                                    </select>
                          </div>

                        </div>
                      </div>
                      <div class="acho my-3">
                        <label class="sr-only" for="inlineFormInputGroupUsername">Precio</label>
                        <div class="input-group">
                          <div class="input-group-prepend"> 
                          <select onChange={this.handlepriceChange} type="text" class="form-control mitad-2" id="precio" placeholder="Ciudad"> 
                                    <option  disabled selected>Precio</option>
                                      <option  value="priceASC">Menor a mayor</option>
                                      <option value="priceDESC">Mayor a menor</option>
                                    </select>
                          </div>
                        </div>
                      </div>



                      <div class="acho" style={{marginLeft:"120px"}}>
                        <label>Ordenar Por cercanía</label>
                          <div class="input-group-prepend"> 
                          <input class="form-check-input " onChange={this.handleOnchangeNearbrSearch} checked={this.state.SearchNearby} type="checkbox"></input>
                          </div>
                       
                      </div>

                      <div class="acho" style={{marginLeft:"0px"}}>
                        <label>Ordenar Por Activo</label>
                          <div class="input-group-prepend"> 
                          <input class="form-check-input " onChange={this.handleOnchangeActiveSearch} checked={this.state.SearchActive} type="checkbox"></input>
                          </div>
                       
                      </div>

                   

                      <div class="limp"></div>

                    </div>

                  </form>
                </div>


                {this.state.providers.length===0 && (<div class="alert alert-success"><span >No se encontraron proveedores en Esta búsqueda</span></div>)}
                {this.state.providers.map((item, index) => {
                  return (<div>
                    {item.isActive}
                    <ProviderCard category={this.state.category !=="" ? this.state.category : undefined} user={this.props.user}  history={this.props.history} index={index} item={item} onprovidercontac={this.handleClickProviderContact} ></ProviderCard>
                  </div>
                  )
                })}

              
<div class="limp"></div>
<button onClick={this.nextpage} type="button" class="cargar-mas">Cargar Más Proveedores</button>
              
              </div>

              <div class="col-md-3 blog-ct let-client-sec">
                <div class="azul-border"><h3>Publicidad</h3></div>

                <div class="publicidad-lateral">
                <Advertising adspaceid="1"></Advertising>
                </div>

                <div class="publicidad-lateral">
               <Advertising adspaceid="1"></Advertising>
                </div>
                <div class="letras-pequeñas-publ"><p>Espacio Publicitario ¿Quieres anunciarte con nosotros?</p></div>

                <div class="azul-border"><h3>Recomendados</h3></div>

                <div class="publicidad-lateral">
                 <CarrouselLateral></CarrouselLateral>
                </div>


              </div>




            </div>
          </div>
        </div>
      </div>




    </div>
    );
  }
}

export default ContenidoBusqueda;