import React, { Component } from 'react';
import { Redirect, Route, withRouter } from 'react-router-dom';
import GoogleLogin from 'react-google-login';
//import FacebookLogin from 'react-facebook-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Toolbarmenu from './menu/toolbarMenu'

const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
const validpassword = RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})");

class HeaderEditar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      secondpassword: '',
      secondpasswordsign: '',
      validationText: '',
      redirect: false,
      user: undefined,
      islogin: false,
      errors: {
        email: true,
        emailsign: true,
        passdis: true,
        passinvalid: true,
      }
    }

    this.responseGoogle = this.responseGoogle.bind(this);
    this.responseFacebook = this.responseFacebook.bind(this);
    this.responseFacebooklogin = this.responseFacebooklogin.bind(this);
    this.responseGooglelogin = this.responseGooglelogin.bind(this);



    this.handleSumitClick = this.handleSumitClick.bind(this);
    this.handleSumitClicklogin = this.handleSumitClicklogin.bind(this);
    this.handletextChange = this.handletextChange.bind(this);
    this.validatePassword = this.validatePassword.bind(this);
    this.validateUsername = this.validateUsername.bind(this);
    this.setRedirect = this.setRedirect.bind(this);
    this.renderRedirect = this.renderRedirect.bind(this);
    this.checkuserlogin = this.checkuserlogin.bind(this);
    this.loginbuttons = this.loginbuttons.bind(this);
    this.renderprofilenav = this.renderprofilenav.bind(this);
    this.validateemail = this.validateemail.bind(this);
    this.checkerrors = this.checkerrors.bind(this);
    this.opennav = this.opennav.bind(this);
    this.clearfields = this.clearfields.bind(this);
    this.checkboxselectedheader = this.checkboxselectedheader.bind(this);
    this.changeaccountcheckboxheader = this.changeaccountcheckboxheader.bind(this);
    this.notify = this.notify.bind(this);
  }

  notify(data){
    console.log("notifychat get notifications");
      this.getnotifications();  
  }

  getnotifications(){
   

    const data = {
      page:0
  }

  axios.post('https://serviadvisor.com/getactivenoticount/',null,{withCredentials:true}).then(res => {
    const notifications= res.data;
    this.setState({
      notificationsCount:notifications
    })
})

    axios.post('https://serviadvisor.com/notificationspagination/',data,{withCredentials:true}).then(res => {
     
      if(!res.data.error){
        const notifications= res.data;
        this.setState({
          notifications:notifications
      })
      }

  
  })
  }


  loginbuttons = () => {

    const signupbutton = <div><button onClick={(evt) => this.clearfields(evt)} type="button" className="btn" data-toggle="modal" data-target="#myModal-reg">Registrarse</button>
      <button onClick={(evt) => this.clearfields(evt)} type="button" className="btn" data-toggle="modal" data-target="#myModal-2">Iniciar Sesión</button></div>;
    const logoutbutton = <button onClick={this.handlelogaoutclick} type="button" className="btn" >Cerrar Sesión</button>

    let html;

    if (this.props.islogin) {
      html = logoutbutton

      return (
        <div>
          {html}
        </div>
      )
    }
    else {
      html = signupbutton

      return (
        <div>
          {html}
        </div>
      )
    }
  }


  clearfields(evt) {
    this.setState({
      email: '',
      emailsign: '',
      passwordsign: '',
      password: '',
      secondpassword: ''
    })

  }


  checkuserlogin() {
    axios.get('https://serviadvisor.com/user', { withCredentials: true }).then(res => {
      console.log(res.data);
      if (res.data[0]) {
        const data = res.data[0];
        const userdata = {
          userid: data.id,
          username: data.username,
          photo_url: data.photo_url,
          active: data.active,
          accountType: data.accountType
        }

        console.log(userdata);
        this.props.logindata(userdata, true);

        console.log("condicional data");


      }
    })
  }

  logaout() {
    axios.get('https://serviadvisor.com/logout', { withCredentials: true }).then(res => {
      console.log("log out");

      this.setState({
        islogin: false,
        user: undefined
      }
      )

      this.props.history.push('/');

    })
  }

  handlelogaoutclick = e => {
    this.logaout();
  }

  componentDidMount() {

    this.checkuserlogin();
    this.getnotifications();




    this.props.sockets.notifyListcallback=this.notify
  }

  handletextChange(name, event) {


    this.setState({ [name]: event.target.value });

    if (name === "secondpasswordsign") {
      this.validateSecondPassword(event);
    }

    if (name === "username") {
      this.validateUsername(event);
    }

    if (name === "password") {
      //  this.validatePassword(event);
    }

    if (name === "passwordsign") {
      this.validatePassword(event);
    }

    if (name === "email") {
      this.validateemail(event, false);
    }

    if (name === "emailsign") {
      this.validateemail(event, true);
    }

  }


  validateemail(event, sign) {
    const email = event.target.value
    const checkemail = validEmailRegex.test(event.target.value)
    const errors = this.state.errors
    if (sign) {
      errors.emailsign = checkemail
    } else {
      errors.email = checkemail
    }

    this.setState({ errors: errors });
  }

  validateSecondPassword(event) {
    const password = this.state.passwordsign;
    const second = event.target.value;


    let errors = this.state.errors;
    if (second === password) {
      errors.passdis = true;
    }
    else {

      errors.passdis = false;

    }
    this.setState({
      errors: errors
    })
  }

  validatePassword(event) {
    const password = event.target.value;
    let checkpass = validpassword.test(password);
    const errors = this.state.errors;
    errors.passinvalid = checkpass;

    this.setState({
      errors
    })
  }

  validateUsername(event) {

  }



  responseGoogle = (response) => {

    const data = {
      accounttype: "cliente",
      access_token: response.accessToken
    }


    axios.post('https://serviadvisor.com/oauth/google', data, { withCredentials: true }).then(res => {

      if (res.data.error) {
        const notify = () => toast.error(res.data.error);
        notify();
      } else {

        const data = res.data;
        const userdata = {
          userid: data.userid,
          username: data.username,
          photo_url: data.photo_url,
          active: '',
          accountType: data.accountType
        }

        this.props.logindata(userdata, true);
        this.signupclose.click();
      }



    })

  }

  responseGooglelogin = (response) => {

    const data = {
      accounttype: "cliente",
      access_token: response.accessToken
    }


    axios.post('https://serviadvisor.com/oauth/googlelogin', data, { withCredentials: true }).then(res => {



      if (res.data.error) {
        const notify = () => toast.error(res.data.error);
        notify();
      } else {

        const data = res.data;
        const userdata = {
          userid: data.userid,
          username: data.username,
          photo_url: data.photo_url,
          active: '',
          accountType: data.accountType
        }

        this.props.logindata(userdata, true);
        this.loginclose.click();
      }

    })

  }

  responseFacebook = (response) => {

    const data = {
      accounttype: "cliente",
      access_token: response.accessToken
    }


    axios.post('https://serviadvisor.com/oauth/facebook', data, { withCredentials: true }).then(res => {

      if (res.data.error) {
        const notify = () => toast.error(res.data.error);
        notify();
      } else {

        const data = res.data;
        const userdata = {
          userid: data.userid,
          username: data.username,
          photo_url: data.photo_url,
          active: '',
          accountType: data.accountType
        }

        this.props.logindata(userdata, true);
        this.signupclose.click();
      }
    }).catch(error => {
      const notify = () => toast("error");
      notify();
    });
  }

  responseFacebooklogin = (response) => {

    const data = {
      accounttype: "cliente",
      access_token: response.accessToken
    }


    axios.post('https://serviadvisor.com/oauth/facebooklogin', data, { withCredentials: true }).then(res => {



      if (res.data.error) {
        const notify = () => toast.error(res.data.error);
        notify();
      } else {

        const data = res.data;
        const userdata = {
          userid: data.userid,
          username: data.username,
          photo_url: data.photo_url,
          active: '',
          accountType: data.accountType
        }

        this.props.logindata(userdata, true);
        this.loginclose.click();
      }

    })
  }

  checkerrors() {
    if (!this.state.errors.emailsign) {
      return false;
    }

    if (!this.state.errors.passdis) {
      return false;
    }

    if (!this.state.errors.passinvalid) {
      return false;
    }

    return true;
  }

  opennav = (e) => {
    this.props.slideout.toggle();
  }

  handleSumitClick(event) {
    event.preventDefault()

    if (!this.checkerrors()) {
      return
    }

    const newuser = {
      accounttype: "cliente",
      email: this.state.emailsign,
      password: this.state.passwordsign,
    }

    axios.post('https://serviadvisor.com/singup', newuser).then(response => {

      if (response.data.error) {
        const notify = () => toast.error(response.data.error);
        notify();
      } else {

        const logincredentials = {
          email: this.state.emailsign,
          password: this.state.passwordsign
        }

        axios.post('https://serviadvisor.com/login', logincredentials, { withCredentials: true }).then(res => {

          if (res.data.error) {
            const notify = () => toast.error(res.data.error);
            notify();
          } else {

            const data = res.data.userData;
            const userdata = {
              userid: data.userid,
              username: data.username,
              photo_url: data.photo_url,
              active: '',
              accountType: data.accountType
            }

            this.props.logindata(userdata, true);
            this.signupclose.click();
          }

        })
      }

    })
  }


  handleSumitClicklogin(event) {
    event.preventDefault()
    const logincredentials = {
      email: this.state.email,
      password: this.state.password,
    }

    axios.post('https://serviadvisor.com/login', logincredentials, { withCredentials: true }).then(response => {
      const data = response.data.userData;
      if (!data) {
        const notify = () => toast.error("Usuario O contraseña Invalida");
        notify();
      } else {
        const userdata = {
          userid: data.userid,
          username: data.username,
          photo_url: data.photo_url,
          active: data.active,
          accountType: data.accountType
        }
        this.props.logindata(userdata, true);
        this.loginclose.click();

      }


    }).catch(error => {
      const notify = () => toast.error("Usuario O contraseña Invalida");
      notify();
    })
  }

  setRedirect = () => {
    this.setState({
      redirect: true
    })
  }

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to='/registro-empresa2' />
    }
  }

  changeaccountcheckboxheader(e) {
    e.preventDefault();
    const type = e.target.checked ? "1" : "2";
    const data = {
      type
    }


    axios.post('https://serviadvisor.com/changeAccountType', data, { withCredentials: true }).then(async (res) => {
      const data = res.data;
      if (!data.error) {
        //await this.checkuserlogin();
        //this.props.history.push('./')

        if (type === "2") {
          

          var url = window.location.toString();
          //window.location = url.replace(/perfil/, 'perfil-cliente');
          window.location.href="/perfil-cliente/propuestas"
        }else{
          var url = window.location.toString();
          //window.location = url.replace(/perfil-cliente/, 'perfil');
          window.location.href="/perfil/propuestas"
        }

        //window.location.href="/"
      }
    })

  }

  checkboxselectedheader(accountType) {
    if (accountType === "empresa") {
      return (
        <input onChange={(e) => this.changeaccountcheckboxheader(e)} type="checkbox" name="header" class="onoffswitch-checkbox" id="myonoffswitchheader" checked />
      )
    } else {
      return (
        <input onChange={(e) => this.changeaccountcheckboxheader(e)} type="checkbox" name="header" class="onoffswitch-checkbox" id="myonoffswitchheader" />
      )
    }


  }

  renderprofilenav() {

    if (this.props.user) {
      return (
        <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">


          <ul className="nav navbar-nav navbar-right">

            <li>
              <div className="tit-fot">
                <a href="#">
                  {this.props.user.photo_url && (<a href="#"><img src={this.props.user.photo_url} alt="pav" /></a>)}
                  {!this.props.user.photo_url && (<a href="#"><img src="/images/fotoperfil.png" alt="pav" /></a>)}
                </a>
              </div>

              <li className="tit-cuenta-tipo"><div className="tit-cuenta-tipo-tit">Cuenta:</div>

                <div class="onoffswitch" title=" Cambia tu tipo de cuenta">
                  {this.checkboxselectedheader(this.props.user.accountType)}
                  <label class="onoffswitch-label" for="myonoffswitchheader">
                    <span class="onoffswitch-inner"></span>
                    <span class="onoffswitch-switch"></span>
                  </label>
                </div>




              </li>

              <li className="tit-nombre"><a href="#">Hola, {this.props.user.username} </a></li>
              <li className="tit-cuenta"><a href={this.props.user.accountType === "cliente" ? ("/perfil-cliente/propuestas") : ("/perfil/propuestas")}>Mi cuenta</a></li>


            </li>

          </ul>

        </div>

      )
    } else {
      return (
        <div></div>
      )
    }



  }

  render() {
    return (
      <div>
        <div id="header">
          <div class="top">
            <div class="container">
              <ul class="top-support">
                <li><span>Bienvenid@s a ServiAdvisor</span></li>

              </ul>
              <div class="top-control">

                {this.loginbuttons()}
              </div>
              <div class="clearfix"></div>
            </div>
          </div>

          <div id="believe-nav">
            <div class="container">
              <div class="min-marg">
                <nav class="navbar navbar-default">

                  <div class="navbar-header">
                    <button onClick={this.opennav} type="button" class="navbar-toggle collapsed" data-toggle="collapse" >
                      <span class="sr-only">Toggle navigation</span>
                      <span class="icon-bar"></span>
                      <span class="icon-bar"></span>
                      <span class="icon-bar"></span>
                    </button>
                    <a class="navbar-brand" href="/"><img src="/images/logo.png" class="logo" /></a>
                  </div>


                  {this.renderprofilenav()}


                </nav>
              </div>
              <div class="srch-form">
                <form class="side-search">
                  <div class="input-group">
                    <input type="text" class="form-control search-wid" placeholder="Search Here" aria-describedby="basic-addon2" />
                    <a href="" class="input-group-addon btn-side-serach" id="basic-addon2"><i class="fa fa-search"></i></a>
                  </div>
                </form>
              </div>
            </div>
          </div>


          <div id="content" class="bannerheader">
            <div id="cat-nav">
              <div class="container">
                <nav class="navbar navbar-default">

                  <div class="navbar-header">
                    <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#cat-nav-mega">
                      <span class="sr-only">Toggle navigation</span>
                      <span class="icon-bar"></span>
                      <span class="icon-bar"></span>
                      <span class="icon-bar"></span>
                    </button>
                  </div>


                  <Toolbarmenu></Toolbarmenu>

                </nav>
              </div>
            </div>



            <div class="cont">



            </div>
          </div>
        </div>




        <div className="modal fade" id="myModal-reg" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button ref={input => this.signupclose = input} type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <h4 className="modal-title" id="myModalLabel">CREAR MI CUENTA</h4>
                <hr />
                <div className="flecha-down"></div>
              </div>
              <div className="modal-body">

                <div className="con-fac">Regístrate y accede a todos los servicios que ServiAdvisor tiene para ti<br /><br /><br />

        ¿Ya tienes cuenta? <button onClick={(evt) => this.clearfields(evt)} type="button" class="btn-regs-pop" data-toggle="modal" data-target="#myModal-2" data-dismiss="modal" aria-label="Close"><span>Acceder a tu cuenta</span></button>


                </div><br />

                <FacebookLogin
                  appId="311566446451744"
                  autoLoad={false}
                  fields="name,email,picture"
                  onClick={this.componentClicked}
                  render={renderProps => (
                    <button class="btn btn-social ext-reg-btn fb-btn" data-pa-name="intro_login_fb" onClick={renderProps.onClick}>
                      <div class="text-con"> <i class="fab fa-facebook-square"></i> <span> Continuar con Facebook</span>
                      </div>
                    </button>

                  )}
                  //onClick={this.componentClicked}
                  callback={this.responseFacebook}></FacebookLogin>

                <br />
                <GoogleLogin
                  clientId="154537549270-knt6kr4a3epb30eed3ntrcl35dtsnt1p.apps.googleusercontent.com"
                  buttonText="Login Con Google"
                  render={renderProps2 => (
                    <button class="btn btn-social ext-reg-btn gp-btn" data-pa-name="intro_login_google" id="gp-auth-btn" onClick={renderProps2.onClick} >
                      <div class="text-con"> <i class="fab fa-google-plus"></i> <span>Continuar con Google</span> </div></button>

                  )}
                  onSuccess={this.responseGoogle}
                  onFailure={this.responseGoogle}
                  cookiePolicy={'single_host_origin'}
                ></GoogleLogin>




                <hr />
                <div className="con-form">
                  <div className="tit-ses">Ingresa con tu dirección de correo electronico</div>
                  <input type="text" className="form-control" id="email" placeholder="Email" onChange={(e) => this.handletextChange("emailsign", e)} value={this.state.emailsign} />
                  {!this.state.errors.emailsign && (<div class="row"><i class="fas fa-times"></i><span>Por favor ingrese un correo valido</span></div>)}
                  <input type="password" className="form-control" id="contraseña" placeholder="Contraseña" onChange={(e) => this.handletextChange("passwordsign", e)} value={this.state.passwordsign} />
                  {!this.state.errors.passinvalid && (<div class="row"><i class="fas fa-times"></i><span>La contraseña debe contener al menos: una letra mayuscula, una letra minuscula, un numero y ser mayor de 6 caracteres</span></div>)}
                  <input type="password" className="form-control" id="contraseña" placeholder="Confirmar Contraseña" onChange={(e) => this.handletextChange("secondpasswordsign", e)} value={this.state.secondpassword} />
                  {!this.state.errors.passdis && (<div class="row"><i class="fas fa-times"></i><span>Las contraseñas no coinciden</span></div>)}
                  <div className="tit-olv"><a href="/change">¿Olvidaste tu Contraseña?</a></div>
                </div>
              </div>
              <div className="modal-footer">
                {this.renderRedirect()}
                <button onClick={e => this.handleSumitClick(e)} type="button" className="btn btn-primary">Ingresar</button>
                <span>{this.state.validationText}</span>
              </div>
            </div>
          </div>
        </div>





        <div className="modal fade" id="myModal-2" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" ref={input => this.loginclose = input} className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <h4 className="modal-title" id="myModalLabel">INGRESAR A MI CUENTA</h4>
                <hr />
                <div className="flecha-down"></div>
              </div>
              <div className="modal-body">

                <div className="con-fac">¿No tienes cuenta? <button type="button" class="btn-regs-pop" data-toggle="modal" data-target="#myModal-reg" data-dismiss="modal" aria-label="Close">Registrarse</button></div><br />


                <FacebookLogin
                  appId="311566446451744"
                  autoLoad={false}
                  fields="name,email,picture"
                  onClick={this.componentClicked}
                  render={renderProps => (
                    <button class="btn btn-social ext-reg-btn fb-btn" data-pa-name="intro_login_fb" onClick={renderProps.onClick}>
                      <div class="text-con"> <i class="fab fa-facebook-square"></i> <span> Continuar con Facebook</span>
                      </div>
                    </button>

                  )}
                  //onClick={this.componentClicked}
                  callback={this.responseFacebooklogin}></FacebookLogin>

                <br />
                <GoogleLogin
                  clientId="154537549270-knt6kr4a3epb30eed3ntrcl35dtsnt1p.apps.googleusercontent.com"
                  buttonText="Login Con Google"
                  render={renderProps2 => (
                    <button class="btn btn-social ext-reg-btn gp-btn" data-pa-name="intro_login_google" id="gp-auth-btn" onClick={renderProps2.onClick} >
                      <div class="text-con"> <i class="fab fa-google-plus"></i> <span>Continuar con Google</span> </div></button>

                  )}
                  onSuccess={this.responseGooglelogin}
                  onFailure={this.responseGooglelogin}
                  cookiePolicy={'single_host_origin'}
                ></GoogleLogin>


                <hr />
                <div className="con-form">
                  <div className="tit-ses">Ingresa con tu dirección de correo electronico</div>
                  <input type="text" className="form-control" id="email" placeholder="Email" onChange={(e) => this.handletextChange("email", e)} value={this.state.email} />
                  {!this.state.errors.email && (<div class="row"><i class="fas fa-times"></i><span>Por favor ingrese un correo valido</span></div>)}
                  <input type="password" className="form-control" id="contraseña" placeholder="Contraseña" onChange={(e) => this.handletextChange("password", e)} value={this.state.password} />
                  <div className="tit-olv"><a href="/change">¿Olvidaste tu Contraseña?</a></div>
                </div>
              </div>
              <div className="modal-footer">

                <button onClick={e => this.handleSumitClicklogin(e)} type="button" className="btn btn-primary">Ingresar</button>
              </div>
            </div>
          </div>
        </div>


      </div>
    );
  }
}

export default HeaderEditar;