import React, {Component} from 'react';
import Toolbarmenu from './menu/toolbarMenu';
import axios from 'axios';

class HeaderRegistro extends Component{

    constructor(props){
        super(props)

        this.checkuserlogin= this.checkuserlogin.bind(this);
    }


    componentDidMount(){
        this.checkuserlogin()
    }

    checkuserlogin() {
        axios.get('https://serviadvisor.com/user', { withCredentials: true }).then(res => {
          console.log(res.data);
          if (res.data[0]) {
            const data = res.data[0];
            const userdata = {
              userid: data.id,
              username: data.username,
              photo_url: data.photo_url,
              active: data.active,
              accountType: data.accountType
            }
    
            console.log(userdata);
            this.props.logindata(userdata, true);
    
            console.log("condicional data");
    
    
          }
        })
      }


    logaout() {
        axios.get('https://serviadvisor.com/logout', { withCredentials: true }).then(res => {
    
    
        
    
          this.props.logindata(undefined, false)
    
          this.props.history.push('/');
    
        })
      }
    
      handlelogaoutclick = e => {
        this.logaout();
      }


    render(){
        return(
            <div>
            <div id="header"> 
            <div class="top"> 
                <div class="container">
                    <ul class="top-support"> 
                        <li><span>Bienvenid@s a ServiAdvisor</span></li>
                    
                    </ul>
                        <div class="top-control">

                            
                         
                         <button type="button" class="btn btn-primary btn" onClick={this.handlelogaoutclick} >Cerrar Sesión</button>
                            
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div> 
            
            <div id="believe-nav"> 
                <div class="container">
                    <div class="min-marg">
                        <nav class="navbar navbar-default">
                          
                                <div class="navbar-header">
                                    <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1">
                                        <span class="sr-only">Toggle navigation</span>
                                        <span class="icon-bar"></span>
                                        <span class="icon-bar"></span>
                                        <span class="icon-bar"></span>
                                    </button>
                                    <a class="navbar-brand" href="/"><img src="/images/logo.png" class="logo"/></a>
                                </div>

                                {/*
                                <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                                  
                                   
                                    <ul class="nav navbar-nav navbar-right">
                                        
                                        <li>
                                          <div class="tit-fot">
                                          <a href="#"><img src="images/fotoperfil.png" alt="pav"/></a>
                                          </div>

                                          <li class="tit-nombre"><a href="#">Hola, Andrea Suarez</a></li>
                                          <li class="tit-cuenta"><a href="#">Mi cuenta</a></li>
                                       

                                        </li>  
                                        
                                    </ul>
                                   
                                </div>
                                */}
                            
                        </nav>
                    </div>
                    <div class="srch-form">
                        <form class="side-search">
                            <div class="input-group">
                                <input type="text" class="form-control search-wid" placeholder="Search Here" aria-describedby="basic-addon2"/>
                                <a href="" class="input-group-addon btn-side-serach" id="basic-addon2"><i class="fa fa-search"></i></a>
                            </div>
                        </form>
                    </div>
                </div>
            </div> 
            
           
            <div id="content" class="bannerheader regist"> 
            <div id="cat-nav">
            <div class="container">
                <nav class="navbar navbar-default">
                   
                    <div class="navbar-header">
                        <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#cat-nav-mega">
                            <span class="sr-only">Toggle navigation</span>
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                        </button>
                    </div>

                  
                  <Toolbarmenu></Toolbarmenu>
                  
                </nav>
            </div>
            </div>      



             <div class="registro">
                  
                  
              </div>
        </div>
        </div> 

        </div>
        );
    }
}

export default HeaderRegistro;