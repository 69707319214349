import React,{Component} from 'react';
import { Carousel } from 'react-responsive-carousel';
import Axios from 'axios';

class Carrouselprovider extends Component{
    constructor(props){
        super(props);
        this.state={
            gallery:[],
            renderdefault:false
        }
        this.rendergalerydefault = this.rendergalerydefault.bind(this);
    }

    componentDidMount(){
        Axios.get('https://serviadvisor.com/getgallery/' + this.props.providerid).then(response => {
            console.log(response.data)
            this.setState({
              gallery: response.data,
              renderdefault:true
            })
          })
    }

    rendergalerydefault() {
        if (this.state.gallery.length===0 && this.state.renderdefault) {
          return (
            <Carousel showArrows={true} >
            <div style={{ height: "370px" }}>
              <img class="img-fluid" src="/images/galeria.jpg" />
            </div>
            </Carousel>
          );
      }else{
        return(
          <Carousel showArrows={true} >
        {this.state.gallery.map((item, index) => {
          return (
            <div style={{ height: "380px" }}>
              <img class="img-fluid" src={"https://serviadvisor.com/getprofilephoto/" + item.photo_url} />
            </div>
     
          )
        })}
         </Carousel>
        )
      }
    }
   
    render(){
    

        return(
            <div class="cont-gal">
                {this.rendergalerydefault()}
            </div>
        )
    }
}

export default Carrouselprovider;