import React, { Component } from 'react'
import Moment from 'react-moment'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from 'react-loader-spinner';


class DealBox extends Component {
    constructor(props) {
        super(props)
        this.state={
            loaderSave: false,
        }
        this.renderAssigment = this.renderAssigment.bind(this);
        this.renderworkdone = this.renderworkdone.bind(this);
        this.renderpaybutton = this.renderpaybutton.bind(this);
        this.onWorkDone = this.onWorkDone.bind(this);
        this.onPayOffer = this.onPayOffer.bind(this);
        this.renderdealOptions = this.renderdealOptions.bind(this);
        this.sendcompletionorder = this.sendcompletionorder.bind(this);
        this.opendealrevision = this.opendealrevision.bind(this);
    }

    dealresponse = () => {
        console.log("deal response in deal message")

        if (this.props.onDealresponse) {
            this.props.onDealresponse(this.props.offer.id, true);
        }
    }

    refuseresponse = () => {
        if (this.props.onDealresponse) {
            this.props.onDealresponse(this.props.offer.id, false);
        }
    }

    onWorkDone = () => {

        if (this.props.onWorkDone) {
            this.props.onWorkDone(this.props.offer.id);
        }
    }

    onPayOffer = () => {
        if (this.props.onpayOffer) {
            this.props.onpayOffer(this.props.offer);
        }
    }

    renderAssigment() {
        if (this.props.offer.serviceAssingment) {
            return (
                <div class="oferta-fecha-asigna">
                    <b>Fecha del servicio:</b> <Moment format={"DD MMM YYYY hh:mm a"}>{this.props.offer.serviceAssingment.AssigmentDate}</Moment>
                </div>
            )
        }
    }

    renderworkdone() {
        if (this.props.type === "client" && this.props.offer.offertStatusId === 5) {
            return (
                <div class="caja-calificar">
                    <button onClick={this.onWorkDone} className={"btn-entrega-trabajo"}>Trabajo Completado / Calificar</button>
                </div>
            )

        }
    }

   

    sendcompletionorder(offerId) {


        this.setState(
            {
                loaderSave:true
            }
        )

        const data = {
            offerId
        }
        axios.post('https://serviadvisor.com/paymentrequest', data, { withCredentials: true }).then(res => {
            const data = res.data;
            if(!res.error){
                this.setState(
                    {
                        loaderSave:false
                    }
                )

                const notify = () => toast.success("Se ha enviado tu Solicitud");
                notify();
            }
            
        })
    }

    renderdealOptions() {
        let html = ""

        const status = this.props.offer.offert_status.id

        console.log(this.props);
        if (this.props.type === "client" && status === 1) {
            return (<div class="casilla-resp">
                <div class="casilla-resp-tit">¿Aceptas esta oferta?</div>
                <div class="casilla-resp-int">
                    <div class="casilla-si"><input onClick={this.dealresponse} type="radio" name={"deal" + this.props.offer.id} id={"radiodeal" + + this.props.offer.id} />
                        <label for={"radiodeal" + + this.props.offer.id}>si</label><br />
                    </div>

                    <div class="casilla-no"> <input onClick={this.refuseresponse} type="radio" name={"deal" + + this.props.offer.id} id={"radiodeal2" + + this.props.offer.id} />
                        <label for={"radiodeal2" + + this.props.offer.id}>no</label><br />


                    </div>

                </div>
            </div>)



        }

        if (this.props.type === "empresa" && status === 5) {
            return (<div class="casilla-resp">

                <div class="casilla-resp-int">
                <button onClick={(e) => this.sendcompletionorder(this.props.offer.id)} className={"btn-solic-oferto"} >Solicitar de entrega de el servicio <Loader
                  visible={this.state.loaderSave}
                  type="Oval"
                  color="#00BFFF"
                  height={25}
                  width={25}

                /></button>

                </div>
            </div>)



        }
    }

    renderpaybutton() {
        if (this.props.type === "client" && this.props.offer.offertStatusId === 3) {
            return (
                <div class="oferta-pagar">
                    <button onClick={this.onPayOffer} className={"btn-pago"} >Paga y reserva tu servicio</button>
                </div>
            )

        }
    }


    opendealrevision() {
        this.props.onDealrevision(this.props.offer.id);
    }

    opendealEdicion(){
        this.props.onDealEdicion(this.props.offer.id);
    }



    redirecttochat() {
        if (this.props.offer.offertStatusId === 5) {
            if (this.props.type === "client") {


                window.location.href = "/empresa/" + this.props.offer.providerId + "/contacto";
            } else {
                window.location.href = "/perfil/chat/" + this.props.chatid
            }

        }


    }

    CancelOffer(){
        this.props.onDealCancel(this.props.offer.id);
    }


    renderCancelButton(){
        if(this.props.type === "empresa"){
            switch(this.props.offer.offertStatusId){
                case 1:
                case 2:
                case 3:
                    return (
          
                        <div >
            
            
                             
            
                                <button onClick={(e) => this.CancelOffer(e)} className={"oferta-estado-soli"}>Cancelar Oferta</button> 
                           
                             
                          
                        </div>
                        
                    )
            }
        }

     
    }

    renderbuttonrevision() {
        if (this.props.offer.offertStatusId === 5) {
        return (
          
            <div class="oferta-revision">


                 

                  
               
                    <button onClick={(e => this.opendealrevision())} className={"oferta-estado-soli"}>Mediación</button>
              
            </div>
            
        )}else if(this.props.offer.offertStatusId === 3){
            return(
                <div>
                     <button onClick={(e) => this.opendealEdicion()} className={"oferta-estado-medi"}>Pide revisión</button> 
                </div>
           
            );
        
        }else{
            return (
          
                <div class="oferta-revision">
    
    
                       {/**  <button onClick={(e  => this.opendealEdicion())} className={"oferta-estado-medi"}>Editar Propuesta</button> */} 
    
                  
                </div>
                
            )
        }


    }


    

    renderDealinfo() {
        let html = ""
        const status = this.props.offer.offert_status.id
        switch (status) {
            case 7:
            case 4: return (<div class="oferta-estado-recha"><b>{this.props.offer.offert_status.name}</b> </div>)

            default: return (<div class="oferta-estado-apro"><b>{this.props.offer.offert_status.name}</b> </div>)
                break;
        }
    }

    render() {
        return (
            <div class="ofertas-cont">
            <div class="ofertas-1">
                <div class="ofertas-1-int">
                    <div class="oferta-text"> <b>Fecha: </b> <Moment format={"DD MMM YYYY hh:mm a"}>{this.props.offer.createdAt}</Moment> </div>
                    {this.renderAssigment()}
                    <div class="oferta-descripcion"><b>Descripción:</b>{this.props.offer.description}</div>
                    {this.renderDealinfo()}
                    {/*<div class="oferta-estado-apro"><b>{this.props.offer.offert_status.name}</b> </div>*/}

                   
                    <div class="oferta-otros-botones">
                        {this.renderworkdone()}

                        {this.renderdealOptions()}
                    </div>


                 



                </div>

                <div class="oferta-caj-2">
              
                    <div class="oferta-valor"> <b>${this.props.offer.amount}  USD</b>  </div>

                   
                    {this.renderpaybutton()}
                </div>
                </div>
                {this.renderCancelButton()}
                {this.renderbuttonrevision()}
              


            </div>
        )
    }
}

export default DealBox