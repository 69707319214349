import React, { Component } from 'react';
import Axios from 'axios';
import Message from './message';
import DealMessage from './DealMessage';
import RevisionMessage from './RevisionMessage';
import LastOffers from '../pages/perfilEmpresa/LastOffers';
import notifications from '../../utils/notifications';
import { Modal, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import setMinutes from "date-fns/setMinutes";
import setHours from "date-fns/setHours";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css'


class Chat extends Component {
  constructor(props) {
    super(props)

    this.state = {
      chat: undefined,
      actualmessage: "",
      messages: [],
      islogin: false,
      caracternumber: 0,
      page: 0,
      cropmodalshow: false,
      cropmodalshowrefuse: false,
      startDate: new Date(),
    }

    registerLocale('es', es)
    this.startnegociation = this.startnegociation.bind(this);
    this.getmessages = this.getmessages.bind(this);
    this.refreshChat = this.refreshChat.bind(this);
    this.refreshmessages = this.refreshmessages.bind(this);
    this.rendermessage = this.rendermessage.bind(this);
    this.dealresponse = this.dealresponse.bind(this);
    this.dealresponseRevision = this.dealresponseRevision.bind(this);
    this.nextpage = this.nextpage.bind(this);
    this.openmodalDate = this.openmodalDate.bind(this);
    this.handleClientResponse = this.handleClientResponse.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.openmodalrefuse = this.openmodalrefuse.bind(this);
    this.refuseOffer = this.refuseOffer.bind(this);
    this.accceptRevision = this.accceptRevision.bind(this);
    this.rejectRevision = this.rejectRevision.bind(this);
  }

  nextpage = () => {
    let pagenum = this.state.page;
    pagenum = pagenum + 1;
    const data = {
      chatid: this.state.chat.id,
      page: pagenum
    }
    Axios.post('https://serviadvisor.com/getmessagespagination', data, { withCredentials: true }).then(res => {
      if (data.error) {

      } else {
        const data = res.data
        const messagesdata = this.state.messages.slice()
        if (data.length > 0) {
          this.setState({
            messages: data.reverse().concat(messagesdata)
          }, () => {
            this.setState({
              page: pagenum
            })
            console.log(this.state.messages);
          })
        }
      }
    })
  }

  componentDidMount() {

    const data = {
      providerId: this.props.providerId
    }

    Axios.post('https://serviadvisor.com/getChat', data, { withCredentials: true }).then(res => {
      const data = res.data;
      if (data.error) {
      } else {
        this.setState({
          chat: data
        }, () => {
          this.getmessages()
          this.props.sockets.sockets.callback = this.refreshmessages;
        })
      }
    })
  }



  dealresponse = (offertId, response) => {
    this.handleClientResponse(offertId, response);
  }

  dealresponseRevision = (revisionId, response) => {
    if (response) {
      this.accceptRevision(revisionId);
    } else {
      this.rejectRevision(revisionId);
    }
  }

  accceptRevision = (RevisionId) => {
    const data = {
      RevisionId: RevisionId
    }

    Axios.post('https://serviadvisor.com/acceptRevision/', data, { withCredentials: true }).then(response => {
      console.log(response);
      if (response.error) {

      } else {
        this.getmessages();
      
      }
    })
  }

  rejectRevision = (RevisionId) => {
    const data = {
      RevisionId: RevisionId
    }

    Axios.post('https://serviadvisor.com/rejectRevision/', data, { withCredentials: true }).then(response => {
      console.log(response);
      if (response.error) {

      } else {
        this.getmessages();
      
      }
    })
  }

  handleChange(date) {
    this.setState({
      startDate: date
    });
  }

  makeAssigments = () => {
    const data = {
      date: this.state.startDate,
      offertId: this.state.offertId
    }

    Axios.post('https://serviadvisor.com/assigndate/', data, { withCredentials: true }).then(response => {
      console.log(response);
      if (response.error) {

      } else {
        this.getmessages();
        this.setState({ cropmodalshow: false });
      }
    })
  }


  refuseOffer = () => {
    const data = {
      offertId: this.state.offertId
    }

    Axios.post('https://serviadvisor.com/declineOffer/', data, { withCredentials: true }).then(response => {
      console.log(response);
      if (response.error) {

      } else {
        this.getmessages();
        this.setState({ cropmodalshowrefuse: false });
      }
    })
  }


  openmodalDate(offertId) {
    this.setState({
      cropmodalshow: true,
      offertId: offertId
    })
  }

  openmodalrefuse(offertId) {
    this.setState({
      cropmodalshowrefuse: true,
      offertId: offertId
    })
  }

  handleClientResponse = (offertId, response) => {
    if (response) {
      this.openmodalDate(offertId)
    } else {
      this.openmodalrefuse(offertId)
    }
  }

  refreshmessages(data) {
    this.getmessages();
  }

  getmessages = () => {
    let pagenum = 0;

    const data = {
      chatid: this.state.chat.id,
      page: pagenum
    }
    Axios.post('https://serviadvisor.com/getmessagespagination', data, { withCredentials: true }).then(res => {
      if (data.error) {

      } else {

        const data = res.data

        this.setState({
          messages: data.reverse(),
          page: 0
        })
      }
    })
  }

  startnegociation = (event) => {
    const data = {
      providerId: this.props.providerId
    }
    Axios.post('https://serviadvisor.com/startNegociation', data, { withCredentials: true }).then(res => {
      const data = res.data;

      if (data.error) {

      } else {
        this.setState({
          chat: data
        }, () => {
          this.props.sockets.sockets.callback = this.refreshmessages;
        })
      }
    })
  }

  onchangetextMessage = (e) => {
    let message = e.target.value;
    const number = e.target.value.length
    this.setState({
      actualmessage: message,
      caracternumber: number
    })
  }

  onenterKey = (e) => {
    if (e.key === "Enter" && e.shiftKey === false) {
      this.sendmessage();
    }
  }

  sendmessage = (event) => {
    const data = {
      chatid: this.state.chat.id,
      message: this.state.actualmessage
    }
    Axios.post('https://serviadvisor.com/messages', data, { withCredentials: true }).then(res => {
      if (data.error) {

      } else {

      }
      this.getmessages();
      this.setState({
        actualmessage: "",
        caracternumber: 0
      })
    })
  }


  refreshChat() {
    // this.getmessages();
  }

  rendermessage(item) {
    if (item.offert !== null && item.offert !== undefined) {
      return (
        <DealMessage onDealresponse={this.dealresponse} type="client" offer={item}></DealMessage>
      )
    } else if (item.revision !== null && item.revision !== undefined) {
      return (
        <RevisionMessage onDealresponse={this.dealresponseRevision} type="client" chatItem={item}></RevisionMessage>
      )
    } else {
      return (
        <Message message={item}></Message>
      )
    }
  }

  render() {
    let modalClose = () => this.setState({ cropmodalshow: false });
    let modalCloserefuse = () => this.setState({ cropmodalshowrefuse: false });
    return (
      <div>
        <div class="title-perfil-empresa">
          <h4 class="azul"> <a href="#">Contáctanos</a></h4>
        </div>
        {!this.state.chat ? (<div>  {/* <p class="sub-indice">fecha estimada para inicio de tu proyecto</p>
                    <input type="date" name="" class="input chat" /> */}

          <input id="startneogciation" onClick={this.startnegociation} type="button" name="" class="input chat" value="Empezar negociacion" />
        </div>) : (<div>   <div class="coooo" >
          <h4 class="subtitulo"><a href="#">¿En qué podemos Ayudarte?</a></h4>
        </div>
          <div class="caja-chat-perfil-emp">

            <div class="subtitulo"><a onClick={this.nextpage} href="javascript:void(0)">Cargar Más...</a></div> <br />

            {this.state.messages.map((item, index2) => {

              return (
                <div key={index2}
                  class={"caja-chat-perfil-emp-ind"} >
                  {this.rendermessage(item)}

                </div>
              );
            })}

          </div>

          <div class="caja-chat-escribir">

            <div class="caja-chat-cir">
              <textarea onKeyPress={this.onenterKey} onChange={this.onchangetextMessage} value={this.state.actualmessage} class="texto-enviar">{this.state.actualmessage}</textarea>
            </div>
          </div>

          <button onClick={this.sendmessage} class="enviar-respuesta">enviar</button>
          <p class="caracteres-enviar-empresa">{this.state.caracternumber}/500 caracteres</p>
          <div class="limp"></div></div>)}



        <div class="col-md-12 blog-ct">
          <div class="title-perfil-empresa">
            <h4 class="azul"> <a href="#">Ofertar anteriores</a></h4>
          </div>
          <div class="ofertas-anteriores-chat-perf">

            {this.state.chat && (<LastOffers chatId={this.state.chat.id}></LastOffers>)}



          </div>


        </div>


        <Modal className="recorte pro-cal" show={this.state.cropmodalshow} onHide={modalClose}
          {...this.props}
          size="lg"
          animation={false}

          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Selecciona una fecha
              <hr />
              <div className="flecha-down"></div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div class="caja-calendario-popup">
              <DatePicker
                locale="es"
                inline
                selected={this.state.startDate}
                onChange={this.handleChange}
                showTimeSelect
                //excludeTimes={[setHours(setMinutes(new Date(), 0), 17), setHours(setMinutes(new Date(), 30), 18), setHours(setMinutes(new Date(), 30), 19), setHours(setMinutes(new Date(), 30), 17)]}
                dateFormat="MMMM d, yyyy h:mm aa"
              />
              <p class="text-calen">
                <span>{"Fecha Seleccionada: " + this.state.startDate}</span>
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.makeAssigments} className={"btn-primary"}>Aceptar</Button>
            <Button onClick={modalClose} className={"btn-success"}>Cerrar</Button>
          </Modal.Footer>
        </Modal>



        <Modal className="recorte pro" show={this.state.cropmodalshowrefuse} onHide={modalCloserefuse}
          {...this.props}
          size="lg"
          animation={false}

          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Confirmación
              <hr />
              <div className="flecha-down"></div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div >
              Esta Seguro de rechazar la propuesta?
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.refuseOffer} className={"btn-primary"}>Aceptar</Button>
            <Button onClick={modalCloserefuse} className={"btn-success"}>Cerrar</Button>
          </Modal.Footer>
        </Modal>


      </div>
    )
  }
}

export default Chat;