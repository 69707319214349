import React, { Component } from 'react';
import Calendar from '../../calendar/calendar';
import axios from 'axios';
import AssignmentView from '../../chat/assigmentview';
import moment from 'moment';
import notifications from '../../../utils/notifications'
import { Modal, Button } from 'react-bootstrap';
import Moment from 'react-moment';


import DatePicker from 'react-datepicker';
import setMinutes from "date-fns/setMinutes";
import setHours from "date-fns/setHours";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/es';


import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css'

class calendario extends Component {

    constructor(props) {
        super(props);
        this.state = {
            assignations: [],
            assignationsloaded: false,
            providerdata:[],
            offers:[],
            events:[],
            modaldateshow: false,
            startDate: new Date(),
            offertId:undefined,
            cropmodalshow: false,
            currentoffer:undefined,
            currentassigment:undefined,
        }
        this.getassigments = this.getassigments.bind(this);
        this.checkprovider = this.checkprovider.bind(this);
        this.confirmdate = this.confirmdate.bind(this);
        this.getoffersAssigned = this.getoffersAssigned.bind(this);
        this.generateevents = this.generateevents.bind(this);
        this.refreshdata = this.refreshdata.bind(this);
        this.showofferinfo = this.showofferinfo.bind(this);
        registerLocale('es', es)
        this.openmodalDate = this.openmodalDate.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.reassignDate = this.reassignDate.bind(this);
    }

    componentDidMount() {
        this.checkprovider();
        this.props.sockets.sockets.callback= this.refreshdata
    }

    handleChange(date) {
        this.setState({
          startDate: date
        });
      }
    
      openmodalDate = (assigment) =>  {
        this.setState({
          modaldateshow : true,
          offertId:assigment.offert.id,
          currentassigment:assigment
        })
      }

    showofferinfo = (event) => {
        this.setState({
          cropmodalshow:true,
          currentoffer:event.offer
        })
    
        console.log(event.offer)
      }

    refreshdata(data){
        console.log("refresh chats")
        if(data.type==="assigment"){
            this.checkprovider();
            notifications.buildNotification(data.type, data.message, data.accountType, data.senderid,data.profileId, data.source);
        }else{
            notifications.buildNotification(data.type, data.message, data.accountType, data.senderid,data.profileId, data.source);
        }
        
    }

    eventdefault = [
        {
          id: 0,
          title: 'All Day Event very long title',
          allDay: false,
          start: new Date("2019-07-18T20:11:48.598Z"),
          end: new Date("2019-07-18T22:11:48.598Z"),
        },
        {
          id: 1,
          title: 'Long Event',
          start: new Date(2015, 3, 7),
          end: new Date(2015, 3, 10),
        }
      ];


    generateevents(){
        let events =[]

        this.state.offers.map((item,index)=>{
            let event = {
            id:item.serviceAssingment.id,
            title: "Servicio a cliente :" + item.client.user.username,
            allDay:false,
            start: new Date(item.serviceAssingment.AssigmentDate),
            end: moment(new Date(item.serviceAssingment.AssigmentDate) ).add(1, 'hours').toDate(),
            offer:item
            }

            events.push(event);

        })

        this.setState({
            events:events
        })

        console.log(events);


    }

    
    getoffersAssigned = (providerId) => {
        const data ={
            providerId:providerId
        }
        axios.post('https://serviadvisor.com/viewoffersconfirm',data,{withCredentials:true}).then(res =>{
            const data = res.data;
            if(!data.error){
                console.log(data);
                this.setState({
                    offers:data
                }, () => {
                    this.generateevents()
                })
            }
        })
    }


    reassignDate = () =>{
        
        const data = {
            date: this.state.startDate,
            offertId: this.state.currentassigment.offert.id,
            assignationId: this.state.currentassigment.id
          }

          axios.post('https://serviadvisor.com/reassigndate/',data,{withCredentials:true}).then(res => {
            this.checkprovider();
            this.setState({
                modaldateshow:false
            })
          })

        
    }

    confirmdate = (offertId) =>{
        const data ={
            offertId:offertId
        }
       axios.post('https://serviadvisor.com/confirmassignation',data,{withCredentials:true}).then(res => {
          const data = res.data;
          if(!data.error){
             this.checkprovider();
          }
       })
    }

    checkprovider() {
        axios.get('https://serviadvisor.com/checkprovider', { withCredentials: true }).then(res => {

            const provider = res.data;
            if (provider.error) {

            } else {
                this.setState(
                    {
                        providerdata:provider
                    }
                )
                this.getassigments(provider.id);
                this.getoffersAssigned(provider.id);
            }
        });

    }


    getassigments(providerId) {
        const data = {
            providerId:providerId
        }

        axios.post('https://serviadvisor.com/pendingassignationsOnly', data, { withCredentials: true }).then(res => {
        const assignations= res.data ;
        console.log(res);
        this.setState({
                assignations:assignations,
                assignationsloaded:true
            })
        })
    }




    render() {
        let modalClose = () => this.setState({ cropmodalshow: false });
        let modalClosedate = () => this.setState({ modaldateshow: false });
        return (



<div class="secio-ca-">


<div class="aviso-ayuda" >
                    <div class="btn-ayuda-pop" data-toggle="modal" data-target="#myModal-ayuda-emp" data-dismiss="modal" >
                    Guía Negociación (AYUDA) -    <i class="fas fa-question-circle" ></i>    </div>
                </div>   





            <div class="secion-caja-cal">

              
                <div class="se-caja-calen">

                    <div class="title-perfil-empresa espacio-top">
                      <h4 class="azul"> Calendario</h4>
                    </div>

                    <div class="calendario">
                            <Calendar showofferinfo={this.showofferinfo} events={this.state.events}></Calendar>
                        </div>
              </div>


              <div class="se-caja-pendientes">
                  <div class="title-perfil-empresa espacio-top">
                        <h4 class="azul"> Asignaciones pendientes</h4>
                  </div>
                        
                  <div class="caja-pendientes">
                                  {!this.state.assignationsloaded && ("Cargando Asignaciones")}
                                  {this.state.assignations.map((item, index) => {
                                      return (
                                        
                                        <AssignmentView offer={item} onReasign={this.openmodalDate} onConfirm={this.confirmdate}></AssignmentView>
                                      )
                                  })}
                  </div>
              </div>

                        
<Modal className="recorte pro" show={this.state.cropmodalshow} onHide={modalClose}
          {...this.props}
          size="lg"
          animation={false}

          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Informacion De la Oferta
              <hr />
                <div className="flecha-down"></div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
         
            <div>
            {this.state.currentoffer && (<div>
            <p>Cliente : {this.state.currentoffer.client.user.username}</p>
            <p>Detalle:{this.state.currentoffer.description} </p>
            <p>Direccion: </p>
            <p>Cantidad: {this.state.currentoffer.amount}</p>
            <p>fecha De Asignacion : <Moment format={"DD MMM YYYY hh:mm a"}>{this.state.currentoffer.serviceAssingment.AssigmentDate}</Moment> </p>
            <div class="oferta-estado-apro"><b>{this.state.currentoffer.offert_status.name}</b> </div>
            </div>)}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={modalClose} className={"btn-success"}>Cerrar</Button>
          </Modal.Footer>
        </Modal>

        <Modal className="recorte pro-cal" show={this.state.modaldateshow} onHide={modalClosedate}
              {...this.props}
              size="lg"
              animation={false}
    
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  Selecciona una fecha 

                <hr />
                <div className="flecha-down"></div>
              </Modal.Title>
              </Modal.Header>
              <Modal.Body>
    
                <div class="caja-calendario-popup">
                  <DatePicker
                    locale="es"
                    inline
                    selected={this.state.startDate}
                    onChange={this.handleChange}
                    showTimeSelect
                    dateFormat="MMMM d, yyyy h:mm aa"
                  />
                  <p class="text-calen">
                    <span>{"Fecha Seleccionada: " + this.state.startDate}</span>
                  </p>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={this.reassignDate} className={"btn-primary"}>Aceptar </Button>
                <Button onClick={modalClosedate} className={"btn-success"}>Cerrar</Button>
              </Modal.Footer>
            </Modal>
    
            </div>

            </div>
        );

    }
}

export default calendario