import React,{Component} from 'react';
import { Modal, Button } from 'react-bootstrap';
import Moment from 'react-moment';
import Axios from 'axios';

class PremiumContend extends Component{
    constructor(props){
        super(props);
        this.state={
            cropmodalshow:false,
            plans:undefined,
            actualselectedPlan: undefined
        }
        this.openmodal= this.openmodal.bind(this);
        this.setuppaybutton= this.setuppaybutton.bind(this);
    }

    componentDidMount(){
        this.getplans();
    }


    openmodal(plan){
        this.setState({
            cropmodalshow:true
        })


        this.setState({
          actualselectedPlan:plan
        })
        this.setuppaybutton(plan.planId,plan.id);
    }

    getplans(){
      Axios.get('https://serviadvisor.com/listsubscriptionsPlans', {withCredentials:true}).then(res => {
        const data = res.data;
        if(data){
          this.setState({
            plans:data
          })
        }
      })
    }


    setuppaybutton(planId,dbplanId){
        setTimeout( () => {
            window.paypal.Buttons({

                createSubscription: function(data, actions) {
              
                  return actions.subscription.create({
              
                    'plan_id': planId
              
                  });
              
                },
              
                onApprove: function(data, actions) {
                  alert('You have successfully created subscription ' + data.subscriptionID);
                  
                  const datasubs ={
                    subscriptionId:data.subscriptionID,
                    dbplanId:dbplanId
                  }

                  Axios.post('https://serviadvisor.com/checkSubscription',datasubs,{withCredentials:true}).then(res => {

                  })
                }
              
              }).render('#paysubButton');
        })
    }

    render(){
        let modalClose = () => this.setState({ cropmodalshow: false });
        return( 
          <div>
      <div class="container">
      <div class="caja-ico-premium">
           <div class="icon-premium-conten"><a href="#"><img src="/images/email.png" alt=""/> </a> <h4>Más Solicitudes</h4> </div>
           <div class="icon-premium-conten"><a href="#"><img src="/images/datos.png" alt=""/> </a> <h4>Datos de Contacto Visible</h4> </div>
           <div class="icon-premium-conten"><a href="#"><img src="/images/clientes.png" alt=""/> </a> <h4>Clientes Potenciales </h4></div>
           <div class="icon-premium-conten"><a href="#"><img src="/images/visualizacion.png" alt=""/> </a> <h4>Visualización Destacada </h4> </div>
           <div class="icon-premium-conten"><a href="#"><img src="/images/recomendados-.png" alt=""/> </a> <h4>Recomendados Bodadvisor</h4> </div>
      </div>
      </div>
      <div class="cont-ttpremum">
        <hr class="linea" />
          <h2 class="titulo-men">Conoce nuestros paquetes</h2>
      </div>
      <div class="container"><div class="tablero-paquetes">
          <div class="columna-1">
            <ul class="cont-col-1">
                  <div class="azul-mem"><p>Si	quiero</p> promocionar	mi	empresa</div>
                  <div class="azul-mem"><p>Si	quiero</p>	que	futuros	clientes	tengan	acceso	básico	a	mis	datos	de	contacto</div>
                  <div class="azul-mem"><p>Si	quiero</p>	que	futuros	clientes	tengan	acceso	completo	a	mis	datos	de	contacto</div>
                  <div class="azul-mem"><p>Si	quiero</p>	publicar	fotografías	de	mis	productos	y	servicios</div>
                  <div class="azul-mem"><p>Si	quiero</p>	publicar	un	video	promocional	de	mi	empresa</div>
                  <div class="azul-mem"><p>Si	quiero</p>	acceder a	la	información	de	contacto	de	clientes	interesados</div>
                  <div class="azul-mem"><p>Si	quiero</p>	ubicar a	mi	empresa	en	una	posición	destacada</div>
                  <div class="azul-mem"><p>Si	quiero</p>	ubicar a	mi	empresa	en	la	sección	de	recomendados	de	BodAdvisor</div>
                  <div class="azul-mem"><p>Si	quiero</p>	publicar	un	artículo	en	la	sección	de	consejos	para	novias	BodAdvisor</div>
                  <div class="azul-mem"><p>Si	quiero</p>	acceder	a	la	herramienta	de	negociación	virtual	de	BodAdvisor</div>
            </ul>

          </div>

            <div class="columna-2">
               <div class="cab-mc">Membresía <br/> Cortesía</div>
              <ul class="Back-gris">
                  <div><i class="fas fa-check"></i></div>
                  <div><i class="fas fa-check"></i></div>
                  <div><i class="fas fa-times"></i></div>
                  <div><i class="fas fa-times"></i></div>
                  <div><i class="fas fa-times"></i></div>
                  <div><i class="fas fa-times"></i></div>
                  <div><i class="fas fa-times"></i></div>
                  <div><i class="fas fa-times"></i></div>
                  <div><i class="fas fa-times"></i></div>
                  <div><i class="fas fa-times"></i></div>
              </ul>
              <div class="caja-bot-mc">
                 <div class="botones"><button class="boton-int" disabled>GRATIS</button></div>
                 <div class="tex-caja">Obtén Cortesía</div>
              </div>
            </div>


            <div class="columna-3">
               <div class="cab-mwe" >Membresía <br/> 	Elite</div>
              <ul class="Back-gris">
                  <div><i class="fas fa-check"></i></div>
                  <div><i class="fas fa-check"></i></div>
                  <div><i class="fas fa-check"></i></div>
                  <div><i class="fas fa-check"></i></div>
                  <div><i class="fas fa-check"></i></div>
                  <div><i class="fas fa-check"></i></div>
                  <div><i class="fas fa-check"></i></div>
                  <div><i class="fas fa-times"></i></div>
                  <div><i class="fas fa-times"></i></div>
                  <div><i class="fas fa-times"></i></div>
              </ul>
              <div class="caja-bot-mwe"><div class="botones">
              {this.state.plans ? 
                this.props.user && this.props.user.accountType==="empresa" ? 
                   <button onClick={(e) => this.openmodal(this.state.plans[0])} className={"btn-prem"}>${this.state.plans[0] && (this.state.plans[0].price)}/USD Mes</button>
                   :
                   <button class="boton-int">${this.state.plans[0] && (this.state.plans[0].price)}/USD Mes</button>
                :
                "Loading"
              }
              </div><div class="tex-caja">Obtén Elite</div>
              </div>
            </div>


            <div class="columna-4">
            <div class="cab-mbg">Membresía <br/>	Business	Gold</div>
              <ul class="Back-gris">
              <div><i class="fas fa-check"></i></div>
                  <div><i class="fas fa-check"></i></div>
                  <div><i class="fas fa-check"></i></div>
                  <div><i class="fas fa-check"></i></div>
                  <div><i class="fas fa-check"></i></div>
                  <div><i class="fas fa-check"></i></div>
                  <div><i class="fas fa-check"></i></div>
                  <div><i class="fas fa-check"></i></div>
                  <div><i class="fas fa-check"></i></div>
                  <div><i class="fas fa-check"></i></div>
              </ul>
              <div class="caja-bot-mbg"><div class="botones">
                {this.state.plans ? 
                    this.props.user && this.props.user.accountType==="empresa" ? 
                   <button onClick={(e) => this.openmodal(this.state.plans[1])} className={"btn-prem"}>${this.state.plans[1] && (this.state.plans[1].price)}/USD Mes</button>
                   :
                   <button class="boton-int">${this.state.plans[1] && (this.state.plans[1].price)}/USD Mes</button>
                :
                "Loading"
              } </div><div class="tex-caja">Obtén Business	Gold</div>
              </div>
                 

            </div>

         </div>


         <div class="container">
           <div class="nota-final">*NOTA: Al realizar el pago de tu membresía envíanos tu sopote de pago al correo membreias@bodavisor.com
           </div>
         </div>
      


                   
            <Modal className="recorte pro" show={this.state.cropmodalshow} onHide={modalClose}
          {...this.props}
          size="lg"
          animation={false}

          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
            Suscripción
              <hr />
                <div className="flecha-down"></div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
                    
            <div>
              <div>
                {this.state.actualselectedPlan && (
                  <div>
                   <p>Nombre de Plan: {this.state.actualselectedPlan.name}</p>
                   <p>Precio De Plan: ${this.state.actualselectedPlan.price}USD/Mes</p>
                   </div>
                )}
               
              </div>
              <br/>
          
            <div id="paysubButton"></div>
          
          
            
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={modalClose} className={"btn-primary"}>Cerrar</Button>
          </Modal.Footer>
        </Modal>





            </div>

            <div class="img-mariot-empresas"><a href="#"><img src="/images/marriot.jpeg" alt=""/> </a> </div>
            
            </div>
        )
    }
}

export default PremiumContend;