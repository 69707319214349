import React, {Component} from 'react';
import {Progress} from 'reactstrap';


class question extends Component{
    constructor(props){
      super(props);
      this.state={
        edit:true,
        question:"",
        response:""
      }
       
        this.handletextChange = this.handletextChange.bind(this);
        this.handledeletequestion = this.handledeletequestion.bind(this);
        this.seteditable= this.seteditable.bind(this);
    }


    
    
    componentDidMount(){

      console.log(this.props)

      this.setState({
        
        question:this.props.textquestion,
        response:this.props.textresponse
      }, () => {
        
      })
      

      if(this.props.edit){
        this.setState({
          edit:false
        })
      }
    }

    seteditable =() =>{
      this.setState({
        edit:true
      })
    }

    handlequestionchange = (e) =>{
      let question = e.target.value;
      this.setState({
        question:question
      })
    }

    handleresponsechange = (e) =>{
      let response = e.target.value;
      this.setState({
        response:response
      })
    } 

    savequestion = (e) =>{
      if(this.props.oneditquestion){
        const question={
          id:this.props.id,
          
          question:this.state.question,
          response:this.state.response
        }
        this.props.oneditquestion(this.props.index,e,question)
      }
      this.setState({
        edit:false
      })
    }

    handletextChange(name, event){
        console.log(this.props.index);
        this.props.Ontextchange(name,this.props.index,event);
        this.setState({[name]: event.target.value});

      }

    handledeletequestion(e){
      if(this.props.edit){
        this.props.onDeleteQuestion(this.props.index,e, this.props.id);
      }else{
        this.props.onDeleteQuestion(this.props.index,e);
      }
      
    }

 


    renderquestions(){
      if(this.state.edit){
        return( <div class="pregun-fre ">
        <div class="pregun-fre-nume">{this.props.index + 1}</div>
        
        <input type="text" class="form-control pre-gun"  onChange={(e) => this.handletextChange("question",e)} id="question" placeholder="Donde puedo encontrar los servicios" value={this.state.question}/>
        <div class="pregun-fre-bloc"> 
        
        </div><textarea onChange={this.handleresponsechange} class="form-control pre-gun"  onChange={(e) => this.handletextChange("response",e)} id="response" placeholder="En nuestro perfil podrán encontrar nuestros servicios">{this.state.response}</textarea>
        <div class="pregun-fre-ico">
        <a class="col-md-8" onClick={(e) => this.handledeletequestion(e)} class="btn-agre-q" href="javascript:void(0);"><i class="fas fa-times naranja"></i> <span> Eliminar</span></a> 
        <a class="col-md-2" onClick={(e) => this.savequestion(e)} class="btn-agre-q" href="javascript:void(0);"><i class="fas fa-save naranja"></i> <span>Guardar</span></a>
        </div> 
    </div>)
      }else{
        return(
          <div class="pregun-fre ">
          <div class="pregun-fre-nume">{this.props.index + 1}</div><label type="text" class="form-control pre-gun tit-preg"  onChange={(e) => this.handletextChange("question",e)} id="question" placeholder="Donde puedo encontrar los servicios" value={this.state.question}>{this.state.question}</label>
          <div class="pregun-fre-bloc"> </div><p class="form-control pre-gun tex-preg"  onChange={(e) => this.handletextChange("response",e)} id="response" placeholder="En nuestro perfil podrán encontrar nuestros servicios">{this.state.response}</p>
          <a  onClick={(e) => this.handledeletequestion(e)} class="btn-agre-q" href="javascript:void(0);"> <i class="fas fa-times naranja"></i><span> Eliminar</span></a>
          <a   onClick={(e) => this.seteditable(e)} class="btn-agre-q" href="javascript:void(0);"><i class="fas fa-save naranja"></i> <span> Editar</span></a>  
      </div>
        )
      }
    }

    render(){
        return(
          <div>
            {this.renderquestions()}
          </div>  
        );
    }
}

export default question;