import React, { Component } from 'react';
import { Redirect, Route, withRouter } from 'react-router-dom';
import GoogleLogin from 'react-google-login';
//import FacebookLogin from 'react-facebook-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToolbarMenu from './menu/toolbarMenu';
import Slideout from 'slideout';
import Moment from 'react-moment';
import notifications from '../utils/notifications'


const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
const validpassword = RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})");










class HeaderPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      emailsign: '',
      passwordsign: '',
      password: '',
      secondpasswords: '',
      secondpasswordsign: '',
      validationText: '',
      redirect: false,
      notifications:[],
      notificationsCount:0,
      user: undefined,
      islogin: false,
      socket: undefined,
      
      errors: {
        email: true,
        emailsign: true,
        passdis: true,
        passinvalid: true,
      }
    }

    this.responseGoogle = this.responseGoogle.bind(this);
    this.responseFacebook = this.responseFacebook.bind(this);
    this.responseFacebooklogin = this.responseFacebooklogin.bind(this);
    this.responseGooglelogin = this.responseGooglelogin.bind(this);



    this.handleSumitClick = this.handleSumitClick.bind(this);
    this.handleSumitClicklogin = this.handleSumitClicklogin.bind(this);
    this.handletextChange = this.handletextChange.bind(this);
    this.validatePassword = this.validatePassword.bind(this);

    this.setRedirect = this.setRedirect.bind(this);
    this.renderRedirect = this.renderRedirect.bind(this);
    this.checkuserlogin = this.checkuserlogin.bind(this);
    this.getFullNotiUrl = this.getFullNotiUrl.bind(this);
    this.loginbuttons = this.loginbuttons.bind(this);
    this.renderprofilenav = this.renderprofilenav.bind(this);
    this.validateemail = this.validateemail.bind(this);
    this.checkerrors = this.checkerrors.bind(this);
    this.opennav = this.opennav.bind(this);
    this.clearfields = this.clearfields.bind(this);
    this.getnotifications = this.getnotifications.bind(this);
    this.notify = this.notify.bind(this);
  }

  componentDidMount() {

    this.checkuserlogin();
    //window.slideout.toggle();

    const button = window.document.querySelector('#button');
    const tooltip = window.document.querySelector('#tooltip');

     window.Popper.createPopper(button, tooltip);
  
 this.getnotifications();




this.props.sockets.notifyListcallback=this.notify
}

notify(data){
  console.log("notifychat get notifications");
    this.getnotifications();  
}


  getnotifications(){
   

    const data = {
      page:0
  }

  axios.post('https://serviadvisor.com/getactivenoticount/',null,{withCredentials:true}).then(res => {
    const notifications= res.data;
    this.setState({
      notificationsCount:notifications
    })
})

    axios.post('https://serviadvisor.com/notificationspagination/',data,{withCredentials:true}).then(res => {
     
      if(!res.data.error){
        const notifications= res.data;
        this.setState({
          notifications:notifications
      })
      }

  
  })
  }


  clearfields(evt) {
    this.setState({
      email: '',
      emailsign: '',
      passwordsign: '',
      password: '',
      secondpassword: ''
    })

  }



  async checkuserlogin() {
    await axios.get('https://serviadvisor.com/user', { withCredentials: true }).then(res => {

      if (res.data[0]) {
        const data = res.data[0];
        const userdata = {
          userid: data.id,
          username: data.username,
          photo_url: data.photo_url,
          active: data.active,
          accountType: data.accountType
        }

        this.props.logindata(userdata, true);
        
        console.log("check user login in header page")
      }
    })
  }

  logaout() {
    axios.get('https://serviadvisor.com/logout', { withCredentials: true }).then(res => {


      this.setState({
        islogin: false,
        user: undefined
      }
      )

      this.props.logindata(undefined, false)

      //this.props.history.push('/');
      window.location.reload();
    })
  }

  handlelogaoutclick = e => {
    this.logaout();
  }

  checkerrors() {
    if (!this.state.errors.emailsign) {
      return false;
    }

    if (!this.state.errors.passdis) {
      return false;
    }

    if (!this.state.errors.passinvalid) {
      return false;
    }
    return true;
  }

  handletextChange(name, event) {


    this.setState({ [name]: event.target.value });

    if (name === "secondpasswordsign") {
      this.validateSecondPassword(event);
    }

    if (name === "username") {
      this.validateUsername(event);
    }

    if (name === "password") {
      // this.validatePassword(event);
    }

    if (name === "passwordsign") {
      this.validatePassword(event);
    }

    if (name === "email") {
      this.validateemail(event, false);
    }

    if (name === "emailsign") {
      this.validateemail(event, true);
    }

  }


  validateemail(event, sign) {
    const email = event.target.value
    const checkemail = validEmailRegex.test(event.target.value)
    const errors = this.state.errors
    if (sign) {
      errors.emailsign = checkemail
    } else {
      errors.email = checkemail
    }

    this.setState({ errors: errors });
  }


  validatePassword(event) {
    const password = event.target.value;
    let checkpass = validpassword.test(password);
    const errors = this.state.errors;
    errors.passinvalid = checkpass;

    this.setState({
      errors
    })
  }

  validateSecondPassword(event) {
    const password = this.state.passwordsign;
    const second = event.target.value;


    let errors = this.state.errors;
    if (second === password) {
      errors.passdis = true;
    }
    else {

      errors.passdis = false;

    }
    this.setState({
      errors: errors
    })
  }

  responseGoogle = (response) => {

    const data = {
      accounttype: "cliente",
      access_token: response.accessToken
    }


    axios.post('https://serviadvisor.com/oauth/google', data, { withCredentials: true }).then(res => {

      if (res.data.error) {
        const notify = () => toast.error(res.data.error);
        notify();
      } else {

        const data = res.data;
        const userdata = {
          userid: data.userid,
          username: data.username,
          photo_url: data.photo_url,
          active: '',
          accountType: data.accountType
        }

        this.props.logindata(userdata, true);
        this.getnotifications();
        this.signupclose.click();
      }



    })

  }

  responseGooglelogin = (response) => {

    const data = {
      accounttype: "cliente",
      access_token: response.accessToken
    }


    axios.post('https://serviadvisor.com/oauth/googlelogin', data, { withCredentials: true }).then(res => {



      if (res.data.error) {
        const notify = () => toast.error(res.data.error);
        notify();
      } else {

        const data = res.data;

        console.log(data);

        const userdata = {
          userid: data.userid,
          username: data.username,
          photo_url: data.photo_url,
          active: '',
          accountType: data.accountType
        }

        this.props.logindata(userdata, true);
        this.getnotifications();
        this.loginclose.click();
      }

    })

  }

  responseFacebook = (response) => {

    const data = {
      accounttype: "cliente",
      access_token: response.accessToken
    }


    axios.post('https://serviadvisor.com/oauth/facebook', data, { withCredentials: true }).then(res => {

      if (res.data.error) {
        const notify = () => toast.error(res.data.error);
        notify();
      } else {

        const data = res.data;
        const userdata = {
          userid: data.userid,
          username: data.username,
          photo_url: data.photo_url,
          active: '',
          accountType: data.accountType
        }

        this.props.logindata(userdata, true);
        this.getnotifications();
        this.signupclose.click();
      }
    }).catch(error => {
      const notify = () => toast("error");
      notify();
    });
  }

  responseFacebooklogin = (response) => {

    const data = {
      accounttype: "cliente",
      access_token: response.accessToken
    }


    axios.post('https://serviadvisor.com/oauth/facebooklogin', data, { withCredentials: true }).then(res => {



      if (res.data.error) {
        const notify = () => toast.error(res.data.error);
        notify();
      } else {

        const data = res.data;
        const userdata = {
          userid: data.userid,
          username: data.username,
          photo_url: data.photo_url,
          active: '',
          accountType: data.accountType
        }

        this.props.logindata(userdata, true);
        this.loginclose.click();
      }

    })
  }

  handleSumitClick(event) {
    event.preventDefault()

    if (!this.checkerrors()) {
      return
    }

    const newuser = {
      accounttype: "cliente",
      email: this.state.emailsign,
      password: this.state.passwordsign,
    }

    axios.post('https://serviadvisor.com/singup', newuser).then(response => {

      if (response.data.error) {
        const notify = () => toast.error(response.data.error);
        notify();
      } else {

        const logincredentials = {
          email: this.state.emailsign,
          password: this.state.passwordsign
        }

        axios.post('https://serviadvisor.com/login', logincredentials, { withCredentials: true }).then(res => {

          if (res.data.error) {
            const notify = () => toast.error(res.data.error);
            notify();
          } else {

            const data = res.data.userData;
            const userdata = {
              userid: data.userid,
              username: data.username,
              photo_url: data.photo_url,
              active: '',
              accountType: data.accountType
            }

            this.props.logindata(userdata, true);
            this.getnotifications();
            this.signupclose.click();
          }

        })
      }

    })
  }


  handleSumitClicklogin(event) {
    event.preventDefault()
    const logincredentials = {
      email: this.state.email,
      password: this.state.password,
    }

    axios.post('https://serviadvisor.com/login', logincredentials, { withCredentials: true }).then(response => {
      const data = response.data.userData;
      if (!data) {
        const notify = () => toast.error("Usuario O contraseña Invalida");
        notify();
      } else {
        const userdata = {
          userid: data.userid,
          username: data.username,
          photo_url: data.photo_url,
          active: data.active,
          accountType: data.accountType
        }
        this.props.logindata(userdata, true);
        this.loginclose.click();

      }


    }).catch(error => {
      const notify = () => toast.error("Usuario O contraseña Invalida");
      notify();
    })
  }

  changeaccount(e) {
    e.preventDefault();
    const type = e.target.value;
    const data = {
      type
    }

    axios.post('https://serviadvisor.com/changeAccountType', data, { withCredentials: true }).then(async (res) => {
      const data = res.data;
      if (!data.error) {
        //await this.checkuserlogin();
        //this.props.history.push('./')
       // window.location.reload();
      }
    })
  }


  changeaccountcheckbox(e) {
    e.preventDefault();
    const type = e.target.checked ? "1" : "2";
    const data = {
      type
    }

    
    axios.post('https://serviadvisor.com/changeAccountType', data, { withCredentials: true }).then(async (res) => {
      const data = res.data;
      if (!data.error) {
        //await this.checkuserlogin();
        //this.props.history.push('./')
      //  window.location.reload();
      }
    })
    
  }

  checkboxselected(accountType) {
    if (accountType === "empresa") {
      return (
        <input onChange={(e) => this.changeaccountcheckbox(e)} type="checkbox" name="onoffswitch" class="onoffswitch-checkbox" id="myonoffswitch" checked />
      )
    } else {
      return (
        <input onChange={(e) => this.changeaccountcheckbox(e)} type="checkbox" name="onoffswitch" class="onoffswitch-checkbox" id="myonoffswitch" />
        )
    }


  }


  getFullNotiUrl(accountType) {
    if (accountType === "empresa") {
      return (
        <div style={{background:"#ff8b00",height:"40px"}}><a style={{color:"white",textAlign:"center", marginTop:"10px", marginLeft:"5px"}} href="/perfil/notificaciones">Ver Todas las Notificaciones</a></div>
      )
    } else {
      return (
        <div  style={{background:"#ff8b00",height:"40px"}}><a style={{color:"white",textAlign:"center", marginTop:"10px", marginLeft:"5px"}} href="/perfil-cliente/notificaciones">Ver Todas las Notificaciones</a></div>
        )
    }


  }  

  checkselected(accountType) {
    if (accountType === "empresa") {
      return (
        <select onChange={e => this.changeaccount(e)}>
          <option value="1" selected>Proveedor</option>
          <option value="2">Cliente</option>
        </select>)
    } else {
      return (
        <select onChange={e => this.changeaccount(e)}>
          <option value="1" >Proveedor</option>
          <option value="2" selected>Cliente</option>
        </select>)
    }


  }

  updateNotification = (notificationId, type,message, accountType,senderid, profileId, source ) => {
    const data ={
      notificationId:notificationId
    }
    axios.post('https://serviadvisor.com/updateNotification', data, { withCredentials: true }).then(async (res) => {
      const data = res.data;
      if (!data.error) {
        //await this.checkuserlogin();
        //this.props.history.push('./')
      
      }
    })

    let localAccountType = 1;

    if(accountType == "cliente"){
      localAccountType  = "1";
    }else{
      localAccountType = "2";
    }


    const Accountdata = {
      type:localAccountType
    }

    
    axios.post('https://serviadvisor.com/changeAccountType', Accountdata, { withCredentials: true }).then(async (res) => {
      const data = res.data;
      if (!data.error) {
        //await this.checkuserlogin();
        //this.props.history.push('./')
        let url = await this.buildNotification(type,message,accountType,senderid,profileId,source)
        this.props.history.push(url);
      }
    })

  
  

  }


  loginbuttons = () => {

    const signupbutton = <div><button onClick={(evt) => this.clearfields(evt)} type="button" className="" data-toggle="modal" data-target="#myModal-reg">Registrarse</button>
      <button onClick={(evt) => this.clearfields(evt)} type="button" className="" data-toggle="modal" data-target="#myModal-2">Iniciar Sesión</button></div>;




    let html;

    if (this.props.islogin) {
      html = <div><button onClick={this.handlelogaoutclick} type="button" className="btn btn-primary btn" >Cerrar Sesión</button>

      </div>;

      return (
        <div>
          {html}
        </div>
      )
    }
    else {
      html = signupbutton

      return (
        <div>
          {html}
        </div>
      )
    }
  }

  


  renderprofilenav() {
   
    if (this.props.user) {

      return (
        <div>
          <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
            <ul className="nav navbar-nav navbar-right">
              <li>
                <div className="tit-fot">
                  {this.props.user.photo_url && (<a href="#"><img src={this.props.user.photo_url} alt="pav" /></a>)}
                  {!this.props.user.photo_url && (<a href="#"><img src="/images/fotoperfil.png" alt="pav" /></a>)}
                </div>
                <li className="tit-cuenta-tipo"><div className="tit-cuenta-tipo-tit">Cuenta:</div>
                  <div class="onoffswitch" data-toggle="tooltip" title=" Cambia tu tipo de cuenta">
                   {this.checkboxselected(this.props.user.accountType)}
                    <label class="onoffswitch-label" for="myonoffswitch">
                      <span class="onoffswitch-inner"></span>
                      <span class="onoffswitch-switch"></span>
                    </label>
                  </div>
                </li>
                <li className="tit-nombre"><a href="#">Hola,{this.props.user.username} </a></li>
                <li className="tit-cuenta"><a href={this.props.user.accountType === "cliente" ? ("/perfil-cliente/propuestas") : ("/perfil/propuestas")}>Mi cuenta</a>
               
                
                </li>
                <div class="btn-group show-on-hover">
          <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown">
            Notificaciones  {this.state.notificationsCount !== undefined && this.state.notificationsCount> 0 ? this.state.notificationsCount : ("")} <span class="caret"></span>
          </button>
          <ul class="dropdown-menu dropdown-notify" role="menu">
       
          {this.state.notifications.map((item,index)=>{
                    return(
                      <li style={{ background: item.IsCheck == false ? "#ff8c00" : "none"}}>
                        <div  >

                    
                          
                        <a onClick={ (e) => this.updateNotification(item.id,item.type, item.message,item.accountType, item.senderid, item.profileId, item.source)} href="#">
                        <div> {item.message}</div>
                        {/* {"type: " + item.type + " Message: " + item.message + " accountType: " + item.accountType + " SenderID: " +  item.senderid + " profipleId: "  + item.profileId + " Source: " + item.source + " " + */}
                        <Moment format={"DD MMM YYYY hh:mm a"}>
                             {item.createdAt}
                        </Moment> 
                         </a>
                        </div>
                         
                      </li>
                   
                    )
                })}

          {this.getFullNotiUrl(this.props.user.accountType)}
            
          </ul>
          
        </div>
              </li>
            </ul>


         

          </div>
        </div>
      )
    } else {
      return (
        <div></div>
      )
    }
  }

  setRedirect = () => {
    this.setState({
      redirect: true
    })
  }

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to='/registro-empresa2' />
    }
  }

  opennav = (e) => {
    this.props.slideout.toggle();
  }


  buildNotification = async(type, message, usertype, senderid, profileid, source) => {
    let providername;
    if(usertype !== "cliente"){
      providername = await notifications.GetProviderUserName(profileid);
    }
    
    let redirecturl = "";
   if(source){
      switch (type) {

        case 'chatnotify':
            redirecturl = usertype === "cliente" ? "/perfil/chat/" + source : "/empresa/" + providername + "/contacto";
           

            break;
        case 'offer':
            redirecturl = usertype === "cliente" ? "/perfil/propuestas" : "/empresa/" + providername + "/contacto";
          
            break;
        case 'chatlist':
            redirecturl = usertype === "cliente" ? "/perfil/calendario" : "/perfil-cliente/calendario";
          
            break;
        case 'assigment':
            redirecturl = usertype === "cliente" ? "/perfil/calendario" : "/perfil-cliente/calendario";
          
            break;

        case 'reassigment':
            redirecturl = usertype === "cliente" ? "/perfil/calendario" : "/perfil-cliente/calendario";
           
            break;

        case 'confirmed':
            redirecturl = usertype === "cliente" ? "/perfil/propuestas" : "/perfil-cliente/propuestas";
          
            break;
        case 'rejected':
            redirecturl = usertype === "cliente" ? "/perfil/propuestas" : "/perfil-cliente/propuestas";
          
            break;
        case 'paid':
            redirecturl = usertype === "cliente" ? "/perfil/propuestas" : "/perfil-cliente/propuestas";
           
            break;
        case 'payout':
            redirecturl = usertype === "cliente" ? "/perfil/propuestas" : "/perfil-cliente/propuestas";
           
            break;
        case 'revision':
          redirecturl = usertype === "cliente" ? "/perfil/chat/" + source : "/empresa/" + providername + "/contacto";
          

          break;
        case 'revisionUpdate':
          redirecturl = usertype === "cliente" ? "/perfil/chat/" + source : "/empresa/" + providername + "/contacto";
          

          break;
    }
   }else{
    switch (type) {

        case 'chatnotify':
            redirecturl = usertype === "empresa" ? "/perfil/chat" : "/empresa/" + source + "/contacto";
           

            break;
        case 'offer':
            redirecturl = usertype === "empresa" ? "/perfil/propuestas" : "/empresa/" + source + "/contacto";
           
            break;
        case 'chatlist':
            redirecturl = usertype === "empresa" ? "/perfil/calendario" : "/perfil-cliente/calendario";
          
            break;
        case 'assigment':
            redirecturl = usertype === "empresa" ? "/perfil/chat" : "";
          
            break;

        case 'confirmed':
            redirecturl = usertype === "empresa" ? "/perfil/chat" : "";
         
            break;
        case 'rejected':
            redirecturl = usertype === "empresa" ? "/perfil/chat" : "";
           
            break;
        case 'paid':
            redirecturl = usertype === "empresa" ? "/perfil/chat" : "";
           
            break;
        case 'payout':
            redirecturl = usertype === "empresa" ? "/perfil/chat" : "";
           
            break;
        case 'revision':
          redirecturl = usertype === "empresa" ? "/perfil/chat" : "/empresa/" + source + "/contacto";
          

          break;
        case 'revisionUpdate':
          redirecturl = usertype === "empresa" ? "/perfil/chat" : "/empresa/" + source + "/contacto";
          

          break;
    }
   }

   return redirecturl
}


  render() {
    return (
      <div>


        <div id="header">
          <div className="top">
            <div className="container">
              <ul className="top-support">
                <li><span>Bienvenid@s a ServiAdvisor</span></li>

              </ul>
              <div className="top-control">
                {this.loginbuttons()}

              </div>
              <div className="clearfix"></div>
            </div>
          </div>




          <div id="believe-nav">
            <div className="container">
              <div className="min-marg">
                <nav className="navbar navbar-default">





                  <div className="navbar-header">
                    <button onClick={this.opennav} type="button" className="navbar-toggle collapsed" data-toggle="collapse" >
                      <span className="sr-only">Toggle navigation</span>
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                    </button>
                    <a className="navbar-brand" href="/"><img src="/images/logo.png" className="logo" /></a>


                  </div>


                  {this.renderprofilenav()}



                </nav>
              </div>
              <div className="srch-form">
                <form className="side-search">
                  <div className="input-group">
                    <input type="text" className="form-control search-wid" placeholder="Search Here" aria-describedby="basic-addon2" />
                    <a href="" className="input-group-addon btn-side-serach" id="basic-addon2"><i className="fa fa-search"></i></a>
                  </div>
                </form>
              </div>
            </div>
          </div>


          <div id="content" className="bannerheader">
            <div id="cat-nav">
              <div className="container">
                <nav className="navbar navbar-default">

                  <div className="navbar-header">
                    <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#cat-nav-mega">
                      <span className="sr-only">Toggle navigation</span>
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                    </button>
                  </div>


                  <ToolbarMenu></ToolbarMenu>

                </nav>
              </div>
            </div>



            <div className="container">

              <h3 className="tit-buscador">¡Completa tu proyecto aquí!</h3>

            </div>
          </div>
        </div>


        <div className="modal fade" id="myModal-reg" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button ref={input => this.signupclose = input} type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <h4 className="modal-title" id="myModalLabel">CREAR MI CUENTA</h4>
                <hr />
                <div className="flecha-down"></div>
              </div>
              <div className="modal-body">

                <div className="con-fac">Regístrate y accede a todos los servicios que ServiAdvisor tiene para ti<br /><br /><br />

                  ¿Ya tienes cuenta? <button onClick={(evt) => this.clearfields(evt)} type="button" class="btn-regs-pop" data-toggle="modal" data-target="#myModal-2" data-dismiss="modal" aria-label="Close"><span>Acceder a tu cuenta</span></button>



                </div><br />

                <FacebookLogin
                  appId="311566446451744"
                  autoLoad={false}
                  fields="name,email,picture"
                  onClick={this.componentClicked}
                  render={renderProps => (
                    <button class="btn btn-social ext-reg-btn fb-btn" data-pa-name="intro_login_fb" onClick={renderProps.onClick}>
                      <div class="text-con"> <i class="fab fa-facebook-square"></i> <span> Continuar con Facebook</span>
                      </div>
                    </button>

                  )}
                  //onClick={this.componentClicked}
                  callback={this.responseFacebook}></FacebookLogin>

                <br />
                <GoogleLogin
                  clientId="154537549270-knt6kr4a3epb30eed3ntrcl35dtsnt1p.apps.googleusercontent.com"
                  buttonText="Login Con Google"
                  render={renderProps2 => (
                    <button class="btn btn-social ext-reg-btn gp-btn" data-pa-name="intro_login_google" id="gp-auth-btn" onClick={renderProps2.onClick} >
                      <div class="text-con"> <i class="fab fa-google-plus"></i> <span>Continuar con Google</span> </div></button>


                  )}
                  onSuccess={this.responseGoogle}
                  onFailure={this.responseGoogle}
                  cookiePolicy={'single_host_origin'}
                ></GoogleLogin>





                <hr />
                <div className="con-form">
                  <div className="tit-ses">Ingresa con tu dirección de correo electronico</div>
                  <input type="text" className="form-control" id="email" placeholder="Email" onChange={(e) => this.handletextChange("emailsign", e)} value={this.state.emailsign} />
                  {!this.state.errors.emailsign && (<div class="row"><i class="fas fa-times"></i><span>Por favor ingrese un correo valido</span></div>)}
                  <input type="password"

                    className="form-control" id="contraseña" placeholder="Contraseña" onChange={(e) => this.handletextChange("passwordsign", e)} value={this.state.passwordsign} />
                  {!this.state.errors.passinvalid && (<div class="row"><i class="fas fa-times"></i><span>La contraseña debe contener al menos: una letra mayuscula, una letra minuscula, un numero y ser mayor de 6 caracteres</span></div>)}
                  <input type="password" className="form-control" id="contraseña" placeholder="Confirmar Contraseña" onChange={(e) => this.handletextChange("secondpasswordsign", e)} value={this.state.secondpasswordsign} />
                  {!this.state.errors.passdis && (<div class="row"><i class="fas fa-times"></i><span>Las contraseñas no coinciden</span></div>)}
                  <div className="tit-olv"><a href="/change">¿Olvidaste tu Contraseña?</a></div>
                </div>
              </div>
              <div className="modal-footer">
                {this.renderRedirect()}
                <button onClick={e => this.handleSumitClick(e)} type="button" className="btn btn-primary">Ingresar</button>

              </div>
            </div>
          </div>
        </div>





        <div className="modal fade" id="myModal-2" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" ref={input => this.loginclose = input} className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <h4 className="modal-title" id="myModalLabel">INGRESAR A MI CUENTA</h4>
                <hr />
                <div className="flecha-down"></div>
              </div>
              <div className="modal-body signup-con">

                <div className="con-fac">¿No tienes cuenta? <button type="button" class="btn-regs-pop" data-toggle="modal" data-target="#myModal-reg" data-dismiss="modal" aria-label="Close">Registrarse</button></div><br />


                <FacebookLogin
                  appId="311566446451744"
                  autoLoad={false}
                  fields="name,email,picture"
                  onClick={this.componentClicked}
                  render={renderProps => (
                    <button class="btn btn-social ext-reg-btn fb-btn" data-pa-name="intro_login_fb" onClick={renderProps.onClick}>
                      <div class="text-con"> <i class="fab fa-facebook-square"></i> <span> Continuar con Facebook</span>
                      </div>
                    </button>

                  )}
                  //onClick={this.componentClicked}
                  callback={this.responseFacebooklogin}></FacebookLogin>

                <GoogleLogin
                  clientId="154537549270-knt6kr4a3epb30eed3ntrcl35dtsnt1p.apps.googleusercontent.com"
                  buttonText="Login Con Google"
                  render={renderProps2 => (

                    <button class="btn btn-social ext-reg-btn gp-btn" data-pa-name="intro_login_google" id="gp-auth-btn" onClick={renderProps2.onClick} >
                      <div class="text-con"> <i class="fab fa-google-plus"></i> <span>Continuar con Google</span> </div></button>

                  )}
                  onSuccess={this.responseGooglelogin}
                  onFailure={this.responseGooglelogin}
                  cookiePolicy={'single_host_origin'}
                ></GoogleLogin>


                <hr />
                <div className="con-form">
                  <div className="tit-ses">Ingresa con tu dirección de correo electronico </div>
                  <input type="text" className="form-control" id="email" placeholder="Email" onChange={(e) => this.handletextChange("email", e)} value={this.state.email} />
                  {!this.state.errors.email && (<div class="row"> <i class="fas fa-times"></i> <span> Por favor ingrese un correo valido</span></div>)}
                  <input type="password" className="form-control" id="contraseña" placeholder="Contraseña" onChange={(e) => this.handletextChange("password", e)} value={this.state.password} />
                  <div className="tit-olv"><a href="/change">¿Olvidaste tu Contraseña?</a></div>
                </div>
              </div>
              <div className="modal-footer">

                <button onClick={e => this.handleSumitClicklogin(e)} type="button" className="btn btn-primary">Ingresar</button>
              </div>
            </div>
          </div>
        </div>






      </div>




    );
  }
}

export default HeaderPage;