import React,{Component} from 'react';
import axios from 'axios';


class NotificationComponent extends Component{
    constructor(props){
        super(props)
        this.handleclickredirect = this.handleclickredirect.bind(this);
        this.changeaccountcheckbox = this.changeaccountcheckbox.bind(this);
    }

    handleclickredirect(){
        //window.location.replace(this.props.url)
       this.checkaccounttype()
    }

    checkaccounttype(){
        if(this.props.usertype ==="cliente"){
            this.changeaccountcheckbox("2");
        }else{
            this.changeaccountcheckbox("1");
        }

    }

    changeaccountcheckbox(type) {
      
      
        const data = {
          type
        }
    
        
        axios.post('https://serviadvisor.com/changeAccountType', data, { withCredentials: true }).then(async (res) => {
          const data = res.data;
          if (!data.error) {
            //await this.checkuserlogin();
            //this.props.history.push('./')
            window.location.href = this.props.url
          }
        })
        
      }


    render(){
        return(
            <div onClick={e => this.handleclickredirect()}>
                <span  >
                    <h3>{this.props.usertype ==="cliente" ? ("Cliente"): ("Proveedor")}</h3>
                    
                    {this.props.message}  </span>
            </div>
        )
    }
}

export default NotificationComponent;