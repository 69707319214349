import React,{Component} from 'react';
import Slider from "react-slick";
import axios from  'axios';
import premium from './menu/carouselpremium';


class Otrascontrenido extends Component{
    constructor(props){
        super(props)
        this.state={
            categories:[]
        }
    }

    componentDidMount(){
        this.getothercategories();
    }

    makerows(othercategories){
        const categoriestemp = Object.assign([], othercategories);
      
        let categories=[];
        let categoryrow = [];
        let cont = 0;
        categoriestemp.forEach((item,index)=> {
            cont++;
            categoryrow.push(item);
            if(cont === 25){
                categories.push(categoryrow);
                categoryrow=[]
                cont =0
            }


            console.log(categoriestemp.length + " index" + index);

            if(categoriestemp.length == index+1 && cont < 25){
                
                categories.push(categoryrow);
            }
        })

      
        console.log(categories);


        this.setState({
            categories:categories
        })
    }



    getothercategories(){
        axios.get('https://serviadvisor.com/otheractivitiesactive').then(res => {
            const data = res.data;
            if (data) {
                this.makerows(data);
            }
        })
    }

    render(){
        const settings = {
            dots: true,
            infinite: this.state.categories.length > 1,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1

        };
        return(
            <div>
                 
                 <div class="rec-blog">
                  <div class="container">
                      <div class="rec-blog-inner">
                        

                 <Slider {...settings}>
                    {this.state.categories.map((item2,index2) => 
                            <div>
                            {item2.map((item,index)=> 
                            <div class="gri-cat blog-ct">
                            <a href={"search/?category=" + item.id}>
                                {item.image_url && item.image_url !== "" ? 
                                <img src={item.image_url} alt="" class="img-responsive"/>
                                :
                                <img src="/images/foto-categorias.jpg" alt="" class="img-responsive"/>
                            }
                                </a>
                            <div class="blog-ct-title">
                                <a href="">{item.name}</a>
                            </div>
                        </div>
                            )}
                            </div>
                    )}



                 
                 </Slider>
                 
                 </div>
                    
                    </div>
                    </div>
                    </div>
        )
    }


    
}

export default Otrascontrenido;