import React,{Component} from 'react';
import Advertising from './menu/advertising';

class PublicidadFooter extends Component{
    render(){
        return(
        <div> 
            


            <div class="img-mariot-empresas">
            <Advertising adspaceid="2"></Advertising>    
            </div>



        </div>
        );
    }
}

export default PublicidadFooter;