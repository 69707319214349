import React, { Component } from 'react';
import Axios from 'axios';

class TituloPerfil extends Component {

  constructor(props) {
    super(props)
    this.state = {
      categoriesObject: [],
      activities: []
    }
  }

  componentDidMount() {
    Axios.get('https://serviadvisor.com/activities').then(res => {
      const activitiesdata = res.data;
      console.log(activitiesdata);
      this.setColumns(activitiesdata);
      this.setState({
        activities: activitiesdata
      })
    });
  }

  setColumns(activities) {
    let activitiesobj = [];
    let activityRow = []
    let count = 0;

    activities.forEach((item, index) => {

      activityRow.push(item);
      count++;
      if (count === 5) {
        activitiesobj.push(activityRow)
        activityRow = []

        count = 0;
      }

      if (activities.length == index + 1 && count < 5) {

        activitiesobj.push(activityRow);
      }
    })

    this.setState({
      categoriesObject: activitiesobj
    })

    console.log(activitiesobj);
  }

  render() {
    return (
      <div>

        <div class="menu-cate-empre">
          <div id="content" class="content">
            <h2 class="">¿Qué Estás Buscando?</h2>
          </div>

          <div id="content" class="content cat-emp">




            {this.state.categoriesObject.map((item, index) => {

              return (
                <div class="col-md-2 fuente-lista margen">
                  <ul class="quitar-punto">
                    {item.map((item2, index2) => {
                      return (
                        
                          <li class="menu-cate-aba"><a href={"/search/?category=" + item2.id}>{item2.name}</a></li>
                         
                        
                       
                      )
                    })}
                    
                      {this.state.categoriesObject.length === (index + 1) && (<li class="menu-cate-aba"><a href="/otrascategorias">Otras Categorías</a></li>)}
                  </ul>

                </div>
              )



            })}




          </div>
          <div class="limp"></div>

        </div>







      </div>
    );
  }
}

export default TituloPerfil;