import React, { Component } from 'react';
import axios from 'axios';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

class Articulos extends Component {
    constructor(props) {
        super(props)
        this.getarticles = this.getarticles.bind(this);

        this.state = {
            articles: [],
            editorState: EditorState.createEmpty(),
            type: "list",
            actualtitle: undefined,
            actualArticleid: undefined,
            filetoupload: undefined,
            shorttext: undefined,
            activityId: undefined,
            comentList:[],
            activities:[]
        }
        this.onChange = (editorState) => this.setState({ editorState });

        this.rendermode = this.rendermode.bind(this);
        this.renderlist = this.renderlist.bind(this);
        this.rendernew = this.rendernew.bind(this);
        this.renderedit = this.renderedit.bind(this);
        this.getarticles = this.getarticles.bind(this);
        this.getarticleData = this.getarticleData.bind(this);
        this.makenewArticle = this.makenewArticle.bind(this);
        this.deletepost = this.deletepost.bind(this);
        this.editarticle = this.editarticle.bind(this);
        this.getactivities = this.getactivities.bind(this);
        this.handlecombochange = this.handlecombochange.bind(this);
        this.onchangeshortdescription = this.onchangeshortdescription.bind(this);
    }

    handlecombochange = e => {
        console.log(e.target.value);
        this.setState({
           activityId: e.target.value
        })
    
      }


    changetype = (type) => {

        this.setState({
            type: type,
            actualtitle: undefined,
            actualArticleid: undefined,
            filetoupload:undefined,
            editorState: EditorState.createEmpty()
        })
    }

    getarticleData() {
        const article = "";
        this.setState({
            editorState: article ? EditorState.createWithContent(convertFromRaw(article)) : EditorState.createEmpty()
        })
    }


    deletepost(postid, e) {
        axios.delete('https://serviadvisor.com/blog/' + postid, { withCredentials: true }).then(res => {
            const data = res.data;
            if (!data.error) {
                this.getarticles();
            }
        })
    }


    editarticle(articleid) {
        axios.get('https://serviadvisor.com/article/' + articleid).then(res => {
            const data = res.data

            if (data) {
                this.setState({
                    actualtitle: data.title,
                    actualArticleid: articleid,
                    shorttext: data.shorttext,
                    activityId: data.activityId
                }, () => {
                    let articletext;

                    try {
                        articletext = JSON.parse(data.fulltext)
                    } catch (e) {
                        articletext = null
                    }


                    this.setState({
                        editorState: articletext ? EditorState.createWithContent(convertFromRaw(articletext)) : EditorState.createEmpty()
                    })
                })
            }
        }

        )
        this.changetype("edit");
    }

    updatearticle = async (e) => {


        const fulltext = await convertToRaw(this.state.editorState.getCurrentContent())

        const data = new FormData()
        const file = this.state.filetoupload;
        
        if(this.state.filetoupload){
            data.append('file', file)
        }
       
        data.append('article',JSON.stringify(fulltext))
        data.append('title',this.state.actualtitle);
        data.append('shorttext',this.state.shorttext);
        data.append('activityId',this.state.activityId);

        /*
        const data = {
            article: JSON.stringify(fulltext),
            title: this.state.actualtitle
        }
        */
        axios.put('https://serviadvisor.com/blog/' + this.state.actualArticleid, data, { withCredentials: true }).then(res => {
            const data = res.data;
            if (!data.error) {
                this.getarticles();
            }
        })

        this.changetype("list");
    }


    chandlechangefile = (e) => {
        console.log(e.target.files[0]);


        if(e.target.files[0].size > 1000000){
            alert("File is too big!");
          
         };

        this.setState({
            filetoupload:e.target.files[0]
        },() => {
            console.log(this.state.filetoupload)
        })
    }


    renderlist = () => {

        return (
            <div class="caja-articulos">
                <input type="button" onClick={(e) => this.changetype("new")} value="Agregar Nuevo articulo"></input>
                {this.state.articles.map((item, idex) => {
                    return (
                        <div>
                            <p>{item.title ? item.title : "sin titulo"} - {item.createdAt}


                                <button onClick={(e) => this.editarticle(item.id)}  class="btn btn-primary" value="Editar"> Editar</button>

                                <button onClick={(e) => this.deletepost(item.id, e)}  class="btn btn-success" value="Eliminar">Eliminar </button>
                                <button onClick={(e) => this.getcoments(item.id, e)}  class="btn btn-success" value="Comentarios"> Comentarios</button>

                                </p>
                        </div>
                    )
                })}
            </div>
        )
    }



    getcoments(articleId){
        axios.get('https://serviadvisor.com/getcoments/'+ articleId).then(res => {
            const data = res.data;
            this.setState({
                comentList:data
            })
        })

        this.setState({
            type:"coments"
        })
    }

    deletecoment(comentId,blogPostId){
        axios.delete('https://serviadvisor.com/comentadmin/' + comentId, {withCredentials:true}).then(res => {
            const data = res.data;
            if(!data.error){
                this.getcoments(blogPostId);
            }
        })
    }


    rendercoments = () =>{
        return(
            <div class="caja-comentarios">
                <h4>Comentarios</h4>
                {this.state.comentList.map((item,index)=> {
                    return(
                        <div>
                            <div>Usuario:  {item.user.username}</div>
                            <div> Comentario:  {item.coment}</div>
                            <div><input onClick={e => this.deletecoment(item.id,item.blogPostId)} type="button" value="eliminar"></input></div>
                            
                        </div>
                    )
                })}
                  <input id="verarticulos" onClick={(e) => this.changetype("list")} type="button" value="Ver todos los articulos"></input>
            </div>
        )
    }

    rendernew = () => {
        return (
            <div class="caja-crear-articolos">

                <h4>Creacion de articulo</h4>
                <div>
                    <label  for="tituloarticulo">Titulo de Articulo  </label>
                    <input autocomplete="false"  class="form-control" onChange={this.onchangetitle} id="tituloarticulo" type="text"></input>

                    <label for="categoriaarticulo">Categoria </label>
                    <select onChange={this.handlecombochange} type="text" class="form-control mitad-2" id="categoriaarticulo" placeholder="Categoria">
                      <option disabled selected>Selecciona una categoria</option>
                      {this.state.activities.map((item, index) => <option key={index} value={item.id}>{item.name}</option>)}
                    </select>

                    <label for="imagenarticulo">Imagen de articulo  </label>
                    <input   class="form-control" accept="image/png, image/jpeg" onChange={this.chandlechangefile} id="imagenarticulo" type="file"></input>

                    <label for="descripcioncorta">Texto Corto </label>
                    <textarea class="form-control" id="descripcioncorta" onChange={this.onchangeshortdescription}></textarea>

                </div>
                <div >
                    <Editor
                        editorState={this.state.editorState}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        onEditorStateChange={this.onChange}
                    />
                </div>


                    <button id="guardararticulo"  onClick={this.makenewArticle}   class="btn btn-primary" value="Guadar"> Guadar</button>

                    <button id="verarticulos" onClick={(e) => this.changetype("list")}  class="btn btn-success" value="Ver todos los articulos"> Ver todos los articulos </button>

            </div>

        )
    }

    getoption(cityId, item2, index) {
        if (cityId === item2.id) {
          return (
            <option key={index} value={item2.id} selected>{item2.name}  </option>
          )
        } else {
          return (
            <option key={index} value={item2.id}>{item2.name}  </option>
          )
        }
      }



    renderedit = () => {
        return (
            <div class="caja-editar-articulo">
                <h4>Editar Articulo</h4>
                <div class="cont-editar-articulo">
                    <label for="tituloarticulo">Titulo de Articulo  </label>
                    <input class="form-control" onChange={this.onchangetitle} id="tituloarticulo" type="text" value={this.state.actualtitle}></input>
                    
                    <select onChange={this.handlecombochange} type="text" class="form-control mitad-2" id="categoriaarticulo" placeholder="Categoria">
                      <option disabled selected>Selecciona una categoria</option>
                      {this.state.activities.map((item, index) => this.getoption(this.state.activityId, item, index))}
                    </select>
                    
                    <label for="imagenarticulo">Imagen de articulo  </label>
                    <input class="form-control" accept="image/png, image/jpeg" onChange={this.chandlechangefile} id="imagenarticulo" type="file" ></input>

                    <label for="descripcioncorta">Texto Corto  </label>
                    <textarea class="form-control" id="descripcioncorta" onChange={this.onchangeshortdescription} value={this.state.shorttext}></textarea>
                </div>
                <div >
                    <Editor
                        editorState={this.state.editorState}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        onEditorStateChange={this.onChange}
                    />
                </div>
                <button id="guardararticulo"  onClick={this.updatearticle}   class="btn btn-primary" value="Guadar"> Guadar</button>

                <button id="verarticulos" onClick={(e) => this.changetype("list")}  class="btn btn-success" value="Ver todos los articulos"> Ver todos los articulos </button>

                
            </div>
        )
    }

    rendermode() {

        switch (this.state.type) {
            case "list":
                return this.renderlist()
                break;
            case "new":
                return this.rendernew()
                break;
            case "edit":
                return this.renderedit()
                break;
            case "coments":
                return this.rendercoments();
                break;
        }
    }


    componentDidMount() {
        this.getarticles();
        this.getactivities();
    }


    getactivities(){
        axios.get("https://serviadvisor.com/activities").then(res => {
            const data = res.data;
            this.setState({
                activities:data
            })
        })
    }

    getarticles() {
        axios.post('https://serviadvisor.com/blogarticles', null, { withCredentials: true }).then(res => {
            const data = res.data;
            if (data && !data.error) {

                this.setState({
                    articles: data
                })
            }
        })
    }

    onchangetitle = (e) => {
        const title = e.target.value;
        this.setState({
            actualtitle: title
        })
    }

    onchangeshortdescription = (e) => {
        const short = e.target.value;
        this.setState({
            shorttext: short
        })
    }

    makenewArticle = async (e) => {
        try {
            const fulltext = await convertToRaw(this.state.editorState.getCurrentContent())


            const data = new FormData()
            const file = this.state.filetoupload;
         
            data.append('file', file)
            data.append('article',JSON.stringify(fulltext))
            data.append('title',this.state.actualtitle);
            data.append('shorttext',this.state.shorttext);
            data.append('activityId',this.state.activityId);

            /*
            const data = {
                article: JSON.stringify(fulltext),
                title: this.state.actualtitle,
                shorttext: this.state.shorttext
            }
            */

            axios.post('https://serviadvisor.com/blog', data, { withCredentials: true }).then(res => {
                this.getarticles();
            })
        } catch (e) {
            console.log(e.message)
        }

    }



    render() {
        return (
            <div>

                {this.rendermode()}


            </div>
        )
    }
}

export default Articulos;