import React,{Component} from 'react';
import Axios from 'axios';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import notifications from '../../../utils/notifications';

class PerfilSection extends Component{
    constructor(props){
        super(props);
        this.state={
            editorState: EditorState.createEmpty()
        }
        this.onChange = (editorState) => this.setState({ editorState });
        this.notify = this.notify.bind(this);
    }

    getlistofCategories(list){
        
        console.log(list)
        let html=""

        list.forEach(element => {
            html+= element.activity.name + ", "
        })

        return(
           <span>{html}</span>
        )
    }

    componentDidMount(){
       
        this.props.sockets.sockets.callback=this.notify
    }

    notify(data){
          console.log(this.props.user);
          notifications.buildNotification(data.type, data.message, this.props.user.accountType, data.senderid, data.profileId); 
  }

    render(){
    return(
        <div>
               <div class="title-perfil-empresa espacio-top">
                        <h4 class="azul"> <a href="#">Información</a></h4>
                      </div>
                      <p class="color-gris">{this.getlistofCategories(this.props.categorieslists)} /  {this.props.city.name}</p>
                      <p class="sub-indice negrilla">Precio: Desde ${this.props.start_price} - ${this.props.end_price}</p>
    
                      {/*<p class="espacios-parrafos">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>*/}
    
                      <div class="title-perfil-empresa">
                        <h4 class="azul"> <a href="#">Descripción</a></h4>
                      </div>
                      {this.props.shortdescription}
    
                      <Editor
                          toolbarHidden 
                          readOnly
                          editorState={this.props.editorState}
                          wrapperClassName="demo-wrapper"
                          editorClassName="demo-editor"
                          onEditorStateChange={this.onChange}
                        />
                     
        </div>
        )
    }
}

export default PerfilSection