import React, {Component} from 'react';
import HeaderEditar from '../HeaderEditar';
import TituloPerfil from '../TituloPerfil';
import Footer from '../Footer';
import ContenidoPerfilClienteEditar from '../ContenidoPerfilClienteEditar';
import sockets from '../../sockets/sockets';
import profileutils from '../../utils/profiledata';


class PerfilClienteEditar extends Component{
    constructor(props){
        super(props);
        this.state={
            tab:"",
            islogin: false,
            user: undefined
        }

        this.settab= this.settab.bind(this);
        this.setactiveMenu = this.setactiveMenu.bind(this); 
        this.logindata = this.logindata.bind(this);
        const user = profileutils.getuserprofile();
        console.log(user);
        if (user) {
            sockets.subscribeUserToSocket(user.userid)
            this.setState({
                user: user
            })
        }
    }

    logindata = (user, islogin) => {
        this.props.loginroot(user,islogin);
        if (!islogin) {
            profileutils.deleteprofile();
        }
        this.setState({
            user: user,
            islogin: islogin
        })
        profileutils.setuserprofile(user);
        if (user) {
            sockets.subscribeUserToSocket(user.userid);
            sockets.subscribeChat();
        }
    }


    
    componentDidMount(){
       

        let param = this.props.match.params.tab;
        if(param === undefined || param === "" ){
            param="perfil"
        }

        this.setState({
            tab:param
          });


          if(this.state.user && this.state.user.accountType==="cliente"){
            this.setactiveMenu(param);
          }
        

         // this.getproviderinfo();
    }

    settab(name) {
        this.setState({
            tab:name
        })

        this.props.history.push('/perfil-cliente/'+ name);
        this.setactiveMenu(name);
    }

    setactiveMenu(tab){

        const tabs = ["calendario","propuestas","favoritos","notificaciones","perfil","account","pagos"];

        tabs.map((item,index)=>{
            document.getElementById(item).className="";
        })
        
        if(document.getElementById(tab)){
        document.getElementById(tab).className += " activo"
       
        }
        console.log(tab);
    }


    render(){
        return(
            <div>
                <HeaderEditar sockets={sockets} slideout={this.props.slideout} user={this.state.user} logindata={this.logindata} islogin={this.state.islogin} history={this.props.history}></HeaderEditar>
                {this.state.user && this.state.user.accountType==="cliente" ?
                     <ContenidoPerfilClienteEditar user={this.state.user} islogin={this.state.islogin} onactivemenu={this.setactiveMenu} sockets={sockets} tab={this.state.tab} onChangetab={this.settab}  params={this.props.match.params}></ContenidoPerfilClienteEditar>
                     :
                     <h4>Logueate con una cuenta de cliente para acceder</h4>
                }
               
                <Footer user={this.state.user} islogin={this.state.islogin}></Footer>
            </div>
        );
    }
}

export default PerfilClienteEditar;