import React, { Component } from 'react';
import { Redirect, Switch, Route, withRouter } from 'react-router-dom';
import GoogleLogin from 'react-google-login';
//import FacebookLogin from 'react-facebook-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
const validpassword = RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})");


class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      emailsign:'',
      passwordsign:'',
      password: '',
      secondpassword: '',
      redirect: false,
      redirecturl: '',
      modallogin: false,
      errors:{
        email:true,
        emailsign:true,
        passdis:true,
        passinvalid:true,
      }
    }

    this.responseGoogle = this.responseGoogle.bind(this);
    this.responseFacebook = this.responseFacebook.bind(this);



    this.handleSumitClick = this.handleSumitClick.bind(this);
    this.handleSumitClicklogin = this.handleSumitClicklogin.bind(this);
    this.handletextChange = this.handletextChange.bind(this);
    this.validatePassword = this.validatePassword.bind(this);
    this.validateUsername = this.validateUsername.bind(this);
    this.setRedirect = this.setRedirect.bind(this);
    this.renderRedirect = this.renderRedirect.bind(this);
    this.checkerrors = this.checkerrors.bind(this);


  }


  checkerrors(){
    if(!this.state.errors.emailsign){
      return false;
    }

    if(!this.state.errors.passdis){
      return false;
    }

    if(!this.state.errors.passinvalid){
      return false;
    }

    return true;
  }

  handletextChange(name, event) {


    this.setState({ [name]: event.target.value });

    if (name === "secondpasswordsign") {
      this.validateSecondPassword(event);
    }

    if (name === "username") {
      this.validateUsername(event);
    }

    if (name === "password") {
      this.validatePassword(event);
    }

    if (name === "passwordsign") {
      this.validatePassword(event);
    }

    if(name==="email"){
      this.validateemail(event,false);
    }

    if(name==="emailsign"){
      this.validateemail(event,true);
    }

  }

  validateemail(event,sign) {
    const email = event.target.value
    const checkemail = validEmailRegex.test(event.target.value)
    const errors = this.state.errors
    if(sign){
      errors.emailsign = checkemail
    }else{
      errors.email = checkemail
    }
 
    this.setState({ errors: errors });
  }

  validatePassword(event) {
    const password= event.target.value;
    let checkpass = validpassword.test(password);
    const errors = this.state.errors;
    errors.passinvalid= checkpass;

    this.setState({
      errors
    })
  }

  validateSecondPassword(event) {
    const password = this.state.passwordsign;
    const second = event.target.value;
    
    
    let errors = this.state.errors;
    if (second === password) {
      errors.passdis = true;
    }
    else {
    
      errors.passdis = false;
    
    }
    this.setState({
      errors:errors
    })
  }

  validateUsername(event) {

  }



  responseGoogle = (response) => {
    console.log(response);
    const data = {
      accounttype: "empresa",
      access_token: response.accessToken
    }

    console.log(data);
    axios.post('https://serviadvisor.com/oauth/google', data, { withCredentials: true }).then(res => {
      console.log("login desde server");

      if (res.data.error) {
        const notify = () => toast.error(res.data.error);
        notify();
      }




      if (res.data.accountcreated) {
        this.signupclose.click();

        const accountcreated = res.data.accountcreated;
        if (accountcreated === true) {

          // this.setRedirect('/registro-empresa2');
          this.props.history.push('/registro-empresa');
        }
      }


    })

  }

  responseGooglelogin = (response) => {
    console.log(response);
    const data = {
      accounttype: "empresa",
      access_token: response.accessToken
    }

    console.log(data);
    axios.post('https://serviadvisor.com/oauth/googlelogin', data, { withCredentials: true }).then(res => {
      console.log("login desde server");

      if (res.data.error) {
        const notify = () => toast.error(res.data.error);
        notify();
      } else {
        console.log("login desde server");
        const data = res.data;
        const userdata = {
          userid: data.userid,
          username: data.username,
          photo_url: data.photo_url,
          active: '',
          accountType: data.accountType
        }

        this.props.logindata(userdata,true);
        this.loginclose.click();
      }
     


    })

  }

  responseFacebook = (response) => {
    console.log(response);
    const data = {
      accounttype: "empresa",
      access_token: response.accessToken
    }

    console.log(data);
    axios.post('https://serviadvisor.com/oauth/facebook', data, { withCredentials: true }).then(res => {
      console.log("login desde server");

      if (res.data.error) {
        const notify = () => toast.error(res.data.error);
        notify();
      }




      if (res.data.accountcreated) {
        this.signupclose.click();

        const accountcreated = res.data.accountcreated;
        if (accountcreated === true) {

          // this.setRedirect('/registro-empresa2');
          this.props.history.push('/registro-empresa');
        }
      }




    }).catch(error => {
      const notify = () => toast(error);
      notify();
    })
  }

  responseFacebooklogin = (response) => {
    console.log(response);
    const data = {
      accounttype: "empresa",
      access_token: response.accessToken
    }

    console.log(data);
    axios.post('https://serviadvisor.com/oauth/facebooklogin', data, { withCredentials: true }).then(res => {
      console.log("login desde server");
      console.log(res.data);
      this.loginclose.click();

    })
  }


  handleSumitClick(event) {
    event.preventDefault();

    if(!this.checkerrors()){
      return 
    }

    const newuser = {
      accounttype: "empresa",
      email: this.state.emailsign,
      password: this.state.passwordsign,
    }


    axios.post('https://serviadvisor.com/singup', newuser).then(response => {

      if (response.data.error) {
        toast.error(response.data.error);
      
      } else {

        const logincredentials = {
          email: this.state.emailsign,
          password: this.state.passwordsign
        }
        console.log(response);
        axios.post('https://serviadvisor.com/login', logincredentials, {withCredentials:true}).then(response => {
         
          this.signupclose.click();
          this.props.history.push('/registro-empresa');
          console.log(response);
        })
      }
    })
  }


  handleSumitClicklogin(event) {
    event.preventDefault()
    const logincredentials = {
      email: this.state.email,
      password: this.state.password,
    }

    axios.post('https://serviadvisor.com/login', logincredentials, {withCredentials:true}).then(response => {
      const data = response.data.userData;

      if (!data) {
        const notify = () => toast.error("Usuario O contraseña Invalida");
        notify();
      }else{
        const userdata = {
          userid: data.userid,
          username: data.username,
          photo_url: data.photo_url,
          active: data.active,
          accountType: data.accountType
        }
        this.props.logindata(userdata,true);
        this.loginclose.click();
        console.log(response);
      }

 
    }).catch(error => {
      const notify = () => toast.error("Usuario O contraseña Invalida");
      notify();
    })

    

  }

  clickclosemodal = (e) => {
    this.inputElement.click();
  }


  loginbuttons = () => {

   
    let html;

    if (!this.props.islogin) {
     
      console.log("is login: " + this.state.islogin)
      return (
        
            <div class="foot-empr  res-cuad2">
                <div class="btn-footer log">
                  <button type="button" class="btn btn-primary btn-lg" data-toggle="modal" data-target="#myModal-reg-emp">
                    Registra tu Empresa
</button>
                </div>
                <div class="btn-footer log">
                  <button type="button" class="btn btn-primary btn-lg" data-toggle="modal" data-target="#myModal">
                    Ingresa a tu cuenta
</button>
                </div>


              </div>
        
      )
    }
   
  }

  setRedirect = (url) => {
    this.setState({
      redirecturl: url,
      redirect: true
    })
  }

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirecturl} push />
    }
  }

  render() {
    return (



      <div>
        <ToastContainer />
        {this.renderRedirect()}
        <div id="footer" class="fot-men">
          <div class="footer-widget">
            <div class="container">
              <div class="container">
                <p>¡Bienvenido a SERVIADVISOR!</p>
              </div>
            </div>

          </div>
          <div class="footer-menu">
            <div class="container ">

              <div class="foot-caja-1">
                <div class="col-foot-6 res-cuad1">
                  <div class="btn-footer-eres">
                    <a href="#">¿Eres un proveedor que ofrece excelente calidad a sus clientes?</a>
                  </div>
                </div>
                {this.loginbuttons()}
              </div>

              <div class="foot-caja-2">
                <div class="foot-menu-2 col-foot-8">
                  <ul>
                    <li class="menu-inf"><a href="#">Quiénes somos</a></li>
                    <li class="menu-inf"><a href="#">Términos y Condiciones</a></li>
                    <li class="menu-inf"><a href="#">Políticas de Privacidad</a></li>
                    <li class="menu-inf"><a href="#">Contáctanos</a></li>
                    <li class="menu-inf"><a href="#">Ayuda</a></li>
                    
                  </ul>
                </div>

                <div class="foot-menu-3 col-foot-4">
                  <ul class="ft-soc clearfix">
                    <li><a href="#"><img src="images/fa.png" alt="" class="img-responsive" /></a></li>
                    <li><a href="#"><img src="images/tw.png" alt="" class="img-responsive" /></a></li>
                    <li><a href="#"><img src="images/p.png" alt="" class="img-responsive" /></a></li>
                    <li><a href="#"><img src="images/you.png" alt="" class="img-responsive" /></a></li>

                  </ul>


                </div>
              </div>
            </div>
          </div>








          <div class="footer-text">
            <div class="container">
              <p>Copyright 2019 - ServiAdvisor. Todos los derechos Reservados</p>
            </div>
          </div>

        </div>


        <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <button ref={input => this.loginclose = input} type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <h4 class="modal-title" id="myModalLabel">INGRESAR A MI CUENTA DE EMPRESA</h4>
                <hr />
                <div class="flecha-down"></div>
              </div>
              <div class="modal-body">

                <div class="con-fac">¿No tienes cuenta? <button type="button" class="btn-regs-pop" data-toggle="modal" data-target="#myModal-reg-emp" data-dismiss="modal" aria-label="Close">Registrarse</button></div><br />



                <FacebookLogin
                  appId="311566446451744"
                  autoLoad={false}
                  fields="name,email,picture"
                  onClick={this.componentClicked}
                  render={renderProps => (
                   

                    <button class="btn btn-social ext-reg-btn fb-btn" data-pa-name="intro_login_fb" onClick={renderProps.onClick}>
                      <div class="text-con"> <i class="fab fa-facebook-square"></i> <span> Continuar con Facebook</span>
                      </div>
                    </button>

                  )}
                  //onClick={this.componentClicked}
                  callback={this.responseFacebooklogin}></FacebookLogin>

                <br />
                <GoogleLogin
                  clientId="154537549270-knt6kr4a3epb30eed3ntrcl35dtsnt1p.apps.googleusercontent.com"
                  buttonText="Login Con Google"
                  render={renderProps2 => (
                   
                    <button class="btn btn-social ext-reg-btn gp-btn" data-pa-name="intro_login_google" id="gp-auth-btn" onClick={renderProps2.onClick} >
                    <div class="text-con"> <i class="fab fa-google-plus"></i> <span>Continuar con Google</span> </div></button>

                  )}
                  onSuccess={this.responseGooglelogin}
                  onFailure={this.responseGooglelogin}
                  cookiePolicy={'single_host_origin'}
                ></GoogleLogin>


                <hr />
                <div class="con-form">
                  <div class="tit-ses">Ingresa con tu dirección de correo electronico</div>
                  <input type="text" class="form-control" id="email" placeholder="Email" onChange={(e) => this.handletextChange("email", e)} />
                  {!this.state.errors.email && (<div class="row"><i class="fas fa-times"></i><span>Por favor ingrese un correo valido</span></div>)}
                  <input type="password" class="form-control" id="contraseña" placeholder="Contraseña" onChange={(e) => this.handletextChange("password", e)} />
                  <div className="tit-olv"><a href="/change">¿Olvidaste tu Contraseña?</a></div>
                </div>
              </div>
              <div class="modal-footer">

                <button onClick={e => this.handleSumitClicklogin(e)} type="button" class="btn btn-primary">Ingresar</button>
              </div>
            </div>
          </div>
        </div>





        <div class="modal fade" id="myModal-reg-emp" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" ref={input => this.signupclose = input} class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <h4 class="modal-title" id="myModalLabel">CREAR MI CUENTA DE EMPRESA</h4>
                <hr />
                <div class="flecha-down"></div>
              </div>
              <div class="modal-body">

                <div class="con-fac">Regístrate y accede a todos los servicios que ServiAdvisor tiene para ti<br /><br />

                  ¿Ya tienes cuenta? <button type="button" class="btn-regs-pop" data-toggle="modal" data-target="#myModal" data-dismiss="modal" aria-label="Close"><span>Acceder a tu cuenta</span></button> </div><br />


                <FacebookLogin
                  appId="311566446451744"
                  autoLoad={false}
                  fields="name,email,picture"
                  onClick={this.componentClicked}
                  render={renderProps => (
                  

                    <button class="btn btn-social ext-reg-btn fb-btn" data-pa-name="intro_login_fb" onClick={renderProps.onClick}>
                      <div class="text-con"> <i class="fab fa-facebook-square"></i> <span> Continuar con Facebook</span>
                      </div>
                    </button>

                  )}
                  //onClick={this.componentClicked}
                  callback={this.responseFacebook}></FacebookLogin>

                <br />
                <GoogleLogin
                  clientId="154537549270-knt6kr4a3epb30eed3ntrcl35dtsnt1p.apps.googleusercontent.com"
                  buttonText="Login Con Google"
                  render={renderProps2 => (
                   

                    <button class="btn btn-social ext-reg-btn gp-btn" data-pa-name="intro_login_google" id="gp-auth-btn" onClick={renderProps2.onClick} >
                      <div class="text-con"> <i class="fab fa-google-plus"></i> <span>Continuar con Google</span> </div></button>

                  )}
                  onSuccess={this.responseGoogle}
                  onFailure={this.responseGoogle}
                  cookiePolicy={'single_host_origin'}
                ></GoogleLogin>




                <hr />
                <div class="con-form">
                  <div class="tit-ses">Ingresa con tu dirección de correo electronico</div>
                  <input type="text" class="form-control" id="email" placeholder="Email" onChange={(e) => this.handletextChange("emailsign", e)} />
                  {!this.state.errors.emailsign && (<div class="row"><i class="fas fa-times"></i><span>Por favor ingrese un correo valido</span></div>)}
                  <input type="password" class="form-control" id="contraseña" placeholder="Contraseña" onChange={(e) => this.handletextChange("passwordsign", e)} />
                  {!this.state.errors.passinvalid && (<div class="row"><i class="fas fa-times"></i><span>La contraseña debe contener al menos: una letra mayuscula, una letra minuscula, un numero y ser mayor de 6 caracteres</span></div>)}
                  <input type="password" class="form-control" id="contraseña" placeholder="Confirmar Contraseña" onChange={(e) => this.handletextChange("secondpasswordsign", e)} />
                  {!this.state.errors.passdis && (<div class="row"><i class="fas fa-times"></i><span>Las contraseñas no coinciden</span></div>)}
                  <div className="tit-olv"><a href="/change">¿Olvidaste tu Contraseña?</a></div>
                </div>
              </div>
              <div class="modal-footer">

                <button onClick={e => this.handleSumitClick(e)} type="button" class="btn btn-primary">Ingresar</button>
              </div>
            </div>
          </div>
        </div>



        <div class="modal fade" id="myModal-ayuda-emp" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" >
          <div class="modal-dialog-guia" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" ref={input => this.signupclose = input} class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <h4 class="modal-title" id="myModalLabel">Guía Negociación</h4>
                <hr />
                <div class="flecha-down"></div>
              </div>
              <div class="modal-body">

               


              <img src="/images/Ciclo-Serviadvisor-empresa.png" class="img-guia" />



                <hr />
                <div class="con-fo">
                 
                </div>
              </div>
              <div class="modal-footer">

                
              </div>
            </div>
          </div>
        </div>


        <div class="modal fade" id="myModal-ayuda-cli" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" >
          <div class="modal-dialog-guia" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" ref={input => this.signupclose = input} class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <h4 class="modal-title" id="myModalLabel">Guía Negociación</h4>
                <hr />
                <div class="flecha-down"></div>
              </div>
              <div class="modal-body">

               

              <img src="/images/Ciclo-Serviadvisor-cliente.png" class="img-guia" />


                <hr />
                <div class="con-fo">
                 
                </div>
              </div>
              <div class="modal-footer">

                
              </div>
            </div>
          </div>
        </div>








      </div>
    );
  }
}

export default withRouter(Footer);