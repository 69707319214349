import React, { Component } from 'react';
import Moment from 'react-moment';

class ChatMessage extends Component {
    constructor(props) {
        super(props)


    }

    render() {
        return (
            <div>
                
                <div class="caja-chat">
                    <div class="img-caja-chat"><a href="#"><img src={this.props.message.user.photo_url === null ? ("/images/fotoperfil.png") : (this.props.message.user.photo_url)} class="img-responsive" /></a></div>
                    <div class="titulo-chat">{this.props.message.user.username === null ? ("Usuario") : (this.props.message.user.username)}</div>
                    <div class="caja-chat-cir">
                        <p>{this.props.message.message}</p>
                    </div>
                </div>
                <p class="alinear-derecha-fecha"><Moment format={"DD MMM YYYY hh:mm a"}>{this.props.message.createdAt}</Moment></p>
                <div class="limp"></div>
            </div>
        )
    }
}

export default ChatMessage;