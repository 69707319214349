import socketIOClient from "socket.io-client";

const sockets = [];
let socket;

sockets.connect = () =>{
    socket = socketIOClient("https://serviadvisor.com");
    sockets.socket=socket;
       
    socket.on("connection", data => {
        console.log(data);
    });
}

sockets.connect();

sockets.subscribeUserToSocket = (user) => {
    if(sockets.Isregistered === false){
        socket.emit("subscribeUser", user);
        sockets.Isregistered = true;
    }
}

sockets.Isregistered = false;
sockets.IschatRegistered = false
sockets.callback = undefined;
sockets.notifyListcallback = undefined;

sockets.subscribeChat =  function() {
    if(sockets.IschatRegistered === false){
        socket.on("notify", data => {
            console.log("notifychat");
            //cb(data);
            
            sockets.callback(data);
            sockets.notifyListcallback(data);
        }
        );
        sockets.IschatRegistered = true;
    }
   
}

sockets.unsubscribechat = function() {
    socket.off("notify", data => {
        
    })
}

sockets.subscribelistOfchats = (cb) => {
    socket.on("notify", data => {
        console.log("notifychat");
        cb(data);
    }
    );
}

sockets.subscribenotify = (cb) => {
    socket.on("notify", data => {
        console.log("chatlist");
        cb(data);
    }
    );
}


export default sockets