import React, { Component } from 'react';
import axios from 'axios';
import Dropzone from 'react-dropzone';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Modal, Button } from 'react-bootstrap';
import {Progress} from 'reactstrap';
import notifications from '../../utils/notifications';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';

class Perfilcliente extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      city: "",
      cel: "",
      email: "",
      birthdate: "",
      client: [],
      profilephotouploadprogress: 0,
      crop: {
        aspect: 3 / 2,
        unit: '%',
        x: 0,
        y: 0,
        width: 50,
        height: 50
      },
      profileimageloaded: false,
      googleaccount: false,
      facebookaccount: false,
      serviadvisorlogin: false,
      googlemeail: "",
      facebookemail: "",
      localemail: "",
      loaderSave:false,
      loadsuccess:false
    }
    this.handletextChange = this.handletextChange.bind(this);
    this.handleclicksubmit = this.handleclicksubmit.bind(this);
    this.notify = this.notify.bind(this);
    this.checkgoogleAccount = this.checkgoogleAccount.bind(this);
    this.checkFacebookAccount = this.checkFacebookAccount.bind(this);
    this.checkLocalAccount = this.checkLocalAccount.bind(this);
    this.checkaccounts = this.checkaccounts.bind(this);
  }

  componentDidMount() {
    axios.post('https://serviadvisor.com/checkclient/', null, { withCredentials: true }).then(res => {
      console.log(res);
      const clientdata = res.data;
      this.setState({
        client: clientdata,
        name: clientdata.name,
        city: clientdata.city,
        cel: clientdata.cel,
        birthdate: clientdata.birthdate,
        email: clientdata.user.email
      }, () => {
        if (this.state.client.user.photo_url !== "" && this.state.client.user.photo_url !== undefined) {
          this.setState({
            croppedImageUrl: this.state.client.user.photo_url
          })
          this.checkaccounts()
        }
      })
    })
    this.props.sockets.callback= this.notify
  }

  notify(data){
    notifications.buildNotification(data.type, data.message, data.accountType, data.senderid,data.profileId);
  }

  uploadprofilephoto() {
    const data = new FormData()
    const file = this.state.croppedimageblob;
    console.log(file);
    data.append('file', file)

    const config = {
      url: 'https://serviadvisor.com/addprofilephoto',
      method: 'post',
      data: data,
      withCredentials: true,
      onUploadProgress: ProgressEvent => {
        this.setState({
          profilephotouploadprogress: (ProgressEvent.loaded / ProgressEvent.total * 100)
        })
      }
    };
    axios.request(config).then(res => { // then print response status
      console.log(res.data);
      this.setState({
        profileimageloaded: true
      })
    });
  }


  handleclicksubmit = (e) => {
    const data = {
      city: this.state.city,
      cel: this.state.cel,
      birthdate: this.state.birthdate,
      name: this.state.name,
      email: this.state.email
    }
    this.setState({
      loaderSave:true
    })
    axios.put('https://serviadvisor.com/clients', data, { withCredentials: true }).then(res => {
      const data = res.data;
      if (!data.error) {
        this.setState({
          loaderSave:false,
          loadsuccess:true
        })
      }
    })
  }

  checkaccounts() {
    this.checkgoogleAccount();
    this.checkFacebookAccount();
    this.checkLocalAccount();
  }

  checkgoogleAccount() {
    axios.post('https://serviadvisor.com/checkGooglelogin', null, { withCredentials: true }).then(res => {
      const data = res.data;
      if (data.status === "ok") {

        this.setState({
          googleaccount: true,
          googlemeail: data.email
        })
      }
    })
  }

  checkFacebookAccount() {
    axios.post('https://serviadvisor.com/checkfacebooklogin', null, { withCredentials: true }).then(res => {
      const data = res.data;
      if (data.status === "ok") {

        this.setState({
          facebookaccount: true,
          facebookemail: data.email
        })
      }
    })
  }

  checkLocalAccount() {
    axios.post('https://serviadvisor.com/checklocallogin', null, { withCredentials: true }).then(res => {
      const data = res.data;
      if (data.status === "ok") {

        this.setState({
          serviadvisorlogin: true,
          localemail: data.email

        })
      }
    })
  }


  handletextChange(name, event) {
    if (name === "username") {
      this.validateUsername(event);
    }


    this.setState({ [name]: event.target.value });
  }

  handleclickuploadProfilePhoto = e => {
    e.preventDefault();
    this.uploadprofilephoto();
    this.setState({
      cropmodalshow: false
    })
  }

  handleOndropProfileImage = (files, rejectedfiles) => {
    if (files && files.length > 0) {
      this.setState({
        cropmodalshow: true,
        imgprofile: URL.createObjectURL(files[0])
      })
    }
  }

  handleoncrop = (crop) => {
    this.setState({ crop });
  }

  handleimageloaded = (image) => {
    this.imageRef = image;
  }

  handlecropComplete = (crop, pixelcrop) => {
    this.makeClientCrop(crop);
  }

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width * scaleX;
    canvas.height = crop.height * scaleY;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height *scaleY
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        this.setState({
          croppedimageblob: blob
        })
        resolve(this.fileUrl);
      }, "image/jpeg");
    });
  }


  render() {
    let modalClose = () => this.setState({ cropmodalshow: false });
    return (





      <div class="rec-blog perfil-empresa">
        <div class="cont">
          <div class="rec-blog">
            <div class="row">

              <div class="rlog">

                <div class="fondo-gris">
                  <div class="title-perfil-cliente espacio-top">
                  <h4 class="azul"> <span>Tu Perfil </span><hr class="naranja" /></h4>
                  </div>
                  <div class="col-cli-1">


                 
                    <Dropzone accept='image/jpeg, image/png' maxSize={this.state.maxImagesizeGal} onDrop={this.handleOndropProfileImage}>
                      {({ getRootProps, getInputProps }) => (

<div>
                   
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />


                          <div class="foto cliente">
                          {this.state.croppedImageUrl && (
                            <div class="img-det-client">
                              <img alt="Crop" style={{ height: "100%", width: "100%" }} src={this.state.croppedImageUrl} />
                            </div>
                          )}

                          {!this.state.croppedImageUrl && (
                            <div>
                              Clic o Arrastra para subir un archivo!
                                <div class="text-regis">Formato PNG O JPG de un maximo de 1MB</div>
                            </div>
                          )}
                          </div>
                       
                        
                      <Progress max="100" color="success" value={this.state.profilephotouploadprogress} >{Math.round(this.state.profilephotouploadprogress, 2)}%</Progress>
                      <button type="button" class="btn btn-success btn sel-arch">Seleccionar Foto</button>
                      </div>

</div>

                      )}
                    </Dropzone>
                 

                   
                  </div>
                  <div class="col-cli-2">
                    <div class="caja-campo">
                      <h4>Tu Nombre</h4>
                      <input type="text" class="form-control perfil-cliente-ed" id="nombre" placeholder="Nombre" onChange={(e) => this.handletextChange("name", e)} value={this.state.name} autocomplete="off" />
                    </div>
                    <div class="caja-campo">
                      <h4>Tu Correo</h4>
                    {this.state.serviadvisorlogin && (<div><h5>Cuenta Local</h5><label class="form-control perfil-cliente-ed">{this.state.localemail}</label></div>) }
                    {this.state.googleaccount && (<div><h5>Cuenta de Google vinculada</h5><label class="form-control perfil-cliente-ed">{this.state.googlemeail}</label></div>) }
                    {this.state.facebookaccount && (<div><h5>Cuenta de Facebook vinculada</h5><label class="form-control perfil-cliente-ed">{this.state.facebookemail}</label></div>) }
                      {/* <input type="hiden" class="form-control perfil-cliente-ed" id="email" placeholder="Correo electronico" onChange={(e) => this.handletextChange("email", e)} value={this.state.email} autocomplete="off" />*/}
                    </div>
                    <div class="caja-campo">
                      <h4>Tu número celular </h4>
                      <input type="text" class="form-control perfil-cliente-ed" id="cel" placeholder="Numero Celular" onChange={(e) => this.handletextChange("cel", e)} value={this.state.cel} autocomplete="off" />
                    </div>
                    <div class="caja-campo">
                      <h4>Tu Fecha de Nacimiento</h4>
                      <input type="date" class="form-control perfil-cliente-ed" id="birthdate" placeholder="Fecha de nacimiento" onChange={(e) => this.handletextChange("birthdate", e)} value={this.state.birthdate} autocomplete="off" />
                    </div>
                    <div class="caja-campo">
                      <h4>Tu Ciudad</h4>
                      <input type="text" class="form-control perfil-cliente-ed" id="city" placeholder="Ciudad" onChange={(e) => this.handletextChange("city", e)} value={this.state.city} autocomplete="off" />
                    </div>
                    <div class="caja-campo">
                      <button onClick={this.handleclicksubmit} class="form-control perfil-cliente-ed" type="button" value="Guardar">Guardar   <Loader
          visible={this.state.loaderSave}
          type="TailSpin"
          color="#00BFFF"
          height={13}
          width={13}
       
        /></button>

{this.state.loadsuccess && (<span class="alert"><i class="fas fa-check-circle"></i> Actualizacion realizada con exitó.</span>)}
                     
                    </div>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>


                      
        <Modal className="recorte" show={this.state.cropmodalshow} 
          {...this.props}
          size="lg"
          animation={false}

          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header >
            <Modal.Title id="contained-modal-title-vcenter">
              Imagen de Perfil
              <hr />
                <div className="flecha-down"></div>
          </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Seleccione el área de recorte para la imagen</h4>
            <div>
              <ReactCrop
                onChange={this.handleoncrop}
                src={this.state.imgprofile}
                crop={this.state.crop}
                onImageLoaded={this.handleimageloaded}
                onComplete={this.handlecropComplete}>

              </ReactCrop>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleclickuploadProfilePhoto} className={"btn-primary"}>Recortar y guardar</Button>
          </Modal.Footer>
        </Modal>



      </div>

    )
  }
}

export default Perfilcliente