import React,{Component} from 'react'
import axios from 'axios';
import notifications from '../../utils/notifications'

class ListOfChatsClient extends Component{

    constructor(props){
        super(props)
        this.state= {
            chats:[]
        }
        this.getlist = this.getlist.bind(this);
        this.goTomessages= this.goTomessages.bind(this);
        this.refreshchats = this.refreshchats.bind(this);
    }

    componentDidMount(){
        this.getlist();
        this.props.sockets.callback=this.refreshchats
    }

    getlist(){
        axios.post('https://serviadvisor.com/listchatsClient',null, {withCredentials:true}).then(res => {
            const data = res.data;
            if(data.error){

            }else{
                this.setState({
                    chats:data
                })
            }
        })
    }

    refreshchats(data){
        console.log("refresh chats")
        if(data.type==="chatlist"){
            this.getlist();
        }else{
            notifications.buildNotification(data.type, data.message, data.accountType, data.senderid, data.profileId,data.source);
            
        }
        
    }
    
    goTomessages = (chatid) => {
        this.props.Ongetmessages(chatid)
        console.log(this.props);
    }

    render(){
        return(
            <div>
                  
                
                <div class="title-perfil-cliente espacio-top"> 
            <h4 class="azul"> <span class="bg-blanco">Proveedores previamente contactados</span> <hr class="naranja" /> </h4>
            </div>
                 <div class="grid-p">
                  {this.state.chats.map((item,index)=>{
                      return( 
                        <div class="chat-micuenta">
                            <div class="foto-chat-cuen"> <img src={ item.provider.user.photo_url===null ? ("/images/fotoperfil.png") : (item.provider.user.photo_url)} alt="pav"/>  </div>
                            {/*<div class="tit-chat-cuen ">Usuario: {item.provider.user.username === undefined || item.provider.user.username===null ? (<span>Usuario</span>) : (item.provider.user.username)} </div> */}
                            <div class="tit-chat-cuen ">Nombre de la empresa:  {item.provider.business_name === undefined || item.provider.business_name===null ? (<span>Empresa</span>) : (item.provider.business_name)} </div>
                            <button class="btn-quitar"><a href={"/empresa/" + item.provider.id + "/contacto"}>Ir al chat</a></button>
                            <button  onClick={(e) => this.goTomessages(item.id)} class="btn-cont">Ver propuestas</button>
                            <div class="limp"></div>  
                          
                         </div>)
                  })}
                  
                  
                  </div>
            </div>
        );
    }
}

export default ListOfChatsClient;