import React, { Component } from 'react';
import $ from 'jquery';
import profileutils from '../../utils/profiledata';
import axios from 'axios';

class MenuEdicionClient extends Component {
    constructor(props){
        super(props);
        this.logaout = this.logaout.bind(this);
    }

    componentDidMount(){
        
        $('#btn-men-lat').click(function(){
          $('.men-lat-emp-res').show(0);
        });
        $('.tab-7 li').click(function(){
            $('.clad + .men-lat-emp-res').hide(0);
          
          });
          
          $(document).on('click','#btn-men-lat',function(){
            $(this).addClass('clad');
          });

          $('.tab-7 li').click(function(){
            $('#btn-men-lat').removeClass('clad');
          
          });   
    
    }

    logaout = async () => {


        axios.get('https://serviadvisor.com/logout', { withCredentials: true }).then(res => {
    
    
          profileutils.deleteprofile();
    
    
          window.location.replace("/");
        })
    
      }


    settab= (name) =>{
        this.props.onchangetab(name);
      
    }

    render() { 
        return (
            <div>
                <div class="col-md-3 blog-ct ">

                    <div class="azul "><h3>Mi Cuenta</h3></div>
                    
                    <div class="men-lat-emp" id="menu-lat-micuenta">
                        <ul class="tab-7">
                        <li id="cerrar"> <i class="fas fa-times"></i> </li>
                            <li id="perfil" onClick={(e) => this.settab("perfil")} class=""><p>Perfil</p> <div class="border-bot-gris"></div></li>
                            <li id="favoritos"  onClick={(e) => this.settab("favoritos")} class=""><p>Favoritos</p> <div class="border-bot-gris"></div></li>
                            <li id="propuestas" onClick={(e) => this.settab("propuestas")} class=""><p>Chat y Ofertas</p> <div class="border-bot-gris"></div></li>
                            <li id="calendario" onClick={(e) => this.settab("calendario")} class=""><p>Calendario</p> <div class="border-bot-gris"></div></li>

                            <li id="pagos" onClick={(e) => this.settab("pagos")} class=""><p>Pagos</p> <div class="border-bot-gris"></div></li>
                            <li id="notificaciones"  onClick={(e) => this.settab("notificaciones")} class=""><p>Notificaciones</p> <div class="border-bot-gris"></div></li>
                            <li id="account" onClick={(e) => this.settab("account")} class=""><p>Configuracion de Cuenta</p> <div class="border-bot-gris"></div></li>
                           
                        </ul>
                        <div class="cerrar-sesion"><a onClick={this.logaout} href="javascript:void(0)"><i class="fas fa-sign-out-alt"></i>
                        Cerrar Sesión</a></div>
                    </div>

                    <div id="btn-men-lat" ><i class="fas fa-bars"></i> Menú</div>

                    <div class="men-lat-emp-res" id="menu-lat-micuenta">
                        <ul class="tab-7">
                        <li id="cerrar"> <i class="fas fa-times"></i> </li>


                        <li id="perfil" onClick={(e) => this.settab("perfil")} class=""><p>Perfil</p> <div class="border-bot-gris"></div></li>
                            <li id="favoritos"  onClick={(e) => this.settab("favoritos")} class=""><p>Favoritos</p> <div class="border-bot-gris"></div></li>
                            <li id="propuestas" onClick={(e) => this.settab("propuestas")} class=""><p>Chat y Ofertas</p> <div class="border-bot-gris"></div></li>
                            <li id="calendario" onClick={(e) => this.settab("calendario")} class=""><p>Calendario</p> <div class="border-bot-gris"></div></li>
                            
                            <li id="pagos" onClick={(e) => this.settab("pagos")} class=""><p>Pagos</p> <div class="border-bot-gris"></div></li>
                            <li id="notificaciones"  onClick={(e) => this.settab("notificaciones")} class=""><p>Notificaciones</p> <div class="border-bot-gris"></div></li>
                            <li id="account" onClick={(e) => this.settab("account")} class=""><p>Configuracion de Cuenta</p> <div class="border-bot-gris"></div></li>
                           
                        </ul>

                        <div class="cerrar-sesion" ><a onClick={this.logaout} href="javascript:void(0)"><i class="fas fa-sign-out-alt"></i> Cerrar Sesión</a>
                        </div>
                    </div>



                    <div>
                    </div>
                    
                </div>
            </div>
        );
    }

}

export default MenuEdicionClient;