import React, { Component } from 'react';
import Axios from 'axios';
import { toast } from 'react-toastify';
import axios from 'axios'
import queryString from 'query-string';


const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);



class ChangePasswordContend extends Component {
    constructor(props) {
        super(props)

        this.state= {
            change:false,
            message:false,
            email:"",
            password:"",
            secondpassword:"",
            token:"",
            errors: {
                email: true,
                emailsign: true,
                passdis: true,
                passdislink: true,
              }
        }
      
      
        this.sendtokenrequest = this.sendtokenrequest.bind(this);
      }

    
      componentDidMount(){
      const token = this.props.params.token;
      
      if(token && token !==""){
        this.setState({
          change:true,
          token:token
        })
      }

      
      }
    
      sendchangerequest(){
        const data={
          password:this.state.password,
          token:this.state.token
        }
        axios.post('https://serviadvisor.com/changelocal',data,{withCredentials:true}).then(res => {
          const resdata = res.data;

          if(!resdata.error){

          }else{

          }
        })
      }

    checkerrors() {
        if (!this.state.errors.emailsign) {
          return false;
        }
    
        if (!this.state.errors.passdis) {
          return false;
        }
    
    
    
        return true;
      }


      sendtokenrequest = (e) => {
        const data={
          email:this.state.email
        }
        axios.post('https://serviadvisor.com/sendmailtoken',data,{withCredentials:true}).then(res => {
          const resdata = res.data;
          if(!resdata.error){
            this.setState({
              message:true
            })
          }else{
            const notify = () => toast.error("Correo Electronico Invalido");
            notify();
          }
        })
      }
    
      handletextChange(name, event) {
    
    
        this.setState({ [name]: event.target.value });
    
        if (name === "secondpassword") {
          this.validateSecondPassword(event);
        }
    
        if (name === "username") {
          this.validateUsername(event);
        }
    
        if (name === "password") {
          this.validatePassword(event);
        }
    
        if (name === "passwordsign") {
          this.validatePassword(event);
        }
    
        if (name === "email") {
          this.validateemail(event, false);
        }
    
        if (name === "emailsign") {
          this.validateemail(event, true);
        }
    
        if (name === "passwordlink") {
          //this.validateSecondPasswordlink(event, true);
        }
    
        if (name === "secondpasswordlink") {
          this.validateSecondPasswordlink(event);
        }
    
      }
    
      validateemail(event, sign) {
        const email = event.target.value
        const checkemail = validEmailRegex.test(event.target.value)
        const errors = this.state.errors
        if (sign) {
          errors.emailsign = checkemail
        } else {
          errors.email = checkemail
        }
    
        this.setState({ errors: errors });
      }
    
    
      validatePassword(event) {
    
      }
    
      validateSecondPassword(event) {
        const password = this.state.password;
        const second = event.target.value;
    
    
        let errors = this.state.errors;
        if (second === password) {
          errors.passdis = true;
        }
        else {
    
          errors.passdis = false;
    
        }
        this.setState({
          errors: errors
        })
      }
    
      validateSecondPasswordlink(event) {
        const password = this.state.passwordlink;
        const second = event.target.value;
    
    
        let errors = this.state.errors;
        if (second === password) {
          errors.passdislink = true;
        }
        else {
    
          errors.passdislink = false;
    
        }
        this.setState({
          errors: errors
        })
      }


    handlechangepass(event) {
        event.preventDefault();
    
        if (!this.state.errors.passdis) {
          return
        }
        
        const newpass = {
          password: this.state.password,
          token:this.state.token
        }

        
    
        axios.post('https://serviadvisor.com/changelocal', newpass, { withCredentials: true }).then(response => {
    
          if (response.data.error) {
            const notify = () => toast.error(response.data.error);
            notify();
          } else {
            this.setState({
              password: "",
              secondpassword: ""
            })
            const notify = () => toast.info("Contraseña cambiada");
            notify();
            this.props.history.push('/');
          }
    
        })
    
      }

    render() {
        return (
            <div>
                <div class="rec-blog perfil-empresa recar-contr">
                    <div class="container caj-recu">

                    {this.state.change ?
                    
                    <div className="caja-recu">
                      <div className="tit-ses-rec">Cambiar Tu Contraseña</div>
                      <div className="caja-recu-form">
                    <input onChange={(e) => this.handletextChange("password", e)} type="password" class="form-control perfil-cliente-ed" id="nombre" placeholder="Contraseña" value={this.state.password} /><div class="editar"></div>
                    <input onChange={(e) => this.handletextChange("secondpassword", e)} type="password" class="form-control perfil-cliente-ed" id="nombre" placeholder="Contraseña" value={this.state.secondpassword} /><div class="editar"></div>
                    <button onClick={e => this.handlechangepass(e)} type="button" class="btn btn-primary" value="Cambiar Contraseña">Cambiar Contraseña</button>
                    </div> {!this.state.errors.passdis && (<div class="row aviso-recu"><i class="fas fa-times"></i><span>Las contraseñas no coinciden</span></div>)}
                    
                   
                   
                    </div>
                    
                    :
                    <div className="caja-recu">
                    <div className="tit-ses-rec">Ingresa con tu dirección de correo electronico</div>
                    
                    <div className="caja-recu-form">
                    <input type="text" className="form-control recupe" id="email" placeholder="Email" onChange={(e) => this.handletextChange("email", e)} />
                    <button onClick={this.sendtokenrequest} type="button" class="btn btn-primary btn-recupe" value="Enviar">Enviar</button>
                    </div>{!this.state.errors.email && (<div class="row aviso-recu"><i class="fas fa-times"></i><span>Por favor ingrese un correo valido</span></div>)}
                   
                    
                    <span>A tu dirección de correo electrónico sera enviado un enlace para que cambies tu contraseña.</span>
                    
                    </div>
                }


                     
                    </div>
                </div>

                <div class="caja-btn-bienv">
                    <div class="atras-btn"><a href="javascript: history.go(-1)"><i class="fas fa-chevron-left"></i>Ir atras</a> </div>
                    <div class="inicio-btn"><a href="/"><i class="fas fa-chevron-left"></i>Ir a inicio</a> </div>
                </div>

            </div>
        )
    }
}

export default ChangePasswordContend;