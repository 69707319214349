import React, { Component } from 'react';
import Question from './question';
import Axios from 'axios';
import { Redirect, Switch, Route } from 'react-router-dom';
import Map from './maps/Googlemap';

class FormularioEmpresaTres extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: [],
      lat: "",
      lng: "",
      redirect: false
    }

    this.handletextChange = this.handletextChange.bind(this);
    this.addnewquestion = this.addnewquestion.bind(this);
    this.deletequestion = this.deletequestion.bind(this);
    this.setRedirect = this.setRedirect.bind(this);
    this.renderRedirect = this.renderRedirect.bind(this);
    this.setcoordinates = this.setcoordinates.bind(this);
  }

  componentDidMount() {
    document.getElementById("paso3").className += " activo"
  }

  handletextChange(name, index, event) {
    const questionstemp = Object.assign([], this.state.questions);
    //this.setState({[name]: event.target.value});

    questionstemp[index][name] = event.target.value;

    this.setState({
      questions: questionstemp
    })

    console.log("campo :" + name + "texto: " + event.target.value + "indice: " + index);
  }

  addnewquestion() {
    const questionstemp = Object.assign([], this.state.questions);

    const newquestion = {
      question: "",
      response: ""
    }

    questionstemp.push(newquestion);

    this.setState({
      questions: questionstemp
    })


  }

  deletequestion(index, event) {
    const questionstemp = Object.assign([], this.state.questions);

    console.log(index);

    questionstemp.splice(index, 1);

    this.setState({
      questions: questionstemp
    })

  }


 
  handlesubmit = (e) => {
    e.preventDefault()
    const data = this.state.questions;

    Axios.post('https://serviadvisor.com/faqs', data, { withCredentials: true }).then(res => {
      console.log(res.data);
      const coorsData = {
        lat: this.state.lat,
        lng: this.state.lng
      }
      Axios.put('https://serviadvisor.com/updateCoordinates',coorsData, {withCredentials:true}).then(res => {
       
      })

      const datacheck = {
        activateaccount:true
    }

    Axios.post('https://serviadvisor.com/activeprofile', datacheck,{withCredentials:true}).then(res => {
        const data = res.data;

        if(!data.error){
          this.setRedirect();
        }
    })


    
    })
  }

  setcoordinates = (latlng) => {
    const lat = latlng.lat();
    const lng = latlng.lng();
    this.setState({
      lat: lat,
      lng: lng
    }, () => {
      console.log(this.state.lat);
      console.log(this.state.lng);
    })
    console.log(latlng);

  }

  setRedirect = () => {
    this.setState({
      redirect: true
    })
  }

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to='/activacion' />
    }
  }




  render() {
    return (
      <div>


        <div id="content">
          <div class="rec-blog">
            <div class="container grid-reg">
              <div class="rec-blog-inner">
                <div class="row">
                  <div class="col-md-6 corr-res">
                    <h4>Preguntas Frecuentes</h4>
                    <p class="">Diligencia los siguientes campos con la preguntas y respuestas para tus clientes</p>


                    {this.state.questions.map((item, index) => {
                      return (
                        <Question
                          index={index}
                          key={index}
                          Ontextchange={this.handletextChange}
                          onDeleteQuestion={this.deletequestion}
                          textquestion={item.question}
                          textresponse={item.response}
                        >
                        </Question>
                      );
                    })}

                    <div class="agregar-pregunta"><a onClick={this.addnewquestion} href="javascript:void(0);" >Agregar Pregunta <i  class="fas fa-plus naranja"></i> </a> </div>
                  </div>
                  <div class="col-md-6">
                    <h4>Localización en Mapa</h4>
                    <p>Has click en donde esta ubicada tu empresa para Seleccionar la posicion</p>
                    <div class="map" style={{width:"100%", marginLeft:"0", height: "400px"
                    
                    }}>
                      <Map editable={true} OnsetCoors={this.setcoordinates}></Map>

                    </div>
                  </div>
                  {this.renderRedirect()}
                  
                  </div>
                  
              </div>

              <p>
                  <button onClick={this.handlesubmit} type="button" class="btn btn-primary btn sig3" >Registrarme <i class="fas fa-angle-right"></i> </button>
                  </p>

            </div>
          </div>
        </div>


      </div>
    );
  }
}

export default FormularioEmpresaTres;