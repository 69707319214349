import React, { Component } from 'react';
import ImageforUpload from '../../imageforUpload';
import axios from 'axios';
import Dropzone from 'react-dropzone';
import { Redirect, Switch, Route } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Modal, Button } from 'react-bootstrap';
import Axios from 'axios';
import notifications from '../../../utils/notifications';
import {Progress} from 'reactstrap';

class Galeria extends Component {
  constructor(props) {
    super(props)
    this.state = {
      profilePhotoisSet: true,
      galleryImagesisSet: false,
      ImagesFiles: [],
      imagecurrentindex: 0,
      redirect: false,
      photo_url:undefined,
      maxImagesizeGal: 1000000,
      imgprofile: null,
      profilephotouploadprogress:0,
      profileimageloaded: false,
      crop: {
        aspect: 3 / 2,
        unit: '%',
        x: 0,
        y: 0,
        width: 50,
        height: 50
      },
      cropmodalshow: false,
      croppedImageUrl: null,
      croppedimageblob: null

    }

    axios.defaults.withCredentials = true;
    this.checkimages = this.checkimages.bind(this);
    this.addImageforUpload = this.addImageforUpload.bind(this);
    this.clickupload = this.clickupload.bind(this);
    this.uploadImagetoServer = this.uploadImagetoServer.bind(this);
    this.uploadprofilephoto = this.uploadprofilephoto.bind(this);
    this.deleteImage = this.deleteImage.bind(this);
    this.loadimages = this.loadimages.bind(this);
    this.getproviderdata = this.getproviderdata.bind(this);
    this.refreshdata= this.refreshdata.bind(this);
    
  }

  getproviderdata = () => {
    axios.get('https://serviadvisor.com/checkprovider', { withCredentials: true }).then(res => {

      const provider = res.data;
      if (provider.error) {

      } else {
        console.log(provider);
        this.setState({
          id: provider.id,
          photo_url:provider.user.photo_url
        },
          () => {

           
              if (this.state.photo_url !== "" && this.state.photo_url !== undefined) {
                this.setState({
                  croppedImageUrl: this.state.photo_url
                })
              }

            Axios.get('https://serviadvisor.com/getgallery/' + this.state.id).then(response => {
              console.log(response.data)
              this.loadimages(response.data);
            })
          }
        )
        console.log("datos de proveedor")
      }
    })
  }


  loadimages(imagedata) {
    var currentindex = this.state.imagecurrentindex;
    const ImageFilesTemp = Object.assign([], this.state.ImagesFiles);
    imagedata.forEach(image => {
      const ImagetoUpload = {
        id: image.id,
        name: image.photo_url,
        file: undefined,
        image_local_url: "https://serviadvisor.com/getprofilephoto/" + image.photo_url,
        image_url: '',
        loaded: true,
        error: false,
        load: 100,
        index: currentindex
      }

      currentindex = currentindex + 1;

      this.setState({
        imagecurrentindex: currentindex
      })

      ImageFilesTemp.push(ImagetoUpload);
    })


    this.setState({
      ImagesFiles: ImageFilesTemp
    })


  }

  componentDidMount() {
    this.getproviderdata();
    this.props.sockets.sockets.callback= this.refreshdata

  

  }

  refreshdata(data){
    notifications.buildNotification(data.type, data.message, data.accountType, data.senderid, data.profileId,data.source);
     
  }

  checkimages() {


  }

  clickupload = (e) => {
    this.inputElement.click();
  }





  addImageforUpload = (files) => {

    const ImageFilesTemp = Object.assign([], this.state.ImagesFiles);
    var currentindex = this.state.imagecurrentindex;


    const ImagetoUpload = {
      name: files[0].name,
      file: files[0],
      image_local_url: URL.createObjectURL(files[0]),
      image_url: '',
      loaded: false,
      error: false,
      load: 0,
      index: currentindex
    }

    currentindex = currentindex + 1;

    this.setState({
      imagecurrentindex: currentindex
    })

    ImageFilesTemp.push(ImagetoUpload);

    this.setState({
      ImagesFiles: ImageFilesTemp
    })


    this.uploadImagetoServer(ImagetoUpload);

  }

  handleOndrop = (files, rejectedfiles) => {
    console.log(files);

    const maxImagesizeGal = 2097152;
    let errormessage = "";


    if (rejectedfiles && rejectedfiles.length > 0) {
      const currentRejectedFile = rejectedfiles[0];
      const currentRejectedFileType = currentRejectedFile.type;
      const currentRejectedFileSize = currentRejectedFile.size;
      const notify = () => toast.error("File Size Or type not correct");
      return notify();
    }

    if (files && files.length > 0) {
      this.addImageforUpload(files);
    }
  }

  uploadImagetoServer(image) {
    const data = new FormData()
    data.append('file', image.file)

    const config = {
      url: 'https://serviadvisor.com/addtogallery',
      method: 'post',
      withCredentials: true,
      data: data,
      onUploadProgress: ProgressEvent => {
        const images = this.state.ImagesFiles.slice();
        images[image.index].load = (ProgressEvent.loaded / ProgressEvent.total * 100);

        if (ProgressEvent.loaded === ProgressEvent.total) {

          console.log("progress complete")
        }
        this.setState({
          ImagesFiles: images
        });
      }
    };
    axios.request(config).then(res => {
      console.log(res.data);
      const images = this.state.ImagesFiles.slice();
      images[image.index].id = res.data.image.id;
      images[image.index].loaded = true;
      this.setState({
        ImagesFiles: images
      })
      console.log(this.state.ImagesFiles[image.index].id + " " + images[image.index].id);
    }).catch(error => {
      const images = this.state.ImagesFiles.slice();
      images[image.index].error = true;
      this.setState({
        ImagesFiles: images
      })
    });

  }

  deleteImage(id, index) {
    console.log("delete image");

    axios.delete('https://serviadvisor.com/gallery/' + id, { withCredentials: true }).then(res => {
      console.log(res.data);

      if (!res.data.error) {
        const images = this.state.ImagesFiles.slice()
        images[index] = [];
        this.setState({
          ImagesFiles: images
        })
      }
    })


  }

  uploadprofilephoto() {
    const data = new FormData()
    const file = this.state.croppedimageblob;
    console.log(file);
    data.append('file', file)

    const config = {
      url: 'https://serviadvisor.com/addprofilephoto',
      method: 'post',
      data: data,
      onUploadProgress: ProgressEvent => {
        this.setState({
          profilephotouploadprogress: (ProgressEvent.loaded / ProgressEvent.total * 100)
        })
      }
    };
    axios.request(config).then(res => { // then print response status
      console.log(res.data);
      this.setState({
        profileimageloaded: true
      })
    });
  }

  handleclickuploadProfilePhoto = e => {
    e.preventDefault();
    this.uploadprofilephoto();
    this.setState({
      cropmodalshow: false
    })
  }

  handleSumitClick(event) {
    event.preventDefault()
    this.setRedirect();
  }

  setRedirect = () => {
    this.setState({
      redirect: true
    })
  }

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to='/registro-empresa3' />
    }
  }


  handleOndropProfileImage = (files, rejectedfiles) => {
    if (files && files.length > 0) {
      this.setState({
        cropmodalshow: true,
        imgprofile: URL.createObjectURL(files[0])
      })
    }
  }

  handleoncrop = (crop) => {
    this.setState({ crop });
    console.log(crop);
    console.log(this.imageRef.naturalWidth)
    console.log(this.imageRef.naturalHeight)
    console.log(this.imageRef.width)
    console.log(this.imageRef.height)
  }

  handleimageloaded = (image) => {
    this.imageRef = image;
  }

  handlecropComplete = (crop, pixelcrop) => {
    this.makeClientCrop(crop);
  }

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width * scaleX;
    canvas.height = crop.height * scaleY;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height *scaleY
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        this.setState({
          croppedimageblob: blob
        })
        resolve(this.fileUrl);
      }, "image/jpeg");
    });
  }



  render() {
    let modalClose = () => this.setState({ cropmodalshow: false });
    return (
      <div>
         <div class="title-perfil-empresa espacio-top">
          <h4 class="azul"> Galería</h4>
        </div>

                 
<div class="col-md-4 bg-gris foto-regi">
                    <h4>Foto de perfil o logotipo</h4>
                    <p>Sube una fotografía representativa de tu empresa o tu logotipo</p>

                    <Dropzone accept='image/jpeg, image/png' maxSize={this.state.maxImagesizeGal} onDrop={this.handleOndropProfileImage}>
                      {({ getRootProps, getInputProps }) => (



                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <div class="foto">
                            {this.state.croppedImageUrl && (
                              <img alt="Crop" style={{ height: "100%", width: "100%" }} src={this.state.croppedImageUrl} />
                            )}

                            {!this.state.croppedImageUrl && (
                              <div>
                                <b>Click Aqui! o Arrastra un archivo a esta zona para selecionar tu foto.</b>
                              <div class="text-regis">Formato PNG O JPG de un máximo de 1MB</div>
                              </div>
                            )}


                          </div>
                          <Progress max="100" color="success" value={this.state.profilephotouploadprogress} >{Math.round(this.state.profilephotouploadprogress,2) }%</Progress>
                          <button type="button" class="btn btn-success btn sel-arch" >Seleccionar Foto </button>
                        </div>
                        


                      )}
                    </Dropzone>
                    

                  

                   
               { /* <div class="click-subir"> Click en el boton para Guardar tu foto!</div> */}

                    <div class="img-pub-empresas"><a href="#"><img src="/images/premium.png" alt=""/> </a> </div>

                    <div class="text-regis">Click en el recuadro o Arrastra un archivo para selecionar tu foto</div>


                  </div>
        <div class="col-md-8 foto-regi-left">

          <h4>Galería de Imágenes</h4>
          <p>Sube o arrastra las fotos de tu empresa aquí. Muestra tus servicios, instalaciones o productos para que te contacten más rápido.  </p>



          <Dropzone accept='image/jpeg, image/png' maxSize={this.state.maxImagesizeGal} onDrop={this.handleOndrop}>
            {({ getRootProps, getInputProps }) => (



              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div class="foto-gale">
                  Clic o Arrastra para subir un archivo!
                                <div class="text-regis">Formato PNG O JPG de un máximo de 1MB</div>

                </div>
              </div>


            )}
          </Dropzone>

          {/* <button for="selectFile" type="button" class="btn btn-success btn sel-arch" onClick={this.clickupload} >Seleccionar Archivo </button>
                        <input  style={{display:"none"}} name="image" ref={input => this.inputElement = input} id="selectFile" type="file" onChange={this.addImageforUpload} visbility="hidden"></input>
                            */}

<div class="grid-gale">
          {this.state.ImagesFiles.map((item, index) => {
            return (
              <div key={index}
              class={"item-gale"}>
                {item.name &&
                  (
                    <ImageforUpload
                      name={item.name}
                      loaded={item.loaded}
                      key={index}
                      index={item.index}
                      id={item.id}
                      image_url={item.image_local_url}
                      load={item.load}
                      error={item.error}
                      onImageDelete={this.deleteImage}>

                    </ImageforUpload>)}
              </div>
            );
          })}

</div>



        </div>


        <Modal className="recorte" show={this.state.cropmodalshow} 
          {...this.props}
          size="lg"
          animation={false}

          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header >
            <Modal.Title id="contained-modal-title-vcenter">
              Imagen de Perfil
              <hr />
                <div className="flecha-down"></div>
              </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4> Seleccione el área de recorte para la imagen</h4>
            <div>
              <ReactCrop
                onChange={this.handleoncrop}
                src={this.state.imgprofile}
                crop={this.state.crop}
                onImageLoaded={this.handleimageloaded}
                onComplete={this.handlecropComplete}>

              </ReactCrop>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleclickuploadProfilePhoto} className={"btn-primary"}>Recortar y Guardar</Button>
          </Modal.Footer>
        </Modal>

      </div>
    );
  }
}

export default Galeria;