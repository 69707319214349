import { ToastContainer, toast } from 'react-toastify';
import React from 'react'
import Notification from './notificationComponent';
import Axios from 'axios';

const notifications = {};

notifications.GetProviderUserName = async(providerId) =>  {
    let data;

    await Axios.get('https://serviadvisor.com/provider/' +  providerId, { withCredentials: true }).then(res => {
        const datares = res.data;
     
        if (!datares.error) {
            data =  datares[0].user.username;
        } else {
            data = providerId;
        }
    })

    return data
}


function notify(message, url, usertype) {
    if(usertype==="cliente"){
        toast.warning(<Notification usertype={"proveedor"} message={message} url={url}></Notification>, {autoClose: 15000});
    }else{
        toast.info(<Notification usertype={"cliente"} message={message} url={url}></Notification>, {autoClose: 15000});
    }
  
}


notifications.buildNotification = (type, message, usertype, senderid, profileid, source) => {

    let redirecturl = "";
   if(source){
    switch (type) {

        case 'chatnotify':
            redirecturl = usertype === "cliente" ? "/perfil/chat/" + source : "/empresa/" + profileid + "/contacto";
            notify(message, redirecturl, usertype);

            break;
        case 'offer':
            redirecturl = usertype === "cliente" ? "/perfil/propuestas" : "/empresa/" + profileid + "/contacto";
            notify(message, redirecturl, usertype);
            break;
        case 'chatlist':
            redirecturl = usertype === "cliente" ? "/perfil/calendario" : "/perfil-cliente/calendario";
            notify(message, redirecturl, usertype);
            break;
        case 'assigment':
            redirecturl = usertype === "cliente" ? "/perfil/calendario" : "/perfil-cliente/calendario";
            notify(message, redirecturl, usertype);
            break;

        case 'reassigment':
            redirecturl = usertype === "cliente" ? "/perfil/calendario" : "/perfil-cliente/calendario";
            notify(message, redirecturl, usertype);
            break;

        case 'confirmed':
            redirecturl = usertype === "cliente" ? "/perfil/propuestas" : "/perfil-cliente/propuestas";
            notify(message, redirecturl, usertype);
            break;
        case 'rejected':
            redirecturl = usertype === "cliente" ? "/perfil/propuestas" : "/perfil-cliente/propuestas";
            notify(message, redirecturl, usertype);
            break;
        case 'paid':
            redirecturl = usertype === "cliente" ? "/perfil/propuestas" : "/perfil-cliente/propuestas";
            notify(message, redirecturl,usertype);
            break;
        case 'payout':
            redirecturl = usertype === "cliente" ? "/perfil/propuestas" : "/perfil-cliente/propuestas";
            notify(message, redirecturl, usertype);
            break;
        case 'revision':
                redirecturl = usertype === "cliente" ? "/perfil/chat/" + source : "/empresa/" + profileid + "/contacto";
                notify(message, redirecturl, usertype);
                
        case 'revisionUpdate':
            redirecturl = usertype === "cliente" ? "/perfil/chat/" + source : "/empresa/" + profileid + "/contacto";
            notify(message, redirecturl, usertype);
    
                break;
            
    }
   }else{
    switch (type) {

        case 'chatnotify':
            redirecturl = usertype === "empresa" ? "/perfil/chat" : "/empresa/" + profileid + "/contacto";
            notify(message, redirecturl);

            break;
        case 'offer':
            redirecturl = usertype === "empresa" ? "/perfil/propuestas" : "/empresa/" + profileid + "/contacto";
            notify(message, redirecturl);
            break;
        case 'chatlist':
            redirecturl = usertype === "empresa" ? "/perfil/calendario" : "/perfil-cliente/calendario";
            notify(message, redirecturl);
            break;
        case 'assigment':
            redirecturl = usertype === "empresa" ? "/perfil/chat" : "";
            notify(message, redirecturl);
            break;

        case 'confirmed':
            redirecturl = usertype === "empresa" ? "/perfil/chat" : "";
            notify(message, redirecturl);
            break;
        case 'rejected':
            redirecturl = usertype === "empresa" ? "/perfil/chat" : "";
            notify(message, redirecturl);
            break;
        case 'paid':
            redirecturl = usertype === "empresa" ? "/perfil/chat" : "";
            notify(message, redirecturl);
            break;
        case 'payout':
            redirecturl = usertype === "empresa" ? "/perfil/chat" : "";
            notify(message, redirecturl);
            break;
        case 'revision':
            redirecturl = usertype === "cliente" ? "/perfil/chat/" + source : "/empresa/" + profileid + "/contacto";
            notify(message, redirecturl, usertype);
        case 'revisionUpdate':
            redirecturl = usertype === "cliente" ? "/perfil/chat/" + source : "/empresa/" + profileid + "/contacto";
            notify(message, redirecturl, usertype);

            break;
    }
   }
}

export default notifications;