import React, {Component} from 'react';
import HeaderRegistro from '../HeaderRegistro';
import Browser from '../Browser';
import Footer from '../Footer';
import TituloRegistro from '../TituloRegistro';
import FormularioEmpresaUno from '../FormularioEmpresaUno';


class RegistroEmpresa extends Component{
    constructor(props){
        super(props);
        this.state={
            islogin:false,
            user:undefined
        }
        this.logindata= this.logindata.bind(this);
    }

    logindata=(user, islogin)=>{
        this.props.loginroot(user,islogin);
        this.setState({
            user:user,
            islogin:islogin
        })
    }

    render(){
        return(
            <div>
                
                <HeaderRegistro slideout={this.props.slideout} user={this.state.user} logindata={this.logindata} islogin={this.state.islogin} history={this.props.history}></HeaderRegistro>
                <TituloRegistro> </TituloRegistro>
                <FormularioEmpresaUno></FormularioEmpresaUno>
                <Footer user={this.state.user} islogin={this.state.islogin}></Footer>
            </div>
        );
    }
}

export default RegistroEmpresa;