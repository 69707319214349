import React, {Component} from 'react';
import HeaderPerfilEmpresa from '../HeaderPerfilEmpresa';
import TituloPerfil from '../TituloPerfil';
import Footer from '../Footer';
import ContenidoPerfil from './perfilEmpresa/contenidoperfil';
import CategoriasFooter from '../CategoriasFooter';
import PublicidadFooter from '../PublicidadFooter';
import sockets from '../../sockets/sockets';
import profileutils from '../../utils/profiledata';

class PerfilEmpresa extends Component{
    constructor(props){
        super(props)
        console.log(this.props);
        this.state={
            islogin:false,
            user:undefined,
            tab:""
        }
        this.logindata= this.logindata.bind(this);
        this.settab= this.settab.bind(this);
        this.setactiveMenu = this.setactiveMenu.bind(this); 

        const user = profileutils.getuserprofile();
        console.log(user);
        if (user) {
            sockets.subscribeUserToSocket(user.userid)
            this.setState({
                user: user
            })
        }
    }

    logindata = (user, islogin) => {
        this.props.loginroot(user,islogin);
        if (!islogin) {
            profileutils.deleteprofile();
        }
        this.setState({
            user: user,
            islogin: islogin
        })
        profileutils.setuserprofile(user);
        if (user) {
            sockets.subscribeUserToSocket(user.userid);
            console.log(sockets );
            sockets.subscribeChat();
        }
    }

    settab(name) {
      
        this.setState({
            tab:name
        })

        this.props.history.push('./'+ name);
        this.setactiveMenu(name);
    }

    setactiveMenu(tab){

        const tabs = ["perfil","contacto","calificacion","faq","video","mapa"];

        tabs.map((item,index)=>{
            if(document.getElementById(item)){
                document.getElementById(item).className="perfil-item";
            }
           
        })
        
        if(document.getElementById(tab)){
        document.getElementById(tab).className += " activo"
       
        }
        console.log(tab);
    }

    componentDidMount(){
      
        this.setState({
            tab:this.props.match.params.tab
          });
          this.setactiveMenu(this.props.match.params.tab);

         // this.getproviderinfo();
    }
    

    render(){
        return(
            <div>
                <HeaderPerfilEmpresa sockets={sockets} slideout={this.props.slideout}  user={this.state.user} logindata={this.logindata} islogin={this.state.islogin} history={this.props.history}></HeaderPerfilEmpresa>
                <TituloPerfil location={this.props.location}></TituloPerfil>
                <ContenidoPerfil islogin={this.state.islogin} user={this.state.user} sockets={{sockets}} tab={this.state.tab} onChangetab={this.settab}  params={this.props.match.params}></ContenidoPerfil>
                <CategoriasFooter></CategoriasFooter>
                <PublicidadFooter></PublicidadFooter>
                <Footer  user={this.state.user} islogin={this.state.islogin}></Footer>
            </div>
        );
    }
}

export default PerfilEmpresa;