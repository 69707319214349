import React, { Component } from 'react';
import axios from 'axios';
import ReactStars from 'react-stars';
import Moment from 'react-moment';

const hover = {
    background: "#ffe9f5"
}


class ProviderCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            favvisible: true,
            isfav: true,
            requestcomplete: false,
            reloadcard:true
        }
        this.handleClickProviderContact = this.handleClickProviderContact.bind(this);
        this.handlefavclick = this.handlefavclick.bind(this);
        this.checkfavfn = this.checkfavfn.bind(this);
    }

    handleClickProviderContact(event, id) {
        event.preventDefault();
        this.props.history.push('/empresa/' + id + '/contacto');
    }

    handlefavclick= () => {
        const data = {
            providerId: this.props.item.id
        }
        axios.post('https://serviadvisor.com/addfav', data, { withCredentials: true }).then(res => {
            const favdata = res.data;
            
            this.setState({
                requestcomplete: true
            })
            if (!favdata.error) {
                this.setState({
                    isfav: false
                })
            }else{
                this.setState({
                    isfav: true
                })
            }
        })
    }


    checkfavfn(){
        const data={
            providerId:this.props.item.id
        }
    
        
        if(this.state.reloadcard){
            axios.post('https://serviadvisor.com/checkfav',data, { withCredentials: true }).then(res => {
                const data = res.data;
                if(data){
                    if (data.error) {
                        this.setState({
                            favvisible: false
                        })
                    } else {
                        const visible = data.status === "ok" ? false : true;
                        this.setState({
                            isfav: visible
                        })
    
                      
                    }

                        
        this.setState({
            reloadcard:false
        }
        )
                }
               
            })
        }
       
    }

    componentDidMount() {
        /*
        const data={
            providerId:this.props.item.id
        }
        axios.post('https://serviadvisor.com/checkfav',data, { withCredentials: true }).then(res => {
            const data = res.data;
            if(data){
                if (data.error) {
                    this.setState({
                        favvisible: false
                    })
                } else {
                    const visible = data.status === "ok" ? false : true;
                    this.setState({
                        isfav: visible
                    })
                }
            }
           
        })
        */
    
        this.checkfavfn()
      
       
    }

    showloginbutton(){

        if(!this.state.reloadcard){
            this.setState({
                reloadcard:true
            })
        }
        
        return(
            <div  class="corazon"><i data-toggle="modal" data-target="#myModal-reg"  class="fas fa-heart"></i></div>
        )
    }

    getlistofCategories(list){
        
        let html=""

        list.forEach(element => {
            if(element.activity){
                html+= element.activity.name + ", "
            }
          
        })

        return(
           <span>{html}</span>
        )
    }


    render() {
        return (
            <div key={this.props.index} class="perfil-busque-p">

                <div class="perfil-busq">
                    <div class="columna-1-novias-busq">
                        <div class="imagen-novias-busq"><a href={this.props.category ? "/empresa/" + this.props.item.id + "/perfil?category=" + this.props.category : "/empresa/" + this.props.item.id + "/perfil"}>
                            {this.props.item.user.photo_url && (<img src={this.props.item.user.photo_url} alt="" class="img-responsive img-per-busq" />)}
                            {!this.props.item.user.photo_url && (<img src="/images/img amarillis cont.png" alt="" class="img-responsive img-per-busq-po" />)}
                        </a></div>
                    </div>


                    <div class="columna-1-datos ">
                        <div class="titulo-cliente"><h4 class="azul"><a href={this.props.category ? "/empresa/" + this.props.item.user.username + "/perfil?category=" + this.props.category : "/empresa/" + this.props.item.user.username  + "/perfil"}> {this.props.item.business_name}</a></h4>
                        
                        {!this.props.item.isActive && ("Desconectado") }
                        {this.props.item.isActive && (<span style={{color:"green"}}>Conectado</span>) }
                        
                        </div>
                        <div class="columna-2-estat">


                            <div class="border-gris-right">
                            <ReactStars
                                                            count={5}
                                                            edit={false}
                                                            size={13}
                                                            half={false}
                                                            value={this.props.item.rating}
                                                            color2={'#ffd700'} />

                                <div class="letras-pequeñas"> <p >{this.getlistofCategories(this.props.item.categorieslists)} / {this.props.item.city.name}</p></div>


                                <div class="limp"></div>


                                <div class="texto-novias ">
                                    <p>{this.props.item.shortdescription ? (<div>{this.props.item.shortdescription}</div>) : (<div>Descripcion Corta</div>)} </p>
                                </div>
                            </div>
                        </div>
                        <div class="columna-3-estat">

                            <div class="acomodar01 border-bot">

                          

                             {this.props.user ? 
                                 this.checkfavfn()
                                :
                                    this.showloginbutton()
                            }
                        {this.props.user && (this.state.isfav ?
                        (<div  class="corazon"><i onClick={this.handlefavclick} class="fas fa-heart"></i>  </div>) :
                         (<div  class="corazon activo"><i class="fas fa-heart"></i></div>))}
                        
                               
                               {this.state.isfav ?
                                (<div onClick={this.handlefavclick}  class="letras-pequeñas-cora ">Añadir a Favoritos </div>) : ""
                               }
                                <div class="limp"></div>

                            </div>

                            <div class="acomodar02 border-bot">
                                <div class="tex-precio"> <div class="tamaño-fuente-peq">Precio desde </div><div class="negrilla-float">UDS ${this.props.item.start_price}</div></div>

                                <div class="limp"></div>

                            </div>

                            <div class="acomodar03">
                                <div class="col-auto my-3">
                                    <button onClick={e => this.handleClickProviderContact(e, this.props.item.id)} type="submit" class="btn btn-primary acomoda btn-cot-busq"><a href="#">Cotiza AQUÍ Gratis</a></button>
                                </div>
                            </div>



                        </div>

                    </div>



                </div>


            </div>
        );
    }
}

export default ProviderCard