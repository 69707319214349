import React, { Component } from 'react';
import Menu from './empresa/perfil/MenuEdicion';
import Conversaciones from './empresa/perfil/Conversaciones';
import Galeria from './empresa/edicion/Galeria';
import Preguntas from './empresa/edicion/Preguntas';
import InformacionContacto from './empresa/edicion/InformacionContacto';
import ComentariosCalificaciones from './empresa/perfil/ComentariosCalificaciones';
import Chat from './chat/Chat';
import Mapatab from './empresa/edicion/Mapa';
import Calendario from './empresa/edicion/calendario';
import Videos from './empresa/edicion/videosEmpresa';
import Notificaciones from './empresa/edicion/notificaciones';
import Account from './empresa/perfil/AdministrarCuenta';
import Payouts from './empresa/edicion/payouts';
import Businesshours from './empresa/edicion/business_hours';

class ContenidoEmpresaMicuentaChat extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tab: ""
        }

        this.rendertab = this.rendertab.bind(this);

    }


    componentDidMount() {

        console.log("socket prop")
        console.log(this.props.sockets)
    }






    rendertab() {

        let html = "";

        switch (this.props.tab) {

            case 'galeria':
                html = <Galeria user={this.props.user} sockets={this.props.sockets}></Galeria>;
                break;

            case 'faq':
                html = <Preguntas user={this.props.user} sockets={this.props.sockets}></Preguntas>;
                break;

            case 'chat':

                html = <Conversaciones match={this.props.match} user={this.props.user} messageType={"message"} sockets={this.props.sockets} tab={"list"} ></Conversaciones>;
                break;

            case 'coments':
                html = <ComentariosCalificaciones user={this.props.user} sockets={this.props.sockets}></ComentariosCalificaciones>
                break;

            case 'mapa':
                html = <Mapatab user={this.props.user} sockets={this.props.sockets}></Mapatab>
                break;

            case 'calendario':
                html = <Calendario user={this.props.user} sockets={this.props.sockets}></Calendario>
                break;

            case 'notificaciones':
                html = <Notificaciones user={this.props.user} sockets={this.props.sockets}></Notificaciones>
                break;

            case 'account':
                html = <Account user={this.props.user} sockets={this.props.sockets}></Account>
                break;


            case 'informacion':

                html = <InformacionContacto user={this.props.user} sockets={this.props.sockets} ></InformacionContacto>;
                break;

            case 'propuestas':

                html = <Conversaciones user={this.props.user} messageType={"deals"} sockets={this.props.sockets} tab={"list"} ></Conversaciones>;
                break;

            case 'videos':

                html = <Videos user={this.props.user} sockets={this.props.sockets}  ></Videos>;
                break;

            case 'pagos':

                html = <Payouts user={this.props.user} sockets={this.props.sockets}  ></Payouts>;
                break;

            case 'businesshours':

                html = <Businesshours user={this.props.user} sockets={this.props.sockets}  ></Businesshours>;
                break;




            default:

                html = "<div></div>";

                break;
        }

        return (
            <div>
                {html}
            </div>
        );
    }

    render() {
        return (
            <div>

                <div class="rec-blog perfil-empresa">
                    <div class="container">
                        <div class="rec-blog-inner micuenta-empresa">
                            <div class="row">


                                <Menu onchangetab={this.props.onChangetab}></Menu>

                                <div class="col-md-9 blog-ct espacio-centro reco">

                                    {this.rendertab()}

                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default ContenidoEmpresaMicuentaChat;