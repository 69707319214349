import React,{Component} from 'react'
import Moment from 'react-moment';
import moment from 'moment';
import $ from 'jquery';

class business_hours extends Component{
    constructor(props){
        super(props);


        this.gethour = this.gethour.bind(this);
    }

    componentDidMount(){
        this.hidehours()
      
    }


    showhours(){
        $('.caja-hora-est').show();
    }

    hidehours(){
        $('.caja-hora-est').hide();
    }

     getdaymessage(){

        var date = moment(Date.now())
        var day = date.day().toString()
        let actualday="";
        actualday += day;

        let message="";
        
        if(this.props.hours === null || this.props.hours === undefined ){
            return ""
        }

        this.props.hours.forEach(element => {
            if(element.daynumber== actualday){
                message="Hoy " +  element.dayname; 
                if(element.hours.length>0){
                    element.hours.forEach(hour => {
                        message+= " de " + this.gethourraw(hour.starthour) + " - " + this.gethourraw(hour.endhour) + " Y de " 
                    })

                    message = message.substring(0, message.length - 5);;
                }else{
                    message += " Cerrado"
                }



              
            }
        });
       

    return message
    }


    gethourraw(hour){
        var time = moment(hour, "HH:mm");
        console.log(time.format("hh:mm A"));
        var timestring = time.format("hh:mm a");
        return timestring;
    }

    gethour(hour){

        var time = moment(hour, "HH:mm");
        console.log(time.format("hh:mm A"));
        var timestring = time.format("hh:mm a");
       
        return(
            <span class="time-st">
               {timestring}
            </span>
        )
    }

  

    
    
    render(){
        return(
            <div class="caja-hora">
                  <p class="negrilla"> Horario de Atención</p>
                 <div class="horario-hoy">  <div onMouseOut={(e) => this.hidehours()} onMouseOver={(e) => this.showhours()} class="btn-horario-desp"> { this.getdaymessage()}  </div> <div onMouseOut={(e) => this.hidehours()} onMouseOver={(e) => this.showhours()} class="btn-horario-desp2"><i class="fas fa-chevron-down"></i> </div></div> 
               

                {this.props.hours && <div class="caja-hora-est">
                {this.props.hours !== undefined  ?
                
                this.props.hours.map((item,index)=>
                    <div class="caja-hora-item">
                      
                        <span>{item.dayname} </span>
                        <div class="caja-horario-est">
                        {item.hours.map((item2,index2)=>
                            <div class="filas-horas">{this.gethour(item2.starthour)} - {this.gethour(item2.endhour)}  </div>
                        )}

                        {item.hours.length === 0 && ("Cerrado")}
                        
                        </div>
                    </div>
                )
                
                : "" }
                </div>
    }
     <p class="margin-bottom"></p>
            </div>
        )
    }
}

export default business_hours                                                                    