import React, { Component } from 'react';
import Moment from 'react-moment';


class RevisionMessage extends Component {

  constructor(props) { 
    super(props)
    this.dealresponse = this.dealresponse.bind(this)
    this.refuseresponse = this.refuseresponse.bind(this);
    this.renderdealOptions = this.renderdealOptions.bind(this);
    this.renderDealinfo = this.renderDealinfo.bind(this);
    this.renderAssigment = this.renderAssigment.bind(this);
    this.renderAssigmentDate = this.renderAssigmentDate.bind(this);
    this.renderRevision = this.renderRevision.bind(this);
    this.renderOffer = this.renderOffer.bind(this);
    this.renderrevisionStatus = this.renderrevisionStatus.bind(this);
  }

    dealresponse = () => {
        console.log("deal response in deal message")

        if (this.props.onDealresponse) {
          this.props.onDealresponse(this.props.chatItem.revision.id, true);
        }
    }

    refuseresponse = () => {
        if (this.props.onDealresponse) {
        this.props.onDealresponse(this.props.chatItem.revision.id, false);
        }
    }

    renderAssigmentDate = () =>{
    
      if(this.props.chatItem.revision.offert && this.props.chatItem.revision.offert.serviceAssingment){
        return( <Moment format={"DD MMM YYYY hh:mm a"}>{this.props.chatItem.revision.offert.serviceAssingment.AssigmentDate}</Moment>)
      }else{
        return(<div>dfsdfsdf</div>)
      }
    
    }

    renderOffer = () =>{
      if(this.props.chatItem.revision.offert){
        return (<div> 
          <div class="texto-chat-oferta">
          {this.props.type === "client" && (<span>{this.props.chatItem.user.username} te hizo esta Oferta</span>)}
       
          <div class="oferta-chat"  >${this.props.chatItem.revision.originalamount}  USD </div>
          <ul>
            <li>Description: {this.props.chatItem.revision.originaldescription}</li>
            <li>Date {this.renderAssigmentDate()}</li>
          </ul> </div></div>)
      }
    }

    renderRevision = () => {
      return (<div>
        <div class="texto-chat-oferta">
          {this.props.type === "client" && (<span>{this.props.chatItem.user.username} te Solicita La siguiente Revision</span>)}
       
        <div class="oferta-chat"  >${this.props.chatItem.revision.amount}  USD </div>
        <ul>
          <li>Description {this.props.chatItem.revision.description}</li>
          <li>Date: {this.renderAssigmentDate()}</li>
        </ul>
        </div>
      </div>)
    }


    renderDealinfo(){
        return(   <center>
         
            <table>
                <tr>
                    <td>
                
                    {this.renderOffer()}
                    </td>
   
                    <td>
                    {this.renderRevision()}
           
                    </td>
                </tr>
              
           </table>
           {this.renderrevisionStatus()}
           </center>);
    }
  
  
renderrevisionStatus(){
  const status = this.props.chatItem.revision.revisionStatusId
  if(status===1){
    return(<div style={{textAlign:"center"}}> <div class="oferta-estado-Apro"><b>Revisión creada</b> </div></div>);
    }else if(status===2){
      return(<div style={{textAlign:"center"}}> <div class="oferta-estado-Apro"><b>Revisión aceptada</b> </div></div>);
    }else if(status===3){
      return(<div  style={{textAlign:"center"}} ><div class="oferta-estado-recha"><b>Revisión rechazada</b> </div></div>);
    }
}



  renderAssigment(){
  
  }

  renderdealOptions() {
    let html=""
    const status = this.props.chatItem.revision.revisionStatusId
    if (this.props.type === "client" && status===1) {
      return(<div class="casilla-resp">
        <div class="casilla-resp-tit">¿Aceptas esta Revision A la Oferta?</div>
               <div class="casilla-resp-int">

        <div class="casilla-si"><input onClick={this.dealresponse} type="radio" name={"deal" + this.props.chatItem.id} id={"radiodeal" + + this.props.chatItem.id} />
          <label for={"radiodeal" + + this.props.chatItem.id}>si</label><br />
        </div>

        <div class="casilla-no"> <input onClick={this.refuseresponse} type="radio" name={"deal" + + this.props.chatItem.id} id={"radiodeal2" + + this.props.chatItem.id} />
          <label for={"radiodeal2" + + this.props.chatItem.id}>no</label><br />

          </div>
        </div>


      </div>)
         


    }
  }


  render() {
    return (
      <div>

        <div class="caja-chat">
      
         {this.renderDealinfo()}
          {this.renderdealOptions()}
          {this.renderAssigment()}
         
        </div>
        
        <p class="alinear-derecha-fecha"><Moment format={"DD MMM YYYY hh:mm a"}>{this.props.chatItem.createdAt}</Moment></p>
        <div class="limp"></div>
      </div>
    )
  }
}

export default RevisionMessage;