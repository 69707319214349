import React, { Component } from 'react';
import axios from 'axios';

class comision extends Component {

    constructor(props) {
        super(props)
        this.state={
            comisionlist:[],
            comisionname: "",
            comisionvalue: ""
        }

        this.handlenewcomision = this.handlenewcomision.bind(this);
        this.handlecomisionname = this.handlecomisionname.bind(this);
        this.handlecomisionvalue = this.handlecomisionvalue.bind(this);
        this.getcomisions = this.getcomisions.bind(this);
    }


    getcomisions() {
        axios.post('https://serviadvisor.com/administrator/comision', null, { withCredentials: true }).then(res => {
            const data = res.data;
            if(!data.error){
                this.setState({
                    comisionlist:data
                })
            }
        })
    }

    componentDidMount() {
        this.getcomisions()
    }


    handledeletecomision(id){

        const data = {
            id
        }

        axios.post('https://serviadvisor.com/administrator/deletecomision',data, { withCredentials: true }).then(res => {
            const data = res.data;
            if (!data.error) {
                this.getcomisions();
            }
        })
    }

    handleupdatecomision(id){
        const name = document.getElementById("comisionname:" + id).value;
        const value = document.getElementById("comisionvalue:" + id).value;

        const data={
            id,
            name,
            value
        }

        axios.post('https://serviadvisor.com/administrator/updatecomision', data, {withCredentials:true}).then(res=> {
            const data = res.data;

            if(!data.error){
                this.getcomisions()
            }
        })

    }


    handlecomisionname = e => {
        const name = e.target.value;
        this.setState({
            comisionname: name
        })
    }

    handlecomisionvalue = e => {
        const value = e.target.value;
        this.setState({
            comisionvalue: value
        })
    }


    handlenewcomision(){

        const data ={
            name:this.state.comisionname,
            value: this.state.comisionvalue
        }

        axios.post('https://serviadvisor.com/administrator/addcomision/', data, {withCredentials:true}).then(res =>{
            const resdata = res.data;
            if(!resdata.error){
                this.setState({
                    comisionname:"",
                    comisionvalue:""
                })
                this.getcomisions();
            }
        })
    }

    render() {
        return (
            <div class="caja-comision">
                <h4>Comision</h4>
                la comision por pago a proveedor se maneja con el nombre "payout"
                {this.state.comisionlist.map((item, index) => {
                    return (
                        <div class="cont-comision">
                            <p>
                                <input id={"comisionname:" + item.id} type="text" defaultValue={item.name}></input>
                                <input id={"comisionvalue:" + item.id} type="text" defaultValue={item.value}></input>
                             

                                <button onClick={e => this.handleupdatecomision(item.id)}  class="btn btn-primary" value="Guardar">Guardar </button>
                                <button onClick={e => this.handledeletecomision(item.id)}  class="btn btn-success" value="Eliminar"> Eliminar</button>
                            </p>
                        </div>
                    )
                })}

<p>Agregar Comision</p>
                <input onChange={this.handlecomisionname} value={this.state.comisionname} type="text" ></input>
                <input onChange={this.handlecomisionvalue} value={this.state.comisionvalue} type="text" ></input>
           

                <button onClick={e => this.handlenewcomision()} class="btn btn-primary" value="Agregar Nueva">Agregar Nueva</button>
            </div>
        )
    }
}

export default comision