import React, { Component } from 'react';
import axios from 'axios';
import { Redirect, Switch, Route } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dropzone from 'react-dropzone';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Modal, Button } from 'react-bootstrap';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';
import notifications from '../../../utils/notifications'
const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);




class InformacionContacto extends Component {

  constructor(props) {
    super(props)
    this.state = {
      lastusername:'',
      username: '',
      email: '',
      name: '',
      city: '',
      webpage: '',
      tel: '',
      business_name: '',
      photo_url: '',
      shortdescription: '',
      description: '',
      start_price: '',
      end_price: '',
      oldactivitylist: [],
      activitylist: [],
      redirect: false,
      categories: [],
      cities: [],
      providerdataisready: false,
      editorState: EditorState.createEmpty(),
      loaderActive: true,
      loaderSave:false,
      loadsuccess:false,
      errors: {
        email: true,
        username: true,
        usernameinuse: true,
        startprice: true,
        endprice: true,
        description: true,
        check:true,
      },
      empty: {
        username: false,
        business_name: false,
        start_price: false,
        end_price: false,
        name: false,
        email: false,
        city: false,
        tel: false,
        webpage: false,
        activity: false,
        shortdescription: false
      },
      other:[],
      newactivity:""
    }
    this.onChange = (editorState) => this.setState({ editorState });


    this.handleSumitClick = this.handleSumitClick.bind(this);
    this.handletextChange = this.handletextChange.bind(this);
    this.validateUsername = this.validateUsername.bind(this);
    this.validateemail =this.validateemail.bind(this);
    this.handlecombochange = this.handlecombochange.bind(this);
    this.setproviderdata = this.setproviderdata.bind(this);
    this.refreshdata = this.refreshdata.bind(this);
    this.getothercategories = this.getothercategories.bind(this);
  }

  setproviderdata = async () => {
    axios.get('https://serviadvisor.com/checkprovider', { withCredentials: true }).then(async (res) => {
      console.log("get provider data contacto")
      const provider = res.data;
      if (provider.error) {

      } else {
        console.log(provider);
        this.setState({
          lastusername: provider.user.username,
          username: provider.user.username,
          photo_url: provider.user.photo_url,
          email: provider.email,
          name: provider.name,
          city: provider.cityId,
          tel: provider.tel,
          webpage: provider.webpage,
          business_name: provider.business_name,
          shortdescription: provider.shortdescription,
          description: provider.description,
          start_price: provider.start_price,
          end_price: provider.end_price,
          oldactivitylist: provider.categorieslists,
          providerdataisready: true,
        

        }, () => {
          if (this.state.photo_url !== "" && this.state.photo_url !== undefined) {
            this.setState({
              croppedImageUrl: this.state.photo_url
            })

          }
        });

        let providerdescription;

        try {
          providerdescription = JSON.parse(provider.description)
        } catch (e) {
          providerdescription = null
        }

        this.getothercategories();
        await axios.get('https://serviadvisor.com/categories').then(res => {
          const categoriesdata = res.data;

          this.setState({
            categories: categoriesdata
          }, () => {
          
          });
        });


        await axios.get('https://serviadvisor.com/cities').then(res => {
          const citiesdata = res.data;
          console.log(citiesdata);
          this.setState({
            cities: citiesdata
          })
        })




        await this.setState({
          editorState: providerdescription ? EditorState.createWithContent(convertFromRaw(providerdescription)) : EditorState.createEmpty()
        })


        this.setState({
          loaderActive: false
        }, () => {
          this.verifycheckbox()
         // document.getElementById("radio" + this.state.activityId).checked = true
        })
      }
    })
  }


  componentDidMount() {

    this.setproviderdata();
    this.props.sockets.sockets.callback = this.refreshdata
  }

  refreshdata(data) {

    notifications.buildNotification(data.type, data.message, data.accountType, data.senderid, data.profileId,data.source);

  }


  getothercategories(){
    axios.get('https://serviadvisor.com/othercategories').then(res => {
      const categoriesdata = res.data;

      this.setState({
        other: categoriesdata
      });
    });

  }

  verifycheckbox(){
    const oldactivitylist = this.state.oldactivitylist
    const activitylist = this.state.activitylist


    oldactivitylist.forEach((item,index)=> {
      document.getElementById("radio" + item.activityId).checked= true
      activitylist.push("" + item.activityId);
    })

    this.setState({
      activitylist
    })
  }

  handleclickcheckcategory = e =>{
    console.log(e.target.value);
    console.log(e.target.checked);
   
    let activitiestemp = Object.assign([], this.state.activitylist);

    if(e.target.checked=== true){
      activitiestemp.push(e.target.value);
    }else{
      activitiestemp.forEach((item,index)=> {
       

        if(activitiestemp[index] === e.target.value){
          console.log("incide: " + index + " Valor: " + item + " valor array " + activitiestemp[index] )
         activitiestemp.splice(index,1)
        }
      })
    }
    
    this.setState({
      activitylist:activitiestemp
    }, () => {
      console.log(this.state.activitylist);
    })

    
  }
  

  validateemail(event) {
    const email = event.target.value
    const checkemail = validEmailRegex.test(event.target.value)
    const errors = this.state.errors
    errors.email = checkemail
  
    this.setState({ errors: errors });
  }

  validatedescription(event){
    const description = event.target.value;
    if(description.length > 500){
      const errors= this.state.errors;
      errors.description= false;
      this.setState({
        errors:errors
      })
    }else{
      const errors= this.state.errors;
      errors.description= true;
      this.setState({
        errors:errors
      })
    }
  }


  handletextChange(name, event) {
    if (name === "username") {
     
      if(event.target.value !== this.state.lastusername){
        this.validateUsername(event);
      }else{
        const errors = this.state.errors;
        errors.usernameinuse = true;
        this.setState({
          errors: errors
        })
      }
    }

    
    if(name==="email"){
      this.validateemail(event);
    }

    if(name==="shortdescription"){
      this.validatedescription(event);
    }


    this.setState({ [name]: event.target.value });
  }


  validatestartprice(event){
    const start_price = event.target.value;
    const errors = this.state.errors;
    if(start_price==="" || start_price === undefined){
      errors.startprice = false
    } else{
      errors.startprice = true
    }

    this.setState({
      errors
    })
  }


  validatendprice(event){
    const end_price = event.target.value;
    const errors = this.state.errors;
    if(end_price==="" || end_price === undefined){
      errors.endprice = false
    }else{
      errors.endprice = true
    }

    this.setState({
      errors
    })
  }

  


  validateUsername(event) {
    const value = event.target.value;
    if (value.length < 7) {
      const errors = this.state.errors;
      errors.username = false;
      this.setState({
        errors: errors
      })
    } else {
      const errors = this.state.errors;
      errors.username = true;
      this.setState({
        errors: errors
      }, () => {
        const data = {
          username: value
        }
        axios.post('https://serviadvisor.com/checkusername', data, { withCredentials: true }).then(res => {
          console.log(res);
          const errors = this.state.errors;
          if (res.data.error) {

            errors.usernameinuse = false;

          } else {
            errors.usernameinuse = true;
          }

          this.setState({
            errors: errors
          })
        })
      })
    }
  }


  handleinputnumber(name,evt) {
    
   // alert(evt.target.value + " " + name + " " + evt.target.validity.valid + "value" +  this.state[name] )


    if(name ==="start_price"){
      this.validatestartprice(evt);
    }

    if(name ==="end_price"){
      this.validatendprice(evt);
    }

    this.setState({ [name]: (evt.target.validity.valid) ? evt.target.value : this.state[name] });


  
  }

  handleClickCategory = e => {
    console.log(e.target.value);
    e.target.checked = true
    this.setState({
      activityId: e.target.value
    })
  }

  handlecombochange = e => {
    console.log(e.target.value);
    this.setState({
      city: e.target.value
    })

  }

  handleSumitClick(event) {
    event.preventDefault()


    if(this.checkemptys()){
      const notify = () => toast.error("Por favor Ingresa tus datos completos");
    notify();
      return
    }

    if(!this.checkerrors()){
      const errors = this.state.errors;
      errors.check= false;
      this.setState({
        errors
      })

      return
    }else{
      const errors = this.state.errors;
      errors.check= true;
      this.setState({
        errors
      })
    }


    this.setState({
      loaderSave:true
    })
    const descriptiondata = convertToRaw(this.state.editorState.getCurrentContent())



    const newuser = {
      username: this.state.username,
      email: this.state.email,
      name: this.state.name,
      cityId: this.state.city.toString(),
      webpage: this.state.webpage === "" || this.state.webpage === null ? undefined : this.state.webpage,
      business_name: this.state.business_name === "" || this.state.business_name === null ? undefined : this.state.business_name,
      activitylist: this.state.activitylist,
      shortdescription: this.state.shortdescription,
      tel: this.state.tel === "" || this.state.tel === null ? undefined : this.state.tel,
      description: JSON.stringify(descriptiondata),
      start_price: this.state.start_price.toString(),
      end_price: this.state.end_price.toString(),
    }

    console.log(newuser);

    axios.put('https://serviadvisor.com/provider', newuser, { withCredentials: true }).then(response => {

      this.setState({
        loaderSave:false,
        loadsuccess:true
      })

      if (response.data.error) {
        const notify = () => toast.error(response.data.error);
        notify();
      } else {
        this.setRedirect();
      }

    }).catch(error => {
      if (error.error) {
        const notify = () => toast.error("Rellene los campos requeridos");
        notify();
      }

    })
  }

  uploadprofilephoto() {
    const data = new FormData()
    const file = this.state.croppedimageblob;
    console.log(file);
    data.append('file', file)

    const config = {
      url: 'https://serviadvisor.com/addprofilephoto',
      method: 'post',
      data: data,
      onUploadProgress: ProgressEvent => {

      }
    };
    axios.request(config).then(res => { // then print response status
      console.log(res.data);

    });
  }

  handleclickuploadProfilePhoto = e => {
    e.preventDefault();
    this.uploadprofilephoto();
  }

  handleOndropProfileImage = (files, rejectedfiles) => {
    if (files && files.length > 0) {
      this.setState({
        cropmodalshow: true,
        imgprofile: URL.createObjectURL(files[0])
      })
    }
  }

  handleoncrop = (crop) => {
    this.setState({ crop });
  }

  handleimageloaded = (image) => {
    this.imageRef = image;
  }

  handlecropComplete = (crop, pixelcrop) => {
    this.makeClientCrop(crop);
  }

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        this.setState({
          croppedimageblob: blob
        })
        resolve(this.fileUrl);
      }, "image/jpeg");
    });
  }



  getoption(cityId, item2, index) {
    if (cityId === item2.id) {
      return (
        <option key={index} value={item2.id} selected>{item2.name}  </option>
      )
    } else {
      return (
        <option key={index} value={item2.id}>{item2.name}  </option>
      )
    }
  }

 checkerrors(){

    if(!this.state.errors.email){
      return false;
    }

    if(!this.state.errors.username){
      return false;
    }

    if(!this.state.errors.description){
      return false;
    }

    if(!this.state.errors.startprice){
      return false;
    }

    if(!this.state.errors.endprice){
      return false;
    }

    return true
    
 }

 onchangenewactivity= (e) => {
  const value = e.target.value;

  this.setState({
    newactivity:value
  })
}

sendnewactivity = e => {
  e.preventDefault();
  const newactivity = this.state.newactivity;

  if(newactivity!=="" && newactivity !== undefined && newactivity.length < 30){
  

    const data= {
      name:newactivity
    }

    axios.post("https://serviadvisor.com/activitiesprovider", data,{withCredentials:true}).then(res => {
      const data = res.data;
      if(!data.error){
        this.getothercategories();
      }
    })
    
  } 


}


checkemptys(){
  let error = this.state.empty;
  let fail = false;
  if(this.state.business_name === ""){
    error.business_name = true;
    fail= true;
  }else{
    error.business_name = false;
  }

  if(this.state.name === ""){
    error.name = true;
    fail= true;
  }else{
    error.name = false;
  }

  if(this.state.start_price === "" || this.state.start_price === null){
    error.start_price = true;
    fail= true;
  }else{
    error.start_price = false;
  }

  if(this.state.end_price ==="" || this.state.end_price === null){
    error.end_price = true;
    fail = true;
  }else{
    error.end_price = false;
  }

  if(this.state.email ==="" || this.state.email ===null){
    error.email = true;
    fail = true;
  }else{
    error.email = false;
  }

  if(this.state.username === ""){
    error.username = true;
    fail = true;
  }else{
    error.username = false;
  }

  if(this.state.shortdescription === "" || this.state.shortdescription === null){
    error.shortdescription = true;
    fail = true;
  }else{
    error.shortdescription = false;
  }

  if(this.state.city === "" || this.state.city === null){
    error.city = true;
    fail = true;
  }else{
    error.city = false;
  }

  if(this.state.activitylist.length === 0 ){
    error.activity = true;
    fail= true;
  }else{
    error.activity = false;
  }

  this.setState({
    empty:error
  }, () => {return fail})
  
  return fail
}



  render() {
    let modalClose = () => this.setState({ cropmodalshow: false });
    return (
      <div>

        <div id="content">


          <div class="rec-blog">

      {this.state.loaderActive ?

        <div class="loader_informacion_contacto rec-blog">
          <Loader
          visible={this.state.loaderActive}
          type="ThreeDots"
          color="#00BFFF"
          height={100}
          width={100}
       
        />
        </div>
        :
        <div class="grid-reg">
        <div class="rec-blog">



          <div class="row bgri">



            <div class="col-md-12">
            <div class="title-perfil-empresa espacio-top">
              <h4 class="azul">Información de tu empresa</h4>
            </div>

                 
          
              <input type="text" class="form-control comple" id="nombre-empresa" placeholder="Nombre de tu Empresa" value={this.state.business_name} onChange={(e) => this.handletextChange("business_name", e)} />
              <input type="text" pattern="[0-9]*" class="form-control mitad-1" id="desde" placeholder="Precio Desde" value={this.state.start_price} onInput={(e) => this.handleinputnumber("start_price",e)} />
              <input type="text" pattern="[0-9]*" class="form-control mitad-2" id="hasta" placeholder="Precio Hasta" value={this.state.end_price} onInput={(e) => this.handleinputnumber("end_price",e)} />

             
              <div>
             
                 
              {!this.state.errors.startprice && (<div class="row"><i class="fas fa-times"></i><span>Por favor ingrese un valor inicial valido</span></div>)}
              {!this.state.errors.endprice && (<div class="row"><i class="fas fa-times"></i><span>Por favor ingrese un valor final valido</span></div>)}
              
              </div>


              <div>

                <textarea class="form-control comple" id="descrip-corta" placeholder="Descripción Corta" value={this.state.shortdescription} onChange={(e) => this.handletextChange("shortdescription", e)} ></textarea>

              </div>


              <div class="text-regis">Describe a tu empresa para que conozcan tus servicios y productos. </div>
              {!this.state.errors.description && (<div class="row"><i class="fas fa-times"></i><span>La descripcion Corta debe tener un máximo de 500 caracteres</span></div>)}
            
            </div>



            <div class="col-md-12">
            <div class="title-perfil-empresa espacio-top">
          <h4 class="azul"> Descripción</h4>
        </div>
            
              <div >
                <Editor
                  editorState={this.state.editorState}
                  wrapperClassName="demo-wrapper"
                  editorClassName="demo-editor"
                  onEditorStateChange={this.onChange}
                />
              </div>
            </div>




          </div>







          <div class="row centrado bgri rom">




            <div class="col-md-6 ">
           
              <div class="title-perfil-empresa espacio-top">
          <h4 class="azul"> Datos</h4>
        </div>
        <h4>Nombre de Usuario</h4>
        
              <input type="text" class="form-control comple" id="Usuario" placeholder="Usuario" onChange={(e) => this.handletextChange("username", e)} value={this.state.username} />

              <div class="text-regis">El usuario debe tener al menos 5 caracteres.</div>
              {!this.state.errors.username && (<div class="row"><i class="fas fa-times"></i><span>El usuario debe tener al menos 5 caracteres</span></div>)}
              {!this.state.errors.usernameinuse && (<div class="row"><i class="fas fa-times"></i><span>El Nombre de ususario esta en uso</span></div>)}


              <h4>Datos de Contacto</h4>
              <input type="text" class="form-control comple" id="nombre" placeholder="Nombre" onChange={(e) => this.handletextChange("name", e)} value={this.state.name} />
              <input type="text" class="form-control mitad-1" id="email" placeholder="Email" value={this.state.email} onChange={(e) => this.handletextChange("email", e)} />
              {/*<input type="text" class="form-control mitad-2" id="ciudad" placeholder="Ciudad" onChange={(e) => this.handletextChange("city",e)}/>*/}
              <select onChange={this.handlecombochange} type="text" class="form-control mitad-2" id="ciudad" placeholder="Ciudad">
                <option disabled selected>Selecciona una Ciudad</option>
                {this.state.cities.map((item, index) => this.getoption(this.state.city, item, index))}
              </select>
              {!this.state.errors.email && (<div class="row"><i class="fas fa-times"></i><span>Por favor ingrese un correo valido</span></div>)}
              <input type="text" pattern="[0-9]*" class="form-control mitad-1" id="telefono" placeholder="Teléfono" value={this.state.tel} onChange={(e) => this.handleinputnumber("tel", e)} />
              <input type="text" class="form-control mitad-2" id="web" placeholder="Web" value={this.state.webpage} onChange={(e) => this.handletextChange("webpage", e)} />
              <div class="text-regis">Ingresa la información de tu empresa para que puedan contactarte.</div>
            </div>



            <div class="col-md-6 ">
              <div class="img-pub-empresas"><a href="/premium"><img src="/images/porque-adm-cuen.png" alt="" /> </a> </div>
            </div>



          </div>

          <div class="row bgri">

        

          <div class="title-perfil-empresa espacio-top">
          <h4 class="azul"> Selecciona el sector de actividad de tu empresa</h4>
        </div>

        





            <div class="col-md-12 sep">
              
              {this.state.categories.map((item, index) => {
                return (
                  <div  class="formulario">
			               
                      <h5>{item.name}</h5>
                      <div class="radio">

                      {item.activities.map((item2, index2) => {
                        return (
                          <div class="caja-lab-ch formulario"><div class="checkbox">

                            <input onChange={this.handleclickcheckcategory} type="checkbox" name="categoryradio" key={item2.id} value={item2.id} id={"radio" + item2.id} />
                            <label for={"radio" + item2.id}>{item2.name}</label><br />

                          </div>  </div>
                        );
                      })}

                    </div>
                  </div>
                );
              })}





            </div>

              
          

            <div class="col-md-12 sep">
              
              {this.state.other.map((item, index) => {
                return (
                  <div  class="formulario">
			               
                      <h5>{item.name}</h5>
                      <div class="radio">

                      {item.activities.map((item2, index2) => {
                        return (
                          <div class="caja-lab-ch formulario"><div class="checkbox">

                            <input onChange={this.handleclickcheckcategory} type="checkbox" name="categoryradio" key={item2.id} value={item2.id} id={"radio" + item2.id} />
                            <label for={"radio" + item2.id}>{item2.name}</label><br />

                          </div>  </div>
                        );
                      })}

                    </div>
                  </div>
                );
              })}


            </div>
            <div class="col-md-12 sep int-btn">
              <div class="caj-flex">
                <input onChange={this.onchangenewactivity}  value={this.state.newactivity} type="text"></input> <button onClick={this.sendnewactivity}  className={"btn-success agre"}> <i class="fa fa-plus naranja"></i>  Agregar Actividad </button>
              </div>
            </div>
           

          </div>






         
        <p>
      
          <button onClick={e => this.handleSumitClick(e)} type="button" class="btn btn-primary btn sig" > <a href="">Guardar <i class="fas fa-angle-right"></i>  <Loader
          visible={this.state.loaderSave}
          type="TailSpin"
          color="#00BFFF"
          height={13}
          width={13}
       
        /> </a> </button>
              {this.state.loadsuccess && (<span class="alert"><i class="fas fa-check-circle"></i> Actualizacion realizada con exitó.</span>)}
          </p>
          
        </div>
        {!this.state.errors.check && (<div class="alert alert-warning"><i class="fas fa-times"></i><span>Algunos de los campos no son validos</span></div>)}
      </div>
        
   
   
    }
          
          
        
          </div>
        </div>



        <Modal className="recorte" show={this.state.cropmodalshow} onHide={modalClose}
          {...this.props}
          size="lg"
          animation={false}

          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Imagen de Perfil
              <hr />
                <div className="flecha-down"></div>
          </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Seleccione el área de recorte para la imagen</h4>
            <div>
              <ReactCrop
                onChange={this.handleoncrop}
                src={this.state.imgprofile}
                crop={this.state.crop}
                onImageLoaded={this.handleimageloaded}
                onComplete={this.handlecropComplete}>

              </ReactCrop>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={modalClose} className={"btn-primary"}>Cerrar</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default InformacionContacto;