import React, { Component } from 'react';
import axios from 'axios';


class ToolbarMenu extends Component {

    constructor(props){
        super(props)
        this.state={
            categories:[],
            actualimg:"/images/ba.jpg"
        }
        this.getcategories= this.getcategories.bind(this);
        this.setState= this.setState.bind(this);
    }

    componentDidMount(){
        this.getcategories();
    }

    getcategories(){
      axios.get(' https://serviadvisor.com/categories').then(res => {
          const data = res.data;
          if(data){
              this.setState({
                  categories:data
              })
          }
      }) 
    }


    setimage(imageurl){
        this.setState({
            actualimg:imageurl
        })
    }

    render() {
        return (
            <div class="collapse navbar-collapse" id="cat-nav-mega">
                <ul className="nav navbar-nav">


                    {this.state.categories.map((item,index)=> {
                        return(
                            <li class="nav-item dropdown">
                            <a onMouseOver={(e) =>  this.setimage("/"+ item.activities[0].image_url)} class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">{item.name}</a>
                            <div class={"dropdown-menu  " + "caj-men-" + item.name}>
                                <div class="menu-cat">
                                   
                                   {item.activities.map((item2,index2)=>{
                                       return(
                                        <a onMouseOver={(e) =>  this.setimage("/"+ item2.image_url)} class="dropdown-item" href={"/search/?category=" + item2.id}><i class="fas fa-angle-double-right"></i>{item2.name}</a>
                                       )
                                   })}
                                 
                                    </div>
                                <div class={"img-menu-cat " + "caj-im-" + item.name}>
                                   <img src={this.state.actualimg}></img>
                                </div></div>
                        </li> 
                        )
                    })}

                    <li><a href="/blog">Consejos </a></li>

                </ul>

            </div>
        )
    }
}

export default ToolbarMenu;