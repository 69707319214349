import React,{Component} from 'react';

class rightbutton extends Component{
    render(){
        return(
        <button {...this.props}>Prev</button>
        )
    }
}

export default rightbutton;