import React,{Component} from 'react';
import Header from '../HeaderRegistro';
import Footer from '../Footer';
import sockets from '../../sockets/sockets';
import profileutils from '../../utils/profiledata';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


class deleteaccount extends Component{
    constructor(props){
        super(props)
        this.state = {
            islogin: false,
            user: undefined,
            token:undefined,
            key:"",
            errorkey:false
        }
        this.logindata = this.logindata.bind(this);
        const user = profileutils.getuserprofile();
        console.log(user);
        if (user) {
            sockets.subscribeUserToSocket(user.userid)
            this.setState({
                user: user
            })
        }
        this.changekey = this.changekey.bind(this);
    }

    componentDidMount(){
        this.checktoken()
    }



    logaout() {
        axios.get('https://serviadvisor.com/logout', { withCredentials: true }).then(res => {
    
            const data = res.data;

            if(!data.error){
                this.setState({
                    islogin: false,
                    user: undefined
                  }
                  )
            
                  this.logindata(undefined, false)
                  
                  const notify = () => toast.info("Se ha eliminado la cuenta");
                  notify();
        
                  this.props.history.push('/');

               
            }else{
                const notify = () => toast.info("Error al eliminar tu cuenta");
                notify();
            }
       
        
        })
      }

    logindata = (user, islogin) => {

        console.log("user login ------------------")
        console.log(user)
        this.props.loginroot(user,islogin);

        if (!islogin) {
            profileutils.deleteprofile();
            //sockets.unsubscribechat();
        }
        this.setState({
            user: user,
            islogin: islogin
        })
        profileutils.setuserprofile(user);
        if (user && user !== undefined) {
            console.log("subscripcion usuario ------------------")
            sockets.subscribeUserToSocket(user.userid)
            sockets.subscribeChat()
        }
    }



    checktoken(){
        const token = this.props.match.params.token;
        const data={
            token:token
        }


        const config = {
            headers: { Authorization: "Bearer " + token },
            withCredentials:true
        };
        
        const bodyParameters = {
           token: token
        };
      
        axios.post('https://serviadvisor.com/verefydeletetoken',bodyParameters,config).then( res => {
            const data = res.data;
            if(!data.error){
                if(token && token !==""){
                    this.setState({
                      token:token
                    })
                  }
            }
        })

      
    }


    changekey(e){
        const value = e.target.value;

        this.setState({
            key:value
        })

        if(value!== "Eliminar"){
            this.setState({
                errorkey:true
            })
        }
    }

    senddeleteconfirmation(e){
        e.preventDefault()

        const data={
            token: this.props.match.params.token,
            key: this.state.key
        }

        axios.post('https://serviadvisor.com/confirmdelete',data,{withCredentials:true}).then(res =>{
            const data = res.data;
            if(!data.error){
                this.logaout();
            }   
           
        }   
           
        )
    }



    render(){
        return(
            <div >
                <Header sockets={sockets} user={this.state.user} logindata={this.logindata} islogin={this.state.islogin} history={this.props.history}></Header>
                <div class="caja-elinimar-cuenta">
                    {this.state.token && 
                    (<div><h3>Borrar Cuenta</h3>
                        <p class="text-eliminar">Si quieres eliminar tu cuenta escribe la Palabra "Eliminar" en el cuadro de texto y haz click en el boton eliminar cuenta.</p>
                        <input type="text" className={"btn-elim-inp"} onChange={this.changekey} value={this.state.key} placeholder="Eliminar" /> 
                      
                        <button onClick={(e) => this.senddeleteconfirmation(e)} className={"btn-success btn-elim"}>Eliminar cuenta</button></div>)}


                        <div class="caja-btn-bienv">
                            <div class="atras-btn"><a href="javascript: history.go(-1)"><i class="fas fa-chevron-left"></i>Ir atras</a> </div>
                            <div class="inicio-btn"><a href="/"><i class="fas fa-chevron-left"></i>Ir a inicio</a> </div>
                        </div>
                    
                </div>
               
                <Footer user={this.state.user} logindata={this.logindata} islogin={this.state.islogin}></Footer>        
            </div>
        )
    }

}

export default deleteaccount;