import React,{Component} from 'react';
import axios from 'axios'

class Paypalproduct extends Component{
    constructor(props){
        super(props)

        this.state={
            products:[]
        }

        this.getproducts= this.getproducts.bind(this);
        this.makenewproduct = this.makenewproduct.bind(this);
    }

    componentDidMount(){
        this.getproducts();
    }

    getproducts(){
        axios.post('https://serviadvisor.com/listproducts',null,{withCredentials:true}).then(res => {
            const data = res.data;
            if(!data.error){
                this.setState({
                    products:data
                })
            }
        })
    }

    makenewproduct = (e) => {
        axios.post('https://serviadvisor.com/registerProduct',null,{withCredentials:true}).then(res => {
            this.getproducts();
        })
    }

    deleteproduct(){

    }


    render(){
        return(
            <div class="caja-paypal">
           <div class="cont-paypal-">
                Agregar nuevo:
                <label for="nombreproducto"></label>
                <input id="nombreproducto" type="text" ></input>
                <button onClick={this.makenewproduct} class="btn btn-primary" value="Nuevo">Nuevo</button>
        
            </div>
            {this.state.products.map((item,index)=>{
               
               return(
                <div class="cont-paypal">
                    
                   <p class="ti-pay">Nombre: {item.name}  IdPaypal: {item.productId}</p>

                </div>
               )
            })}
        </div>
        )
    }
}

export default Paypalproduct