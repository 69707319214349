import React,{Component} from 'react';
import axios from 'axios';
import Loader from 'react-loader-spinner';


class orderadmin extends Component{
    constructor(props){
        super(props)
        this.state={
            order:undefined,
            orderid:"",
            loaderSave:false
        }

        this.onchangeorderid = this.onchangeorderid.bind(this);
        this.handlechangeorder= this.handlechangeorder.bind(this);
        this.renderorder = this.renderorder.bind(this);
        this.hanldesavestatus = this.hanldesavestatus.bind(this);
    }

    componentDidMount(){
        
    }

    handlechangeorder(e){

        const data = {
            orderId:this.state.orderid
        }

        axios.post('https://serviadvisor.com/administrator/getorder', data, {withCredentials:true}).then(res => {
            const data = res.data;
            if(!data){
                this.setState({
                    order:undefined
                })
                return
            }

            if(!data.error){
                this.setState({
                    order:data
                })
            }
        })
    }

    onchangeorderid(e){
        const orderid= e.target.value;

        this.setState({
            orderid
        })
    }

    hanldesavestatus(e){

        if(this.state.order){


            this.setState({
                loaderSave:true
            })

            const newstatus = document.getElementById("ordercheckstatus").checked;

            const data ={
                orderid: this.state.order.id,
                newstatus: newstatus
            }

            axios.post('https://serviadvisor.com/administrator/editgetorder',data, {withCredentials:true}).then(res => {
                const data = res.data;
                if(!data.error){
                    this.setState({
                        loaderSave:false
                    })
                }
            })


        }

       



    }


    renderorder(){
        if(this.state.order){
            const order= this.state.order
            return(
                <div>
                    <p>id : {order.id}</p>
                    <p>id orden paypal . {order.order_id}</p>
                    <p>Email paypal: {order.email}</p>
                    <p>id de oferta: {order.offertId}</p>
                    <p>Id cliente: {order.clientId}</p>
                    <p>devolución:  <input id="ordercheckstatus" type="checkbox" defaultChecked={order.refund}></input> </p>
                    <button onClick={(e) => this.hanldesavestatus(e)}>Guardar estado</button>
                </div>
                )
        }
      
    }

    render(){
        return(
            <div>
                <input type="text" onChange={(e)=> this.onchangeorderid(e)} value={this.state.orderid}></input>
                <button onClick={(e)=> this.handlechangeorder(e)}>Burcar Orden de pago cliente</button>
                

                {this.renderorder()}

                
            </div>
        )
    }
}

export default orderadmin