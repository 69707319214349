import React,{Component} from 'react';
import axios from 'axios'

class SubscriptionPlan extends Component{
    constructor(props){
        super(props)

        this.state={
            plans:[],
            products:[],
            premiumproviders:[],
            PlanName:"",
            planPrice:"",
            productId:undefined,
            planid:undefined,
            userId:0
        }

        this.getplans= this.getplans.bind(this);
        this.makenewPlan = this.makenewPlan.bind(this);
        this.getproducts = this.getproducts.bind(this);
        this.handlecombochange = this.handlecombochange.bind(this);
        this.changeplanname= this.changeplanname.bind(this);
        this.changeprice = this.changeprice.bind(this);
    }

    componentDidMount(){
        this.getproducts();
        this.getsubscriptors();
        this.getplans();
    }

    getproducts(){
        axios.post('https://serviadvisor.com/listproducts',null,{withCredentials:true}).then(res => {
            const data = res.data;
            if(!data.error){
                this.setState({
                    products:data
                })
            }
        })
    }

    changeplanname = (e) => {
        const name = e.target.value;
        this.setState({
            PlanName:name
        })
    }

    changeprice= (e) =>{
        const price = e.target.value;
        this.setState({
            planPrice:price
        })

    }

    getplans(){
        axios.post('https://serviadvisor.com/listsubscriptionsPlans',null,{withCredentials:true}).then(res => {
            const data = res.data;
            if(!data.error){
                this.setState({
                    plans:data
                })
            }
        })
    }

    makenewPlan = (e) => {
        const data ={
            price:this.state.planPrice,
            planName:this.state.PlanName,
            productId:this.state.productId
        }
        axios.post('https://serviadvisor.com/createSubscriptionPlan',data,{withCredentials:true}).then(res => {
            this.getproducts();
        })
    }

    getsubscriptors(){
        axios.get('https://serviadvisor.com/getpremiumproviders', {withCredentials:[true]}).then(res => {
            const data = res.data;
            if(!data.error){
                this.setState({
                    premiumproviders: data
                })
            }
        })
    }

    deleteproduct(){

    }


    handlecomboplanchange = e => {
        console.log(e.target.value);
        this.setState({
          planid: e.target.value
        })
    }

    handlecombochange = e => {
        console.log(e.target.value);
        this.setState({
          productId: e.target.value
        })
    
      }


      cancelsubscription(UserId){

        const data ={
            UserId
        }
          axios.post('https://serviadvisor.com/cancelSubscriptionadmin', data, {withCredentials:true}).then(res=> {
              const resdata = res.data;
              if(!resdata.error){
                this.getsubscriptors();
              }
          })
      }

      handleidchange(e){
          const value =  e.target.value;

          this.setState({
              userId:value
          })
      }

      handleaddsubscription(){

        const data = {
            userId: this.state.userId,
            planId: this.state.planid
        }

        axios.post('https://serviadvisor.com/checkdirectSubscription', data , {withCredentials:true}).then( res => {
            const data = res.data;

            if(!data.error){

                this.getsubscriptors();
                this.setState({
                    planid: undefined,
                    userId:""
                  })
            }else{
                alert(data.error);
            }
        })

        
      }


    render(){
        return(
        <div class="caja-comision">
            <div class="cont-comision">
                <p>Agregar nuevo:</p>
                <label for="nombreplan">Nombre plan: </label>
                <input onChange={this.changeplanname} id="nombreplan" type="text" ></input>
                <label for="PrecioPlan">Precio plan: </label>
                <input onChange={this.changeprice} id="Precioplan" type="text" ></input>
                <select onChange={this.handlecombochange} type="text"  placeholder="Producto">
                      <option disabled selected>Selecciona un producto</option>
                      {this.state.products.map((item, index) => <option key={index} value={item.productId}>{item.name} : {item.productId} </option>)}
                    </select>
                    <button onClick={this.makenewPlan} class="btn btn-primary" value="Nuevo">Nuevo</button>    
            </div>
            {this.state.plans.map((item,index)=>{
               
               return(
               <div class="cont-comision-a">
                    
                   <p>Nombre: {item.name}  IdPaypal: {item.planId} Precio: {item.price}</p>

                </div>
               )
            })}


            <h4>Empresas Premium</h4>
            
            <span>Id</span><input type="text" onChange={(e) => this.handleidchange(e)}  value={this.state.userId}></input> 

            <select onChange={this.handlecomboplanchange} type="text"  placeholder="Producto">
                      <option disabled selected>Selecciona un Plan</option>
                      {this.state.plans.map((item, index) => <option key={index} value={item.id}>{item.name} : {item.planId} </option>)}
                    </select>

                    <button onClick={(e) => this.handleaddsubscription()} class="btn btn-primary">Activar membresia directa</button>

            {this.state.premiumproviders.map((item,index)=> 
                <div>
                    <p><span>UserId : {item.user.id } </span><span> Empresa: {item.business_name}</span><span></span><span><button onClick={(e) => this.cancelsubscription(item.user.id)}>Cancelar Subscipcion</button></span></p> 
                </div>
            )}

        </div>
        )
    }
}

export default SubscriptionPlan