import React, {Component} from 'react';
import axios from 'axios';

const types= {
    spaces:"spaces",
    listOfads:"list"
}

const adtype=[
    {
        type:"lateral"
    },
    {
        type:"banner"
    }
]


class adversitingPage extends Component{
    constructor(props){
        super(props);
        this.state = {
            type: types.spaces,
            spaceAdId: "",
            spacepage:"",
            spacename:"",
            spaceType:"",
            redirection: "",
            spaces:[],
            ads:[],
            adname : "",
            adcompany:"",
            filetoupload:undefined,
            imgobject:undefined
        }
        this.rendertab = this.rendertab.bind(this);
        this.addnewaddAspace = this.addnewaddAspace.bind(this);
        this.adslistcontent = this.adslistcontent.bind(this);
        this.onchangeSpaceName = this.onchangeSpaceName.bind(this);
        this.onchangeSpacepage = this.onchangeSpacepage.bind(this);
        this.onchangeSpacetype = this.onchangeSpacetype.bind(this);
        this.handleEditadspace = this.handleEditadspace.bind(this);
        this.handledeleteadspace = this.handledeleteadspace.bind(this);
        this.handlegoback = this.handlegoback.bind(this);
        this.getads = this.getads.bind(this);
        this.handlechangefile = this.handlechangefile.bind(this);
        this.handlechangeadname = this.handlechangeadname.bind(this);
        this.handlechangeadcompany = this.handlechangeadcompany.bind(this); 
        this.handledeleteadd = this.handledeleteadd.bind(this);
    }

    componentDidMount(){
        this.getalladdspaces();
    }


    getalladdspaces(){
        axios.post('https://serviadvisor.com/advertisingspaces',null,{withCredentials:true}).then(res => {
            console.log(res.data);
            const data = res.data;
            if(!data.error){
                this.setState({
                    spaces:data
                })
            }
        })


    
    }


    handleEditadspace(evt,id){
        evt.preventDefault();
      
        const name = document.getElementById("adspacename:"+ id).value
        const type = document.getElementById("adspacetype:"+ id).value

  
        

        const data = {
            name: name,
            type: type
        }

        
        axios.put('https://serviadvisor.com/advertisingspace/' + id, data, { withCredentials: true }).then(res => {
            const resdata = res.data;
            if (!resdata.error) {
                this.getalladdspaces();
            }
        })
        

    }

    
    handledeleteadspace(evt){

    }
    
    onchangeSpaceName(evt){
        evt.preventDefault();
        let name = evt.target.value;
        this.setState({
            spacename:name
        })
    }

    onchangeSpacetype = (evt) => {
       
        evt.preventDefault();
        let type = evt.target.value;
        this.setState({
            spaceType: type
        })
    }

    onchangeSpacepage(evt){
        evt.preventDefault()
        let page = evt.target.value;
        this.setState({
            spacepage:page
        })
    }

    addnewaddAspace(evt){
        const data={
            name:this.state.spacename,
            page:this.state.spacepage,
            type:this.state.spaceType
        }

        console.log(data);
        
        axios.post('https://serviadvisor.com/advertisingspace',data, {withCredentials:true}).then(res => {
        const data = res.data;
        if(!data.error){
            this.getalladdspaces();
        }
        })
        
    }


    getoption(item,type,index){
        if(item.type === type) {
            return(
            <option  key={index} value={item.type} selected>{item.type}  </option>
            )
        }else{
            return(
            <option  key={index} value={item.type}>{item.type}  </option>
            )
        }
    }


    Adspacescontent(){
        return(
            <div class="caja-publicidad">
               
               <h3>Crear Nuevo Espacio de publicidad</h3>
                <label for="nombre_adspace">Nombre</label>
                <input onChange={evt => this.onchangeSpaceName(evt)} id="nombre_adspace" type="text"></input>

                <label for="paginaad">Pagina</label>
                <input onChange={evt => this.onchangeSpacepage(evt)} id="paginaad" type="text"></input>

                <label>Tipo</label>
                <select id="adspace_type" onChange={this.onchangeSpacetype}>
                   <option >Selecciona un Tipo</option>
                    <option value="lateral">Lateral</option>
                    <option value="banner">Banner</option>
                </select>

                <button onClick={evt => this.addnewaddAspace(evt)}  class="btn btn-primary">Agregar nuevo</button>
                
                <h3>Espacios De publicidad</h3>


                {this.state.spaces.map((item,index) => 
                <div>


                    <label>ID</label>
                <input type="text" defaultValue={item.id}  readonly="readonly"></input>
                    

                    <label>Nombre</label>
                    <input id={"adspacename:" + item.id} type="text" defaultValue={item.name}></input>
                    <label>Tipo</label>
                    <select id={"adspacetype:"  + item.id}>
                        {adtype.map((item2,index2)=> this.getoption(item2,item.type,index2))}
                    </select>
                   
                    <button onClick={e => this.handleEditadspace(e, item.id)}  class="btn btn-primary" value="Guardar">Guardar</button>
                    <button onClick={e => this.handleviewadslist(e,item.id)}  id="varads" value="Ver todos los Anuncios" class="btn btn-success">Ver todos los Anuncios</button>

                  

                
                </div>
                )}


            </div>
        )


    }


    handleviewadslist(evt, id){
        evt.preventDefault();
        this.setState(
            {
                spaceAdId: id,
                type: types.listOfads
            }, () =>{

                this.getads(id)
            }
        )
    }

    handlegoback(evt){
        this.setState(
            {
                spaceAdId: "",
                type: types.spaces
            }
        )

        this.clearnewadd();
    }


    getads(spaceAdId){
        const data= {
            adspaceId:spaceAdId
        }

        axios.post('https://serviadvisor.com/advertising',data,{withCredentials:true}).then(res => {
            console.log(res.data);
            const data = res.data;
            if(!data.error){
                this.setState({
                    ads:data
                })
            }
        })


    
    }

    handlechangefile = (e) => {
        console.log(e.target.files[0]);


        if(e.target.files[0].size > 1000000){
            alert("File is too big!");
          return
         };



         var reader = new FileReader();
         var url = reader.readAsDataURL(e.target.files[0]);
       
          reader.onloadend = function (e) {
             this.setState({
                 imgSrc: reader.result
             })
           }.bind(this);

        this.setState({
            filetoupload:e.target.files[0]
        },() => {
            console.log(this.state.filetoupload)
        })
    }

    handlechangeadname = (e) => {
        e.preventDefault();

        const adname = e.target.value;
        this.setState({
            adname
        })
    }

    handlechangeadcompany = (e) => {
        e.preventDefault();

        const adcompany = e.target.value;

        this.setState({
            adcompany
        })
    }

    handlechangeredirection = (e) => {
        e.preventDefault();

        const redirection = e.target.value;

        this.setState({
            redirection
        })
    }

    handleaddad = (e) => {
       

        try {
           
            const data = new FormData()
            const file = this.state.filetoupload;
         
            data.append('file', file)
            data.append('name',this.state.adname);
            data.append('company_name',this.state.adcompany);
            data.append('advertisingId',this.state.spaceAdId);
            data.append('redirection', this.state.redirection);

         

            axios.post('https://serviadvisor.com/ads', data, { withCredentials: true }).then(res => {
                this.getads(this.state.spaceAdId);
                this.clearnewadd();
            })
        } catch (e) {
            console.log(e.message)
        }



    }

    handledeleteadd(e,id){
        e.preventDefault();
        axios.delete("https://serviadvisor.com/ads/" + id, {withCredentials:true}).then(res => {
            const data= res.data;
            if(!data.error){
                this.getads(this.state.spaceAdId);
            }
        })
    }


    handleeditad(e,id){
        e.preventDefault();
        const adname = document.getElementById("adname:" + id).value;
        const adcompany = document.getElementById("adcompany:" + id).value;
        const redirection = document.getElementById("redirection:" + id).value;
        const image = document.getElementById("adimage:" + id).files[0];
        
        alert("adimage:" + id)

        if(image){
            if(image.size > 1000000){
                alert("File is too big!");
                return
             };
        }
        
        const data = new FormData()
      
        
        if(image){
            data.append('file', image)
        }
       
        data.append('name',adname)
        data.append('company_name',adcompany);
        data.append('redirection',redirection);
      

        axios.put('https://serviadvisor.com/ads/' + id, data, { withCredentials: true }).then(res => {
            const resdata = res.data;
            if (!resdata.error) {
                this.setState({
                    ads:[]
                }, () => {
                    this.getads(this.state.spaceAdId)
                })
               
            }
        })

    }

    clearnewadd= (e) =>{
        this.setState({
            adname:"",
            adcompany:"",
            filetoupload:undefined,
            imgSrc:undefined,
            redirection:"",
            ads:[]
        })
    }

    adslistcontent(){
        return(
            <div>
                <button onClick={e => this.handlegoback(e)}>Ir Atras</button>

                <h3>Nuevo Anuncio</h3>

                <label>Nombre</label>
                <input onChange={this.handlechangeadname} value={this.state.adname} type="text"></input>

                <label>Nombre empresa</label>
                <input onChange={this.handlechangeadcompany} value={this.state.adcompany} type="text"></input>

                <label>url de Anuncio</label>
                <input onChange={this.handlechangeredirection} value={this.state.redirection} type="text"></input>

                <label>Imagen</label>
                <input type="file" onChange={this.handlechangefile}  accept="image/png, image/jpeg" ></input>

                <img class="adimg" src={this.state.imgSrc}></img>

                <button onClick={this.handleaddad} class="btn btn-primary">Agregar</button>
                <button onClick={this.clearnewadd} class="btn btn-success">  Cancelar</button>


                <h3>Lista de Anuncios</h3>

                {this.state.ads.map((item,index)=> 
                    <div>
                        <label>ID</label>
                        <input type="text" readonly="readonly" defaultValue={item.id}></input>

                        <label>Nombre</label>
                        <input id={"adname:" + item.id} type="text" defaultValue={item.name}></input>

                        <label>Empresa</label>
                        <input id={"adcompany:"+ item.id}type="text" defaultValue={item.company_name}></input> 

                        <label>Url de anuncio</label>
                        <input id={"rredirection:"+ item.id}type="text" defaultValue={item.redirection}></input> 

                        <label for="imgad">Imagen de articulo  </label>
                        <input id={"adimage:" + item.id}  accept="image/png, image/jpeg" onChange={this.chandlechangefile} type="file" ></input>
                        <img src={"https://serviadvisor.com/adphoto/" + item.img_url}></img>

                        <button onClick={(e) => this.handleeditad(e,item.id)} class="btn btn-primary">Guardar</button>
                        <button onClick={(e) => this.handledeleteadd(e,item.id)} class="btn btn-success">Eliminar</button>
                    </div>
                )}
            </div>
        )
    }


    rendertab(){
        let html="";
        switch(this.state.type){
            case types.spaces: html= this.Adspacescontent();
                break;
            case types.listOfads: html= this.adslistcontent();
                break
        }

        return html;
    }

    render(){
        return(
            <div>
              
                {this.rendertab()}
            </div>
        )
    }

}


export default adversitingPage;