import React, { Component } from 'react';
import Moment from 'react-moment';


class DealMessage extends Component {

  constructor(props) { 
    super(props)
    this.dealresponse = this.dealresponse.bind(this)
    this.refuseresponse = this.refuseresponse.bind(this);
    this.renderdealOptions = this.renderdealOptions.bind(this);
    this.renderDealinfo = this.renderDealinfo.bind(this);
    this.renderAssigment = this.renderAssigment.bind(this);
  }

  dealresponse = () => {
    console.log("deal response in deal message")

    if (this.props.onDealresponse) {
      this.props.onDealresponse(this.props.offer.offert.id, true);
    }
  }

  refuseresponse = () => {
    if (this.props.onDealresponse) {
      this.props.onDealresponse(this.props.offer.offert.id, false);
    }
  }


  renderDealinfo(){
    let html=""
    const status = this.props.offer.offert.offert_status.id
    switch (status) {
      case 1:
      case 7:
      case 4: return(<div style={{textAlign:"center"}}> <div class="oferta-estado-recha"><b>{this.props.offer.offert.offert_status.name}</b> </div></div>)
       
      default:  return(<div  style={{textAlign:"center"}} ><div class="oferta-estado-apro"><b>{this.props.offer.offert.offert_status.name}</b> </div></div>)
        break;
    }
  }
  


  renderAssigment(){
  
  }

  renderdealOptions() {
    let html=""
    const status = this.props.offer.offert.offert_status.id
    if (this.props.type === "client" && status===1) {
      return(<div class="casilla-resp">
        <div class="casilla-resp-tit">¿Aceptas esta oferta?</div>
               <div class="casilla-resp-int">

        <div class="casilla-si"><input onClick={this.dealresponse} type="radio" name={"deal" + this.props.offer.id} id={"radiodeal" + + this.props.offer.id} />
          <label for={"radiodeal" + + this.props.offer.id}>si</label><br />
        </div>

        <div class="casilla-no"> <input onClick={this.refuseresponse} type="radio" name={"deal" + + this.props.offer.id} id={"radiodeal2" + + this.props.offer.id} />
          <label for={"radiodeal2" + + this.props.offer.id}>no</label><br />

          </div>
        </div>


      </div>)
         


    }
  }


  render() {
    return (
      <div>

        <div class="caja-chat">
          <div class="img-caja-chat"><a href="#"><img src={this.props.offer.user.photo_url === null ? ("/images/fotoperfil.png") : (this.props.offer.user.photo_url)} class="img-responsive" />  </a></div>
          <div class="texto-chat-oferta">
            {this.props.type === "client" && (<span>{this.props.offer.user.username} te ha hecho una oferta</span>)}
          </div>

          <div class="oferta-chat"  >${this.props.offer.offert.amount}  USD
         </div>
         {this.renderDealinfo()}
          {this.renderdealOptions()}
          {this.renderAssigment()}
         
        </div>
        
        <p class="alinear-derecha-fecha"><Moment format={"DD MMM YYYY hh:mm a"}>{this.props.offer.createdAt}</Moment></p>
        <div class="limp"></div>
      </div>
    )
  }
}

export default DealMessage;