import React,{Component} from 'react';
import axios from 'axios';
import Advertising from '../../menu/advertising';
import CarrouselLateral from '../../menu/carrousellateral';

class PostList extends Component{
    
    constructor(props){
        super(props)
        this.state={
            articles : [],
            activities:[],
            bestarticles:[],
            lastarticle:[]

        }
        this.getarticles= this.getarticles.bind(this);
        this.getactivities = this.getactivities.bind(this);
        this.getbestarticles = this.getbestarticles.bind(this);
    }

    componentDidMount(){
        this.getarticles(this.props.params.category);
        this.getbestarticles(this.props.params.category);
        this.getactivities();
        console.log(this.props.params.category);
    }

    getactivities(){
        axios.get("https://serviadvisor.com/activities").then(res=>{
            const data= res.data;
            if(data && !data.error){
                this.setState({
                    activities:data
                })
            }
        })
    }

    getarticles(category){
        const data={
            category
        }
        axios.post("https://serviadvisor.com/blogarticles",data).then(res=>{
            const data= res.data;
            if(data && !data.error){
                this.setState({
                    articles:data
                })
               

               
                  
                    let article = undefined
                    if(data.length > 0){
                       article= data[0]
                    }
                   
                    this.setState({
                        lastarticle:article
                    })
                
                    console.log("fsdfsfsdfd");
            }
        })
    }

    getbestarticles(category){
        const data={
            category
        }
        axios.post("https://serviadvisor.com/bestblogarticles",data).then(res=>{
            const data= res.data;
            if(data && !data.error){
                this.setState({
                    bestarticles:data
                })
            }
        })
    }

    
    render(){
        return(
            <div class="container">
                <div class="cj-blog">
                     <div class="semilla-busca">Consejos</div>
                </div>
           


                <div class="col-md-9 blog-ct espacio-centro flex">


<div class="banner-blog">

    {this.state.lastarticle && 
    (
    <div><div class="caja-img-banner">
        <a href={"/blog/" + this.state.lastarticle.id + "/post"}><img class="img-blog" src={"https://serviadvisor.com/blogphoto/" + this.state.lastarticle.image_url}  alt=""/> </a> 
    </div>
    <div class="caja-text-banner">
        <div class="titulo_blog">{this.state.lastarticle.title}</div>
        <div class="detalle_post">{this.state.lastarticle.shorttext}</div>
        <div class="btn btn-primary boton-leer"><a href={"/blog/" + this.state.lastarticle.id + "/post"}>Leer Mas</a></div>
    </div>
    </div>
    )
    }

 </div>
          

            {this.state.articles.map((item,index)=>{
                return(
                    <div class="caja-art">   
                    

                        <div class="blog-sub-col-1 blog-display">
                        <div class="foto-blog-nov"><a href={"/blog/" + item.id +"/post"}><img class="img-blog" src={"https://serviadvisor.com/blogphoto/" + item.image_url} alt=""/> </a> 
                         </div>
                        <div class="cont-text_blog">
                           <div class="titulo_blog">
                           <a href={"/blog/" + item.id +"/post"}><h3> {item.title}</h3></a>
                           </div>
                           <div class="detalle_post">
                             {item.shorttext}
                             
                           </div>
                           <div class="btn btn-primary boton-leer"><a href={"/blog/" + item.id +"/post"}>Leer Mas</a></div>
                          
                        </div>
                        <hr class="linea"></hr>
                        </div>                   

                        
                    </div>
                )
            })}
        </div>


        <div class="col-md-3 blog-ct let-client-sec">

        <div class="azul-border"><h3>Artículos más leídos </h3></div>
                <div class="publicidad-lateral">

                {this.state.bestarticles.map((item,index)=>
                    <div class="blog-sub-col-1 blog-display">
                    <div class="foto-blog-nov-lat"><a href={"/blog/" + item.id + "/post"}><img class="img-blog"src={"https://serviadvisor.com/blogphoto/" + item.image_url} alt=""/> </a>  </div>
                    <div class="titulo_blog"> <a href={"/blog/" + item.id + "/post"}><h3> {item.title}</h3></a></div>
                </div>
                )}
                
                
                </div>

        <div class="azul-border"><h3>Catergorias</h3></div>
        <div class="publicidad-lateral cont-cate-blog">

                {this.state.activities.map((item,index) => 
                 <div class="btn btn-primary cate-blog"><a href={"/blog/" + item.name} >{item.name}</a></div>
                
                )}
                </div>
               
            
                
          
                <div class="publicidad-lateral">
               <Advertising adspaceid="1"></Advertising>
                </div>
                <div class="letras-pequeñas-publ"><p>Espacio Publicitario ¿Quieres anunciarte con nosotros?</p></div>

                <div class="azul-border"><h3>Recomendados</h3></div>

                <div class="publicidad-lateral">
                 <CarrouselLateral></CarrouselLateral>
                </div>
                



          
              </div>
        </div>)
    }
}

export default PostList;