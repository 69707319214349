import React,{Component} from 'react';
import axios from 'axios';
import DealBox from '../../chat/DealBox';

class LastOffers extends Component{
    constructor(props){
        super(props);
        this.state={
            deals:[]
        }
    }

    componentDidMount(){
        const data={
            chatid:this.props.chatId
        }
        axios.post('https://serviadvisor.com/getdeals',data,{withCredentials:true}).then(res => {
            const delasdata = res.data;
            if(!delasdata.error){
                this.setState({
                    deals:delasdata
                })
            }   
        })
    }

    render(){
        return(
            <div class="propue-caj">
                {this.state.deals.map((item,index)=>{
                    return(<div class="propue-2">
                    <DealBox offer={item.offert}></DealBox>
                    </div>
                    )
                })}
            </div>
        )
    }
}

export default LastOffers