
const profileutils=[]



profileutils.setuserprofile = (user) => {
    localStorage.setItem("user",JSON.stringify(user));
}


profileutils.deleteprofile = () => {
    localStorage.removeItem("user");
}

profileutils.getuserprofile = () =>{
    let user = localStorage.getItem("user");
 
    if(user && !"undefined"){
    console.log(user);
    user = JSON.parse(user);
    }else{
        user=undefined;
    }
    return(user);
}

export default profileutils;