import React, { Component } from 'react';
import Producto from './paypalproduct';
import SubscriptionPlan from './subscriptionplan';
import Articulos from './articulos';
import Categorias from './categoriesAdmin';
import Cities from './citiesAdmin';
import Usuariosadmin from './usuariosadmin';
import Usuarios from './usuarios';
import Anuncios from './advertisingPage';
import Axios from 'axios';
import Comision from './comision';
import Revision from './revision';
import Ofertas from './ofertas';
import Orders from './ordersadmin';

class adminContent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tab: undefined,
            superadmin: false,
        }
        this.rendertab = this.rendertab.bind(this);
    }

    componentDidMount() {
        this.checkusertype();
        const tab = this.props.params.tab;
        this.settab(tab);


    }

    settab(tab) {
        this.setState({
            tab: tab
        })
    }

    handlelistclick = (e, name) => {
        this.props.history.push('/AdvisorAdministrator/' + name);
        this.settab(name);
    }

    deletepublisher(){
        
    }

    checkusertype() {
        Axios.post('https://serviadvisor.com/checkusertype', null, { withCredentials: true }).then(res => {
            const data = res.data;
            if (data && data.status === "ok") {
                this.setState({
                    superadmin: true
                })
            } else {
                this.setState({
                    superadmin: false
                })
            }
        })
    }

    rendertab(tab) {
        let html = "";

        switch (tab) {
            case 'productos': html = this.state.superadmin ? <Producto></Producto>  : "Acces Denied"
                break;
            case 'planes':  html = this.state.superadmin ?  <SubscriptionPlan></SubscriptionPlan>  : "Acces Denied"
                break;
            case 'articulos': html = <Articulos></Articulos>
                break;
            case 'categorias': html = <Categorias></Categorias>
                break;
            case 'ciudades': html = <Cities></Cities>
                break;
            case 'usuarios': html = <Usuarios></Usuarios>
                break;
            case 'administradores':  html = this.state.superadmin ? <Usuariosadmin></Usuariosadmin> : "Acces Denied"
                break;
            case 'publicidad' : html = <Anuncios></Anuncios>
            break;
            case 'comision' : html = <Comision></Comision>
            break;
            case 'revision' : html = <Revision></Revision>
            break;
            case 'ofertas' : html = <Ofertas></Ofertas>
            break;
            case 'orders' : html = <Orders></Orders>
            break;

            default: html = <div>default</div>
        }

        return (
            <div>
                {html}
            </div>
        )
    }

    render() {
        return (
            <div class="container-admin">




<div id="header">
    <div class="top">
        <div class="container">
        <ul class="top-support"><li><span>Bienvenid@s a ServiAdvisor</span></li></ul>
        
        </div>
        <div id="believe-nav">
<div class="container">

<div class="min-marg">

    <nav class="navbar navbar-default">
        <div class="navbar-header">

        <a class="navbar-brand" href="/"><img src="/images/logo.png" class="logo" />
        </a>
        </div>

    
    </nav>

</div>



</div>

</div>


    </div>

</div>




                <div class="blog-ct-admin">

                    <div class="col-md-3 ">

                        <div class="azul "><h3>Administrator</h3></div>

                        <div>
                            <ul class="tab-7">
                                <li onClick={e => this.handlelistclick(e, "articulos")} id="articulos" class=""><p>Articulos</p> <div class="border-bot-gris"></div></li>
                                <li onClick={e => this.handlelistclick(e, "categorias")} id="categorias" class=""><p>categorias</p> <div class="border-bot-gris"></div></li>
                                <li onClick={e => this.handlelistclick(e, "ciudades")} id="ciudades" class=""><p>Ciudades</p> <div class="border-bot-gris"></div></li>


                                {this.state.superadmin && <li onClick={e => this.handlelistclick(e, "productos")} id="Productos" class=""><p>Productos</p> <div class="border-bot-gris"></div></li>}
                                {this.state.superadmin && <li onClick={e => this.handlelistclick(e, "planes")} id="Planes" class=""><p>Planes de Subscripcion</p> <div class="border-bot-gris"></div></li>}
                                {this.state.superadmin && <li onClick={e => this.handlelistclick(e, "administradores")} id="administradores" class=""><p>Administradores</p> <div class="border-bot-gris"></div></li>}
                                {this.state.superadmin && <li onClick={e => this.handlelistclick(e, "comision")} id="comision" class=""><p>Comisiones</p> <div class="border-bot-gris"></div></li>}
                                {this.state.superadmin && <li onClick={e => this.handlelistclick(e, "usuarios")} id="usuarios" class=""><p>Usuarios</p> <div class="border-bot-gris"></div></li>}
                                {this.state.superadmin && <li onClick={e => this.handlelistclick(e, "revision")} id="revision" class=""><p>Revision</p> <div class="border-bot-gris"></div></li>}
                                {this.state.superadmin && <li onClick={e => this.handlelistclick(e, "ofertas")} id="ofertas" class=""><p>Ofertas</p> <div class="border-bot-gris"></div></li>}
                                {this.state.superadmin && <li onClick={e => this.handlelistclick(e, "orders")} id="orders" class=""><p>Ordenes clientes</p> <div class="border-bot-gris"></div></li>}
                                {this.state.superadmin && <li onClick={e => this.handlelistclick(e, "publicidad")} id="Publicidad" class=""><p>Publicidad</p> <div class="border-bot-gris"></div></li>}


                            </ul>
                        </div>

                        <div>
                        </div>

                    </div>
                    <div class="col-md-9">

                        {this.rendertab(this.state.tab)}
                    </div>
                </div>
            </div>
        )
    }
}
export default adminContent;