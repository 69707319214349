import React,{Component} from 'react';
import Axios from 'react';
import Mapa from '../../maps/Googlemap';
import notifications from '../../../utils/notifications';

class MapSection extends Component{
    constructor(props){
        super(props)
        this.state={
        
        }
        this.notify = this.notify.bind(this);
    }

    componentDidMount(){
        
        this.props.sockets.sockets.callback=this.notify
    }

    notify(data){
          console.log(this.props.user);
          notifications.buildNotification(data.type, data.message, this.props.user.accountType, data.senderid, data.profileId); 
  }
    
    render(){
        return(
            <div>
                     <div class="title-perfil-empresa espacio-top">
                                      <h4 class="azul"> <a href="#">Información</a></h4>
                                  </div> 
                                  {console.log(this.props.location)}
                                  {this.props.mapisready  && this.props.location.lat !==null && (<Mapa location={this.props.location}></Mapa>)}
                                
                                  <p class="espacio-arriba-abajo"> </p>
            </div>
        )
    }
}

export default MapSection;