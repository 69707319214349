import React, {Component} from 'react';
import HeaderPage from '../HeaderPage';
import TituloPerfilCliente from '../TituloPerfilCliente';
import Footer from '../Footer';
import ContenidoPerfilCliente from '../ContenidoPerfilCliente';
import CategoriasFooter from '../CategoriasFooter';
import PublicidadFooter from '../PublicidadFooter';



class PerfilCliente extends Component{
    constructor(props){
        super(props);
        this.state={
            islogin:false,
            user:undefined
        }
        this.logindata= this.logindata.bind(this);
    }

    logindata=(user, islogin)=>{
        this.props.loginroot(user,islogin);
        this.setState({
            user:user,
            islogin:islogin
        })
    }

    render(){
        return(
            <div>
                <HeaderPage  slideout={this.props.slideout} user={this.state.user} logindata={this.logindata} islogin={this.state.islogin} history={this.props.history}></HeaderPage>
                <TituloPerfilCliente></TituloPerfilCliente>
                <ContenidoPerfilCliente></ContenidoPerfilCliente>
                <CategoriasFooter></CategoriasFooter>
                <PublicidadFooter></PublicidadFooter>
                <Footer user={this.state.user} islogin={this.state.islogin}></Footer>
            </div>
        );
    }
}

export default PerfilCliente;