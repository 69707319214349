import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

class Usuarios extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pager: {},
            pageOfItems: [],
            userStatus: [],
            usernamefilter:""
        }
        this.loadPage = this.loadPage.bind(this);
        this.loadPagefilter = this.loadPagefilter.bind(this);
        this.handleupdatebutton = this.handleupdatebutton.bind(this);
    }

    componentDidMount() {
        this.loadPage();
        this.getStatus();
    }
    getStatus() {
        axios.get('https://serviadvisor.com/getuserstatus').then(res => {
            const data = res.data;
            if (data) {
                this.setState({
                    userStatus: data
                })
            }
        })
    }

    handleonchangefilter(event){
        event.preventDefault();
        const filter = event.target.value;
        this.setState({
            usernamefilter:filter
        })
    }

    getoption(statusId, item2, index) {
        if (statusId === item2.id) {
            return (
                <option key={index} value={item2.id} selected>{item2.name}  </option>
            )
        } else {
            return (
                <option key={index} value={item2.id}>{item2.name}  </option>
            )
        }
    }

    loadPagefilter(page){

       
              // get page of items from api
        if (!page) {
            const params = new URLSearchParams(window.location.search);
            page = parseInt(params.get('page')) || 1;
        }

        if (page !== this.state.pager.currentPage) {
            const data = {
                page: page,
                filter: this.state.usernamefilter
            }

          

            axios.post("https://serviadvisor.com/administrator/getusersfilter", data, { withCredentials: true })
                .then(response => response.data)
                .then(({ pager, pageOfItems }) => {
                    this.setState({ pager, pageOfItems });
                });


        }
    }

    handleupdatebutton(e,id){
        e.preventDefault();
       
        const active = document.getElementById("campocheck:" + id).checked;
        const status = document.getElementById("campostatus:" + id).value;

        const data = {
            active,
            status,
            user:id
        }

        axios.put("https://serviadvisor.com/administrator/updateusers",data,{withCredentials:true}).then(res => {
            this.loadPage(this.state.pager.page);
            this.getStatus();
        })
    }


    loadPage(page) {


        // get page of items from api
        if (!page) {
            const params = new URLSearchParams(window.location.search);
            page = parseInt(params.get('page')) || 1;
        }

       
            const data = {
                page: page,
                filter: this.state.usernamefilter
            }


            if(this.state.usernamefilter ===""){
                axios.post("https://serviadvisor.com/administrator/getusers", data, { withCredentials: true })
                .then(response => response.data)
                .then(({ pager, pageOfItems }) => {
                    this.setState({ pager, pageOfItems });
                });
            }else{
                axios.post("https://serviadvisor.com/administrator/getusersfilter", data, { withCredentials: true })
                .then(response => response.data)
                .then(({ pager, pageOfItems }) => {
                    this.setState({ pager, pageOfItems });
                });
            }

          


        
    }

    render() {
        const { pager, pageOfItems } = this.state;
        return (<div class="caja-usuarios">

            <div className="card text-center m-3">
              
                <div className="card-body text-left">
                </div>
                <table class="table table-sm">
                    <thead>
                        <tr>

                            <th scope="col">ID</th>
                            <th scope="col">Username</th>
                            <th scope="col">Foto</th>
                            <th scope="col">Tipo de Cuenta</th>
                            <th scope="col">Validado</th>
                            <th scope="col">Estado</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>

                        {pageOfItems.map((item, index) =>


                            <tr key={item.id}>

                                <td> {item.id}</td>
                                <td> {item.username}</td>
                                <td><img src={item.photo_url}></img></td>
                                <td>{item.accountType}</td>
                                <td><input id={"campocheck:" + item.id} type="checkbox" defaultChecked={item.active}></input></td>
                                <td>
                                    <select id={"campostatus:" + item.id} type="text" class="form-control"  placeholder="Ciudad">

                                        {this.state.userStatus.map((item2, index2) => this.getoption(item.userStatusId, item2, index2))}
                                    </select>
                                </td>
                                <button onClick={(e) => this.handleupdatebutton(e,item.id)} class="btn btn-primary">Guardar Cambios</button>
                            </tr>

                        )}






                    </tbody>
                </table>


                <label>Buscar por Username:</label> <input onChange={e => this.handleonchangefilter(e)} type="text"></input>
                <button onClick={(e) => this.loadPage(1)}  class="btn btn-primary" value="Guardar">Buscar </button>
                <button onClick={(e) => {
                    this.setState({
                        usernamefilter:""
                    }, () => {
                        this.loadPage(1)
                    })
                   }} class="btn btn-success" > Limpiar busqueda</button>

               
              


                <div className="card-footer pb-0 pt-3">
                    {pager.pages && pager.pages.length &&
                        <ul className="pagination">
                            <li className={`page-item first-item ${pager.currentPage === 1 ? 'disabled' : ''}`}>
                                <Link to={{ search: `?page=1` }} className="page-link">First</Link>
                            </li>
                            <li className={`page-item previous-item ${pager.currentPage === 1 ? 'disabled' : ''}`}>
                                <Link to={{ search: `?page=${pager.currentPage - 1}` }} className="page-link">Previous</Link>
                            </li>

                            {pager.pages.map(page =>
                                <li key={page} className={`page-item number-item ${pager.currentPage === page ? 'active' : ''}`}>
                                    <Link onClick={(e) => this.loadPage(page)} to={{ search: `?page=${page}` }} className="page-link">{page}</Link>
                                </li>
                            )}
                            <li className={`page-item next-item ${pager.currentPage === pager.totalPages ? 'disabled' : ''}`}>
                                <Link to={{ search: `?page=${pager.currentPage + 1}` }} className="page-link">Next</Link>
                            </li>
                            <li className={`page-item last-item ${pager.currentPage === pager.totalPages ? 'disabled' : ''}`}>
                                <Link to={{ search: `?page=${pager.totalPages}` }} className="page-link">Last</Link>
                            </li>
                        </ul>
                    }
                </div>
            </div>

        </div>)
    }
}

export default Usuarios;