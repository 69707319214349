import React, { Component } from 'react';
import DealBox from './DealBox';
import Axios from 'axios';
import notifications from '../../utils/notifications';
import { Modal, Button } from 'react-bootstrap';
import ReactStars from 'react-stars'
import { toast } from 'react-toastify';
import Moment from 'react-moment';


import DatePicker from 'react-datepicker';
import setMinutes from "date-fns/setMinutes";
import setHours from "date-fns/setHours";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/es';


import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css'

class DealsClient extends Component {
    constructor(props) {
        super(props);

        this.state = {
            description: "",
            price: 0,
            Deals: [],
            FullDeals:[],
            cropmodalshow: false,
            currentoffer:undefined,
            modalqualify: false,
            actualOfferid: undefined,
            actualOfferidrev: undefined,
            activePay:false,
            coment: "",
            actualPage:0,
            starts:0,
            cropmodalshowok: false,
            cropmodalshowrefuse: false,
            modalrevisionshow:false,
            startDate: new Date(),
            files:undefined,
            mensaje:"",
            tipodesolicitud: ""

        }

        registerLocale('es', es)
        this.getDeals = this.getDeals.bind(this);
        this.refreshChat = this.refreshChat.bind(this);
        this.refreshmessages = this.refreshmessages.bind(this);

        this.renderbox = this.renderbox.bind(this);
        this.finishAndqualify = this.finishAndqualify.bind(this);
        this.sendqualify = this.sendqualify.bind(this);
        this.onChangeComent = this.onChangeComent.bind(this);
        this.payoffer = this.payoffer.bind(this);
        this.offerhaspay = this.offerhaspay.bind(this);
        this.checkproviderpaymentprofile= this.checkproviderpaymentprofile.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.refuseOffer = this.refuseOffer.bind(this);
        this.dealrevision = this.dealrevision.bind(this);
        this.onchangetipo = this.onchangetipo.bind(this);
        this.onchangemensaje = this.onchangemensaje.bind(this);
        this.sendrevision = this.sendrevision.bind(this);

        this.goTonextPage = this.goTonextPage.bind(this);
    }

    dealrevision =(offerid) => {
      this.setState({
        modalrevisionshow:true,
        actualOfferidrev:offerid
      })
    }


    dealresponse = (offertId,response) =>  {

        this.handleClientResponse(offertId,response);
        
    }

    showofferinfo = (offer) => {

        console.log(offer);
        this.setState({
          cropmodalshow:true,
          currentoffer:offer,
          activePay:false
        }, () => {
            if(offer.offertStatusId!==5)
           
            this.checkproviderpaymentprofile(offer.providerId, offer);
        })

    
      }


      checkproviderpaymentprofile(providerid,offer){
          console.log(providerid);
          const data={
              providerId:providerid
          }
          Axios.post('https://serviadvisor.com/checkifpaymentprofile',data,{withCredentials:true}).then(res => {
              const resdata = res.data;
              if(!resdata.error){
                 
                  this.setState({
                      activePay:true
                  }, () => {
                    this.renderbutton(offer,this.offerhaspay);
                  })
              }
          })
      }

      handleChange(date) {
        this.setState({
          startDate: date
        });
      }
    
    
      makeAssigments = () => {
    
        const data = {
          date: this.state.startDate,
          offertId: this.state.offertId
        }
    
        Axios.post('https://serviadvisor.com/assigndate/', data, { withCredentials: true }).then(response => {
          console.log(response);
          if (response.error) {
    
          } else {
            this.getDeals();
           
            this.setState({ cropmodalshowok: false });
            }
        })
      }
    
    
      refuseOffer = () => {
        const data = {
          offertId: this.state.offertId
        }
    
        Axios.post('https://serviadvisor.com/declineOffer/', data, { withCredentials: true }).then(response => {
          console.log(response);
          if (response.error) {
    
          } else {
            this.getDeals();
            this.setState({ cropmodalshowrefuse: false });
            }
        })
      }

    offerhaspay(order,offer){
        const data={
            order_id:order.id,
            payer_id:order.payer.payer_id,
            email:order.payer.email_address,
            offertId:offer.id,
            providerId:offer.providerId,
            clientId:offer.clientId
        }

        Axios.post('https://serviadvisor.com/checkorder',data,{withCredentials:true}).then(res => {
            console.log(res.data);
            this.setState({
              cropmodalshow:false,
              currentoffer:undefined
            })
            this.getDeals();
        })
    }

    renderbutton(offer,cb) {
        const amount= offer.amount;
        console.log(amount)
         setTimeout(() =>{
          window.paypal.Buttons({
              createOrder: function(data, actions) {
                // Set up the transaction
                return actions.order.create({
                  purchase_units: [{
                    amount: {
                      value: amount
                    }
                  }]
                });
              },
              onApprove: function(data, actions) {
                  return actions.order.capture().then(function(details) {
                  
                    // Call your server to save the transaction
                      console.log(details);
                      console.log(data);
                      cb(details,offer);
                  });
                }
            }).render('#paypal-button-container');
         })
   
     }

    componentDidMount() {
        this.getDeals();
        this.props.sockets.callback = this.refreshmessages;
      
    }

    finishAndqualify = (offertid) => {

        this.setState({
            modalqualify: true,
            actualOfferid: offertid,
            coment: ""
        })
    }


    sendqualify() {

        const data={
            starts:this.state.starts,
            coment:this.state.coment,
            offertId:this.state.actualOfferid
        }

        Axios.post('https://serviadvisor.com/offerserviceComplete',data,{withCredentials:true}).then(res => {
            const data= res.data;
        if(!data.error){
            this.setState({
                modalqualify:false
            })
            this.getDeals();
        }else{
            toast.error(data.error)
        }
        
        
        })

       
    }


    refreshmessages(data) {
        if (data.type == "offer") {
            this.getDeals();
        } else {
            notifications.buildNotification(data.type, data.message, this.props.user.accountType, data.senderid, data.profileId);
        }
    }

    gotoprevPage(){
      let pagenum = this.state.actualPage;
      pagenum = pagenum - 1;


      


      this.changepage(pagenum);
  }

  goTonextPage(){

      let pagenum = this.state.actualPage;
      pagenum = pagenum + 1;

       
    
      this.changepage(pagenum);
    
  }

  changepage(number){

      console.log(this.state.actualPage)

      const data = {
          chatid: this.props.chatid,
          page: number
      }
      Axios.post('https://serviadvisor.com/getdealsPage', data, { withCredentials: true }).then(res => {
          if (data.error) {

          } else {

              const Dealsdata = res.data

              this.setState({
                  Deals: Dealsdata,
                  actualPage:number
              }, () => {  })
          }
      })
  }


    renderNextPage = () =>{

      let pageNumber = Math.ceil(this.state.FullDeals.length / 12);
       


      if(this.state.FullDeals.length > 12 && pageNumber - 1 !== this.state.actualPage){
          return(<a class="nextpageletter" onClick= {(e) => this.goTonextPage()} href="javascript:void(0)">Pagina Siguiente</a>)
      }
   }

   renderPrevPage = () =>{
       if(this.state.FullDeals.length > 12 && this.state.actualPage > 0){
           return(<a class="nextpageletter" onClick= {(e) => this.gotoprevPage()} href="javascript:void(0)">Pagina Anterior</a>)
       }
    }


    getDeals = () => {

        const data = {
            chatid: this.props.chatid,
            page:0
        }
        Axios.post('https://serviadvisor.com/getdealsPage', data, { withCredentials: true }).then(res => {
            if (data.error) {

            } else {

                const Dealsdata = res.data

                this.setState({
                    Deals: Dealsdata
                }, () => { setTimeout(this.refreshChat, 1000) })
            }

        })


        Axios.post('https://serviadvisor.com/getdeals', data, { withCredentials: true }).then(res => {
          if (data.error) {

          } else {

              const Dealsdata = res.data

              this.setState({
                  FullDeals: Dealsdata
              }, () => {  })
          }

      })
    }

    onchangemensaje(e){
      const mensaje = e.target.value;

      this.setState({
        mensaje:mensaje
      })

    }

    onchangetipo(e){
      const tipo = e.target.options[e.target.selectedIndex].text;

      this.setState({
        tipodesolicitud:tipo
      })
    }


    sendrevision(){
      const data = new FormData()


      if(this.state.mensaje==="" || this.state.tipodesolicitud===""){
        const notify = () => toast.error("llena los campos para enviar la solicitud");
        notify();
        return
      }

      data.append('chatid', this.props.chatid);
      data.append('offerid', this.state.actualOfferidrev);
      data.append('type', 'cliente');
      data.append('razon', this.state.tipodesolicitud );
      data.append('mensaje', this.state.mensaje);

      if(this.state.files){
        for(var x = 0; x<this.state.files.length; x++) {

          if (this.state.files[x]) {
       const file = this.state.files[x];
       var pattern = /image-*/;
 
       if (!file.type.match(pattern)) {
         const notify = () => toast.error("Formato de imagen erronea");
         notify();
         return;
       }
 
     
     }
 
 
         data.append('files', this.state.files[x]);
     }
      }

     

      Axios.post('https://serviadvisor.com/administrator/revisionmail',data, {withCredentials:true,  headers: {
        'content-type': 'multipart/form-data'
      }}).then(res => {
        const data = res.data;
        if(!data.error){
         
          this.setState({
            modalrevisionshow:false
          })

          const notify = () => toast.info("enviada la solicitud de mediacion");
          notify();

        }


      
      })

      
    }

    onfileschange(e){
        this.setState({
          files: e.target.files
        })
    }


    onChangeComent = (e) => {
        const value = e.target.value

        this.setState({
            coment: value
        })
    }

    setstars= (starts) =>{
       this.setState({
           starts:starts
       })
    }

    payoffer= (offer) =>{
        this.showofferinfo(offer);
    }

    refreshChat() {
        //this.getmessages();
    }

    // first timeout to start the process

    renderbox(item) {
        if (item.offert !== null && item.offert !== undefined)
            return (
                <DealBox onDealrevision={this.dealrevision} chatid={this.props.chatid} onDealresponse={this.dealresponse}  onpayOffer={this.payoffer} onWorkDone={this.finishAndqualify} type={"client"} offer={item.offert}></DealBox>
            )
    }

    openmodalDate(offertId) {
        this.setState({
          cropmodalshowok: true,
          offertId:offertId
        })
      }
    
      openmodalrefuse(offertId) {
        this.setState({
          cropmodalshowrefuse: true,
          offertId:offertId
        })
      }

    handleClientResponse = (offertId,response) => {
        if(response){
          this.openmodalDate(offertId)
        }else{
          this.openmodalrefuse(offertId)
        }
        
      }


    render() {
        let modalClose = () => this.setState({ modalqualify: false });
        let modalClose2 = () => this.setState({ cropmodalshow: false });
        let modalCloseok = () => this.setState({ cropmodalshowok: false });
        let modalCloserefuse = () => this.setState({ cropmodalshowrefuse: false });
        let modalrevision = () => this.setState({modalrevisionshow:false});
        return (
            <div>
               
                <div class="title-perfil-empresa">
                    <h4 class="azul"> Propuestas   <a href="javascript:history.back()" class="btn-atras"> <i class="fas fa-arrow-alt-circle-left"></i></a></h4>

                   
                </div>
               


                <div class="caja-t-ofert">



                    <div class="ofertas-anteriores">
                        {this.state.Deals.map((item, index2) => {

                            return (
                                <div key={index2} 
                                class={"propue"}>
                                    {this.renderbox(item)}

                                </div>
                            );
                        })}

                    </div>
                    <div class="limp"></div>
                    
                    {this.renderPrevPage()}
                    {this.renderNextPage()}


                </div>




                <Modal className="recorte pro" show={this.state.modalqualify} onHide={modalClose}
                    {...this.props}
                    size="lg"
                    animation={false}

                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Califica el servicio y deja tu comentario
                           
                <hr />
                <div className="flecha-down"></div>
              </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div >

                            <ReactStars
                                count={5}
                                onChange={this.setstars}
                                size={24}
                                half={false}
                                value={this.state.starts}
                                color2={'#ffd700'} />
                          
                          <div className="text-satis">Esta satifecho con el servicio que recibio? </div>

                            <textarea onChange={this.onChangeComent}  value={this.state.coment} className={"caja-comentario"}> </textarea>

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.sendqualify} className={"btn-primary"}>Aceptar</Button>
                        <Button onClick={modalClose} className={"btn-success"}>Cerrar</Button>
                    </Modal.Footer>
                </Modal>

                
                <Modal className="recorte pro" show={this.state.cropmodalshow} onHide={modalClose2}
          {...this.props}
          size="lg"
          animation={false}

          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Informacion De la Oferta
             
                <hr />
                <div className="flecha-down"></div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
                    
            <div>
           
            
            {this.state.currentoffer && (<div>
            <p>Detalle:{this.state.currentoffer.description} </p>
            <p>Direccion: </p>
            <p>Precio: {this.state.currentoffer.amount}</p>
            <div class="oferta-estado-apro"><b>{this.state.currentoffer.offert_status.name}</b> </div>
            <p>fecha De Asignacion : <Moment format={"DD MMM YYYY hh:mm a"}>{this.state.currentoffer.serviceAssingment.AssigmentDate}</Moment> </p>
                
            {this.state.activePay ? (
                 <div id="paypal-button-container"></div>
            ) :
             "Solicita al proveedor que configure su cuenta de Pago Paypal"}
           
          
          
            </div>)}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={modalClose2} className={"btn-success"}>Cerrar</Button>
          </Modal.Footer>
        </Modal>





        <Modal className="recorte pro-cal" show={this.state.cropmodalshowok} onHide={modalCloseok}
              {...this.props}
              size="lg"
              animation={false}
    
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  Selecciona una fecha
            
                <hr />
                <div className="flecha-down"></div>
              </Modal.Title>
              </Modal.Header>
              <Modal.Body>
    
              <div class="caja-calendario-popup">
                  <DatePicker
                    locale="es"
                    inline
                    selected={this.state.startDate}
                    onChange={this.handleChange}
                    showTimeSelect
                    //excludeTimes={[setHours(setMinutes(new Date(), 0), 17), setHours(setMinutes(new Date(), 30), 18), setHours(setMinutes(new Date(), 30), 19), setHours(setMinutes(new Date(), 30), 17)]}
                    dateFormat="MMMM d, yyyy h:mm aa"
                  />
                  <p class="text-calen">
                    <span>{"Fecha Seleccionada: " + this.state.startDate}</span>
                  </p>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={this.makeAssigments} className={"btn-primary"}>Aceptar</Button>
                <Button onClick={modalCloseok} className={"btn-success"}>Cerrar</Button>
              </Modal.Footer>
            </Modal>
    
    
    
            <Modal className="recorte pro" show={this.state.cropmodalshowrefuse} onHide={modalCloserefuse}
              {...this.props}
              size="lg"
              animation={false}
    
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  Confirmacion
                  
                 
                <hr />
                <div className="flecha-down"></div>
              </Modal.Title>
              </Modal.Header>
              <Modal.Body>
    
                <div >
                    Esta Seguro de rechazar la propuesta?
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={this.refuseOffer} className={"btn-primary"}>Aceptar</Button>
                <Button onClick={modalCloserefuse} className={"btn-success"}>Cerrar</Button>
              </Modal.Footer>
            </Modal> 



              <Modal className="recorte pro" show={this.state.modalrevisionshow} onHide={modalrevision}
                    {...this.props}
                    size="lg"
                    animation={false}

                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Formulaio de mediación 
                           
                <hr />
                <div className="flecha-down"></div>
              </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>


                  

                    <div class="caja-pop-med">
                          <select onChange={this.onchangetipo} className="sel-pop">
                           <option>Selecciona una opción</option>
                            <option>No me entregó a tiempo</option>
                            <option>Entregó incompleto</option>
                            <option>Insatisfecho con la calidad</option>
                            <option>Fin de propuesta por mutuo acuerdo</option>
                            <option>otro</option>
                          </select>
                          <p>
                            <br></br>
                         <textarea onChange={this.onchangemensaje}></textarea>
                         </p>
                         <p className="text-evid">Evidencia: arrastra dentro de la zona punteado tus archivos</p>
                          <input onChange={(e)=> this.onfileschange(e)} name="files" multiple="multiple"  accept="image/*" type="file" className="s-pop"></input>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={(e)=> this.sendrevision()}  className={"btn-primary"}>Aceptar</Button>
                        <Button onClick={modalrevision} className={"btn-success"}>Cerrar</Button>
                    </Modal.Footer>
                </Modal>       


            </div>
        );
    }
}

export default DealsClient