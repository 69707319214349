import React,{Component} from 'react';
import Moment from 'react-moment'

class assigmentview extends Component{
    constructor(props){
        super(props)
        this.onConfirmDate = this.onConfirmDate.bind(this);
    }

    onConfirmDate = () =>{
        if(this.props.onConfirm){
            this.props.onConfirm(this.props.offer.offert.id)
        }
    }

    onreasign = () => {
        if(this.props.onReasign){
            this.props.onReasign(this.props.offer)
        }
    }
    
    
    render(){
        return(
        
             <div class="assigment_box">
                                    
                                        {this.props.type==="client" ? (
                                            <div ><span>Proveedor:  {this.props.offer.offert.provider.user.username}</span></div>
                                        ):
                                        ( <div><div ><span>Cliente:  {this.props.offer.offert.client.user.username}</span></div>
                                        <div><span>Nombre:  {this.props.offer.offert.client.name}</span></div></div>) }
                                   
                                     <div ><span>Descripción:  {this.props.offer.description}</span></div>
                                     <div ><span> Precio: $ {this.props.offer.offert.amount}</span></div>
                                     <div ><span> Fecha: <Moment format={"DD MMM YYYY hh:mm a"}>{this.props.offer.AssigmentDate}</Moment></span></div>
                                     <button onClick={this.onConfirmDate}  className={"btn-primary btn-cuent"} type="button" value="Confirmar Fecha">Confirmar Fecha</button>
                                     <button onClick={this.onreasign}  className={"btn-success btn-cuent"} type="button" value="Reasignar Fecha">Reasignar Fecha</button>
                                    
                                     
                                </div>
        )
    }
}

export default assigmentview;