import React, { Component } from 'react';
import MapContainer from '../../maps/Googlemap';
import axios from 'axios';
import notifications from '../../../utils/notifications'
import Loader from 'react-loader-spinner';

class Mapa extends Component {

    constructor(props) {
        super(props)
        this.state = {
            lat: 0,
            lng: 0,
            mapready: false,
            location: [],
            loaderSave:false,
            loadsuccess:false,
        }
        this.setcoordinates = this.setcoordinates.bind(this);
        this.setlocation = this.setlocation.bind(this);
        this.handlesubmit = this.handlesubmit.bind(this);
        this.refreshdata = this.refreshdata.bind(this);
    }

    componentDidMount() {
        let data;
        axios.get('https://serviadvisor.com/checkprovider', { withCredentials: true }).then(res => {

            data = res.data;
            if (data.error) {

            } else {
                console.log(data);
                this.setState({
                    lat: data.lat,
                    lng: data.long
                })
                this.setlocation(data.lat, data.long);
            }
        })

        this.props.sockets.sockets.callback = this.refreshdata
    }

    refreshdata(data) {
        notifications.buildNotification(data.type, data.message, data.accountType, data.senderid, data.profileId,data.source);
    }

    setlocation = (lat, lng) => {
        this.setState({
            location: {
                lat: lat,
                lng: lng
            }
        }, () => {
            this.setState({
                mapready: true
            })
        })
    }

    handlesubmit = () => {

        this.setState({
            loaderSave:true
        })

        const coorsData = {
            lat: this.state.lat,
            lng: this.state.lng
          }
          axios.put('https://serviadvisor.com/updateCoordinates',coorsData, {withCredentials:true}).then(res => {
            this.setState({
                loaderSave:false,
                loadsuccess:true
            })
          })
    }



    setcoordinates = (latlng) => {
        const lat = latlng.lat();
        const lng = latlng.lng();
        this.setState({
            lat: lat,
            lng: lng
        }, () => {
            console.log(this.state.lat);
            console.log(this.state.lng);
        })
        console.log(latlng);

    }


    render() {
        return (
            <div>
                 <div class="title-perfil-empresa espacio-top">
          <h4 class="azul"> Localización</h4>
        </div>
                 

                <p>Ubica el pin en el lugar en donde tu empresa se encuentra ubicada. </p>
                <button onClick={this.handlesubmit} type="button" class="btn btn-primary btn guar-map" >Guardar Mapa  <i class="fas fa-angle-right"></i>   <Loader
          visible={this.state.loaderSave}
          type="Oval"
          color="#00BFFF"
          height={25}
          width={25}
       
        /></button>

{this.state.loadsuccess && (<span class="alert"><i class="fas fa-check-circle"></i> Actualizacion realizada con exitó.</span>)}

                <div class="wrapper">
                    {this.state.mapready && (<MapContainer editable={true} location={this.state.location} OnsetCoors={this.setcoordinates}></MapContainer>)}


                </div>
              
            </div>
        );
    }
}

export default Mapa;