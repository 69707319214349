import React, { Component } from 'react';
import DealBox from './DealBox';
import Axios from 'axios';
import notifications from '../../utils/notifications';
import Loader from 'react-loader-spinner';
import { Modal, Button } from 'react-bootstrap';
import ReactStars from 'react-stars'
import { toast } from 'react-toastify';

class Deals extends Component {
    constructor(props) {
        super(props);

        this.state = {
            description: "",
            price: 0,
            Deals: [],
            FullDeals:[],
            loaderSave: false,
            loadsuccess: false,
            files:undefined,
            mensaje:"",
            tipodesolicitud: "",
            tipoderevision : 1,
            actualPage:0,
            totalpagenumber:0, 
            newprice: "",
            newDescription: "",
            filter:0,
            order:""
        }


        this.getDeals = this.getDeals.bind(this);
        this.dealEdicion = this.dealEdicion.bind(this);
        this.cancelTheDeal = this.cancelTheDeal.bind(this);
        this.refreshChat = this.refreshChat.bind(this);
        this.refreshmessages = this.refreshmessages.bind(this);
        this.onchangetextPrice = this.onchangetextPrice.bind(this);
        this.renderbox = this.renderbox.bind(this);
        this.dealrevision = this.dealrevision.bind(this);
        this.onchangetipo = this.onchangetipo.bind(this);
        this.onchangetiporevision = this.onchangetiporevision.bind(this);
        this.onchangemensaje = this.onchangemensaje.bind(this);
        this.sendrevision = this.sendrevision.bind(this);

        this.goTonextPage = this.goTonextPage.bind(this);

        this.onChangeNewPrice = this.onChangeNewPrice.bind(this);
        this.onChangeNewDescription =this.onChangeNewDescription.bind(this);

        this.handleOnFilterChange = this.handleOnFilterChange.bind(this);
    }

    componentDidMount() {
        this.getDeals();
        this.props.sockets.sockets.callback = this.refreshmessages

    }


    refreshmessages(data) {
        if (data.type == "offer" || data.type == "assigment" || data.type == "rejected") {
            this.getDeals();
        } else {
            notifications.buildNotification(data.type, data.message, data.accountType, data.senderid, data.profileId);
        }
    }

    gotoprevPage(){
        let pagenum = this.state.actualPage;
        pagenum = pagenum - 1;


        


        this.changepage(pagenum);
    }

    goTonextPage(){

        let pagenum = this.state.actualPage;
        pagenum = pagenum + 1;

         
      
        this.changepage(pagenum);
      
    }

    changepage(number){

        console.log(this.state.actualPage)

        const data = {
            chatid: this.props.chatid,
            page: number,
            filter: this.state.filter,
            order: this.state.order
        }
        Axios.post('https://serviadvisor.com/getdealsPage', data, { withCredentials: true }).then(res => {
            if (data.error) {

            } else {

                const Dealsdata = res.data

                this.setState({
                    Deals: Dealsdata,
                    actualPage:number
                }, () => {  })
            }
        })
    }

    getDeals = () => {

        const data = {
            chatid: this.props.chatid,
            page:0,
            filter: this.state.filter,
            order: this.state.order
            
        }
        Axios.post('https://serviadvisor.com/getdealsPage', data, { withCredentials: true }).then(res => {
            if (data.error) {

            } else {

                const Dealsdata = res.data

                this.setState({
                    Deals: Dealsdata
                }, () => { 
                    
                 })
            }

        })


        Axios.post('https://serviadvisor.com/getdeals', data, { withCredentials: true }).then(res => {
            if (data.error) {

            } else {

                const Dealsdata = res.data
                
              

                this.setState({
                    FullDeals: Dealsdata,
                    totalpagenumber:Math.ceil(Dealsdata.length / 12)
                }, () => { 
                   
                 })
            }

        })

    }


    renderNextPage = () =>{

       let pageNumber = this.state.totalpagenumber;
    
     
        let html = "";

       if(this.state.FullDeals.length > 12 && pageNumber - 1 !== this.state.actualPage){
          html = <a class="nextpageletter" onClick= {(e) => this.goTonextPage()} href="javascript:void(0)">Pagina Siguiente</a>
       }

      

       return(html)
    }

    renderPageNumber = ()=>{
      let html =  <span>Pagina <span>{this.state.actualPage +1 } De  {this.state.totalpagenumber }</span></span> 
      return(html)
    }

    renderPrevPage = () =>{
        
        let html ="";

        if(this.state.FullDeals.length > 12 && this.state.actualPage > 0){
            html = <a class="nextpageletter" onClick= {(e) => this.gotoprevPage()} href="javascript:void(0)">Pagina Anterior</a>;
        }

        return(html)
     }

    onchangetextMessage = (e) => {
        let description = e.target.value;

        this.setState({
            description: description
        })
    }


    onchangetextPrice = (e) => {
        //alert(e.target.value)
        this.setState({ price: (e.target.validity.valid) ? e.target.value : this.state.price });
    }

    sendmessage = (event) => {
        event.preventDefault();
        const data = {
            chatid: this.props.chatid,
            description: this.state.description,
            price: this.state.price
        }

        this.setState({
            loaderSave:true
        })


        Axios.post('https://serviadvisor.com/Deals', data, { withCredentials: true }).then(res => {
            const datares = res.data;
            this.getDeals();
            if (!datares.error) {
                this.setState({
                    loadsuccess: true,
                    loaderSave:false
                })
            } else {

            }
        })
    }


    editDeal = (event) => {
        event.preventDefault();

        const data = {
            offerId: this.state.actualOfferidEdicion,
            description: this.state.newDescription,
            price: this.state.newprice,
            revisionType: parseInt(this.state.tipoderevision)
        }

        Axios.post('https://serviadvisor.com/sendRevision', data, { withCredentials: true }).then(res => {
            const datares = res.data;
            this.getDeals();
            if (!datares.error) {
              this.setState({modalEdisionShow:false});
            } else {

            }
        })
    }

    cancelTheDeal = (event) => {
        event.preventDefault();
        const data = {
            offerId: this.state.actualOfferidEdicion,
        }

        this.setState({
            loaderSave:true
        })


        Axios.post('https://serviadvisor.com/CancelDeal', data, { withCredentials: true }).then(res => {
            const datares = res.data;
            this.getDeals();
            if (!datares.error) {
                this.setState({
                    loadsuccess: true,
                    loaderSave:false,
                    modalCancelDealShow: false
                })
            } else {

            }
        })
    }


    handleOnFilterChange = (e) => {
        const value = e.target.value;
        this.setState({
          filter:value,
          actualPage:0
        }, () => {
          this.getDeals()
        })
       
      }



      
    handleOnOrderChange = (e) => {
        const value = e.target.value;
        this.setState({
          order:value,
          actualPage:0
        }, () => {
          this.getDeals()
        })
       
      }

    refreshChat() {
        //this.getmessages();
    }

    // first timeout to start the process

    renderbox(item) {
        if (item.offert !== null && item.offert !== undefined)
            return (
                <DealBox onDealCancel={this.dealCancel} onDealEdicion={this.dealEdicion} onDealrevision={this.dealrevision} chatid = {this.props.chatid} type="empresa" offer={item.offert}></DealBox>
            )
    }


    onchangemensaje(e){
        const mensaje = e.target.value;
  
        this.setState({
          mensaje:mensaje
        })
  
      }
  
      onchangetipo(e){
        const tipo = e.target.options[e.target.selectedIndex].text;
  
        this.setState({
          tipodesolicitud:tipo
        })
      }

      onchangetiporevision(e){
        const tipo = e.target.options[e.target.selectedIndex].value;
  
        this.setState({
          tipoderevision:tipo
        })
      }
  

      onChangeNewDescription(e){
          const description = e.target.value;

          this.setState({
              newDescription: description
          })
      }

      onChangeNewPrice(e){
          const price = e.target.value;

          this.setState({
              newprice: price
          })
      }
  
      sendrevision(){
        const data = new FormData()
  
  
        if(this.state.mensaje==="" || this.state.tipodesolicitud===""){
          const notify = () => toast.error("llena los campos para enviar la solicitud");
          notify();
          return
        }
  
        data.append('chatid', this.props.chatid);
        data.append('offerid', this.state.actualOfferidrev);
        data.append('type', 'empresa');
        data.append('razon', this.state.tipodesolicitud );
        data.append('mensaje', this.state.mensaje);
        

        if(this.state.files){
            for(var x = 0; x<this.state.files.length; x++) {
  
                if (this.state.files[x]) {
             const file = this.state.files[x];
             var pattern = /image-*/;
       
             if (!file.type.match(pattern)) {
               const notify = () => toast.error("Formato de imagen erronea");
               notify();
               return;
             }
       
           
           }
       
       
               data.append('files', this.state.files[x]);
           }
        }

      
  
        Axios.post('https://serviadvisor.com/administrator/revisionmail',data, {withCredentials:true,  headers: {
          'content-type': 'multipart/form-data'
        }}).then(res => {
          const data = res.data;
          if(!data.error){
           
            this.setState({
              modalrevisionshow:false
            })
  
            const notify = () => toast.info("enviada la solicitud de mediacion");
            notify();
  
          }
  
  
        
        })
  
        
      }
  
      onfileschange(e){
          this.setState({
            files: e.target.files
          })
      }

      dealrevision =(offerid) => {
        this.setState({
          modalrevisionshow:true,
          actualOfferidrev:offerid
        })
      }


      dealEdicion =(offerid) => {
        this.setState({
          modalEdisionShow:true,
          actualOfferidEdicion:offerid
        })
      }

      dealCancel =(offerid) => {
        this.setState({
          modalCancelDealShow:true,
          actualOfferidEdicion:offerid
        })
      }


    render() {
        let modalrevision = () => this.setState({modalrevisionshow:false});
        let modalEdicion = () => this.setState({modalEdisionShow:false});
        let CancelDeal = () => this.setState({modalCancelDealShow:false});
        return (
            <div>
                  <div class="aviso-ayuda" >
                    <div class="btn-ayuda-pop" data-toggle="modal" data-target="#myModal-ayuda-emp" data-dismiss="modal" >
                    Guía Negociación (AYUDA) -    <i class="fas fa-question-circle" ></i>    </div>
                </div>  
                <div class="title-perfil-empresa">
                    <h4 class="azul"> Propuestas  <a href="javascript:history.back()" class="btn-atras"> <i class="fas fa-arrow-alt-circle-left"></i></a></h4>
                </div>

                <div class="caja-dist-ofert">

                    <div class="caja-redactar">
                        <div class="title-perfil-empresa">
                            <h4 class="azul">Redactar Propuesta</h4>
                        </div>
                        <div class="caja-chat-escribir">

                            <form class="form-redactar">
                                <textarea onChange={this.onchangetextMessage} class="form-control comple red-pro" id="detalle" placeholder="Detalle"></textarea>
                                <div class="caja-redactarcaja-valor-pro">
                                    <div class="precio-prop"> USD  </div><input pattern="^\d*(\.\d{0,2})?$" onInput={this.onchangetextPrice} type="text" class="form-control mitad-2" id="cantidad" placeholder="Cantidad" value={this.state.price} autocomplete={false} />
                                    <button onClick={this.sendmessage} class="enviar-propuesta">enviar <Loader
                                        visible={this.state.loaderSave}
                                        type="Oval"
                                        color="#00BFFF"
                                        height={25}
                                        width={25}

                                    /></button>

                                    {this.state.loadsuccess && (<span class="alert"><i class="fas fa-check-circle"></i> Propuesta enviada con exitó.</span>)}
                                </div>

                            </form>

                        </div>


                    </div>

                    <div>
                        <span> Filtra tus propuestas: </span>
                        <select onChange={this.handleOnFilterChange}>
                            <option value="" selected>Mostrar Todas</option>
                            <option value="1">Mostrar No Asignadas</option>
                            <option value="2">Mostrar Asignadas</option>
                            <option value="3">Mostrar Confirmadas</option>
                            <option Value="4">Mostrar Rechazadas</option>
                            <option Value="5">Mostrar En Proceso</option>
                            <option Value="6">Mostrar Entragadas</option>
                        </select>

                        <span> Ordena tus propuestas: </span>
                        <select onChange={this.handleOnOrderChange}>
                            <option value="" selected>Ordenar Por Fecha</option>
                            <option value="1">Ascendente</option>
                            <option value="2">Descendente</option>
                        </select>

                    </div> 

                    <div>
                       
                    </div> 

                    <div class="ofertas-anteriores caja-ofert-ant">
                        {this.state.Deals.map((item, index2) => {

                            return (
                                <div key={index2}
                                    class={"propue"}>
                                    {this.renderbox(item)}

                                </div>
                            );
                        })}

                    </div>
                    {this.renderPrevPage()}
                    <span> &nbsp; {this.renderPageNumber()} &nbsp; </span>
                    {this.renderNextPage()}
                   



                </div>


                <Modal className="recorte pro" show={this.state.modalrevisionshow} onHide={modalrevision}
                    {...this.props}
                    size="lg"
                    animation={false}

                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Formulaio de mediación 
                           
                <hr />
                <div className="flecha-down"></div>
              </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div class="caja-pop-med">
                          <select onChange={this.onchangetipo} className="sel-pop" >
                            <option>Selecciona una opción</option>
                            <option>No me entregó a tiempo</option>
                            <option>Entregó incompleto</option>
                            <option>Insatisfecho con la calidad</option>
                            <option>El cliente quiere mas </option>
                            <option>Fin de propuesta por mutuo acuerdo</option>
                            <option>otro</option>
                          </select>
                          <p>
                            <br></br>
                         <textarea onChange={this.onchangemensaje}></textarea>
                         </p>
                         <p className="text-evid">Evidencia: arrastra dentro de la zona punteado tus archivos</p>
                          <input onChange={(e)=> this.onfileschange(e)} name="files" multiple="multiple"  accept="image/*" type="file" className="s-pop"></input>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={(e)=> this.sendrevision()}  className={"btn-primary"}>Aceptar</Button>
                        <Button onClick={modalrevision} className={"btn-success"}>Cerrar</Button>
                    </Modal.Footer>
                </Modal>       




                <Modal className="recorte pro" show={this.state.modalEdisionShow} onHide={modalEdicion}
                    {...this.props}
                    size="lg"
                    animation={false}
                    centered
                    aria-labelledby="contained-modal-title-vcenter"
                   
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Edicion de Propuesta 
                           
                <hr />
                <div className="flecha-down"></div>
              </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div class="caja-pop-med">
                        <p className="text-evid">Descripcion de la propuesta</p>

                        <select onChange={this.onchangetiporevision} className="sel-pop" >
                            <option default value="1">Precio</option>
                            <option value = "2">Descripcion</option>
                          </select>
                        {this.state.tipoderevision == 2 ?(<textarea onChange={this.onChangeNewDescription} type="text"></textarea>) : ""}
                             <p>
                             <br></br>
                         
                          </p>
                         {this.state.tipoderevision == 1 ? (<div> <p className="text-evid">Valor de propuesta</p>
                         
                         <input onChange={this.onChangeNewPrice} type="text"></input></div>) : ""}
                     
                        
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.editDeal}  className={"btn-primary"}>Enviar</Button>
                        <Button onClick={modalEdicion} className={"btn-success"}>Cerrar</Button>
                    </Modal.Footer>
                </Modal>       



                <Modal className="recorte pro" show={this.state.modalCancelDealShow} onHide={CancelDeal}
                    {...this.props}
                    size="lg"
                    animation={false}
                    centered
                    aria-labelledby="contained-modal-title-vcenter"
                   
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Cancelar Propuesta 
                           
                <hr />
                <div className="flecha-down"></div>
              </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Esta seguro que quiere cancelar la Propuesta?</p>
                        <p></p>
                        <br></br>
                        <div class="caja-pop-med">
                        <Button onClick={this.cancelTheDeal}  className={"btn-primary"}>Aceptar</Button>
                        <Button onClick={CancelDeal} className={"btn-success"}>Cerrar</Button>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                     
                    </Modal.Footer>
                </Modal>       



            </div>
        );
    }
}

export default Deals