import React,{Component} from 'react'
import {Calendar,momentLocalizer} from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css';
require('moment/locale/es.js');
const localizer = momentLocalizer(moment);

// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.
//const localizer = momentLocalizer(moment) // or globalizeLocalizer


const eventdefault = [
  {
    id: 0,
    title: 'All Day Event very long title',
    allDay: false,
    start: new Date("2019-07-18T20:11:48.598Z"),
    end: new Date("2019-07-18T22:11:48.598Z"),
  },
  {
    id: 1,
    title: 'Long Event',
    start: new Date(2015, 3, 7),
    end: new Date(2015, 3, 10),
  }
];


class MyCalendar extends Component{

  constructor(props){
    super(props);
   
    this.printDate= this.printDate.bind(this);
   
  }

  


  printDate = (date) => {
   
  }

  render(){
    
    return(
      <div class={"caja-calendar"}>
      <Calendar
        popup
        localizer={localizer}
        events={this.props.events}
        startAccessor="start"
        endAccessor="end"
        messages={{
          next: "sig",
          previous: "ant",
          today: "Hoy",
          month: "Mes",
          week: "Semana",
          day: "Día"
        }}
        onSelectEvent={this.props.showofferinfo}
        onNavigate= {this.printDate}
      />


    </div>
    )
  }
}


export default MyCalendar