import React,{Component} from 'react';
import axios from 'axios';
import NotificationView from  '../chat/notificationView';
import notifications from '../../utils/notifications'

class clientNotifications extends Component{
    constructor(props){
        super(props)
        this.state={
            notifications:[],
            page:0
        }
        this.notify = this.notify.bind(this);
        this.nextpage = this.nextpage.bind(this);
    }

    nextpage = () => {
      
        let pagenum = this.state.page;
        pagenum = pagenum + 1;

        const data = {
            page:pagenum
        }

        axios.post('https://serviadvisor.com/notificationspagination/',data,{withCredentials:true}).then(res => {
           

            let data = []
            data =  res.data;
            const notificationData = this.state.notifications.slice()
              
            this.setState({
                notifications: notificationData.concat(data),
                page:pagenum
            })
           
        })

     


    }

    componentDidMount(){
        let pagenum = this.state.page;
      

        const data = {
            page:pagenum
        }

        axios.post('https://serviadvisor.com/readallnotifications/',data,{withCredentials:true}).then(res => {
            axios.post('https://serviadvisor.com/notificationspagination/',data,{withCredentials:true}).then(res => {
                const notifications= res.data;
                this.setState({
                    notifications:notifications
                })
            })
    
            
        })


    

     
        this.props.sockets.callback= this.notify
    }

    notify(data){
      notifications.buildNotification(data.type, data.message, data.accountType, data.senderid,data.profileId);
    }
    
    render(){
        return(
            <div class="contenido-notificaciones">
                 <div class="title-perfil-cliente espacio-top"> <h4 class="azul"> <span class="bg-blanco">Notificaciones</span>  </h4> <hr class="naranja"/> </div>
                  {this.state.notifications.map((item,index)=>{
                    return(
                    <NotificationView notification={item}></NotificationView>
                    )
                })}
                <div><a onClick={this.nextpage}>Mostar Más</a></div>
            </div>
        );
    }
}

export default clientNotifications;