import React, { Component } from 'react'
import Axios from 'axios';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Moment from 'react-moment';
import Advertising from '../../menu/advertising';
import CarrouselLateral from '../../menu/carrousellateral';

import { FacebookShareButton,TwitterShareButton,} from "react-share";
import {
   
    FacebookIcon,
  
    TwitterIcon,
   
    WorkplaceIcon,
  } from "react-share";



class Article extends Component {

    constructor(props) {
        super(props)

        this.state = {
            title: "",
            editorState: EditorState.createEmpty(),
            article: undefined,
            articleloaded: false,
            coments: [],
            messaggelenght: 0,
            messagetext: "",
            image: undefined,
            bestarticles:[],
            activities:[]
        }
        this.onChange = (editorState) => this.setState({ editorState });
        this.getarticle = this.getarticle.bind(this)
        this.handletextchange = this.handletextchange.bind(this);
        this.resetComent = this.resetComent.bind(this);
        this.getbestarticles = this.getbestarticles.bind(this);
    }

    componentDidMount() {
        this.getarticle();
        this.getbestarticles();
        this.getcoments();
        this.getactivities();
    }

    resetComent(){
        this.setState({
            messagetext:""
        })
    }

    sendcoment = (e) => {
        const data = {
            postId: this.props.articleId,
            coment: this.state.messagetext
        }

        Axios.post('https://serviadvisor.com/addcoment', data, { withCredentials: true }).then(res => {
            const data = res.data;
            if (data) {
                this.getcoments()
                this.resetComent()
            }
        })
    }

    handletextchange = (e) => {
        const coment = e.target.value;
        this.setState({
            messagetext: coment
        })
    }


    getcoments() {
        Axios.get('https://serviadvisor.com/getcoments/' + this.props.articleId).then(res => {
            const data = res.data;
            if (data) {
                this.setState({
                    coments: data
                })
            }
        })
    }

    getbestarticles(){
        const data={
            
        }
        Axios.post("https://serviadvisor.com/bestblogarticles",null).then(res=>{
            const data= res.data;
            if(data && !data.error){
                this.setState({
                    bestarticles:data
                })
            }
        })
    }

    getarticle() {

        Axios.get('https://serviadvisor.com/article/' + this.props.articleId).then(res => {
            const data = res.data

            if (data) {
                this.setState({
                    title: data.title,
                    article: data.fulltext,
                    image: data.image_url
                }, () => {
                    let articletext;

                    try {
                        articletext = JSON.parse(data.fulltext)
                    } catch (e) {
                        articletext = null
                    }


                    this.setState({
                        editorState: articletext ? EditorState.createWithContent(convertFromRaw(articletext)) : EditorState.createEmpty()
                    })
                })
            }
        }

        )


    }

    getactivities(){
        Axios.get("https://serviadvisor.com/activities").then(res=>{
            const data= res.data;
            if(data && !data.error){
                this.setState({
                    activities:data
                })
            }
        })
    }

    renderarticle() {

    }


    render() {
        return (
            <div>

<div class="full-container">
<div class="banner-blog">
    <div class="caja-img-banner"> <img src={"https://serviadvisor.com/blogphoto/" + this.state.image}  class="img-blog" /> </div>
    </div>



</div>




                <div class="rec-blog perfil-empresa">
                <div class="container">
                


                    <div class="col-md-9 blog-ct espacio-centro flex">
                    <div class="container comp-art">
                    
                    <div class="iconos-cabezote-blog">
                             <TwitterShareButton url={"https://serviadvisor.com/empresa/" + this.state.paramid + "/perfil"}><TwitterIcon size={36} round={true} /></TwitterShareButton>
                        </div>

                     <div class="iconos-cabezote-blog">
                               <FacebookShareButton url={"https://serviadvisor.com/empresa/" + this.state.paramid + "/perfil"}><FacebookIcon size={36} round={true} /></FacebookShareButton>
                     </div>

                    </div>






                            <div class="row">

                                <div class="col-md-12 blog-ct espacio-centro">



                                    <h2>{this.state.title}</h2>
                                    <div class="caja-text-arti">

                                        <Editor
                                            readOnly
                                            toolbarHidden

                                            editorState={this.state.editorState}
                                            wrapperClassName="caja-art-tex"
                                            editorClassName="art-tex"
                                            onEditorStateChange={this.onChange}
                                        />

                                    </div>

                                    <div class="separa"></div>

                           

                                    {this.state.coments.map((item, index) => {
                                        return (
                                            <div>
                                                 <div class="limp"></div>
                                                <p>
                                                    <div class="caja-chat">
                                                        <div class="img-caja-chat"><a href="#"><img src={item.user.photo_url === null ? ("/images/fotoperfil.png") : (item.user.photo_url)} class="img-responsive" /></a></div>
                                                        <div class="titulo-chat">{item.user.username === null ? ("Usuario") : (item.user.username)}</div>
                                                        <div class="caja-chat-cir">
                                                            <p> {item.coment}</p>
                                                        </div>
                                                    </div>

                                                    <p class="alinear-derecha-fecha"><Moment format={"DD MMM YYYY hh:mm a"}>{item.createdAt}</Moment></p>
                                                    <div class="limp"></div>
                                                  
                                                </p>

                                            </div>
                                        )
                                    })}



<div class="separa"></div>

<div class="col-md-12">
<div class="azul-border"><h3>Comentarios</h3></div>

<br/>

    <div class="caja-chat-escribir">
   
        <div class="img-caja-chat"><img src="/images/fotoperfil.png" class="img-responsive" /></div>
        <div class="caja-chat-cir">
            <textarea onChange={this.handletextchange} value={this.state.messagetext} class="texto-enviar">{this.state.messagetext}</textarea>
        </div>
    </div>

    <button onClick={this.sendcoment} class="enviar-respuesta">enviar</button>
    <p class="caracteres-enviar-empresa">{this.state.messaggelenght}/500 caracteres</p>
  
</div>

                                </div>




                            </div>
                        </div>



                        <div class="col-md-3 blog-ct let-client-sec">

                        <div class="azul-border"><h3>Artículos más leídos </h3></div>
                <div class="publicidad-lateral">

                {this.state.bestarticles.map((item,index)=>
                    <div class="blog-sub-col-1 blog-display">
                    <div class="foto-blog-nov-lat"><a href={"/blog/" + item.id + "/post"}><img class="img-blog"src={"https://serviadvisor.com/blogphoto/" + item.image_url} alt=""/> </a>  </div>
                    <div class="titulo_blog"> <a href={"/blog/" + item.id + "/post"}><h3> {item.title}</h3></a></div>
                </div>
                )}
                
                
                </div>

<div class="azul-border"><h3>Catergorias</h3></div>

        <div class="publicidad-lateral cont-cate-blog">
        {this.state.activities.map((item,index) => 
                 <div class="btn btn-primary cate-blog"><a href={"/blog/" + item.name} >{item.name}</a></div>
                
                )}
        
        </div>

        
        <div class="publicidad-lateral">
               <Advertising adspaceid="1"></Advertising>
                </div>
                <div class="letras-pequeñas-publ"><p>Espacio Publicitario ¿Quieres anunciarte con nosotros?</p></div>

                <div class="azul-border"><h3>Recomendados</h3></div>

                <div class="publicidad-lateral">
                 <CarrouselLateral></CarrouselLateral>
                </div>


       


      </div>

                






                    </div>
                </div>





            </div >)
    }
}

export default Article;