import React, { Component } from 'react';
import Axios from 'axios';

class citiesMenu extends Component {

    constructor(props) {
        super(props)
        this.state = {
            cities: [],
        }
        this.selectmode = this.selectmode.bind(this);
    }

    componentDidMount() {

    }

    selectmode() {

        if (this.props.mode) {
            if (this.props.mode === "panel") {
                return (
                    <div id="citiescontend"  className="panel" style={{
                        top: "50px", marginTop: "50px", zIndex: "3", display: this.props.show ? "flex" : "none"
                    }}>

                        {this.props.cities.map((item, index) => {
                            return (

                                <div class="col-busq-ci" >
                                    {item.map((item4,index4)=>{
                                        return(
                                        <div class="busq-col">
                                                        <li>
                                                            <label class="seg-dato"><a onMouseDown={e => this.props.onhandleclick(e,item4.id, item4.name)} href="javascript:void(0);"> {item4.name} <span>{item4.providers.length}</span> </a></label><br />
                                                        </li>
                                                    </div>
                                                
                                        
                                        )
                                    })}
                                </div>


                            );
                        })}

                    </div>
                )
            } else if (this.props.mode === "list") {
                return (
                    <div id="citiescontend" className="panel" style={{
                       top: "50px",
                          marginTop: "50px",
                           zIndex: "3",
                            display: this.props.show ? "block" : "none"
                    }}>
                        <ul>
                        {this.props.cities.map((item, index) => {
                            return (



                                <div class="col-busq-sol">
                                    <li>
                                        <h4>
                                    <a onMouseDown={e => this.props.onhandleclick(e, item.id, item.name)} href="javascript:void(0);"> {item.name} ({item.providers.length})</a>
                                    </h4>
                                    </li>
                                    
                                </div>


                            );
                        })}
                        </ul>
                    </div>
                )
            }
        }
    }


    render() {


        return (

            <div>
                {this.selectmode()}
            </div>

        )
    }
}

export default citiesMenu;