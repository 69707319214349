import React,{Component} from 'react';
import axios from 'axios';

class advertising extends Component{
    constructor(props){
        super(props)
        this.state={
            ad:[]
        }

        this.getad = this.getad.bind(this);
    }

    

    getad(id){
        axios.get('https://serviadvisor.com/getad/'+ id).then(res => {
            const data = res.data;
            
            if(data || data !==null){
            if(!data.error){
                this.setState({
                    ad:data
                })
            }
        }
        })
    }


    

    componentDidMount(){
      //  this.getad(this.props.adspaceid);
    }

    render(){
        return(
            <div>
                
                <a target="_blank" href={this.state.ad.redirection}><img src={"https://serviadvisor.com/adphoto/" + this.state.ad.img_url}></img>
                </a>
            </div>
        )
    }
}

export default advertising