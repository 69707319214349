import React, { Component } from 'react';
import Axios from 'axios';
import Carouserprovider from '../../galeria/Carrouselprovider';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Helmet } from "react-helmet";
import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';
import notifications from '../../../utils/notifications';
import ReactStars from 'react-stars';
import Moment from 'react-moment';

import PerfilSection from './PerfilSection';
import ContactSection from './ContactSection';
import RatingSection from './RatingSection';
import MapSection from './MapSection';
import FaqSection from './FaqSection';
import VideoSection from './VideoSection';
import Business_hours from './business_hours';

import { FacebookShareButton,TwitterShareButton,} from "react-share";
import {
   
    FacebookIcon,
  
    TwitterIcon,
   
    WorkplaceIcon,
  } from "react-share";


class ContenidoPerfil extends Component {
    constructor(props) {
        super(props)
        this.state = {
            paramid: "",
            id: undefined,
            email: "",
            name: "",
            city: "",
            localemail: "",
            tel: 0,
            lat: 0,
            long: 0,
            webpage: "",
            photo_url: "",
            business_name: "",
            start_price: 0,
            end_price: 0,
            description: "",
            shortdescription: "",
            activity: [],
            dir: "",
            business_hours: [],
            userId: 1,
            gallery: [],
            renderdefault: false,
            editorState: EditorState.createEmpty(),
            location: [],
            mapready: false,
            paypalsubscription: [],
            favvisible: true,
            isfav: true,
            categorieslists:[],
            LoadFinish : false
        }
        this.onChange = (editorState) => this.setState({ editorState });
        this.rendertab = this.rendertab.bind(this);
        this.settab = this.settab.bind(this);
        this.setlocation = this.setlocation.bind(this);
        this.handleClickProviderContact = this.handleClickProviderContact.bind(this);
        this.handlefavclick = this.handlefavclick.bind(this);
        this.notify = this.notify.bind(this);
    }

    handleClickProviderContact(event, id) {
        event.preventDefault();
        this.props.history.push('/empresa/' + id + '/contacto');
    }




    handlefavclick = () => {
        const data = {
            providerId: this.props.params.id
        }
        Axios.post('https://serviadvisor.com/addfav', data, { withCredentials: true }).then(res => {
            const favdata = res.data;
            if (!favdata.error) {
                this.setState({
                    isfav: false
                })
            }
        })
    }


    setlocation = (lat, lng) => {
        this.setState({
            location: {
                lat: lat,
                lng: lng
            }
        }, () => {
            this.setState({
                mapready: true
            })
        })


    }

    componentDidMount() {


        this.setState({
            paramid: this.props.params.id
        })



      
        Axios.get('https://serviadvisor.com/providername/' + this.props.params.id, { withCredentials: true }).then(res => {


            const data = res.data[0];

            if (data) {
                this.setState({
                    id: data.id,
                    email: data.email,
                    name: data.name,
                    city: data.city,
                    tel: data.tel,
                    lat: data.lat,
                    long: data.long,
                    webpage: data.webpage,
                    photo_url: data.user.photo_url,
                    business_name: data.business_name,
                    start_price: data.start_price,
                    end_price: data.end_price,
                    categorieslists: data.categorieslists,
                    description: data.description,
                    shortdescription: data.shortdescription,
                    userId: data.userId,
                    dir: data.dir,
                    paypalsubscription: data.paypalsubscription,
                    business_hours: data.business_hours,
                    rating: data.rating
                }, () => {



                    let providerdescription;

                    try {
                        providerdescription = JSON.parse(data.description)
                    } catch (e) {
                        providerdescription = null
                    }


                    this.setState({
                        editorState: providerdescription ? EditorState.createWithContent(convertFromRaw(providerdescription)) : EditorState.createEmpty()
                    })


                    this.setlocation(data.lat, data.long);


                    Axios.get('https://serviadvisor.com/getgallery/' + this.state.id).then(response => {
                        console.log(response.data)
                        this.setState({
                            gallery: response.data,
                            renderdefault: true
                        })
                    })

                    this.setState({
                        LoadFinish :true
                    })

                    
                    const datafav = {
                        providerId: this.state.id
                    }
                    Axios.post('https://serviadvisor.com/checkfav', datafav, { withCredentials: true }).then(res => {
                        const data = res.data;
                        if (data) {
                            if (data.error) {
                                this.setState({
                                    favvisible: false
                                })
                            } else {
                                const visible = data.status === "ok" ? true : false;
                                this.setState({
                                    isfav: visible
                                })
            
                            }
                        }
            
                    })
            




                });
            }

        });



      



        this.props.sockets.callback = this.notify
    }

    getlistofCategories(list){
        
        let html=""

        list.forEach(element => {
            html+= element.activity.name + ", "
        })

        return(
           <span>{html}</span>
        )
    }

    notify(data) {
        console.log(this.props.user);
        
        notifications.buildNotification(data.type, data.message, data.accountType, data.senderid, data.profileId, data.source);
    }


    settab = (name) => {
        this.props.onChangetab(name);

    }



    rendertab() {

        let html = "";

        switch (this.props.tab) {

            case 'perfil':
                html = <PerfilSection
                    start_price={this.state.start_price}
                    end_price={this.state.end_price}
                    categorieslists={this.state.categorieslists}
                    city={this.state.city}
                    shortdescription={this.state.shortdescription}
                    description={this.state.description}
                    editorState={this.state.editorState}
                    sockets={this.props.sockets}
                    user={this.props.user}
                ></PerfilSection>
                break;

            case 'contacto':
                html = <ContactSection
                    sockets={this.props.sockets}
                    params={this.props.params}
                    islogin={this.props.islogin}
                    providerId = {this.state.id}
                    user={this.props.user}></ContactSection>

                break;

            case 'calificacion':
                html = <RatingSection
                    sockets={this.props.sockets}
                    user={this.props.user}
                    params={this.props.params}
                    islogin={this.props.islogin}
                    providerId={this.state.id}
                ></RatingSection>

                break;

            case 'faq':
                html = <FaqSection
                    params={this.props.params}
                    sockets={this.props.sockets}
                    user={this.props.user}
                    providerId = {this.state.id}></FaqSection>
                break;

            case 'mapa':
                html = <MapSection
                    params={this.props.params}
                    location={this.state.location}
                    mapisready={this.state.mapready}
                    sockets={this.props.sockets}
                    user={this.props.user}></MapSection>
                break;

            case 'video':
                html = <VideoSection
                    params={this.props.params}
                    sockets={this.props.sockets}
                    user={this.props.user}
                    providerId = {this.state.id}></VideoSection>
                    
                break;

            default:

                html = "<div></div>";

                break;
        }

        return (html);
    }



    render() {
        return (
            <div>

                <div class="rec-blog perfil-empresa">
                    <div class="container">
                        <div class="rec-blog-inner">
                            <div class="row">

                                <div class="col-md-7 blog-ct espacio-centro">

                                    <div class="encabezado-perfil-empresa">

                                        <div class="empresa-colum1">
                                            {this.state.photo_url && (<img src={
                                                this.state.photo_url} alt="" class="img-responsive" />)}
                                            {!this.state.photo_url && (<img src="/images/img amarillis cont.png" alt="" class="img-responsive" />)}
                                        </div>
                                        <div class="empresa-colum2">
                                            <div class="empresa-colum2titu">
                                                <div class="titulo-empresa"><a href="#"><h4 class="azul">{this.state.business_name}</h4></a>
                                                </div>
                                            </div>

                                            <div class="empresa-colum2sec2">

                                                <div class="categorias-encabeza-empresa"> {this.state.activity.name}</div>
                                                <div class="estrellas-novias22">
                                                    <ReactStars
                                                        count={5}
                                                        edit={false}
                                                        size={13}
                                                        half={false}
                                                        value={this.state.rating}
                                                        color2={'#ffd700'} />
                                                </div>

                                                <div class="col-cot">  <button type="submit" class="btn btn-primary acomoda"><a onClick={(e) => this.settab("contacto")} href="#">Cotiza AQUI Gratis</a></button> </div>

                                            </div>


                                            <div class="empresa-colum2sec3">


                                                <div class="acomodar01">

                                                    {this.state.isfav ? (<div class="corazon activo"><i class="fas fa-heart"></i></div>) : (<div onClick={this.handlefavclick} class="corazon"><i
                                                        class="fas fa-heart"></i></div>)}





                                                </div>

                                                <div class="iconos-cabezote-empresa">
                                                   


                                                    <TwitterShareButton url={"https://serviadvisor.com/empresa/" + this.state.paramid + "/perfil"}><TwitterIcon size={36} round={true} /></TwitterShareButton>
                                                   

                                                    </div>

                                                <div class="iconos-cabezote-empresa">
                                                  
                                                   
                                                    <FacebookShareButton url={"https://serviadvisor.com/empresa/" + this.state.paramid + "/perfil"}><FacebookIcon size={36} round={true} /></FacebookShareButton>


                                                </div>

                                             
                                            </div>



                                        </div>








                                        <div class="limp"></div>
                                    </div>

                                    <div class="col-cot-2">  <button type="submit" class="btn btn-primary acomoda"><a onClick={(e) => this.settab("contacto")} href="#">Cotiza AQUI Gratis</a></button> </div>






                                    <div>
                                        <ul class="menu-perfil-empresa">

                                            {this.state.paypalsubscription && this.state.paypalsubscription.subscriptionPlan && (
                                                <li class="paquete">
                                                    <span class={this.state.paypalsubscription.subscriptionPlan.name}>{this.state.paypalsubscription.subscriptionPlan.name.replace("Membresia", "")}</span>
                                                </li>
                                            )}


                                            {!this.state.paypalsubscription &&  (
                                                <li class="paquete">
                                                    <span> Cortesia</span>
                                                </li>
                                            )}

                                           
                                            <li class="perfil-item" id="contacto" onClick={(e) => this.settab("contacto")}> <a href="javascript:void(0)">Contáctanos</a>
                                            </li>
                                            <li class="perfil-item" id="perfil" onClick={(e) => this.settab("perfil")}> <a href="javascript:void(0)">Perfil</a>
                                            </li>
                                            <li class="perfil-item" id="calificacion" onClick={(e) => this.settab("calificacion")}> <a href="javascript:void(0)">Calificación</a>
                                            </li>
                                            <li class="perfil-item" id="faq" onClick={(e) => this.settab("faq")}> <a href="javascript:void(0)">FAQ</a>
                                            </li>
                                            <li class="perfil-item" id="mapa" onClick={(e) => this.settab("mapa")}> <a href="javascript:void(0)">Mapa</a>
                                            </li>
                                            <li class="perfil-item" id="video" onClick={(e) => this.settab("video")}> <a href="javascript:void(0)">Video</a>
                                            </li>
                                            <div class="limp"></div>
                                        </ul>

                                    </div>
                                    {this.state.LoadFinish ? this.rendertab() : ""}
                                    


                                </div>

                                <div class="col-md-5 cont-colm">
                                    <div class="galleria">
                                        {/*<a href="#"><img src="/images/Fotos Serviadvisor/slider.png" class="img-responsive"/></a>*/}

                                        {this.state.id && (<Carouserprovider providerid={this.state.id}></Carouserprovider>)}
                                    </div>

                                    <div class="cont-lat-contac fondo-gris">
                                        <div class="title-perfil-empresa">
                                            <h4 class="azul"> <a href="#">Contacta a {this.state.business_name} </a></h4>
                                        </div>
                                        <p class="negrilla"> Dirección</p>
                                        <p class="margin-bottom">{this.state.dir}</p>
                                        <p class="negrilla"> Teléfono</p>
                                        <p class="margin-bottom">{this.state.tel}</p>
                                        <div class="caja-h">
                                        <Business_hours hours={this.state.business_hours}></Business_hours>
                                        </div>

                                        <p class="negrilla"> Contacto</p>
                                        <p class="margin-bottom">{this.state.name}</p>

                                    </div>

                                </div>


                            </div>
                        </div>
                    </div>
                </div>




            </div >
        );
    }
}
export default ContenidoPerfil;