import React, { Component } from 'react';
import axios from 'axios';

class CitiesAdmin extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cities: [],
            cityName: "",
            cityOrder: ""
        }

        this.handleupdatecity = this.handleupdatecity.bind(this);
        this.updatecity = this.updatecity.bind(this);
    }

    handleOnchangeName = (e) => {
        const name = e.target.value;
        this.setState({
            cityName: name
        })
    }

    handleOnchangeOrder = (e) => {
        const orden = e.target.value;
        this.setState({
            cityOrder: orden
        })
    }

    handleNewCity() {
        const name = this.state.cityName;
        const orden = this.state.cityOrder;


        const data = {
            name: name,
            orden: orden
        }


        axios.post('https://serviadvisor.com/addcity', data, { withCredentials: true }).then(res => {
            const citydata = res.data;
            this.setState({
                cityName: "",
                cityOrder: ""
            }, () => {
                this.getcities()
            })
        })
    }

    handleupdatecity(cityId) {
        const name = document.getElementById("cityname:" + cityId).value;
        const orden = document.getElementById("cityorden:" + cityId).value;

        this.updatecity(cityId, name, orden);
    }


    updatecity(cityId, namecity, ordencity) {

        try {
            const data = {
                name: namecity,
                orden: ordencity
            }

            axios.put('https://serviadvisor.com/city/' + cityId, data, { withCredentials: true }).then(res => {
                const resdata = res.data;
                if (!resdata.error) {
                    this.getcities();
                }
            })
        } catch (e) {
            console.log(e.message);
        }

    }




    componentDidMount() {
        this.getcities()
    }


    deletecity(cityId) {
        axios.delete('https://serviadvisor.com/city/' + cityId, { withCredentials: true }).then(res => {
            const data = res.data;
            if (!data.error) {
                this.getcities();
            }
        })
    }

    getcities() {
        axios.get('https://serviadvisor.com/cities/').then(res => {
            const data = res.data;
            if (data) {
                this.setState({
                    cities: data
                })
            }
        })
    }

    render() {
        return (
            <div class="caja-ciudades">
                <h4>Ciudades</h4>

                {this.state.cities.map((item, index) => {
                    return (
                        <div class="cont-ciudades">
                            <p>
                                <input id={"cityname:" + item.id} type="text" defaultValue={item.name}></input>
                                <input id={"cityorden:" + item.id} type="text" defaultValue={item.orden}></input>
                        

                                <button onClick={e => this.handleupdatecity(item.id)}  class="btn btn-primary" value="Guardar">Guardar </button>
                                <button onClick={e => this.deletecity(item.id)}  class="btn btn-success" value="Eliminar"> Eliminar</button>
                            </p>
                        </div>
                    )
                })}

                <p>Agregar Nueva ciudad</p>
                <input onChange={this.handleOnchangeName} value={this.state.cityName} type="text" ></input>
                <input onChange={this.handleOnchangeOrder} value={this.state.cityOrder} type="text" ></input>
           
                <button onClick={e => this.handleNewCity()}  class="btn btn-primary" value="Agregar Nueva">Agregar Nueva</button>
            </div>
        )
    }
}

export default CitiesAdmin;