import React, { Component } from 'react'
import axios from 'axios';
import ListOfChats from '../../chat/ListOfChats';
import ProviderChat from '../../chat/ProviderChat';
import Deals from '../../chat/Deals';

class Conversaciones extends Component {

    constructor(props) {
        super(props)
        this.state = {
            chats: [],
            contend: "",
            chatid: "",
            clientusername:""
        }
        this.rendercontend = this.rendercontend.bind(this);
        this.loadmessages = this.loadmessages.bind(this);
    }

    componentWillReceiveProps() {
        if (this.props.tab) {
            this.setState({
                contend: this.props.tab
            })
        }
    }


    componentDidMount() {

    }

    loadmessages = (chatid,username) => {
        this.setState({
            chatid: chatid,
            clientusername: username,
            contend: this.props.messageType
        })
    }

    rendercontend() {
        switch (this.state.contend) {
            case 'chats':
                return (<ListOfChats match={this.props.match} user={this.props.user} sockets={this.props.sockets} Ongetmessages={this.loadmessages}></ListOfChats>)
                break;

            case 'message':

                return (<ProviderChat match={this.props.match} user={this.props.user}  sockets={this.props.sockets} username={this.state.clientusername} chatid={this.state.chatid}></ProviderChat>)
                break;

            case 'deals':

                return (<Deals match={this.props.match} user={this.props.user}  sockets={this.props.sockets} chatid={this.state.chatid}></Deals>)
                break;

            default:
                return (<ListOfChats match={this.props.match} user={this.props.user}  sockets={this.props.sockets} Ongetmessages={this.loadmessages}></ListOfChats>)
                break;
        }
    }


    render() {
        return (
            <div class="cont-chat">
                {this.rendercontend()}
            </div>
        );
    }
}

export default Conversaciones;