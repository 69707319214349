import React,{Component} from 'react';
import Axios from 'axios';
import queryString from 'query-string';
import { timingSafeEqual } from 'crypto';
import MenuCategories from './menu/categoriesMenu';
import MenuCities from './menu/citiesMenu'


class TituloBusqueda extends Component{

    
  constructor(props){
    super(props)
    this.state={
        activities:[],
        categories:[],
        providerList:[],
        cities:[],
        columnCounter:0,
        categoriesisShowing: false,
        city:0,
        cityname:"",
        category:0,
        categoryname:"",
        q:0,
        params:[],
        showcateMenu :false,
        catemenutype:"panel",
        showcityMenu :false,
        citymenutype:"panel"
    }

    this.showcategories= this.showcategories.bind(this);
    this.showcat = this.showcat.bind(this);
    this.hidecat = this.hidecat.bind(this);
    this.showcity = this.showcity.bind(this);
    this.hidecity = this.hidecity.bind(this);
    this.handleclickcategory = this.handleclickcategory.bind(this);
    this.handleclickcity = this.handleclickcity.bind(this);
    this.makequeries= this.makequeries.bind(this);
    this.onUrlhaschange = this.onUrlhaschange.bind(this);
    this.renderfilter = this.renderfilter.bind(this);
    this.clearparams = this.clearparams.bind(this);
    this.setColumns = this.setColumns.bind(this);
    this.onChangetextCategory = this.onChangetextCategory.bind(this);
    this.onChangetextCity = this.onChangetextCity.bind(this);
    this.setprops= this.setprops.bind(this);
}


setColumns(items, type){
  
  if(type==="cat"){
    let categoriesobj=[];
    let categoriesRow=[]
    let count=0;
    let column=0;
    items.forEach((item, index) => {
  
      categoriesRow.push(item);
      count++;
      if(count>=3){
        categoriesobj.push(categoriesRow)
        categoriesRow=[]
        column++;
        count=0;
      }
    })
  
    if(categoriesRow.length>0){
      categoriesobj.push(categoriesRow)
    }

    this.setState({
      categories:categoriesobj,
      catemenutype:"panel"
    })
  }

  if(type==="city"){
    const halfnumber = items.length/2;
    let citiesobj=[];
    let citiesRow=[]
    let count=0;
 

    items.forEach(item => {
        citiesRow.push(item);
        count++;
        if(count >= halfnumber){
          citiesobj.push(citiesRow);
          citiesRow=[];
          count=0
        }
    });

    this.setState({
      cities:citiesobj,
      citymenutype:"panel"
    })
  }
  

}

componentDidMount(){
    this.setprops();
  //  this.makequeries();
}

setprops(){
 
  if(this.props.categoryId){
    this.setState({
      category: this.props.categoryId
    })
  }

  if(this.props.cityId){
    this.setState({
      city: this.props.cityId
    })
  }
 
}


makequeries(){
  Axios.get('https://serviadvisor.com/activities').then(res =>{
    const activitiesdata= res.data;
  
    this.setState({
        activities:activitiesdata
    })
});

}


showcategories(){
  if(this.state.categoriesisShowing){
      document.getElementById("categoriescontend").style.display = "none";
      this.setState({
          categoriesisShowing:false
      })
  }
  else{
      document.getElementById("categoriescontend").style.display = "block";
      this.setState({
          categoriesisShowing:true
      })
  }
}

onChangetextCategory= e  => {

  let text= e.target.value;

  let firstletter = text.charAt(0);
  let textquery = text.replace("@",'');


  if(text===""){
    Axios.get('https://serviadvisor.com/categories').then(res =>{
      const categoriesdata = res.data;
     
      this.setColumns(categoriesdata,"cat");
    });
  }else if(firstletter==="@" && textquery !== ""){

  

    Axios.get('https://serviadvisor.com/GetProviders/' + textquery).then(res =>{
      const providersData = res.data;
     
     
     this.setState({
        providerList:providersData,
        catemenutype:"user"
      })
      
    });

  }else{
    Axios.get('https://serviadvisor.com/activitiesfilter?filter=' + text).then(res =>{
      const categoriesdata = res.data;
     
     
     this.setState({
        categories:categoriesdata,
        catemenutype:"list"
      })
      
    });
  }

}

showcat(){

  Axios.get('https://serviadvisor.com/categories').then(res =>{
    const categoriesdata = res.data;
 
    this.setColumns(categoriesdata,'cat');
  });

  this.setState({
    showcateMenu:true
  })


}

hidecat(){
  this.setState({
    showcateMenu:false
  })
}


onChangetextCity= e  => {

  let text= e.target.value;

  let firstletter = text.charAt(0);

  if(text===""){
    Axios.get('https://serviadvisor.com/cities').then(res =>{
      const citiesdata = res.data;
     
      this.setColumns(citiesdata,"city");
    });
  }else if(firstletter==="@"){

  }else{
    Axios.get('https://serviadvisor.com/citiesfilter?filter=' + text).then(res =>{
      const citiesdata = res.data;
    
     
     this.setState({
        cities:citiesdata,
        citymenutype:"list"
      })
      
    });
  }

}

showcity(){
  Axios.get('https://serviadvisor.com/cities').then(res =>{
    const citiesdata= res.data;
    this.setColumns(citiesdata,"city");
  })

  this.setState({
    showcityMenu:true
  })
}

hidecity(){
  this.setState({
    showcityMenu:false
  })
}



  onUrlhaschange(category){
    this.props.onUrlhaschange(category);
  }



  handleclickcategory(event, category,category_name){

    document.getElementById("category_input").value=category_name;

    const categorydata={
      name:category_name,
      id:category
    }

  this.props.onsetCategory(categorydata);
 
  //this.makequeries();
  this.setState({
    category:category,
    categoryname:category_name
  }, this.sendparams);

  }

  handleclickcity(event, city, cityName){

    document.getElementById("city_input").value=cityName;

    const citydata ={
      name:cityName,
      id:city
    }

    this.props.onSetcity(citydata)
   
    
    //this.makequeries();
    this.setState({
      city:city,
      cityname:cityName
    }, this.sendparams);
  }


  clearparams(){
   {/* document.getElementById("city_input").value=""; */} 
    document.getElementById("category_input").value="";
    this.props.onSetpage(0);
    this.props.onsetCategory("");
    this.props.onSetcity("");
    this.setState({
      city:0,
      cityname:"",
      category:0,
      categoryname:""
    }, this.sendparams);
  }

  sendparams(){

   
    const category = this.state.category;
    const city = this.state.city;
    const query = this.state.q;
    const params=[];
    if(this.props.categoryId && this.props.categoryId !== 0){
      params.category = this.props.categoryId;
    }

    if(this.props.cityId && this.props.cityId !== 0){
      params.city = this.props.cityId;
    }

    //this.props.history.push(".?city=" + city + "&category=" + category + "&q=" + query);
    const stringified = queryString.stringify(params);
    this.props.history.push(".?"+ stringified);
    this.onUrlhaschange(stringified);

    this.setState({
      params:params
    })
  }


  renderfilter(){
    return(
      <div>
        {this.props.category && (this.props.category)}
        
        {this.props.city && (<span> | {this.props.city} <a  href="javascript:void(0);" onClick={this.clearparams}>Limpiar Busqueda</a> </span>)}

        {this.props.category && !this.props.city && (<a href="javascript:void(0);" onClick={this.clearparams}> Limpiar Busqueda</a>)}
      </div>
    )
  }





    render(){
        return(
            <div> 

<div id="content" class="formbuscador">
          <div class="container">
            <div class="formcot">
              <form autocomplete="off">
                <div class="form-row align-items-center">
                {/*  <div class="my-1">
                    <label class="sr-only" for="inlineFormInputName">Elige Tu Ciudad</label>
                    <div class="input-group">
                    <input onChange={this.onChangetextCity} id="city_input" onBlur={this.hidecity} onFocus={this.showcity} type="text" class="form-control" autocomplete="off" placeholder={this.props.city ? this.props.city : "Ciudad"}/>
                    <MenuCities onhandleclick={this.handleclickcity} mode={this.state.citymenutype} cities={this.state.cities} show={this.state.showcityMenu}></MenuCities>
    </div></div> */}
                  <div class="my-2">
                  <label class="sr-only" for="inlineFormInputGroupUsername">¿Qué Necesitas?</label>
                      <div class="caja-">
               

                      
                        <input onChange={this.onChangetextCategory} id="category_input"  onBlur={this.hidecat}  onClick={this.showcat} type="text" class="form-control"  autocomplete="off" placeholder={this.props.category ? this.props.category : "Categoria"} />
                        <MenuCategories  onhandleclick={this.handleclickcategory} mode={this.state.catemenutype} providerList={this.state.providerList}  categories={this.state.categories} show={this.state.showcateMenu}></MenuCategories>
      </div>
                      </div>

                      <div class=" my-3">
                        <button type="submit" class="btn btn-primary busq"><a href=""><i class="fa fa-search"></i></a> Buscar</button>
                      </div>
                    </div>
  </form>


 


                 

                </div>
   </div>

   <div class="semilla-busca">
   {this.state.params && this.renderfilter()}</div>

            </div>
            
            </div>
        );
    }
}

export default TituloBusqueda;