import React, { Component } from 'react';
import Moment from 'react-moment';
import { Redirect } from 'react-router-dom';


class notificationView extends Component {
    constructor(props) {
        super(props)
        
    }

    render() {
        return (
            <div class="caja-item-notif">
                <a href={this.buildNotification(this.props.notification.type, this.props.notification.message, this.props.notification.accountType, this.props.notification.senderid, this.props.notification.profileid, this.props.notification.source)}>
                <div class="titulo-chat">{this.props.notification.message}</div>
                        
                        <Moment format={"DD MMM YYYY hh:mm a"}>
                             {this.props.notification.createdAt}
                        </Moment> 
                </a>
                       

                   
            </div>
        )
    }


    buildNotification = (type, message, usertype, senderid, profileid, source) => {

        let redirecturl = "";
       if(source){
        switch (type) {
    
            case 'chatnotify':
                redirecturl = usertype === "cliente" ? "/perfil/chat/" + source : "/empresa/" + profileid + "/contacto";
               
    
                break;
            case 'offer':
                redirecturl = usertype === "cliente" ? "/perfil/propuestas" : "/empresa/" + profileid + "/contacto";
              
                break;
            case 'chatlist':
                redirecturl = usertype === "cliente" ? "/perfil/calendario" : "/perfil-cliente/calendario";
              
                break;
            case 'assigment':
                redirecturl = usertype === "cliente" ? "/perfil/calendario" : "/perfil-cliente/calendario";
              
                break;
    
            case 'reassigment':
                redirecturl = usertype === "cliente" ? "/perfil/calendario" : "/perfil-cliente/calendario";
               
                break;
    
            case 'confirmed':
                redirecturl = usertype === "cliente" ? "/perfil/propuestas" : "/perfil-cliente/propuestas";
              
                break;
            case 'rejected':
                redirecturl = usertype === "cliente" ? "/perfil/propuestas" : "/perfil-cliente/propuestas";
              
                break;
            case 'paid':
                redirecturl = usertype === "cliente" ? "/perfil/propuestas" : "/perfil-cliente/propuestas";
               
                break;
            case 'payout':
                redirecturl = usertype === "cliente" ? "/perfil/propuestas" : "/perfil-cliente/propuestas";
               
                break;
        }
       }else{
        switch (type) {
    
            case 'chatnotify':
                redirecturl = usertype === "empresa" ? "/perfil/chat" : "/empresa/" + profileid + "/contacto";
               
    
                break;
            case 'offer':
                redirecturl = usertype === "empresa" ? "/perfil/propuestas" : "/empresa/" + profileid + "/contacto";
               
                break;
            case 'chatlist':
                redirecturl = usertype === "empresa" ? "/perfil/calendario" : "/perfil-cliente/calendario";
              
                break;
            case 'assigment':
                redirecturl = usertype === "empresa" ? "/perfil/chat" : "";
              
                break;
    
            case 'confirmed':
                redirecturl = usertype === "empresa" ? "/perfil/chat" : "";
             
                break;
            case 'rejected':
                redirecturl = usertype === "empresa" ? "/perfil/chat" : "";
               
                break;
            case 'paid':
                redirecturl = usertype === "empresa" ? "/perfil/chat" : "";
               
                break;
            case 'payout':
                redirecturl = usertype === "empresa" ? "/perfil/chat" : "";
               
                break;
        }
       }

       return redirecturl
    }

   
}

export default notificationView;