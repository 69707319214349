import React,{Component} from 'react';

class TituloPerfilCliente extends Component{
    render(){
        return(
        <div> 
            <div class="regis-empre">
                 <div class="container-">
                     <div class="rec-blog">
                         <div class="row">
                             <div class="container">
                                    <div class=""> <span class="tit-perfil-cliente">Mi Cuenta </span> </div>
                             </div>                              
                        </div>
                    </div>
                </div>   
            </div> 
        </div>
        );
    }
}

export default TituloPerfilCliente;