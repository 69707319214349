import React,{Component} from 'react'
import axios from 'axios';
import notifications from '../../utils/notifications'

class ListOfChats extends Component{

    constructor(props){
        super(props)
        this.state= {
            chats:[]
        }
        this.goTomessages= this.goTomessages.bind(this);
        this.getlist = this.getlist.bind(this);
        this.refreshchats = this.refreshchats.bind(this);
    }

    componentDidMount(){
      this.getlist();
      console.log("props in list of chats");
      console.log(this.props);
      this.props.sockets.sockets.callback=this.refreshchats
        
    

      if(this.props.match && this.props.match.params.id){
          this.goTomessages(this.props.match.params.id, "user")  
      }
    }

   

    refreshchats(data){
        console.log("refresh chats")
        if(data.type==="chatlist"){
            this.getlist();
        }else{
            notifications.buildNotification(data.type, data.message, data.accountType, data.senderid, data.profileId,data.source);
           
        }

       
        
    }


    getlist(){
        axios.post('https://serviadvisor.com/listchats',null, {withCredentials:true}).then(res => {
            const data = res.data;
            if(data.error){

            }else{
                this.setState({
                    chats:data
                })
            }
        })
    }
    
    goTomessages = (chatid, username) => {
        this.props.Ongetmessages(chatid,username)
        console.log(this.props);
    }

    render(){
        return(
            <div>

   <div class="aviso-ayuda" >
     <div class="btn-ayuda-pop" data-toggle="modal" data-target="#myModal-ayuda-emp" data-dismiss="modal" >
       Guía Negociación (AYUDA) -    <i class="fas fa-question-circle" ></i>    </div>
</div>   

<div class="alert  alert-success" role="alert">
                    
                    <h5>Cuando hayas realizado tu trabajo y el cliente este satisfecho obtendrás tu pago. </h5> 
                 
                </div>



                
                 <div class="title-perfil-empresa">
                <h4 class="azul"> Clientes que te han contactado</h4>
            </div>
                 <div class="grid-p">
                  {this.state.chats.map((item,index)=>{
                      return( 
                        <div onClick={(e) => this.goTomessages(item.id,item.client.user.username)} class="chat-micuenta">
                            <div class="foto-chat-cuen"> <img src={ item.client.user.photo_url===null ? ("/images/fotoperfil.png") : (item.client.user.photo_url)} alt="pav"/> </div>
                            <div class="tit-chat-cuen">Usuario: {item.client.user.username === undefined || item.client.user.username===null ? (<span>Usuario</span>) : (item.client.user.username)} </div>
                            <div class="tit-chat-cuen-n">Nombre: {item.client.name === undefined || item.client.name===null ? (<span>Sin Nombre</span>) : (item.client.name)} </div>
      
                            <button class="btn-cont full-v">Ver propuestas</button>
      
                            <div class="limp"></div>  
                         </div>)
                  })}
                  </div>
                  
             
            </div>
        );
    }
}

export default ListOfChats;