import React,{Component} from 'react';
import HeaderPage from '../HeaderPage';
import PremiumContend from '../premiumContendpage';
import Footer from '../Footer';
import sockets from '../../sockets/sockets';
import profileutils from '../../utils/profiledata';

class Premium extends Component{
  
    constructor(props) {
        super(props)
        this.state = {
            islogin: false,
            user: undefined
        }
        this.logindata = this.logindata.bind(this);
        const user = profileutils.getuserprofile();
        console.log(user);
        if (user) {
            sockets.subscribeUserToSocket(user.userid)
            this.setState({
                user: user
            })
        }
    }

    logindata = (user, islogin) => {
        this.props.loginroot(user,islogin);
        if (!islogin) {
            profileutils.deleteprofile();
        }
        this.setState({
            user: user,
            islogin: islogin
        })
        profileutils.setuserprofile(user);
        if (user) {
            sockets.subscribeUserToSocket(user.userid)
            sockets.subscribeChat()
        }
    }

    componentDidMount() {
      
    }   
 
    render(){
        return(
            <div>
                <HeaderPage slideout={this.props.slideout} sockets={sockets} user={this.state.user} logindata={this.logindata} islogin={this.state.islogin} history={this.props.history}></HeaderPage>
                <PremiumContend  user={this.state.user} sockets={sockets} user={this.state.user} islogin={this.state.islogin} history={this.props.history}></PremiumContend>
                <Footer user={this.state.user} logindata={this.logindata} islogin={this.state.islogin}></Footer>
            </div>
        )
    }
}

export default Premium