import React, { Component } from 'react';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';

const style = {
  width: '100%',
  height: '100%',
  position: 'relative'
}


export class MapContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      fields: {
        location: {
          lat: 40.730610,
          lng: -73.935242
        }
      }
    }

    //this.getlocation();
    this.setcordinates = this.setcordinates.bind(this);
    this.handleMapReady = this.handleMapReady.bind(this);
    this.setinitialposition = this.setinitialposition.bind(this);
  }

  handleMapReady(mapProps, map) {
    map.setOptions({
      draggableCursor: "pointer",
      draggingCursor: "grab"
    });
  }

  setcordinates = (latlng) => {
    this.props.OnsetCoors(latlng);
  }


  async getlocation() {
    const { lat, lng } = await this.getcurrentLocation();
    this.setState(prev => ({
      fields: {
        ...prev.fields,
        location: {
          lat,
          lng
        }
      },
      currentLocation: {
        lat,
        lng
      }
    }));
  }


  async componentDidMount() {

    if (this.props.location) {

      if (this.props.location.lat !== null && this.props.location.lng !== null) {
        this.setState({
          fields: {
            location: {
              lat: this.props.location.lat,
              lng: this.props.location.lng
            }
          }

        })
      } else {
        this.setinitialposition();
      }
    } else {
      this.setinitialposition();
    }
  }

  setinitialposition = async() => {
    const { lat, lng } = await this.getcurrentLocation();
    this.setState(prev => ({
      fields: {
        ...prev.fields,
        location: {
          lat,
          lng
        }
      },
      currentLocation: {
        lat,
        lng
      }
    }));

  }

  getcurrentLocation() {
    if (navigator && navigator.geolocation) {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(pos => {
          const coords = pos.coords;
          resolve({
            lat: coords.latitude,
            lng: coords.longitude
          });
        });
      });
    }
    return {
      lat: 0,
      lng: 0
    };
  }
  addMarker = (location, map) => {

    if (this.props.editable) {

      this.setState(prev => ({
        fields: {
          ...prev.fields,
          location
        }
      }));
      map.panTo(location);
      this.setcordinates(location);
    };
  }


  

  render() {
    return (
      <div class="caja-map">
        {this.state.fields && (<Map google={this.props.google}
          style={style}
          initialCenter={this.state.fields.location}
          center={this.state.fields.location}
          zoom={14}
          onReady={this.handleMapReady}
          onClick={(t, map, c) => this.addMarker(c.latLng, map)}>

          <Marker position={this.state.fields.location} />

          <InfoWindow onClose={this.onInfoWindowClose}>
            <div>
              <h1></h1>
            </div>
          </InfoWindow>
        </Map>)}
      </div>


    );
  }
}

export default GoogleApiWrapper({
  apiKey: ("AIzaSyARfQmuW3o7gh6XT1BxxwgEhlXt60fTrqw")
})(MapContainer)