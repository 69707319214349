import React,{Component} from 'react';
import axios from 'axios';

class paypalAccountMessage extends Component{
    constructor(props){
        super(props)
        this.state={
            show:false,
            user:undefined
        }
    }

    componentDidMount(){
        axios.post('https://serviadvisor.com/checkpaypalProfile',null,{withCredentials:true}).then(res => {
            const data= res.data;
            if(data.error){
                this.setState({
                    show:true
                })
            }
        })

        axios.get('https://serviadvisor.com/user', { withCredentials: true }).then(res => {

            if (res.data[0]) {
              const data = res.data[0];
              const userdata = {
                userid: data.id,
                username: data.username,
                photo_url: data.photo_url,
                active: data.active,
                accountType: data.accountType
              }
              
              this.setState({
                  user:userdata
              })


            }
        })
        
    }


    sendconfirmationemail(){
        axios.post('https://serviadvisor.com/requestmailvalidation',null, {withCredentials:true}).then(res => {
            const data = res.data;
            if(!data.error){

            }
        })
    } 

    render(){
        return(
            <div>   
                {this.state.show && (<div style={{paddingBottom:"5px",paddingTop:"5px"}} class="alert alert-danger alert-dismissible" >Agrega tu cuenta de PayPal para poder recibir tus pagos. <b><a href="/perfil/account"> Vincula tu cuenta aquí.</a></b>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
                </div>)}
                {console.log(this.state.user)}
                {this.state.user && !this.state.user.active ? <div style={{paddingBottom:"10px",paddingTop:"10px"}} class="alert alert-warning alert-dismissible" role="alert">
  Todavia No has Activado tu cuenta, Revisa tu correo Y sigue el enlace de activacion <a onClick={(e) => this.sendconfirmationemail()} href="javascript:void(0);">Haz click aca para enviar el correo nuevamente</a>
  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div> : "" }             
                
            </div>
        )
    }
}

export default paypalAccountMessage;