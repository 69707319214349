import React,{Component} from 'react';
import Axios from 'axios';
import notifications from '../../../utils/notifications';

class FaqSection extends Component{
    constructor(props){
        super(props);
        this.state={
            questions:[]
        }
        this.notify = this.notify.bind(this);
    }

    componentDidMount(){
        Axios.get('https://serviadvisor.com/faqs/' + this.props.providerId ).then(res =>{
            console.log(res.data);
            this.setState({
             questions: res.data
           });
          });
          this.props.sockets.sockets.callback=this.notify
        }
    
        notify(data){
              console.log(this.props.user);
              notifications.buildNotification(data.type, data.message, this.props.user.accountType, data.senderid, data.profileId); 
      }

    render(){
        return(
            <div>
                    <div class="title-perfil-empresa espacio-top">
                                      <h4 class="azul">   FAQ - Preguntas frecuentes e interés</h4>
                                  </div> 
                                 

                                  <div>

                                    {this.state.questions.map((item,idex)=>{

                                      return(
                                        <div>
                                          <h5 class="negrilla espacio-top">{item.question}</h5>
                                          <p>{item.response}</p>
                                        </div>
                                      );


                                    })}

                                    
                                    

                                  </div>
            </div>
        )
    }
}

export default FaqSection