import React, { Component } from 'react';
import Question from '../../question';
import Axios from 'axios';
import { Redirect, Switch, Route } from 'react-router-dom';
import notifications from '../../../utils/notifications';


class Preguntas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: [],
      redirect: false,
      providerdata: []
    }

    this.handletextChange = this.handletextChange.bind(this);
    this.addnewquestion = this.addnewquestion.bind(this);
    this.deletequestion = this.deletequestion.bind(this);
    this.setRedirect = this.setRedirect.bind(this);
    this.renderRedirect = this.renderRedirect.bind(this);
    this.loadquestions = this.loadquestions.bind(this);
    this.updatequestion = this.updatequestion.bind(this);
    this.refreshdata = this.refreshdata.bind(this);
  }

  componentDidMount() {
    Axios.get('https://serviadvisor.com/checkprovider', { withCredentials: true }).then(res => {

      const provider = res.data;
      if (provider.error) {

      } else {
        this.setState(
          {
            providerdata: provider
          }
        )
        this.loadquestions(provider.id)
      }
    });
    this.props.sockets.sockets.callback = this.refreshdata
  }

  refreshdata(data) {
    notifications.buildNotification(data.type, data.message, data.accountType, data.senderid, data.profileId,data.source);
}


  loadquestions(providerId) {
    Axios.get('https://serviadvisor.com/faqs/' + providerId).then(res => {
      console.log(res.data);
      this.setState({
        questions: res.data
      });
    });
  }

  handletextChange(name, index, event) {
    const questionstemp = Object.assign([], this.state.questions);
    //this.setState({[name]: event.target.value});

    questionstemp[index][name] = event.target.value;

    this.setState({
      questions: questionstemp
    })

    console.log("campo :" + name + "texto: " + event.target.value + "indice: " + index);
  }

  addnewquestion() {

    Axios.post('https://serviadvisor.com/newfaq', null, { withCredentials: true }).then(res => {
      const datares = res.data;
      this.loadquestions(this.state.providerdata.id);
    })
  }

  updatequestion(index, event, questiondata) {
    const data = {
      faqid: questiondata.id,
      question: questiondata.question,
      response: questiondata.response
    }
    console.log(questiondata)
    Axios.put('https://serviadvisor.com/faqs', data, { withCredentials: true }).then(res => {
      const resdata = res.data;
      this.loadquestions(this.state.providerdata.id);
    })
  }

  deletequestion(index, event, questionid) {

    const data = {
      faqid: questionid
    }
    console.log(data)
    Axios.delete('https://serviadvisor.com/faqs/' + questionid, { withCredentials: true }).then(res => {
      const faqres = res.data;
      this.loadquestions(this.state.providerdata.id);
    })

  }


  handlesubmit = (e) => {
    e.preventDefault()
    const data = this.state.questions;

    Axios.post('https://serviadvisor.com/faqs', data, { withCredentials: true }).then(res => {
      console.log(res.data);
      this.setRedirect();
    })
  }

  setRedirect = () => {
    this.setState({
      redirect: true
    })
  }

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to='/' />
    }
  }


  render() {
    return (
      <div class="contenido-notificaciones">
         <div class="title-perfil-empresa espacio-top">
          <h4 class="azul">Preguntas frecuentes – FAQ </h4>
        </div>
        
        <p>Completa los siguientes campos con las preguntas y respuestas para tus clientes.   </p>

        {this.state.questions.map((item, index) => {
          return (
            <Question
              index={index}
              key={item.id}
              Ontextchange={this.handletextChange}
              onDeleteQuestion={this.deletequestion}
              oneditquestion={this.updatequestion}
              textquestion={item.question}
              textresponse={item.response}
              id={item.id}
              edit={true}
            >
            </Question>
          );
        })}

        <div class="agregar-pregunta"><span onClick={this.addnewquestion}>Agregar Pregunta <i class="fa fa-plus naranja"></i>  </span>

        </div>
        <br/>
        <p>Después de crear tus pregunta  puedes comenzar a configurar. Editar las preguntas es fácil, solo haz clic en editar.</p>
      </div>
    );
  }
}

export default Preguntas;