import React,{Component} from 'react';
import ListOfChats from '../chat/ListOfChatsClient';
import Deals from '../chat/DealsClient';
import notifications from '../../utils/notifications';


class clientOffers extends Component{
    constructor(props){
        super(props)
        this.state = {
            chats: [],
            contend: "",
            chatid: ""
        }
        this.rendercontend = this.rendercontend.bind(this);
        this.loadmessages = this.loadmessages.bind(this);
    }
    componentWillReceiveProps() {
        if (this.props.tab) {
            this.setState({
                contend: this.props.tab
            })
        }
    }


    componentDidMount() {
        this.props.sockets.callback = this.notify
    }

    notify(data) {
        notifications.buildNotification(data.type, data.message, data.accountType, data.senderid, data.profileId,data.source);
        
      }

    loadmessages = (chatid) => {
        this.setState({
            chatid: chatid,
            contend: this.props.messageType
        })
    }

    rendercontend() {
        switch (this.state.contend) {
            case 'chats':
                return (<ListOfChats user={this.props.user} sockets={this.props.sockets}  Ongetmessages={this.loadmessages}></ListOfChats>)
                break;

            case 'deals':

                return (<Deals user={this.props.user} sockets={this.props.sockets} chatid={this.state.chatid}></Deals>)
                break;

            default:
                return (<ListOfChats user={this.props.user} sockets={this.props.sockets}  Ongetmessages={this.loadmessages}></ListOfChats>)
                break;
        }
    }

    
    render(){
        return(
            <div class="pagos-cliente">
                 <div class="aviso-ayuda" >
                    <div class="btn-ayuda-pop" data-toggle="modal" data-target="#myModal-ayuda-cli" data-dismiss="modal" >
                    Guía Negociación (AYUDA) -    <i class="fas fa-question-circle" ></i>    </div>
                </div>  
                <div class="alert alert-info" role="alert">
                    
                    <h5>Realiza el pago de tu servicio, nosotros le pagaremos al proveedor cuando estés satisfecho.</h5> 
                
                </div>
                
                {this.rendercontend()}
                
                </div>
        );
    }
}

export default clientOffers