import React, { Component } from 'react';
import Axios from 'axios';
import Message from './message';
import DealMessage from './DealMessage';
import RevisionMessage from './RevisionMessage';
import notifications from '../../utils/notifications'
import Loader from 'react-loader-spinner';


class ProviderChat extends Component {
    constructor(props) {
        super(props)


        this.state = {
            actualmessage: "",
            messages: [],
            caracternumber:0,
            page:0,
            loaderSave: false,
            loadsuccess: false,
            description: "",
            price: 0,
            
        }

        this.nextpage = this.nextpage.bind(this);
        this.getmessages = this.getmessages.bind(this);
        this.refreshChat = this.refreshChat.bind(this);
        this.refreshmessages = this.refreshmessages.bind(this);
        this.rendermessage = this.rendermessage.bind(this);
        this.onchangetextPrice = this.onchangetextPrice.bind(this);
    }



    componentDidMount() {
        console.log("socket prop")
        console.log(this.props.sockets)
        this.getmessages();
        this.props.sockets.sockets.callback=this.refreshmessages      
    }


    onchangetextMessage = (e) => {
        let description = e.target.value;

        this.setState({
            description: description
        })
    }


    onchangetextPrice = (e) => {
        //alert(e.target.value)
        this.setState({ price: (e.target.validity.valid) ? e.target.value : this.state.price });
    }

    sendDealmessage = (event) => {
        event.preventDefault();
        const data = {
            chatid: this.props.chatid,
            description: this.state.description,
            price: this.state.price
        }

        this.setState({
            loaderSave:true
        })


        Axios.post('https://serviadvisor.com/Deals', data, { withCredentials: true }).then(res => {
            const datares = res.data;
            this.getmessages();
            if (!datares.error) {
                this.setState({
                    loadsuccess: true,
                    loaderSave:false
                })
            } else {

            }
        })
    }



    refreshmessages(data){
       
        if(data.type=="chatnotify" || data.type=="revisionUpdate"){
            this.getmessages();
        }else{
            notifications.buildNotification(data.type, data.message, data.accountType, data.senderid, data.profileId,data.source);
            
        }
     
        console.log(data);
    }

    nextpage = () => {
      
        let pagenum = this.state.page;
        pagenum = pagenum + 1;

        const data = {
            chatid:  this.props.chatid,
            page:pagenum
        }
        Axios.post('https://serviadvisor.com/getmessagespagination', data, { withCredentials: true }).then(res => {
            if (data.error) {

            } else {

                const data = res.data


                const messagesdata = this.state.messages.slice()
        
                if(data.length > 0){
                    this.setState({
                        messages:data.reverse().concat(messagesdata) 
                    }, () => {
                        this.setState({
                            page:pagenum
                          })
                        console.log(this.state.messages);
                    })
                }
      
            }

        })

        
    }

    onenterKey = (e) => {
     
      
        if (e.key === "Enter" && e.shiftKey === false) {
          this.sendmessage();
      }
      
      }

    getmessages = () => {
        let pagenum = 0;
       
        const data = {
            chatid: this.props.chatid,
            page:pagenum
        }
        Axios.post('https://serviadvisor.com/getmessagespagination', data, { withCredentials: true }).then(res => {
            if (data.error) {

            } else {

                const data = res.data

                    this.setState({
                        messages:data.reverse(),
                        page:0
                    })
                

              
            }

        })


    }


    /*
    getmessages = () => {

        const data = {
            chatid: this.props.chatid
        }
        Axios.post('https://serviadvisor.com/getmessages', data, { withCredentials: true }).then(res => {
            if (data.error) {

            } else {

                const messagesdata = res.data

                this.setState({
                  messages:messagesdata
                }, () => {setTimeout(this.refreshChat, 1000)})
            }

        })
    }
    */


    onchangetextMessage = (e) => {
        let message = e.target.value;
        let number = e.target.value.length

        this.setState({
            actualmessage: message,
            caracternumber:number
        })
    }

    sendmessage = (event) => {
        const data = {
            chatid: this.props.chatid,
            message: this.state.actualmessage
        }

        Axios.post('https://serviadvisor.com/messages', data, { withCredentials: true }).then(res => {
    this.getmessages();
    this.setState({
        caracternumber:0,
        actualmessage:""
    })
        if (data.error) {

            } else {

            }
        })
    }


    refreshChat(){
          //this.getmessages();
    }
    
    // first timeout to start the process


    rendermessage(item){
        if(item.offert !==null && item.offert !==undefined){
            return(
                <DealMessage type="provider" offer={item}></DealMessage>
            ) 
        } else if (item.revision !== null && item.revision !== undefined) {
                return (
                  <RevisionMessage onDealresponse={this.dealresponseRevision} type="provider" chatItem={item}></RevisionMessage>
                )
        
        }else{
            return(
                <Message message={item}></Message>
               
            )
        }
    }


    render() {
        return (
            <div>
                <div class="title-perfil-empresa">
        <h4 class="azul"> <a href="#">{this.props.username} Chat</a></h4>
                </div>

                <div class="caja-dist-ofert">

<div class="caja-redactar">
    <div class="title-perfil-empresa">
        <h4 class="azul">Redactar Propuesta</h4>
    </div>
    <div class="caja-chat-escribir">

        <form class="form-redactar">
            <textarea onChange={this.onchangetextMessage} class="form-control comple red-pro" id="detalle" placeholder="Detalle"></textarea>
            <div class="caja-redactarcaja-valor-pro">
                <div class="precio-prop"> USD  </div><input pattern="^\d*(\.\d{0,2})?$" onInput={this.onchangetextPrice} type="text" class="form-control mitad-2" id="cantidad" placeholder="Cantidad" value={this.state.price} autocomplete={false} />
                <button onClick={this.sendDealmessage} class="enviar-propuesta">enviar <Loader
                    visible={this.state.loaderSave}
                    type="Oval"
                    color="#00BFFF"
                    height={25}
                    width={25}

                /></button>

                {this.state.loadsuccess && (<span class="alert"><i class="fas fa-check-circle"></i> Propuesta enviada con exitó.</span>)}
            </div>

        </form>

    </div>




</div>
</div>


                <div class="caja-chat-perfil-emp">

              <div  class="subtitulo"><a onClick={this.nextpage} href="javascript:void(0)">Cargar más...</a> <br/> </div>


                    {this.state.messages.map((item, index2) => {
                       
                        return (
                            <div key={index2} >
                                {this.rendermessage(item)}
                                 
                            </div>
                        );
                    })}

</div>

                    <div class="caja-chat-escribir">
                       
                        <div class="caja-chat-cir">
                            <textarea onKeyPress={this.onenterKey} onChange={this.onchangetextMessage} value={this.state.actualmessage} class="texto-enviar">{this.state.actualmessage}</textarea>
                        </div>
                    </div>

                    <p class="caracteres-enviar-empresa">{this.state.caracternumber}/500 caracteres</p>
                    
                    <button onClick={this.sendmessage} class="enviar-respuesta">enviar</button>
                    <div class="limp"></div>
                    
                    

            </div>
        )
    }
}

export default ProviderChat;