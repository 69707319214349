import React,{Component} from 'react';

class leftbutton extends Component{
    render(){
        return(
        
            <button {...this.props}>Next<div>boton</div>></button>
        )
    }
        
    
}

export default leftbutton;