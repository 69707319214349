import React,{Component} from 'react';
import axios from 'axios';
import Axios from 'axios';
import Moment from 'react-moment';
import { Modal, Button } from 'react-bootstrap';
import ReactStars from 'react-stars'
import { toast } from 'react-toastify';
import notifications from '../../utils/notifications';



class ClientPayouts extends Component{
    
    constructor(props){
        super(props)
        this.state={
            orders:[],
            coment: "",
            starts:0,
        }

        this.getorders= this.getorders.bind(this);
        this.finishAndqualify = this.finishAndqualify.bind(this);
        this.sendqualify = this.sendqualify.bind(this);
        this.onChangeComent = this.onChangeComent.bind(this);
    }

    componentDidMount(){
        this.getorders();
        this.props.sockets.callback = this.notify
    }

    getorders(){
        axios.post('https://serviadvisor.com/getorders',null,{withCredentials:true}).then(res => {
            const data = res.data;

            if(!data.error){
                this.setState({
                    orders:data
                })
            }
        })
    }

    notify(data) {
        notifications.buildNotification(data.type, data.message, data.accountType, data.senderid, data.profileId,data.source);
      }


    finishAndqualify = (offertid) => {

        this.setState({
            modalqualify: true,
            actualOfferid: offertid,
            coment: "",
            starts:0
        })
    }

    sendqualify() {

        const data={
            starts:this.state.starts,
            coment:this.state.coment,
            offertId:this.state.actualOfferid
        }

        Axios.post('https://serviadvisor.com/offerserviceComplete',data,{withCredentials:true}).then(res => {
            const data= res.data;
        if(!data.error){
            this.setState({
                modalqualify:false
            })
            this.getorders()
        }else{
            toast.error(data.error)
        }
        
        
        })

       
    }

    onChangeComent = (e) => {
        const value = e.target.value

        this.setState({
            coment: value
        })
    }
    
    setstars= (starts) =>{
        this.setState({
            starts:starts
        })
     }
    
    render(){

        let modalClose = () => this.setState({ modalqualify: false });
        return(
            <div class="contenido-pagos">
                <div class="aviso-ayuda" >
     <div class="btn-ayuda-pop" data-toggle="modal" data-target="#myModal-ayuda-cli" data-dismiss="modal" >
       Guía Negociación (AYUDA) -    <i class="fas fa-question-circle" ></i>    </div>
</div>  

                <div class="alert alert-info" role="alert">   
                    <h5>Realiza el pago para recibir de tu servicio, nosotros lo guardaremos y le pagaremos al proveedor cuando nos digas que estas satiestés con el trabajo.</h5> 
                </div>
             <div class="title-perfil-cliente espacio-top"> <h4 class="azul"> <span class="bg-blanco">Pagos</span> <hr class="naranja"/> </h4>  </div>
             <div class="ofertas-anteriores">
            {this.state.orders.map((item,index)=> {
                return(
                    <div class="propue">
                        <div class="propue-con">

                        <div class="ofertas-1-int-gan">

                        <div>Proveedor: {item.offert.provider.name}</div>
                                                    <div>Id de orden: {item.order_id}</div>

                        
                                                    {item.offert.offertStatusId === 5 ?  (<button onClick={(e) => this.finishAndqualify(item.offert.id)}>Dar Trabajo Por Completo/ Calificar</button>) : "Trabajo Completado"}
                                            
                                                    <div class="oferta-fech-pagado alert-info">
                                                    <div ><b>Pagado :  </b> <Moment format={"DD MMM YYYY hh:mm a"}>{item.createdAt}</Moment> </div>
                                                    {item.refund=== true && (<div class="pago-dev"  role="alert">
                                                        Pago devuelto al cliente
                                                        </div>)}
                                                    </div>
                                                    
                        </div>

                        <div class="oferta-caj-2">
                        <div class="oferta-valor-gan">
                        <span>Valor: ${item.offert.amount}</span> 
                        </div>
                        </div>

                        </div> 
                       
                </div> 
                    
                )
            })}

</div> 

<Modal className="recorte pro" show={this.state.modalqualify} onHide={modalClose}
                    {...this.props}
                    size="lg"
                    animation={false}

                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Califica El Servicio y deja un comentario
                           
                <hr />
                <div className="flecha-down"></div>
              </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div >

                            <ReactStars
                                count={5}
                                onChange={this.setstars}
                                size={24}
                                half={false}
                                value={this.state.starts}
                                color2={'#ffd700'} />,
                          
                      

                            <textarea onChange={this.onChangeComent} value={this.state.coment}> </textarea>

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.sendqualify} className={"btn-primary"}>Aceptar</Button>
                        <Button onClick={modalClose} className={"btn-success"}>Cerrar</Button>
                    </Modal.Footer>
                </Modal>


        </div>
        )
    }
}

export default ClientPayouts;