import React,{Component} from 'react';
import notifications from '../../../utils/notifications';
import ReactStars from 'react-stars';
import Moment from 'react-moment';
import axios from 'axios';

class RatingSection extends Component{
    constructor(props){
        super(props)
        this.state={
          coments_rating: [],
          provider: []
        }
        this.notify = this.notify.bind(this);
        this.checkprovider = this.checkprovider.bind(this);
    }

    componentDidMount(){
      this.checkprovider();
      this.props.sockets.sockets.callback=this.notify
    }

    notify(data){
          console.log(this.props.user);
          notifications.buildNotification(data.type, data.message, this.props.user.accountType, data.senderid, data.profileId); 
  }


  checkprovider() {

          axios.get('https://serviadvisor.com/coment_rating/' + this.props.providerId).then(res => {
            const data = res.data;
            
            this.setState({
              coments_rating: data
            })
          })
       
  }

    render(){
        return(
            <div>
                 <div class="title-perfil-empresa espacio-top">
                                      <h4 class="azul"> <a href="#">Comentarios de Usuarios</a></h4>
                                  </div> 

                                  {this.state.coments_rating.map((item,index)=>{
          return(
            <div class="caja-calif">
                <div class="caja-calif-cont">
          <div class="img-det-calificacion"><a href="#"><img src={item.client.user.photo_url} alt="" class="img-responsive" /></a></div>
          <div class="caja-cal-text">
          <div class="calificacion negrilla">Calificación:</div>

          <ReactStars
            count={5}
            edit={false}
            size={19}
            half={false}
            value={item.score}
            color2={'#ffd700'} />
</div>
<div class="caja-calif-cont">


          <div class="texto-det-calificacion">
            <p>{item.coment}</p>
          </div>
        </div>
        </div>
        <p class="alinear-derecha-fecha color-gris"><Moment format={"DD MMM YYYY hh:mm a"}>{item.createdAt}</Moment></p>
        <div class="limp"></div>

            </div>
            
          )
        })}



            </div>
        )
    }
}

export default RatingSection;