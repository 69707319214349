import React, { Component } from 'react';
import axios from 'axios';


const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

class Usuariosadmin extends Component {
    constructor(props) {
        super(props)
        this.state = {
            users: [],
            username: "",
            email: "",
            password: "",
            secondpassword: "",
            errors: {
                email: true,
                emailsign: true,
                passdis: true,
                passdislink: true,
            }


        }

        this.hanldeeditpass= this.hanldeeditpass.bind(this);
    }

    checkerrors() {
        if (!this.state.errors.email) {
            return false;
        }

        if (!this.state.errors.passdis) {
            return false;
        }



        return true;
    }

    handletextChange(name, event) {


        this.setState({ [name]: event.target.value });



        if (name === "username") {
            this.validateUsername(event);
        }

        if (name === "password") {
            this.validatePassword(event);
        }

        if (name === "secondpassword") {
            this.validateSecondPassword(event);
        }



        if (name === "email") {
            this.validateemail(event, false);
        }







    }

    validateemail(event, sign) {
        const email = event.target.value
        const checkemail = validEmailRegex.test(event.target.value)
        const errors = this.state.errors
        if (sign) {
            errors.emailsign = checkemail
        } else {
            errors.email = checkemail
        }

        this.setState({ errors: errors });
    }


    validatePassword(event) {
        const password = this.state.secondpassword;
        const second = event.target.value;


        let errors = this.state.errors;
        if (second === password) {
            errors.passdis = true;
        }
        else {

            errors.passdis = false;

        }
        this.setState({
            errors: errors
        })
    }

    validateSecondPassword(event) {
        const password = this.state.password;
        const second = event.target.value;


        let errors = this.state.errors;
        if (second === password) {
            errors.passdis = true;
        }
        else {

            errors.passdis = false;

        }
        this.setState({
            errors: errors
        })
    }



    componentDidMount() {
        this.getusers()
    }

    getusers() {
        axios.post('https://serviadvisor.com/getpublishers', null, { withCredentials: true }).then(res => {
            const data = res.data;
            if (!data.error) {
                this.setState({
                    users: data
                })
            }
        })
    }

    newpublisher() {

        this.checkerrors();

        const data={
            email:this.state.email,
            password:this.state.password,
        }

        axios.post('https://serviadvisor.com/addpublisher',data,{withCredentials:true}).then(res => {
            const resdata = res.data;

            if(!resdata.error){
                this.getusers();
            }
        })
    }

    hanldeeditpass(publisherId){

        const publisherpass = document.getElementById("publisherpass:" + publisherId).value;

        const data={
            password:publisherpass
        }

        axios.put('https://serviadvisor.com/publisher/' + publisherId,data,{withCredentials:true}).then(res => {
            const resdata = res.data;
            if(!resdata.error){
                this.setState({
                    users:[]
                }, () => {
                    this.getusers();
                })
               
            }
        })
    }

    deletepublisher(publisherId){
        axios.delete('https://serviadvisor.com/publisher/' + publisherId,{withCredentials:true}).then(res => {
            const data= res.data;
            if(!data.error){
                this.getusers();
            }
        })
    }

    render() {
        return (
            <div class="caja-user-ad">
                <h4>Administradores de contenido</h4>

                {this.state.users.map((item, index) => {
                    return (
                        <div class="cont-item-user">
                             <div class="cont-item-user-">
                            Username: {item.user.username}
                            <input id={"publisherpass:" + item.userId} type="password" placeholder="nueva contraseña"></input>
                       

                            <button onClick={e => this.hanldeeditpass(item.userId)} type="button" class="btn btn-primary" value="Cambiar contraseña">Cambiar contraseña</button>
                            <button onClick={e=> this.deletepublisher(item.userId)} type="button" class="btn btn-success" value="Eliminar" >Eliminar</button>

                            </div>
                            
                            
                        </div>
                    )
                })}


                <input value={this.state.email} onChange={e => this.handletextChange("email", e)} type="text" placeholder="Email"></input>
                <input value={this.state.password} onChange={e => this.handletextChange("password", e)} type="password" placeholder="Contraseña"></input>
                <input value={this.state.secondpassword} onChange={e => this.handletextChange("secondpassword", e)} type="password" placeholder="Repetir Contraseña"></input>
                <button onClick={e => this.newpublisher()} type="button" class="btn btn-primary" value="Agregar nuevo">Agregar nuevo</button>
              
                {!this.state.errors.passdis && (<div class="row"><i class="fas fa-times"></i><span>Las contraseñas no coinciden</span></div>)}
                {!this.state.errors.email && (<div class="row"><i class="fas fa-times"></i><span>Por favor ingrese un correo valido</span></div>)}
            </div>
        )
    }
}

export default Usuariosadmin;