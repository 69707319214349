import React, { Component } from 'react';
import queryString from 'query-string';
import axios from 'axios';

class PaypalBody extends Component {
    constructor(props) {
        super(props)
        this.state = {
            code: "",
            response: undefined,
            paypaluser: undefined,
            profile: undefined
        }
        this.requestToken = this.requestToken.bind(this);
        this.getuserinfo = this.getuserinfo.bind(this);
        this.makepaypalprofile = this.makepaypalprofile.bind(this);
        this.renderprofileinfo = this.renderprofileinfo.bind(this);
    }

    clientid = "AVlvws8g45bp8AXNWwa_X6TUdqX2jCS2suLJgK6HFJSMvO-eoRFAULhYSWQyU3R1CniFoPjShAfigUSP"
    Secret = "ECgDeW9NwjhFudc1vQ95ZHLwey8XKrOSoTsq9R3Q0GUkOIhJNTORBpOHcCLrS069-ei8rh85Q5qNW1HG"


    makepaypalprofile() {
        const data = {
            email: this.state.paypaluser.emails[0].value,
            payer_id: this.state.paypaluser.payer_id,
            user_id: this.state.paypaluser.user_id
        }
        axios.post('https://serviadvisor.com/paypalprofile', data, { withCredentials: true }).then(res => {
            console.log(res.data)

            const paypalprofile= res.data;

            this.setState({
                profile:paypalprofile
            })
        })
    }


    getuserinfo() {
        let headers = {
            "Authorization": "Bearer " + this.state.response.access_token
        }


        axios.get('https://api.sandbox.paypal.com/v1/identity/oauth2/userinfo?schema=paypalv1.1', { headers: headers }).then(res => {
            const data = res.data;
            this.setState({
                paypaluser: data
            }, () => {
                this.makepaypalprofile()
            })

        })
    }


    requestToken() {
        const headers = {
            Authorization: "Basic " + this.clientid + ":" + this.Secret
        };

        const data = {
            grant_type: "authorization_code",
            code: this.state.code
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },


        }

        axios.post("https://api.sandbox.paypal.com/v1/oauth2/token", queryString.stringify(data), {
            auth: {
                username: this.clientid,
                password: this.Secret
            },
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then(res => {
            const paypaluserdata = res.data;

            this.setState({
                response: paypaluserdata
            }, () => {
                this.getuserinfo();
            })
        })
    }

    componentDidMount() {
        const searchParams = new URLSearchParams(this.props.location.search);



        if (searchParams.get("code")) {
            const authCode = searchParams.get("code");
            this.setState({
                code: authCode
            }, () => {
                this.requestToken();
            })
        }
    }


    renderprofileinfo(){
        if(this.state.profile){
            return(<div class="container caja-paypal caja-paypal-activacion">
                <div class="alert alert-success" >Tu cuenta de Paypal se vínculo</div>
                <p>{this.state.profile.email}</p>
                <p>{this.state.profile.payer_id}</p>
                <p>{this.state.profile.user_id}</p>
                
                <div class="caja-btn-bienv">
                    <div class="atras-btn"><a href="javascript: history.go(-1)"><i class="fas fa-chevron-left"></i>Ir atras</a> </div>
                    <div class="inicio-btn"><a href="/"><i class="fas fa-chevron-left"></i>Ir a inicio</a> </div>
                </div>

            </div>)
        }
          
    }

    render() {
        return (
            <div class="container caja-paypal caja-paypal-activacion">
                
              
                {this.renderprofileinfo()}
               

                <div class="caja-btn-bienv">
                    <div class="atras-btn"><a href="javascript: history.go(-1)"><i class="fas fa-chevron-left"></i>Ir atras</a> </div>
                    <div class="inicio-btn"><a href="/"><i class="fas fa-chevron-left"></i>Ir a inicio</a> </div>
                </div>
            </div>  
        )
    }
}

export default PaypalBody;
