import React, {Component} from 'react';
import {Progress} from 'reactstrap';


class ImageforUpload extends Component{
    constructor(props){
        super(props);
        this.deletephoto = this.deletephoto.bind(this);
    }


    deletephoto= e => {
        this.props.onImageDelete(this.props.id,this.props.index);
    }

    render(){
        return(
          <div class="col-" style={{height:"auto"}}>
                
                                
                    <img src={this.props.image_url} alt="Serviadvisor" class="img-gale img-thumbnail img-fluid" style={{maxHeight:"100%", width:"auto"}}/>
                           {this.props.name} {this.props.loaded && <i onClick={this.deletephoto} class="fas fa-times"></i>}
                           {this.props.error && <i onClick={this.deletephoto} class="fal fa-times">Error al subir</i>}  
                        {!this.props.loaded && (<Progress max="100" color="success" value={this.props.load} >{Math.round(this.props.load,2) }%</Progress>)} 
                
               
          </div>  
        );
    }
}

export default ImageforUpload;