import React, { Component } from 'react';
import Axios from 'axios';
import { toast } from 'react-toastify';
import axios from 'axios'
import notifications from '../../../utils/notifications'
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import 'react-toastify/dist/ReactToastify.css';
import Moment from 'react-moment';
import Loader from 'react-loader-spinner';

const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
const validpassword = RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})");


class AdministrarCuenta extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showpaypalbutton: false,
      paypalprofile: undefined,
      profileloaded: false,
      subscription: undefined,
      googleaccount: false,
      facebookaccount: false,
      serviadvisorlogin: false,
      googlemeail: "",
      facebookemail: "",
      localemail: "",
      email: '',
      emailsign: '',
      passwordsign: '',
      password: '',
      secondpassword: '',
      passwordlink: '',
      secondpasswordlink: '',
      validationText: '',
      errors: {
        email: true,
        emailsign: true,
        passdis: true,
        passdislink: true,
        passinvalid: true,
        passinvalidlink: true,
      },
      checkmail: false,
      checkofer: false,
      checknews: false,
      checkmessage: false,
      loaderSave: false,
      loadsuccess:false,
      loadsuccesspass:false,
      loadsuccessdelete:false,
      googleunlink:false,
      facebookunlink:false,
    }
    this.notify = this.notify.bind(this);
    this.checkpaypalProfile = this.checkpaypalProfile.bind(this);
    this.deletepaypalprofile = this.deletepaypalprofile.bind(this);
    this.cancelsubscription = this.cancelsubscription.bind(this);
    this.responseGoogle = this.responseGoogle.bind(this);
    this.responseFacebook = this.responseFacebook.bind(this);
    this.checkgoogleAccount = this.checkgoogleAccount.bind(this);
    this.checkFacebookAccount = this.checkFacebookAccount.bind(this);
    this.checkLocalAccount = this.checkLocalAccount.bind(this);
    this.checkaccounts = this.checkaccounts.bind(this);
    this.validateSecondPasswordlink = this.validateSecondPasswordlink.bind(this);
    this.handlechangepass = this.handlechangepass.bind(this);
  }

  appid = "AVlvws8g45bp8AXNWwa_X6TUdqX2jCS2suLJgK6HFJSMvO-eoRFAULhYSWQyU3R1CniFoPjShAfigUSP";
  redirect = "https://serviadvisor.com/paypal"


  componentDidMount() {
    this.checkpaypalProfile();
    this.checksubscription();
    this.checkaccounts();
    this.checkaccountconfig();
  }


  checkaccountconfig() {
    Axios.post('https://serviadvisor.com/checkaccountconfig', null, { withCredentials: true }).then(res => {
      const data = res.data;
      if (!data.error) {
        this.setState({
          checkmail: data.mail_notifications,
          checknews: data.newsletter,
          checkofer: data.events_offers,
          checkmessage: data.messages
        })
      }
    })
  }


  checkpaypalProfile() {
    Axios.post('https://serviadvisor.com/checkpaypalProfile', null, { withCredentials: true }).then(res => {
      const data = res.data;
      if (!data.error) {
        this.setState({
          paypalprofile: data,
          profileloaded: true
        })
      } else {
        this.setState({
          showpaypalbutton: true
        })
      }
    })
  }

  checksubscription() {

    Axios.post('https://serviadvisor.com/getsubscriptioninfo', null, { withCredentials: true }).then(res => {
      const data = res.data;
      if (!data.error) {
        this.setState({
          subscription: data
        })
      }
    })
  }


  cancelsubscription(id) {
    const data = {
      subscriptionId: id
    }

    Axios.post('https://serviadvisor.com/cancelSubscription', data, { withCredentials: true }).then(res => {
      const data = res.data;
      if (data.error) {
        toast.error(data.error)
      } else {
        this.checksubscription();
      }
    })
  }

  deletepaypalprofile = (event) => {
    Axios.post('https://serviadvisor.com/deletepaypalprofile', null, { withCredentials: true }).then(res => {
      this.setState({
        paypalprofile: undefined,
        profileloaded: false
      }, () => {
        this.checkpaypalProfile()
      })

    })
  }

  checkaccounts() {
    this.checkgoogleAccount();
    this.checkFacebookAccount();
    this.checkLocalAccount();
  }

  checkerrors() {
    if (!this.state.errors.emailsign) {
      return false;
    }

    if (!this.state.errors.passdis) {
      return false;
    }

    if (!this.state.errors.passinvalid) {
      return false;
    }


    return true;
  }

  handletextChange(name, event) {


    this.setState({ [name]: event.target.value });

    if (name === "secondpasswordsign") {
      this.validateSecondPassword(event);
    }

    if (name === "username") {
      this.validateUsername(event);
    }

    if (name === "password") {
      // this.validatePassword(event);
    }

    if (name === "passwordsign") {
      this.validatePassword(event, false);
    }

    if (name === "email") {
      this.validateemail(event, false);
    }

    if (name === "emailsign") {
      this.validateemail(event, true);
    }

    if (name === "passwordlink") {
      this.validatePassword(event, true);
    }

    if (name === "secondpasswordlink") {
      this.validateSecondPasswordlink(event);
    }

  }

  validateemail(event, sign) {
    const email = event.target.value
    const checkemail = validEmailRegex.test(event.target.value)
    const errors = this.state.errors
    if (sign) {
      errors.emailsign = checkemail
    } else {
      errors.email = checkemail
    }

    this.setState({ errors: errors });
  }


  validatePassword(event, link) {
    const password = event.target.value;
    let checkpass = validpassword.test(password);
    const errors = this.state.errors;
    if (link) {
      errors.passinvalidlink = checkpass;
    }
    else {
      errors.passinvalid = checkpass;
    }


    this.setState({
      errors
    })
  }

  validateSecondPassword(event) {
    const password = this.state.passwordsign;
    const second = event.target.value;


    let errors = this.state.errors;
    if (second === password) {
      errors.passdis = true;
    }
    else {

      errors.passdis = false;

    }
    this.setState({
      errors: errors
    })
  }

  validateSecondPasswordlink(event) {
    const password = this.state.passwordlink;
    const second = event.target.value;


    let errors = this.state.errors;
    if (second === password) {
      errors.passdislink = true;
    }
    else {

      errors.passdislink = false;

    }
    this.setState({
      errors: errors
    })
  }

  notify(data) {
    notifications.buildNotification(data.type, data.message, data.accountType, data.senderid, data.profileId);
  }

  checkgoogleAccount() {
    axios.post('https://serviadvisor.com/checkGooglelogin', null, { withCredentials: true }).then(res => {
      const data = res.data;
      if (data.status === "ok") {

        this.setState({
          googleaccount: true,
          googlemeail: data.email
        })
      }
    })
  }

  checkFacebookAccount() {
    axios.post('https://serviadvisor.com/checkfacebooklogin', null, { withCredentials: true }).then(res => {
      const data = res.data;
      if (data.status === "ok") {

        this.setState({
          facebookaccount: true,
          facebookemail: data.email
        })
      }
    })
  }

  checkLocalAccount() {
    axios.post('https://serviadvisor.com/checklocallogin', null, { withCredentials: true }).then(res => {
      const data = res.data;
      if (data.status === "ok") {

        this.setState({
          serviadvisorlogin: true,
          localemail: data.email

        })
      }
    })
  }


  handlechangepass(event) {
    event.preventDefault();

    if (!this.state.errors.passdislink) {
      return
    }

    if (!this.state.errors.passinvalidlink) {
      return
    }

    const newpass = {
      password: this.state.passwordlink,
    }

    axios.put('https://serviadvisor.com/changepass', newpass, { withCredentials: true }).then(response => {

      if (response.data.error) {
        const notify = () => toast.error(response.data.error);
        notify();
      } else {
        this.setState({
          passwordlink: "",
          secondpasswordlink: "",
          loadsuccesspass:true
        })
        this.checkaccounts();
        const notify = () => toast.info("Contraseña cambiada");
        notify();
      }

    })

  }


  handleSumitClick(event) {
    event.preventDefault()

    if (!this.checkerrors()) {
      return
    }

    const newuser = {
      accounttype: "cliente",
      email: this.state.emailsign,
      password: this.state.passwordsign,
    }

    axios.post('https://serviadvisor.com/singuplink', newuser, { withCredentials: true }).then(response => {

      if (response.data.error) {
        const notify = () => toast.error(response.data.error);
        notify();
      } else {
        this.checkaccounts();



      }

    })
  }



  responseFacebook = (response) => {

    const data = {
      accounttype: "cliente",
      access_token: response.accessToken
    }


    axios.post('https://serviadvisor.com/oauth/facebooklink', data, { withCredentials: true }).then(res => {

      if (res.data.error) {
        const notify = () => toast.error(res.data.error);
        notify();
      } else {
        this.checkaccounts();
        const data = res.data;
        const userdata = {
          userid: data.userid,
          username: data.username,
          photo_url: data.photo_url,
          active: '',
          accountType: data.accountType
        }


      }
    }).catch(error => {
      const notify = () => toast("error");
      notify();
    });


  }


  responseGoogle = (response) => {

    const data = {
      accounttype: "cliente",
      access_token: response.accessToken
    }



    axios.post('https://serviadvisor.com/oauth/googlelink', data, { withCredentials: true }).then(res => {

      if (res.data.error) {
        const notify = () => toast.error(res.data.error);
        notify();
      } else {
        this.checkaccounts();
        const data = res.data;
        const userdata = {
          userid: data.userid,
          username: data.username,
          photo_url: data.photo_url,
          active: '',
          accountType: data.accountType
        }



      }



    })


  }




  notify(data) {
    notifications.buildNotification(data.type, data.message, this.props.user.accountType, data.senderid, data.profileId);
  }


  handleonchangecheckmail(e) {
    const value = e.target.checked;

    this.setState({
      checkmessage: value
    })
  }


  handleonchangechecknews(e) {
    const value = e.target.checked;

    this.setState({
      checknews: value
    })
  }

  handleonchageofer(e) {
    const value = e.target.checked;

    this.setState({
      checkofer: value
    })
  }


  handlesubmitsettings(e) {
    e.preventDefault();


    this.setState({
      loadsuccess:false
    })

    const data = {
      mailNotify: this.state.checkemail,
      ofernotify: this.state.checkofer,
      newsnotify: this.state.checknews,
      messagenotify: this.state.checkmessage
    }

    this.setState({
      loaderSave: true
    })
    Axios.put('https://serviadvisor.com/updateaccountconfig', data, { withCredentials: true }).then(res => {
      const data = res.data;
      if (!data.error) {
        this.setState({
          loaderSave: false,
          loadsuccess:true
        })
      }
    })

  }

  handledeleteaccount(e){


    e.preventDefault();


    if(this.state.subscription){
      const notify = () => toast.error("Cancela Tu subscripcion Antes de Eliminar Tu Cuenta");
      notify();
    }else{
      axios.post('https://serviadvisor.com/deleteaccount', null, {withCredentials:true}).then( res => {
        const data = res.data;
      if(!data.error){
        this.setState({
          loadsuccessdelete:true
        })
        const notify = () => toast.success("Se ha enviado a tu direccion de correo un link de eliminacion");
      notify();
      }else{
        const notify = () => toast.error(" No Se ha podido enviar a tu direccion de correo el link de eliminacion");
        notify();
      } 
     

      })
    }
  
  }


  handlegoogleunlinkconfirm(){

    axios.post('https://serviadvisor.com/unlinkgoogleaccount',null,{withCredentials:true}).then( res => {
      const data = res.data;
      if(!data.error){
        this.setState({
          googleunlink:false,
          googleaccount: false,
        })

        this.checkgoogleAccount();

      }else{
        const notify = () => toast.error(data.error);
        notify();
      }
    })

   
  }

  handlegoogleunlinkcancel(){
    this.setState({
      googleunlink:false
    })
  }

  handlefacebookunlinkconfirm(){

    axios.post('https://serviadvisor.com/unlinkfacebookaccount',null,{withCredentials:true}).then( res => {
      const data = res.data;
      if(!data.error){
        this.setState({
          facebookunlink:false,
          facebookaccount:false
        })

        this.checkFacebookAccount();

      }else{
        const notify = () => toast.error(data.error);
        notify();
      }
    })




 
  }

  handlefacebookunlinkcancel(){
    this.setState({
      facebookunlink:false
    })
  }


  handleunlinkbutton(type){
    if(type==="google"){
      this.setState({
        googleunlink:true
      })
    }else{
      this.setState({
        facebookunlink:true
      })
    }
  }

  render() {
    return (
      <div class="contenido-configuracion-cuenta">


        <div class=" blog-ct let-client-sec">
          <div class=" ">

            <div class="title-perfil-empresa espacio-top">
              <h4 class="azul"> Administra tu Cuenta</h4>
            </div>


            <div class="caja-campo">

            <div class="row centrado bgri rom " > 
              <div class="col-md-12"><h4>Login de Serviadvisor</h4>
                {this.state.serviadvisorlogin ?
                  <div class="caja-campo lat-admin">
                    <h5>Credenciales locales Creadas : {this.state.localemail}</h5>
                    <h5>Cambiar Tu Contraseña</h5>
                    <input onChange={(e) => this.handletextChange("passwordlink", e)} type="password" class="form-control perfil-cliente-ed" id="nombre" placeholder="Contraseña" value={this.state.passwordlink} />
                    {!this.state.errors.passinvalidlink && (<div class="row"><i class="fas fa-times"></i><span class="respu">La contraseña debe contener al menos: una letra mayuscula, una letra minuscula, un numero y ser mayor de 6 caracteres</span></div>)}
                    <input onChange={(e) => this.handletextChange("secondpasswordlink", e)} type="password" class="form-control perfil-cliente-ed" id="nombre" placeholder="Contraseña" value={this.state.secondpasswordlink} />
                    {!this.state.errors.passdislink && (<div class="row"> <span class="respu">Las contraseñas no coinciden</span></div>)}
                    <button onClick={e => this.handlechangepass(e)} type="button" value="Cambiar Contraseña" class="btn btn-primary btn-cuent">Cambiar</button>
                    {this.state.loadsuccesspass && (<span class="alert"><i class="fas fa-check-circle"></i> Actualizacion realizada con exitó.</span>)}
                  </div>
                  :
                  <div>
                    <div className="con-form">
                      <div className="tit-ses">Ingresa con tu dirección de correo electrónico</div>
                      <input type="text" className="form-control" id="email" placeholder="Email" onChange={(e) => this.handletextChange("emailsign", e)} />
                      {!this.state.errors.emailsign && (<div class="row"> <span class="respu">Por favor ingrese un correo valido</span></div>)}
                      <input type="password" className="form-control" id="contraseña" placeholder="Contraseña" onChange={(e) => this.handletextChange("passwordsign", e)} />
                      {!this.state.errors.passinvalid && (<div class="row"><i class="fas fa-times"></i><span class="respu">La contraseña debe contener al menos: una letra mayuscula, una letra minuscula, un numero y ser mayor de 6 caracteres</span></div>)}
                      <input type="password" className="form-control" id="contraseña" placeholder="Confirmar Contraseña" onChange={(e) => this.handletextChange("secondpasswordsign", e)} />
                      {!this.state.errors.passdis && (<div class="row"> <span class="respu">Las contraseñas no coinciden</span></div>)}
                      <button onClick={e => this.handleSumitClick(e)} type="button" class="btn btn-primary btn-cuent">Crear Login Serviadvisor</button>
                    </div>
                    <hr class="naranja-lin" />
                  </div>
                }

              </div>

    </div>
     <div class="row bgri rom">  

     


              <div class="col-md-6"> <div class="title-perfil-empresa espacio-top"><h4>Cuenta De Facebook</h4>  </div>
                {this.state.facebookaccount ?
                  <div class="tit-cuentas-vinculadas">
                    <div class="alert alert-info " role="alert">
                    <h5>Cuenta de Facebook vinculada :</h5> <p>{this.state.facebookemail}</p>
                    <button onClick={(e) => this.handleunlinkbutton("facebook")} className={"btn-success desvincular"}>Desvincular </button>
</div>
{this.state.googleunlink && <div>
  <button onClick={(e) => this.handlefacebookunlinkconfirm()} className={"btn-success desvincular-"}>Confirmar</button>
  <button onClick={(e) => this.handlefacebookunlinkcancel()} className={"btn-success desvincular-"}>Cancelar</button></div> }
                    </div>
                  :
                  <FacebookLogin
                    appId="311566446451744"
                    autoLoad={false}
                    fields="name,email,picture"
                    onClick={this.componentClicked}
                    render={renderProps => (
                      <button class="btn btn-social ext-reg-btn fb-btn" data-pa-name="intro_login_fb" onClick={renderProps.onClick}>
                        <div class="text-con"> <i class="fab fa-facebook-square"></i> <span> Continuar con Facebook</span> </div>
                      </button>

                    )}
                    //onClick={this.componentClicked}
                    callback={this.responseFacebook}></FacebookLogin>
                }

             
              </div>


              <div class="col-md-6">
              <div class="title-perfil-empresa espacio-top"><h4>Cuenta De Google</h4>  </div>
                {this.state.googleaccount ?
                  <div class="tit-cuentas-vinculadas">
                                      <div class="alert alert-info " role="alert">
                    <h5>Cuenta de Google vinculada :</h5> <p>{this.state.googlemeail}</p>
                    <button onClick={(e) => this.handleunlinkbutton("google")} className={"btn-success desvincular"}>Desvincular </button>
</div>
{this.state.googleunlink && <div>
  <button onClick={(e) => this.handlegoogleunlinkconfirm()} className={"btn-success desvincular-"}>Confirmar</button>
  <button onClick={(e) => this.handlegoogleunlinkcancel()} className={"btn-success desvincular-"}>Cancelar</button></div> }

                  </div>

                  :
                  <GoogleLogin
                    clientId="154537549270-knt6kr4a3epb30eed3ntrcl35dtsnt1p.apps.googleusercontent.com"
                    buttonText="Login Con Google"
                    render={renderProps2 => (
                      <button class="btn btn-social ext-reg-btn gp-btn" data-pa-name="intro_login_google" id="gp-auth-btn" onClick={renderProps2.onClick} >
                        <div class="text-con"> <i class="fab fa-google-plus"></i> <span>Continuar con Google</span> </div> </button>

                    )}
                    onSuccess={this.responseGoogle}
                    onFailure={this.responseGoogle}
                    cookiePolicy={'single_host_origin'}
                  ></GoogleLogin>
                }
              
              </div>
              <hr class="naranja-lin" />
       </div>


<div class="row centrado bgri rom">  

              <div class="col-md-12">
              <div class="title-perfil-empresa espacio-top"><h4>Deseo recibir un email:</h4>  </div>
              
                <div class="formulario"><div class="checkbox">
                  <input onChange={e => this.handleonchangecheckmail(e)} checked={this.state.checkmessage} type="checkbox" name="notificaciones" id="notificaciones_correo" /><label for="notificaciones_correo" class="negrilla">Cuando me envíen un mensaje</label><br />
                </div></div>



                {/*
              <div class="formulario"><div class="checkbox">
              <input onChange={e => this.handleonchangechecknews(e)} checked={this.state.checknews} type="checkbox" name="info" id="info" /><label for="info" class="negrilla">Deseo recibir un información de novedades.</label><br />
              </div></div>
*/}

                <div class="formulario"><div class="checkbox">
                  <input onChange={e => this.handleonchageofer(e)} checked={this.state.checkofer} type="checkbox" name="ofertas" id="ofertas" /><label for="ofertas" class="negrilla">Cuando me envíen una oferta de trabajo.</label><br />
                </div></div>
                <br></br>
                <button onClick={e => this.handlesubmitsettings(e)} class="btn btn-primary btn-cuent" type="Button" > Guardar <Loader
                  visible={this.state.loaderSave}
                  type="Oval"
                  color="#00BFFF"
                  height={25}
                  width={25}

                /></button>

                {this.state.loadsuccess && (<span class="alert"><i class="fas fa-check-circle"></i> Actualizacion realizada con exitó.</span>)}

              </div>


      </div>
      <div class="row  bgri rom">  

              <div class="col-md-6 sep-arriba">
              <div class="title-perfil-empresa espacio-top"><h4>Datos de cuenta Paypal</h4>  </div>
                
                {this.state.showpaypalbutton && (<a href={" https://www.sandbox.paypal.com/connect/?client_id=" + this.appid + "&response_type=code&scope=openid profile email address https://uri.paypal.com/services/paypalattributes&redirect_uri=" + this.redirect}>
                  <img src="https://www.paypalobjects.com/webstatic/en_US/developer/docs/login/connectwithpaypalbutton.png"></img></a>)}

                {this.state.profileloaded &&
                  (
                    <div class="datos-cuenta-paypal">
                      <p><b>Correo Cuenta de Paypal</b>  : {this.state.paypalprofile.email}</p>
                      <p><b>Payer ID Paypal</b> : {this.state.paypalprofile.buyer_id}</p>
                      
                      <p><button onClick={this.deletepaypalprofile} class="btn btn-success">Desvincular Cuenta de Paypal</button></p>
                    </div>
                  )}
                  </div>  

            <div class="col-md-6 sep-arriba">
                {this.state.subscription ?
                  <div>
                    <div class="title-perfil-empresa espacio-top"><h4>Suscripción</h4>  </div>
                   
                    <div>Tu PLan : {this.state.subscription.subscription.subscriptionPlan.name}</div>

                    <div>Status : {this.state.subscription.paypalSubscription.status}</div>
                    <div>Id Subscripcion : {this.state.subscription.paypalSubscription.id}</div>
                    <div>Id Plan Paypal : {this.state.subscription.paypalSubscription.plan_id}</div>
                    {this.state.subscription.paypalSubscription && (<div>Ultimo Pago : <Moment format={"DD MMM YYYY hh:mm a"}>{this.state.subscription.paypalSubscription.billing_info.last_payment.time}</Moment></div>)}
                    <button onClick={(e) => this.cancelsubscription(this.state.subscription.subscription.subscriptionId)} type="button" value="Cancelar Subscripcion" class="btn btn-success btn-cuent">Cancelar Subscripcion</button>
                  </div>
                  :
                  <div>

                    
<div class="title-perfil-empresa espacio-top"><h4>Suscripción</h4>  </div>
                  <div>Plan Free</div>
                
                  </div>
                }

               

              </div>


             


            </div>
          </div>
          </div>
          <div class="caja-eliminar alert-danger">
                  <h4>Eliminar Mi Cuenta en Serviadvisor</h4>
                  <button onClick={(e) => this.handledeleteaccount(e)} class="btn btn-success btn">Eliminar</button>
                  <div class="tit-elim">  A tu correo sera enviado un enlace para poder eliminar tu cuenta</div>
                  {this.state.loadsuccessdelete && (<span class="alert"><i class="fas fa-check-circle"></i> Se ha enviado un mensaje a tu cuenta de correo.</span>)}
           </div>



        </div>


      </div>
    );
  }
}

export default AdministrarCuenta;