import React, {Component} from 'react';
import axios from 'axios';
import Moment from 'react-moment';
import notifications from '../../../utils/notifications';


class pagos extends Component{
    constructor(props){
        super(props)

        this.state={
            payouts:[]
        }

        this.getpayouts= this.getpayouts.bind(this);
    }   

    getpayouts(){
        axios.post('https://serviadvisor.com/getpayouts',null,{withCredentials:true}).then(res => {
            const data = res.data;
            if(!data.error){
                this.setState({
                    payouts:data
                })
            }
        })
    }

    componentDidMount(){
        this.getpayouts()
        this.props.sockets.sockets.callback = this.refreshdata
    }

    refreshdata(data) {
        notifications.buildNotification(data.type, data.message, data.accountType, data.senderid, data.profileId,data.source);
    }

    render(){
        return(

            <div class="contenido-pagos">
               <div class="alert alert-info" role="alert">
                    
                    <h5>Más cliente este satisfecho, Más pagos. </h5> 
                 
                </div>

            
           
            <div class="title-perfil-empresa espacio-top"><h4 class="azul"> Pagos</h4></div>
            <div class="ofertas-anteriores">
           {this.state.payouts.map((item,index)=> {
               return(
                <div class="propue">
                       <div class="ofertas-1">
                    <div class="ofertas-1-int-gan">
                            <div><b>Cliente:</b> {item.client.user.username}</div>
                            <div><b>Oferta: </b>{item.offert.description}</div> 
                            
                            <div><b>payout_batch_id "Paypal":</b> {item.payout_batch_id}</div>
                            

                            <div class="oferta-fech-pagado alert-info">
                                <div><b>Pagado :  </b>  <Moment format={"DD MMM YYYY hh:mm a"}>{item.createdAt}</Moment> </div>
                            </div>                   
                        </div>
                        <div class="oferta-caj-2">
                        <div class="oferta-valor-gan"> Ganacias: <b>$ {item.offert.amount}</b></div>
                       
</div>

                        </div>
                 </div>      
                   
               )
           })}

        </div>
       </div>
        )
    }
}

export default pagos;