import React,{Component, useEffect} from 'react';
import Calendar from '../../components/calendar/calendar';
import moment from 'moment';
import notifications from '../../utils/notifications'
import axios from 'axios';
import AssignmentView from '../chat/assigmentview';
import { Modal, Button } from 'react-bootstrap';
import Moment from 'react-moment';
import Script from 'react-load-script';
import Loader from 'react-loader-spinner';



import DatePicker from 'react-datepicker';
import setMinutes from "date-fns/setMinutes";
import setHours from "date-fns/setHours";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/es';


import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css'

class clientCalendar extends Component{
    constructor(props){
        super(props)
        this.state = {
            assignations: [],
            assignationsloaded: false,
            clientData:[],
            offers:[],
            events:[],
            activePay:false,
            cropmodalshow: false,
            currentoffer:undefined,
            currentassigment:undefined,
            modaldateshow: false,
            loaderSave: false,
            startDate: new Date(),
        }
        this.refreshdata = this.refreshdata.bind(this);
        this.checkclient = this.checkclient.bind(this);
        this.showofferinfo = this.showofferinfo.bind(this);
        this.renderbutton = this.renderbutton.bind(this);
        this.offerhaspay = this.offerhaspay.bind(this);
        registerLocale('es', es)
        this.openmodalDate = this.openmodalDate.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.reassignDate = this.reassignDate.bind(this);
        this.checkproviderpaymentprofile= this.checkproviderpaymentprofile.bind(this);
    }


    handleChange(date) {
        this.setState({
          startDate: date
        });
      }
    
      openmodalDate = (assigment) =>  {
        this.setState({
          modaldateshow : true,
          offertId:assigment.offert.id,
          currentassigment:assigment
        })
      }

      reassignDate = () =>{
        
        const data = {
            date: this.state.startDate,
            offertId: this.state.currentassigment.offert.id,
            assignationId: this.state.currentassigment.id
          }

          axios.post('https://serviadvisor.com/reassigndate/',data,{withCredentials:true}).then(res => {
            this.checkclient();
            this.setState({
                modaldateshow:false
            })
          })

        
    }

 
    showofferinfo = (event) => {
        this.setState({
          cropmodalshow:true,
          currentoffer:event.offer,
          activePay:false
        }, () => {
          if(event.offer.offertStatusId!==5){
            this.checkproviderpaymentprofile(event.offer.providerId, event.offer);
          }
        })

      
       
      
      }


      checkproviderpaymentprofile(providerid,offer){
        console.log(providerid);
        const data={
            providerId:providerid
        }
        axios.post('https://serviadvisor.com/checkifpaymentprofile',data,{withCredentials:true}).then(res => {
            const resdata = res.data;
            if(!resdata.error){
               
                this.setState({
                    activePay:true
                }, () => {
                  this.renderbutton(offer,this.offerhaspay);
                  this.setState({
                    loaderSave:true
                  })
                })
            }
        })
    }

    componentDidMount() {
        this.checkclient();
        this.props.sockets.callback= this.refreshdata;
    }

    offerhaspay(order,offer){
        const data={
            order_id:order.id,
            payer_id:order.payer.payer_id,
            email:order.payer.email_address,
            offertId:offer.id,
            providerId:offer.providerId,
            clientId:offer.clientId
        }

        axios.post('https://serviadvisor.com/checkorder',data,{withCredentials:true}).then(res => {
            console.log(res.data);
            this.setState({
              cropmodalshow:false,
              currentoffer:undefined
            })
            this.checkclient();
        })
    }

   renderbutton(offer,cb) {
      const amount= offer.amount;
      console.log(amount)
       setTimeout(() =>{
         this.setState({
           loaderSave:false
         })
        window.paypal.Buttons({
            createOrder: function(data, actions) {
              // Set up the transaction
              return actions.order.create({
                purchase_units: [{
                  amount: {
                    value: amount
                  }
                }]
              });
            },
            onApprove: function(data, actions) {
                return actions.order.capture().then(function(details) {
                
                  // Call your server to save the transaction
                
                    cb(details,offer);
                });
              }
          }).render('#paypal-button-container');
       })
 
   }

    refreshdata(data){
        console.log("refresh chats")
        if(data.type==="assigment"){
            this.checkclient();
            notifications.buildNotification(data.type, data.message, data.accountType, data.senderid, data.profileId,data.source);
            
        }else{
           notifications.buildNotification(data.type, data.message, data.accountType, data.senderid, data.profileId,data.source);
        }
        
    }

    generateevents(){
        let events =[]

        this.state.offers.map((item,index)=>{
            let event = {
            id:item.serviceAssingment.id,
            title: "Oferta "+ item.provider.user.username + ":" + item.offert_status.name,
            allDay:false,
            start: new Date(item.serviceAssingment.AssigmentDate),
            end: moment(new Date(item.serviceAssingment.AssigmentDate) ).add(1, 'hours').toDate(),
            offer:item
        }

            events.push(event);

        })

        this.setState({
            events:events
        })

        console.log(events);


    }

    
    getoffersAssigned = (clientId) => {
        const data ={
            clientId:clientId
        }
        axios.post('https://serviadvisor.com/viewclientoffersconfirm',data,{withCredentials:true}).then(res =>{
            const data = res.data;
            if(!data.error){
                console.log(data);
                this.setState({
                    offers:data
                }, () => {
                    this.generateevents()
                })
            }
        })
    }


    

    confirmdate = (offertId) =>{
        const data ={
            offertId:offertId
        }
       axios.post('https://serviadvisor.com/confirmassignation',data,{withCredentials:true}).then(res => {
          const data = res.data;
          if(!data.error){
             this.checkclient();
          }
       })
    }

    checkclient() {
        axios.post('https://serviadvisor.com/checkclient/',null ,{ withCredentials: true }).then(res => {

            const client = res.data;
            if (client.error) {

            } else {
                this.setState(
                    {
                        clientData:client
                    }
                )
                this.getassigments(client.id);
                this.getoffersAssigned(client.id);
            }
        });
    }


    getassigments(clientId) {
        const data = {
            clientId:clientId
        }

        axios.post('https://serviadvisor.com/clientpendingassignationsOnly', data, { withCredentials: true }).then(res => {
        const assignations= res.data ;
        console.log(res);
        this.setState({
                assignations:assignations,
                assignationsloaded:true
            })
        })
    }



    eventdefault = [
     
      ];
    
    render(){
        let modalClose = () => this.setState({ cropmodalshow: false });
        let modalClosedate = () => this.setState({ modaldateshow: false });
        return(

<div class="s-caj">
<div class="aviso-ayuda" >
                    <div class="btn-ayuda-pop" data-toggle="modal" data-target="#myModal-ayuda-cli" data-dismiss="modal" >
                    Guía Negociación (AYUDA) -    <i class="fas fa-question-circle" ></i>    </div>
                </div>   
                
          <div class="secion-caja-cal">



             <div class="se-caja-calen">
                <div class="contenido-calendario">
                    <div class="title-perfil-cliente espacio-top"> <h4 class="azul"> <span class="bg-blanco">Calendario</span> <hr class="naranja"/> </h4>  </div>
                </div>
                <div >
                    <Calendar showofferinfo={this.showofferinfo} events={this.state.events}></Calendar>
                </div>
            </div>

            <div class="contenido-asignaciones se-caja-pendientes">
                <div >
                <div class="title-perfil-cliente espacio-top"> <h4 class="azul"> <span class="bg-blanco">Asignaciones pendientes</span> <hr class="naranja"/> </h4>  </div>
                  
                   
                </div>
                <div >
                    {!this.state.assignationsloaded && ("Cargando Asignaciones")}
                    {this.state.assignations.map((item, index) => {
                        return (
                           
                           <AssignmentView onReasign={this.openmodalDate} type={"client"} offer={item} onConfirm={this.confirmdate}></AssignmentView>
                        )
                    })}

                </div>
            </div>

            </div>





            
            <Modal className="recorte pro" show={this.state.cropmodalshow} onHide={modalClose}
          {...this.props}
          size="lg"
          animation={false}

          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Informacion De la Oferta
              <hr />
                <div className="flecha-down"></div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
                    
            <div>
           
            
            {this.state.currentoffer && (<div>
            <p>Cliente : {this.state.currentoffer.client.user.username}</p>
            <p>Detalle:{this.state.currentoffer.description} </p>
            <p>Direccion: </p>
            <p>Precio: {this.state.currentoffer.amount}</p>
            <div class="oferta-estado-apro"><b>{this.state.currentoffer.offert_status.name}</b> </div>
            <p>fecha De Asignacion : <Moment format={"DD MMM YYYY hh:mm a"}>{this.state.currentoffer.serviceAssingment.AssigmentDate}</Moment> </p>
            
            <Loader
          visible={this.state.loaderSave}
          type="TailSpin"
          color="#00BFFF"
          height={50}
          width={50}
       
        />
            
            {this.state.activePay ? (
                 <div id="paypal-button-container"></div>
            ) :
             "Contacta Con el proveedor para definir la forma de Pago"}
           
           
          
            </div>)}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={modalClose} className={"btn-success"}>Cerrar</Button>
          </Modal.Footer>
        </Modal>


        <Modal className="recorte pro-cal" show={this.state.modaldateshow} onHide={modalClosedate}
              {...this.props}
              size="lg"
              animation={false}
    
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  Selecciona una fecha
                  <hr />
                <div className="flecha-down"></div>
              </Modal.Title>
              </Modal.Header>
              <Modal.Body>
    
              <div class="caja-calendario-popup">
                  <DatePicker
                    locale="es"
                    inline
                    selected={this.state.startDate}
                    onChange={this.handleChange}
                    showTimeSelect
                    dateFormat="MMMM d, yyyy h:mm aa"
                  />
                  <p class="text-calen">
                    <span>{"Fecha Seleccionada: " + this.state.startDate}</span>
                  </p>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={this.reassignDate} className={"btn-primary"}>Aceptar</Button>
                <Button onClick={modalClosedate} className={"btn-success"}>Cerrar</Button>
              </Modal.Footer>
            </Modal>
    

        </div>
        );
    }
}

export default clientCalendar;