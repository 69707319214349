import React, { Component } from 'react';
import axios from 'axios';
import notifications from '../../utils/notifications';
import ReactStars from 'react-stars';
import Moment from 'react-moment';

class clientFavs extends Component {
    constructor(props) {
        super(props)
        this.state = {
            favs: []
        }

        this.getavs = this.getavs.bind(this);
        this.notify = this.notify.bind(this);
    }

    componentDidMount(){
        this.props.sockets.callback= this.notify
      }
  
      notify(data){
        notifications.buildNotification(data.type, data.message, data.accountType, data.senderid, data.profileId,data.source);
        
      }



    handledeletefav(favId) {
        const data = {
            favId: favId
        }
        axios.post('https://serviadvisor.com/unfav', data, { withCredentials: true }).then(res => {
            const data = res.data;
            if (!data.error) {
                this.getavs();
            }
        })
    }

    componentDidMount() {
        this.getavs();
    }

    getavs() {

        axios.post('https://serviadvisor.com/getfav', null, { withCredentials: true }).then(res => {
            const data = res.data;
            console.log(data)
            if (!data.error) {
                this.setState({
                    favs: data
                })
            }
        })
    }

    render() {
        return (
            <div>
            <div class="title-perfil-cliente espacio-top"> <h4 class="azul"> <span class="bg-blanco">Favoritos</span> <hr class="naranja"/> </h4>  </div>
              
            <div className="cont-favo-emp">
                {this.state.favs.map((item, index) => {
                    return (
                        <div className="favo-emp">
                             <div className="tit-fot-cua">
                                {item.provider.user.photo_url && (<a href={"/empresa/" + item.providerId + "/perfil"}> <img src={item.provider.user.photo_url} alt="pav" /></a>)}
                                {!item.provider.user.photo_url && (<a href={"/empresa/" + item.providerId + "/perfil"}> <img src="/images/fotoperfil.png" alt="pav" /></a>)}
                                </div>
                                <div className="con-dat-fav"> 
                                    <div className="con-tit-dat-fav">
                                    {item.provider.business_name}   
                                    </div>

                                <div className="califica-fav">
                                <ReactStars
                                                            count={5}
                                                            edit={false}
                                                            size={20}
                                                            half={false}
                                                            value={item.provider.rating}
                                                            color2={'#ffd700'} />
                                                            </div>

                            <a onClick={(e) => this.handledeletefav(item.id)} href="javascript:void(0);" class="btn-quitar">Quitar favorito </a>
                            <a href={"/empresa/" + item.providerId + "/perfil"} class="btn-cont"> CONTACTAR </a>
                            </div>


                        </div>
                    )
                })}
            </div>
        </div>
        );
    }
}

export default clientFavs;