import React, { Component } from 'react';
import notifications from '../../../utils/notifications';
import ReactPlayer from 'react-player';
import Slider from "react-slick";
import axios from 'axios';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class VideoSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            play: false,
            url: "http:/video.pm4",
            videolist: [],
            items:[]
        }
        this.notify = this.notify.bind(this);
        this.getvideolist = this.getvideolist.bind(this);
        this.getimagethum = this.getimagethum.bind(this);
    }

    componentDidMount() {

        this.props.sockets.sockets.callback = this.notify
        this.getvideolist();
    }

    notify(data) {
        console.log(this.props.user);
        notifications.buildNotification(data.type, data.message, this.props.user.accountType, data.senderid, data.profileId);
    }

    setitems(){
        const items = Object.assign([], this.state.videolist);
        let itemthumb = [];

        items.map((item,index) => {
            const tempitem = {
                id:item.id,
                name:item.name,
                url:item.url,
                video_type:item.video_type,
                order:item.order,
                thumb:""

            }

           
            itemthumb.push(tempitem);
        })

        this.setState({
            items:itemthumb
        },() => {
            this.assignthumbs();
        })


        

    }

    assignthumbs(){
        this.state.items.map((item,index) => {
            this.getimagethum(index,item.url,item.video_type)
        })
    }


    getvideolist() {
        axios.get('https://serviadvisor.com/getvideos/' + this.props.providerId, { withCredentials: true }).then(res => {
            const videodata = res.data;
            this.setState({
                videolist: videodata
            }, () => {
                this.setitems();

                if(this.state.videolist.length > 0){

                    let url = this.state.videolist[0].url

                    this.setState({
                        play:true,
                        url:url
                    })
                }
            })
        })
    }


    handleclickvideo (url, evt){
        evt.preventDefault();

        this.setState({
            play:true,
            url:url
        })
    }

    async getimagethum(index,url, type) {

        let thumburl="";

        if (type === "youtube") {

            var ID = '';
            url = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
            if (url[2] !== undefined) {
                ID = url[2].split(/[^0-9a-z_\-]/i);
                ID = ID[0];
            }
            else {
                ID = url;
            }

            thumburl = "https://img.youtube.com/vi/" + ID + "/default.jpg";
            

        } else if(type ==="vimeo"){

           var regExp = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/
           let parseUrl = regExp.exec(url)
            let id = parseUrl[5];
            let vimeourl="";
             await axios.get("https://vimeo.com/api/v2/video/"+ id + ".json").then(res => {
                
               
                thumburl= res.data[0].thumbnail_medium;
            })
         
        }

        const items = Object.assign([], this.state.videolist);

        items[index].thumb= thumburl;

        this.setState({
            items
        })

      
    }



    render() {

        const settings = {
            dots: true,
            infinite: this.state.items.length > 4,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1, vertical:false,
            responsive: [{ breakpoint: 768, settings: { slidesToShow: 2 } },
            { breakpoint: 990, settings: { slidesToShow: 2 ,infinite: this.state.items.length > 4} },
            { breakpoint: 1024, settings: { slidesToShow: 4 , infinite: this.state.items.length > 4} },
            { breakpoint: 1368, settings: { slidesToShow: 4 ,infinite: this.state.items.length > 4} },
            { breakpoint: 1600, settings: { slidesToShow: 4 ,infinite: this.state.items.length > 4} },
            { breakpoint: 100000, settings: { slidesToShow: 4 ,infinite: this.state.items.length > 4} }

            ]
        };
        return (
            <div class="caja-list-vid">
                <div class="caja-player">
                    <div class="player">                <ReactPlayer url={this.state.url}
                        controls={true}
                        playing={this.state.play} />
                    </div>

                    <Slider {...settings}


                    >

                        {this.state.items.map((item, index) =>
                            <div>
                               <div onClick={evt => this.handleclickvideo(item.url,evt)}>
                                <img src={this.state.items[index].thumb}></img>
                                </div>
                            </div>

                        )}

                    </Slider>


                </div>

            </div>
        )
    }
}

export default VideoSection;