import React, { Component } from 'react';
import axios from 'axios';
import Moment from 'react-moment';
import Loader from 'react-loader-spinner';


class businessHours extends Component {
    constructor(props) {
        super(props)

        this.state = {
            bussinessHours: [],
            days: [],
            loaderSave:false,
            loadsuccess:false
        }


    }



    handlecheckbox(e, index) {
        const value = e.target.checked;



        if (value) {
            this.addfhours(index);
        } else {
            this.clearhours(index);
        }
    }

    
    handlecheckboxadd(e, index) {
        const value = e.target.checked;



        if (value) {
            this.addfhours(index,true);
        } else {
            this.clearShour(index);
        }
    }

    clearShour(index) {
        const days = this.state.days;
        days[index].hours = days[index].hours.splice(0,1);

        this.setState({
            days
        }
        )

    }

    clearhours(index) {
        const days = this.state.days;
        days[index].hours = [];

        this.setState({
            days
        }
        )

    }


    addfhours(index, second) {
        const days = this.state.days;
        let newhour={}

        if(!second){
             newhour = {
                starthour: "08:00",
                endhour: "12:00"
            }
        }else{
             newhour = {
                starthour: "14:00",
                endhour: "18:00"
            }
        }

       
        days[index].hours.push(newhour);

        this.setState({
            days
        })
    }


    setdays(providerdays){
        let setdays={}
        if(providerdays ==="" || providerdays === undefined || providerdays === null){
            setdays=[
                {
                    dayname: "lunes",
                    daynumber: 1,
                    hours: []
                },
                {
                    dayname: "martes",
                    daynumber: 2,
                    hours: []
                },
                {
                    dayname: "miércoles",
                    daynumber: 3,
                    hours: []
                },
                {
                    dayname: "jueves",
                    daynumber: 4,
                    hours: []
                },
                {
                    dayname: "viernes",
                    daynumber: 5,
                    hours: []
                },
                {
                    dayname: "sábado",
                    daynumber: 6,
                    hours: []
                },

                {
                    dayname: "domingo",
                    daynumber: 7,
                    hours: []
                },

            ]
        }
        else{
            setdays = providerdays
        }

       this.setState({
           days:setdays
       })
      
    }




    handleonchangueHour(e,dayindex,hourindex, start){
        const hour = e.target.value;

        const days = this.state.days;
        if(start){
            days[dayindex].hours[hourindex].starthour=hour
        }else{
            days[dayindex].hours[hourindex].endhour=hour
        }

        this.setState({
            days
        })
        
        console.log(days);
    }

    getbusinesshours(){
        
    }

    sendbusinesshours(){

        this.setState({
            loaderSave:true,
            loadsuccess:true
        })


        const data={
            business_hours : this.state.days
        }
        axios.post('https://serviadvisor.com/updatebusinessHours', data, {withCredentials:true}).then(res => {
            const data = res.data;
            if(!data.error){
                this.setState({
                    loaderSave:false,
                    loadsuccess:true
                })
            }
        })
    }

    handleaddhours(index){
        const days = this.state.days;
        let newhour={}



        if(!days[index].hours.length>=1){
             newhour = {
                starthour: "08:00",
                endhour: "12:00"
            }
        }else{
             newhour = {
                starthour: "14:00",
                endhour: "18:00"
            }
        }

        
        if(days[index].hours.length<2){
            days[index].hours.push(newhour);

        }
      
        this.setState({
            days
        })
    }


    handleremovehours(indexday, indexhour){
        const days = this.state.days;

       
       
        days[indexday].hours.splice(indexhour,1)

       

        this.setState({
            days
        })

    }


    componentDidMount() {
        axios.get('https://serviadvisor.com/checkprovider', { withCredentials: true }).then(res => {

            const provider = res.data;
            if (provider.error) {
      
            } else {

                this.setdays(provider.business_hours)

             
            }
          });
    }

    render() {
        return (

            <div class="caja-hor">

             
                <div class="title-perfil-empresa espacio-top"><h4 class="azul"> Horario de Atención</h4></div>
                <p>Agrega tu horario comercial para que las personas puedan planificar fácilmente tu visita </p>
                <div class="caja-horario">
                {this.state.days.map((item, index) =>

                    <div class="caja-dia">

                    <div class="dist-dia"> {item.dayname}   </div>

                    <div class="dist-ana">   
                    <button onClick={e => this.handleaddhours(index)} type="button" value="agregar" class="btn-agregar "><i class="fas fa-plus"></i></button>
                    </div>

                        
                     
                        

                       
                      
                       
                        <div class="dist-hor">
                            {item.hours.map((item2, index2) =>
                                <div class="item-horario">
                                    <input type="time" onChange={(e => this.handleonchangueHour(e,index,index2,true)) } defaultValue={item2.starthour}></input> <span> - </span>
                                    <input type="time" onChange={(e => this.handleonchangueHour(e,index,index2,false)) }  defaultValue={item2.endhour}></input>
                                    <button class="btn-agregar " onClick={e => this.handleremovehours(index,index2)}><i class="fas fa-times"></i></button>
                                </div>
                            )}
                           
                        </div>
                    </div>

                )}
               

                <button onClick={(e) => this.sendbusinesshours() }  type="Button"  value="Guardar Horario" class="btn btn-primary btn-cuent">Guardar Horario <Loader
          visible={this.state.loaderSave}
          type="Oval"
          color="#00BFFF"
          height={25}
          width={25}
       
        /></button>
                
{this.state.loadsuccess && (<span class="alert"><i class="fas fa-check-circle"></i> Actualizacion realizada con exitó.</span>)}
                
                </div>
            </div>
        )
    }

}

export default businessHours;                                                                                