import React, { Component } from 'react';
import axios from 'axios';
import { Redirect, Switch, Route } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);



class FormularioEmpresaUno extends Component {

  constructor(props) {
    super(props)
    this.state = {
      username: '',
      email: '',
      name: '',
      city: '',
      webpage: '',
      business_name: '',
      description: '',
      shortdescription: '',
      start_price: '',
      end_price: '',
      activityId: '',
      activitieslist: [],
      tel: '',
      redirect: false,
      categories: [],
      cities: [],
      other: [],
      errors: {
        email: true,
        username: true,
        usernameinuse: true,
        startprice: true,
        endprice: true,
        description: true
      },
      empty: {
        username: false,
        business_name: false,
        start_price: false,
        end_price: false,
        name: false,
        email: false,
        city: false,
        tel: false,
        webpage: false,
        activity: false,
        shortdescription: false
      },
      newactivity: ""
    }

    this.handleSumitClick = this.handleSumitClick.bind(this);
    this.handletextChange = this.handletextChange.bind(this);
    this.validateUsername = this.validateUsername.bind(this);
    this.setRedirect = this.setRedirect.bind(this);
    this.renderRedirect = this.renderRedirect.bind(this);
    this.handlecombochange = this.handlecombochange.bind(this);
    this.validateemail = this.validateemail.bind(this);
    this.handleclickcheckcategory = this.handleclickcheckcategory.bind(this);
    this.getothercategories = this.getothercategories.bind(this);
  }


  componentDidMount() {

    document.getElementById("paso1").className += " activo"

    axios.get('https://serviadvisor.com/categories').then(res => {
      const categoriesdata = res.data;

      this.setState({
        categories: categoriesdata
      });
    });

    this.getothercategories();

    axios.get('https://serviadvisor.com/cities').then(res => {
      const citiesdata = res.data;
      console.log(citiesdata);
      this.setState({
        cities: citiesdata
      })
    })
  }


  getothercategories() {
    axios.get('https://serviadvisor.com/othercategories').then(res => {
      const categoriesdata = res.data;

      this.setState({
        other: categoriesdata
      });
    });

  }

  handletextChange(name, event) {
    if (name === "username") {
      this.validateUsername(event);
    }

    if (name === "email") {
      this.validateemail(event);
    }

    this.setState({ [name]: event.target.value });
  }


  validateemail(event) {
    const email = event.target.value
    const checkemail = validEmailRegex.test(event.target.value)
    const errors = this.state.errors
    errors.email = checkemail
    this.setState({ errors: errors });
  }

  validatedescription(event) {
    const description = event.target.value;
    if (description.length > 500) {
      const errors = this.state.errors;
      errors.description = false;
      this.setState({
        errors: errors
      })
    }
  }

  validateUsername(event) {
    const value = event.target.value;
    if (value.length < 7) {
      const errors = this.state.errors;
      errors.username = false;
      this.setState({
        errors: errors
      })
    } else {
      const errors = this.state.errors;
      errors.username = true;
      this.setState({
        errors: errors
      }, () => {
        const data = {
          username: value
        }
        axios.post('https://serviadvisor.com/checkusername', data, { withCredentials: true }).then(res => {
          console.log(res);
          const errors = this.state.errors;
          if (res.data.error) {

            errors.usernameinuse = false;

          } else {
            errors.usernameinuse = true;
          }

          this.setState({
            errors: errors
          })
        })
      })
    }
  }

  handleClickCategory = e => {
    console.log(e.target.value);
    this.setState({
      activityId: e.target.value
    })
  }

  handleclickcheckcategory = e => {
    console.log(e.target.value);
    console.log(e.target.checked);

    let activitiestemp = Object.assign([], this.state.activitieslist);

    if (e.target.checked === true) {
      activitiestemp.push(e.target.value);
    } else {
      activitiestemp.forEach((item, index) => {


        if (activitiestemp[index] === e.target.value) {
          console.log("incide: " + index + " Valor: " + item + " valor array " + activitiestemp[index])
          activitiestemp.splice(index, 1)
        }
      })
    }




    this.setState({
      activitieslist: activitiestemp
    }, () => {
      console.log(this.state.activitieslist);
    })


  }

  handlecombochange = e => {
    console.log(e.target.value);
    this.setState({
      city: e.target.value
    })

  }

  handleSumitClick(event) {
    event.preventDefault()
    const notify = () => toast.error("Por favor Ingresa tus datos completos");
    notify();
    if(this.checkemptys()){

     
      return
    }

    const newuser = {
      username: this.state.username,
      email: this.state.email,
      name: this.state.name,
      cityId: this.state.city,
      webpage: this.state.webpage === "" ? undefined : this.state.webpage,
      tel: this.state.tel === "" ? undefined : this.state.tel,
      business_name: this.state.business_name,
      activitylist: this.state.activitieslist,
      shortdescription: this.state.shortdescription,
      start_price: this.state.start_price,
      end_price: this.state.end_price,
      create: true
    }

    console.log(newuser);

    axios.put('https://serviadvisor.com/provider', newuser, { withCredentials: true }).then(response => {

      console.log(response);

      if (response.data.error) {
        const notify = () => toast.error(response.data.error);
        notify();
      } else {
        this.setRedirect();
      }

    }).catch(error => {
      const notify = () => toast.error("Fill the required Fields");
      notify();
    })
  }

  setRedirect = () => {
    this.setState({
      redirect: true
    })
  }

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to='/registro-empresa2' />
    }
  }

  onchangenewactivity = (e) => {
    const value = e.target.value;

    this.setState({
      newactivity: value
    })
  }

  sendnewactivity = e => {
    e.preventDefault();
    const newactivity = this.state.newactivity;

    if (newactivity !== "" && newactivity !== undefined && newactivity.length < 30) {


      const data = {
        name: newactivity
      }

      axios.post("https://serviadvisor.com/activitiesprovider", data, { withCredentials: true }).then(res => {
        const data = res.data;
        if (!data.error) {
          this.getothercategories();
        }
      })

    }


  }

  checkemptys(){
    let error = this.state.empty;
    let fail = false;
    if(this.state.business_name === ""){
      error.business_name = true;
      fail= true;
    }else{
      error.business_name = false;
    }

    if(this.state.name === ""){
      error.name = true;
      fail= true;
    }else{
      error.name = false;
    }

    if(this.state.start_price === "" || this.state.start_price===null){
      error.start_price = true;
      fail= true;
    }else{
      error.start_price = false;
    }

    if(this.state.end_price ==="" || this.state.end_price===null){
      error.end_price = true;
      fail = true;
    }else{
      error.end_price = false;
    }

    if(this.state.email ==="" || this.state.email === null){
      error.email = true;
      fail = true;
    }else{
      error.email = false;
    }

    if(this.state.username === ""){
      error.username = true;
      fail = true;
    }else{
      error.username = false;
    }

    if(this.state.shortdescription === "" || this.state.shortdescription === null){
      error.shortdescription = true;
      fail = true;
    }else{
      error.shortdescription = false;
    }

    if(this.state.city === "" || this.state.city === null){
      error.city = true;
      fail = true;
    }else{
      error.city = false;
    }

    if(this.state.activitieslist.length === 0 ){
      error.activity = true;
      fail= true;
    }else{
      error.activity = false;
    }

    this.setState({
      empty:error
    }, () => {return fail})
    
    return fail
  }



  render() {
    return (
      <div>

        <div id="content">


          <div class="rec-blog">
            <div class="container grid-reg">
              <div class="rec-blog-inner">
                <div class="row">
                  <div class="col-md-6">
                    <h4>Nombre de Usuario</h4>
                    <div class="completa">
                      <input type="text" class={this.state.empty.username ? "form-control comple obligatorio" : "form-control comple "} id="username" placeholder="Nombre de Usuario" autocomplete="off" onChange={(e) => this.handletextChange("username", e)} />
                      {this.state.empty.username && (<i class="fas fa-exclamation-circle requisito"></i>)}
                      <div class="text-regis">El usuario debe tener al menos 5 caracteres.</div>
                      {!this.state.errors.username && (<div class="row"><i class="fas fa-exclamation-circle" style={{color:"red"}}></i><span>El usuario debe tener al menos 5 caracteres</span></div>)}
                      {!this.state.errors.usernameinuse && (<div class="row"><i class="fas fa-exclamation-circle" style={{color:"red"}}></i><span>El Nombre de ususario esta en uso</span></div>)}
                    </div>

                    <h4>Datos de Contacto</h4>
                    <div class="completa">
                      <input type="text" class={this.state.empty.name ? "form-control comple obligatorio" : "form-control comple "} id="nombre" placeholder="Nombre" onChange={(e) => this.handletextChange("name", e)} />
                      {this.state.empty.name && (<i class="fas fa-exclamation-circle requisito"></i>)}

                    </div>

                    <div class="mitad-1">

                      <input type="text" class={this.state.empty.email ? "form-control mitad-1 obligatorio" : "form-control mitad-1 "} id="email" placeholder="Email" onChange={(e) => this.handletextChange("email", e)} />
                      {this.state.empty.email && (<i class="fas fa-exclamation-circle requisito"></i>)}
                    </div>


                    <div class="mitad-2s">
                      {this.state.empty.city && (<i class="fas fa-exclamation-circle requisito"></i>)}
                      {/*<input type="text" class="form-control mitad-2" id="ciudad" placeholder="Ciudad" onChange={(e) => this.handletextChange("city",e)}/>*/}
                      <select onChange={this.handlecombochange} type="text" class={this.state.empty.city ? "form-control mitad-2 obligatorio" : "form-control mitad-2" } id="ciudad" placeholder="Ciudad">
                        <option disabled selected>Selecciona una Ciudad</option>
                        {this.state.cities.map((item, index) => <option key={index} value={item.id}>{item.name}</option>)}
                      </select>

                      {!this.state.errors.email && (<i class="fas fa-exclamation-circle requisito"></i>)}

                    </div>


                    <input type="text" class="form-control mitad-1" id="telefono" placeholder="telefono" onChange={(e) => this.handletextChange("tel", e)} />
                    <input type="text" class="form-control mitad-2" id="web" placeholder="Web" onChange={(e) => this.handletextChange("website", e)} />
                    <div class="text-regis">Diligencia la información de tu empresa para que puedan contactarte.</div>
                  </div>
                  <div class="col-md-6">
                    <h4>Información de Tu Empresa</h4>

                    <div class="completa">
                      <input type="text" class={this.state.empty.business_name ? "form-control comple obligatorio" : "form-control comple" } id="nombre-empresa" placeholder="Nombre de tu Empresa" onChange={(e) => this.handletextChange("business_name", e)} />
                      {this.state.empty.business_name && (<i class="fas fa-exclamation-circle requisito"></i>)}
                    </div>
                    <div class="mitad-1">
                      <input type="text" class={this.state.empty.start_price ? "form-control mitad-1 obligatorio" : "form-control mitad-1" } id="desde" placeholder="Precio Desde" onChange={(e) => this.handletextChange("start_price", e)} />
                      {!this.state.errors.startprice && (<div class="row"><i class="fas fa-times"></i><span>Por favor ingrese un valor inicial valido</span></div>)}
                      {this.state.empty.start_price && (<i class="fas fa-exclamation-circle requisito"></i>)}
                    </div>
                    <div class="mitad-2">
                      <input type="text" class={this.state.empty.end_price ? "form-control mitad-2 obligatorio" : "form-control mitad-2 "} id="hasta" placeholder="Precio Hasta" onChange={(e) => this.handletextChange("end_price", e)} />
                      {!this.state.errors.endprice && (<div class="row"><i class="fas fa-times"></i><span>Por favor ingrese un valor final valido</span></div>)}
                      {this.state.empty.end_price && (<i class="fas fa-exclamation-circle requisito"></i>)}
                    </div>



                    <div class="completa ar-req">

                      <textarea maxlength="150" class="form-control comple" id="descripcion-empresa" placeholder="Descripcion Corta" onChange={(e) => this.handletextChange("shortdescription", e)} ></textarea>
                      {this.state.empty.shortdescription && (<i class="fas fa-exclamation-circle requisito"></i>)}
                      <div class="text-regis">Describe tu empresa para que conozcan tus servicios y Productos. Maximo 150 caracteres</div>

                      {!this.state.errors.description && (<div class="row"><i class="fas fa-times"></i><span>La descripcion Corta debe tener un maximo de 500 caracteres</span></div>)}

                    </div>

                  </div>

                  <div class="col-md-12 sep null-mar">
                    <div class="caj-flex">
                      <h4> <div class="ti-req">Selecciona el sector de Actividad de tu empresa </div> {this.state.empty.activity && (<i class="fas fa-exclamation-circle requisito-tit"></i>)} </h4>
                    </div>

                    <div class="caj-flex caja-cat-regis">


                      {this.state.categories.map((item, index) => {
                        return (
                          <div class="formulario caja-sec-check">

                            <h5>{item.name}</h5>

                            <div class="caja-lab-reg ">
                            {item.activities.map((item2, index2) => {
                              return (
                                <div class="formulario lab-reg"><div class="checkbox">
                                  <input onChange={this.handleclickcheckcategory} type="checkbox" name="category" key={item2.id} value={item2.id} id={"radio" + item2.id} />
                                  <label for={"radio" + item2.id}>{item2.name}</label><br />
                                </div>  </div>
                              );
                            })}
                          </div>

                          </div>
                        );
                      })}
                    </div>




                    <div class="caj-flex caja-cat-regis-t">


                      {this.state.other.map((item, index) => {
                        return (
                          <div class="caja-sec-che">

                            <h5>{item.name}</h5>

                            <div class="caja-sec-check">
                            {item.activities.map((item2, index2) => {
                              return (
                                <div class="formulario lab-reg"><div class="checkbox">
                                  <input onChange={this.handleclickcheckcategory} type="checkbox" name="category" key={item2.id} value={item2.id} id={"radio" + item2.id} />
                                  <label for={"radio" + item2.id}>{item2.name}</label><br />
                                </div> </div>
                              );
                            })}

</div>
                          </div>
                        );
                      })}


                    </div>

                    <div class="caj-flex">
                      <input onChange={this.onchangenewactivity} value={this.state.newactivity} type="text" class="inpuy-agre-cate"></input> <button onClick={this.sendnewactivity} className={"btn-success agre"}> <i class="fa fa-plus naranja"></i> Agregar Actividad</button>

                    </div>


                  </div>

                </div>

                {this.renderRedirect()}
                <button onClick={e => this.handleSumitClick(e)} type="button" class="btn btn-primary btn sig" > <a href="">Siguiente <i class="fas fa-angle-right"></i></a> </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FormularioEmpresaUno;