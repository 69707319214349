import React,{Component} from 'react';
import axios from 'axios';
import Message from '../../chat/message';
import DealMessage from '../../chat/DealMessage';


class revision extends Component{
    constructor(props){
        super(props)

        this.state={
            messages:[],
            page:0,
            chatid: undefined,
        }

        this.rendermessage = this.rendermessage.bind(this);
        this.getmessages = this.getmessages.bind(this);
        this.nextpage = this.nextpage.bind(this);
    }

    componentDidMount(){

    }

    rendermessage(item){
        if(item.offert !==null && item.offert !==undefined){
            return(
                <DealMessage onDealresponse={this.dealresponse} type="client" offer={item}></DealMessage>
            )
        }else{
            return(
                <Message message={item}></Message>
               
            )
        }
    }

    getmessages = () => {
        let pagenum = 0;
       
        const data = {
            chatid: this.state.chatid,
            page:pagenum
        }
        axios.post('https://serviadvisor.com/administrator/listmessages', data, { withCredentials: true }).then(res => {
            if (data.error) {

            } else {

                const data = res.data

                    if(!data.error || data.length===0)
                {
                    this.setState({
                        messages:data.reverse(),
                        page:0
                    })
                }

              
            }

        })


    }


    nextpage = () => {
      
        let pagenum = this.state.page;
        pagenum = pagenum + 1;

        const data = {
            chatid: this.state.chatid,
            page:pagenum
        }
        axios.post('https://serviadvisor.com/administrator/listmessages', data, { withCredentials: true }).then(res => {
            if (data.error) {

            } else {

                const data = res.data


                const messagesdata = this.state.messages.slice()
        
                if(data.length > 0){
                    this.setState({
                        messages:data.reverse().concat(messagesdata) 
                    }, () => {
                        this.setState({
                            page:pagenum
                          })
                        console.log(this.state.messages);
                    })
                }
      
            }

        })

        
    }

    onchangechatid(e){
        const chatid= e.target.value;

        this.setState({
            chatid:chatid
        })
    }

    render(){
        return(
            <div>
                <span>Ingresa el id de el chat  </span>
                <input onChange={(e) => this.onchangechatid(e)} type="text" value={this.state.chatid}></input>
                <button onClick={this.getmessages}>Cargar</button> 


                 <div class="caja-chat-perfil-emp">

<div  class="subtitulo"><a onClick={this.nextpage} href="javascript:void(0)">Cargar más mas mensajes...</a></div> <br/>

    {this.state.messages.map((item, index2) => {
       
        return (
            <div key={index2}
            class={"caja-chat-perfil-emp-ind"} >
                    {this.rendermessage(item)}
                  
            </div>
        );
    })}

</div>
            </div>
        )
    }
}

export default revision;