import React, { Component } from 'react';

import './App.css';

import Home from './components/pages/home';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import ScrollToTop from './components/scrolltotop';
import RegistroEmpresa from './components/pages/RegistroEmpresa';
import RegistroEmpresa2 from './components/pages/RegistroEmpresa2';
import RegistroEmpresa3 from './components/pages/RegistroEmpresa3';
import PerfilCliente from './components/pages/PerfilCliente';
import PerfilEmpresa2 from './components/pages/PerfilEmpresa2';
import PerfilClienteEditar from './components/pages/PerfilClienteEditar';
import Busqueda from './components/pages/Busqueda';
import Editarperfilempresa from './components/pages/PerfilEmpresaMicuentaChat';
import Premium from './components/pages/Premium';
import PaypalPage from './components/pages/paypalpage';
import Page404 from './components/pages/404page';
import Administrator from './components/pages/administrator/adminpage';
import Blog from './components/pages/blog/blogpage';
import Article from './components/pages/blog/articlepage';
import Activation from './components/pages/activation';
import ChagePassword from './components/pages/changePassword';
import OtherPage from './components/pages/OtherCategories';
import PaypalAccountMessage from './components/paypalAccountMessage';
import DeleteAccount from './components/pages/deleteaccount';
import $ from 'jquery';
import axios from 'axios';
import profileutils from './utils/profiledata';

const requestUrl = "https://serviadvisor.com/";
const testrequestUrl = "http://localhost:3000/";

class App extends Component {


  constructor(props) {
    super(props);
    this.state = {
      slideout: undefined,
      activities: [],
      categories: [],
      user: undefined,
      islogin: undefined
    }

    this.login = this.login.bind(this);
    this.close = this.close.bind(this);
    this.logaout = this.logaout.bind(this);
    this.changecheckbox = this.changecheckbox.bind(this);
    this.checkboxselected = this.checkboxselected.bind(this);

  }

  async componentDidMount() {

    console.log(this)

    var slideout = await new window.Slideout({
      'panel': document.getElementById('main'),
      'menu': document.getElementById('menu'),
      'padding': 256,
      'tolerance': 70
    }).
      on('beforeopen', function () {
        this.panel.classList.add('panel-open');

      }).
      on('open', function () {
        this.panel.addEventListener('click', close);
      }).
      on('beforeclose', function () {
        this.panel.classList.remove('panel-open');
        this.panel.removeEventListener('click', close);
      });

    this.setState({
      slideout: slideout
    })




    await axios.get('https://serviadvisor.com/categories').then(res => {
      const categoriesdata = res.data;

      this.setState({
        categories: categoriesdata
      })

    });

    $(".sidebar-dropdown > a").click(function () {
      $(".sidebar-submenu").slideUp(250);
      if (
        $(this)
          .parent()
          .hasClass("active")
      ) {
        $(".sidebar-dropdown").removeClass("active");
        $(this)
          .parent()
          .removeClass("active");
      } else {
        $(".sidebar-dropdown").removeClass("active");
        $(this)
          .next(".sidebar-submenu")
          .slideDown(250);
        $(this)
          .parent()
          .addClass("active");
      }
    });

    $("#toggle-sidebar").click(function () {
      $(".page-wrapper").toggleClass("toggled");
    });






    //window.document.querySelector('.js-slideout-toggle').addEventListener('click', function () {
    // slideout.open();
    //});

    function close(eve) {
      eve.preventDefault();
      slideout.close();
    }

    this.setState({
      slideout: slideout
    })




  }

  logaout = async () => {


    axios.get('https://serviadvisor.com/logout', { withCredentials: true }).then(res => {


      profileutils.deleteprofile();


      window.location.replace("/");
    })

  }

  close = (eve) => {
    eve.preventDefault();
    this.state.slideout.close();
  }

  login = (user, islogin) => {


    this.setState({
      user:user
    })

    if (islogin) {


      $("#usernameslide").html(user.username);

      if (user.photo_url) {
        $("#user_pic").html('<img class="img-responsive img-rounded" src=' + user.photo_url +
          ' alt="User picture"/>')
      } else {
        $("#user_pic").html('<img class="img-responsive img-rounded" src="https://raw.githubusercontent.com/azouaoui-med/pro-sidebar-template/gh-pages/src/img/user.jpg" alt="User picture"/>')

      }



      var html = "";
      user.accountType === "cliente" ? html = '<a href="/perfil-cliente">Mi cuenta</a>' : html = '<a href="/perfil/informacion">Mi cuenta</a>'
      var switchcheck = "";




   
      $("#user_role").html(html);

      $("#loginbuttonlateral").css("display", "none");
      $("#cerrar_sesion").css("display", "block");
      $("#status").html('<i class="fa fa-circle"></i><span id="status">Online</span>')


    } else {
      $("#usernameslide").html("");
      $("#user_pic").html('')

      $("#loginbuttonlateral").css("display", "block");
      $("#cerrar_sesion").css("display", "none");
      $("#user_role").html('');

      $("#status").html("")
    }


    // this.props.history.push('/activacion');
    /*
    if (user && user.active) {

    } else {
      window.location.href = '/activacion';
    }
    */

   
  }

  
  changecheckbox = (e) => {
    e.preventDefault();
    const type = e.target.checked ? "1" : "2";
    const data = {
      type
    }

    axios.post('https://serviadvisor.com/changeAccountType', data, { withCredentials: true }).then(async (res) => {
      const data = res.data;
      if (!data.error) {
        //await this.checkuserlogin();
        //this.props.history.push('./')

        if (type === "2") {
          
        
          var url = window.location.toString();
          //window.location = url.replace(/perfil/, 'perfil-cliente');
          window.location.href="/perfil-cliente/propuestas"
        }else{
          var url = window.location.toString();
          //window.location = url.replace(/perfil-cliente/, 'perfil');
          window.location.href="/perfil/propuestas"
        }

        //window.location.href="/"
      }
    })
 
    
  }

  checkboxselected = (accountType) =>{
    if (accountType === "empresa") {
      return (
       
        <input onChange={(e) => this.changecheckbox(e)} type="checkbox" name="header" class="onoffswitch-checkbox" id="myonoffswitch" checked />
       
      )
    } else {
      return (
        <input onChange={(e) => this.changecheckbox(e)} type="checkbox" name="header" class="onoffswitch-checkbox" id="myonoffswitch" />
        )
    }


  }

  render() {
    return (
      <div id="wrapper" class="homepage-1">

      {this.state.user && this.state.user.accountType==="empresa" && (<PaypalAccountMessage user={this.state.user}></PaypalAccountMessage>)}


        <div id="content">
 

        </div>



        <nav id="menu" class="menu sidebar-wrapper" >

          <ul class="nav navbar-nav mobil">

            <div class="sidebar-content">
              <div class="sidebar-brand">

                <div id="close-sidebar">
                  <i class="fas fa-times" onClick={this.close}></i>
                </div>
              </div>
              <div class="sidebar-header">
                <div id="user_pic" class="user-pic">

                </div>
                <span id="status" class="user-status"> </span>
                {this.state.user && (
                     <div id="switch lateral" class="onoffswitch">
                     {this.checkboxselected(this.state.user.accountType)}
                      <label class="onoffswitch-label" for="myonoffswitch">
                        <span class="onoffswitch-inner"></span>
                        <span class="onoffswitch-switch"></span>
                      </label>
                    </div>
                  )}

                <div class="user-info">
                  <span id="usernameslide" class="user-name">

                  </span>
                  <span id="user_role" class="user-role"></span>
                  <a style={{ display: "none" }} id="cerrar_sesion" onClick={this.logaout}>Cerrar Sesion</a>


                </div>
                <button id="loginbuttonlateral" onClick={this.close} type="button" className="btn btn-primary btn" data-toggle="modal" data-target="#myModal-2">Iniciar Sesión</button>


              </div>




              <div class="sidebar-menu">
                <ul>
                  <li class="header-menu">
                    <span>General</span>
                  </li>

                  {this.state.categories.map((item, index) => {
                    return (
                      <li class="sidebar-dropdown">
                        <a href="#">
                          <i class={"fa fa-hard-hat " + item.name + "_icon"}></i>
                          <span>{item.name}</span>
                          <span class="badge badge-pill badge-warning">{item.activities.length}</span>
                        </a>
                        <div class="sidebar-submenu">
                          <ul>
                            {item.activities.map((item2, index) => {
                              return (
                                <li>
                                  <a href={"search/?category=" + item2.id}>{item2.name}</a>
                                </li>
                              )
                            })}



                          </ul>
                        </div>
                      </li>
                    );
                  })}




                  <li class="header-menu">
                    <span>Extra</span>
                  </li>
                  <li>
                    <a href="/blog">
                      <i class="fa fa-book"></i>
                      <span>Consejos</span>

                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i class="fa fa-calendar"></i>
                      <span>Calendar</span>
                    </a>
                  </li>

                </ul>
              </div>

            </div>
          </ul>
        </nav>

        <main id="main" class="panelnav">
          <header>


          </header>

          <div className="App">

            <Router>
              <div>

                <ScrollToTop>
                  <Switch>
                    <Route path="/" exact render={(props) => <Home {...props} loginroot={this.login} slideout={this.state.slideout} ></Home>} />
                    <Route path="/registro-empresa" render={(props) => <RegistroEmpresa {...props} loginroot={this.login} slideout={this.state.slideout}></RegistroEmpresa>} />
                    <Route path="/registro-empresa2" render={(props) => <RegistroEmpresa2 {...props} loginroot={this.login} slideout={this.state.slideout}></RegistroEmpresa2>} />
                    <Route path="/registro-empresa3" render={(props) => <RegistroEmpresa3 {...props} loginroot={this.login} slideout={this.state.slideout}></RegistroEmpresa3>} />
                    <Route path={"/empresa/:id/:tab"} exact render={(props) => <PerfilEmpresa2  {...props} loginroot={this.login} slideout={this.state.slideout}></PerfilEmpresa2>} />
                    <Route path={"/search/"}  render={(props) => <Busqueda {...props} loginroot={this.login} slideout={this.state.slideout}></Busqueda>} />
                    <Route path={"/perfil/:tab"} exact render={(props) => <Editarperfilempresa {...props} loginroot={this.login} slideout={this.state.slideout}></Editarperfilempresa>} />
                    <Route path={"/perfil/:tab/:id"} render={(props) => <Editarperfilempresa {...props} loginroot={this.login} slideout={this.state.slideout}></Editarperfilempresa>} />
                    <Route path="/perfil-cliente-editar" exact render={(props) => <PerfilCliente {...props} loginroot={this.login} slideout={this.state.slideout}></PerfilCliente>} />
                    <Route path="/perfil-cliente/:tab" exact render={(props) => <PerfilClienteEditar {...props} loginroot={this.login} slideout={this.state.slideout}></PerfilClienteEditar>} />
                    <Route path="/perfil-cliente" exact render={(props) => <PerfilClienteEditar {...props} loginroot={this.login} slideout={this.state.slideout}></PerfilClienteEditar>} />
                    <Route path="/paypal" render={(props) => <PaypalPage {...props} loginroot={this.login} slideout={this.state.slideout}></PaypalPage>} />
                    <Route path="/premium" render={(props) => <Premium {...props} loginroot={this.login} slideout={this.state.slideout}></Premium>} />
                    <Route path="/AdvisorAdministrator" exact render={(props) => <Administrator {...props} loginroot={this.login} slideout={this.state.slideout}></Administrator>} />
                    <Route path="/change" exact render={(props) => <ChagePassword {...props} loginroot={this.login} slideout={this.state.slideout}></ChagePassword>} />
                    <Route path="/change/:token" exact render={(props) => <ChagePassword {...props} loginroot={this.login} slideout={this.state.slideout}></ChagePassword>} />
                    <Route path="/AdvisorAdministrator/:tab" exact render={(props) => <Administrator {...props} loginroot={this.login} slideout={this.state.slideout}></Administrator>} />
                    <Route path="/blog/" exact render={(props) => <Blog {...props} loginroot={this.login} slideout={this.state.slideout}></Blog>} />
                    <Route path="/blog/:category" exact render={(props) => <Blog {...props} loginroot={this.login} slideout={this.state.slideout}></Blog>} />
                    <Route path="/blog/:id/post" exact render={(props) => <Article {...props} loginroot={this.login} slideout={this.state.slideout}></Article>} />
                    <Route path="/activacion/" exact render={(props) => <Activation {...props} loginroot={this.login} slideout={this.state.slideout}></Activation>} />
                    <Route path="/activacion/:token" exact render={(props) => <Activation {...props} loginroot={this.login} slideout={this.state.slideout}></Activation>} />
                    <Route path="/deleteaccount/" exact render={(props) => <DeleteAccount {...props} loginroot={this.login} slideout={this.state.slideout}></DeleteAccount>} />
                    <Route path="/deleteaccount/:token" exact render={(props) => <DeleteAccount {...props} loginroot={this.login} slideout={this.state.slideout}></DeleteAccount>} />
                    <Route path="/ayuda/" exact render={(props) => <OtherPage {...props} loginroot={this.login} slideout={this.state.slideout}></OtherPage>} />
                    <Route path="/otrasCategorias/" exact render={(props) => <OtherPage {...props} loginroot={this.login} slideout={this.state.slideout}></OtherPage>} />
                    <Route render={(props) => <Page404 {...props} loginroot={this.login} slideout={this.state.slideout}></Page404>} />
                  </Switch>
                </ScrollToTop>

              </div>
            </Router>

          </div>
        </main>






      </div>
    );
  }
}



export default App;
