import React, { Component, useDebugValue } from 'react'
import ItemsCarousel from 'react-items-carousel';
import range from 'lodash/range';
import ReactStars from 'react-stars';
import Moment from 'react-moment';
import axios from 'axios';
import Slider from "react-slick";
import LeftButton from './leftbutton';
import RightButton from './rightbutton';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


class LeftNavButton extends React.Component {
    render() {
      return <button {...this.props}>Next</button>
    }
  }

  class RightNavButton extends React.Component {
    render() {
      return <button {...this.props}>Prev</button>
    }
  }



class carouselPremium extends Component {
    constructor(props) {
        super(props)
        this.state = {
            children: [],
            activeItemIndex: 0,
            providers:[],
            providerscontend: []
        }

        this.groupproviders = this.groupproviders.bind(this);
    }

    componentDidMount() {
        this.getpremiumproviders();
        
    }

    getpremiumproviders() {
        axios.get('https://serviadvisor.com/getpublicpremiumproviders').then(res => {
            const data = res.data
            if(data){
                this.groupproviders(data);
                this.setState({
                    providers:data
                })
            }
         
        })

     
    }

    groupproviders(providers) {
        
        let providersRow =  [];
        let providertemp = [];
        let count = 0;



        providers.forEach((item, index) => {

            providersRow.push(item);
            count++;
            if(count >= 3){
                providertemp.push(providersRow);
                providersRow=[];
                count=0;
            }



            if (providers.length == index + 1 && count < 3) {

                providertemp.push(providersRow);
              }
          })


        
       
        this.setState({
            providerscontend: providertemp
        }, () => {
            console.log(this.state.providerscontend);
            console.log(providertemp);
            
        })


    }

    changeActiveItem = (activeItemIndex) => this.setState({ activeItemIndex });

   


    render() {


        const settings = {
            dots: true,
            infinite:  this.state.providers.length > 1,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
    
          };


          const settings2 = {
            dots: true,
            infinite:  this.state.providers.length > 1,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
      
          };
        
        return (
            <div class="caja-car" >
               

                <div class="carr-pre">  
    
      <Slider {...settings}
        
   
        >
        {this.state.providers.map((item, i) =>
                        <div
                            key={i}
                            style={{


                            } }
                            class={"caja-car-pre"}
                       
                        >


                            <div class="perfil-busq">
                                <div class="columna-1-novias-busq car-pre">
                                    <div class="imagen-novias-busq- car-pre-img"><a href={"/empresa/" + item.id + "/perfil"}>
                                        {item.user.photo_url ?  <img src={item.user.photo_url} alt="" class="img-responsive" /> :
                                        <img src="/images/img amarillis cont.png" alt="" class="img-responsive" />
                        }
                                    </a></div>

                                    <div class="titulo-cliente "><h4 class="verde"><a href={"/empresa/" + item.id + "/perfil"}> {item.business_name}</a></h4></div>


                                    <div class="califica-prem">

                                    <ReactStars
                                        count={5}
                                        edit={false}
                                        size={15}
                                        half={false}
                                        value={item.rating}
                                        color2={'#ffd700'} />
                                    </div>
                                    <div class="letras-pequeñas"> <p >
                                       {item.shortdescription}</p></div>









                                </div>





                            </div>



                        </div>
                    )}
      </Slider>

      </div>

<div class="carr-mob-ini">  


      <Slider {...settings2}
      
        >

            {this.state.providerscontend.map((item2,index2)=> 
                <div id="tarjeta_grupo_premium">
                    {item2.map((item,i)=>
                         <div
                         key={i}
                         style={{


                         } }
                         class={"caja-car-pre"}
                    
                     >


                         <div class="perfil-busq">
                             <div class="columna-1-novias-busq car-pre">
                                 <div class="imagen-novias-busq- car-pre-img"><a href={"/empresa/" + item.id + "/perfil"}>
                                     {item.user.photo_url ?  <img src={item.user.photo_url} alt="" class="img-responsive" /> :
                                     <img src="/images/img amarillis cont.png" alt="" class="img-responsive" />
                     }
                                 </a></div>

                                 <div class="titulo-cliente "><h4 class="verde"><a href={"/empresa/" + item.id + "/perfil"}> {item.business_name}</a></h4></div>


                                 <div class="califica-prem">

                                 <ReactStars
                                     count={5}
                                     edit={false}
                                     size={15}
                                     half={false}
                                     value={item.rating}
                                     color2={'#ffd700'} />
                                 </div>
                                 <div class="letras-pequeñas"> <p >
                                    {item.shortdescription}</p></div>









                             </div>





                         </div>



                     </div>
                    
                    )}

                </div>
            )}
    
      </Slider>

      </div>


               {/* <ItemsCarousel
                    gutter={12}
                    activePosition={'center'}
                    chevronWidth={60}
                    numberOfCards={5}
                    slidesToScroll={3}
                    outsideChevron={false}
                    showSlither={false}
                    firstAndLastGutter={false}
                    activeItemIndex={this.state.activeItemIndex}
                    requestToChangeActive={value => this.setState({ activeItemIndex: value })}
                    rightChevron={'>'}
                    leftChevron={'<'}
                >
                    
                    {this.state.providers.map((item, i) =>
                        <div
                            key={i}
                            style={{


                            }}
                        >


                            <div class="perfil-busq">
                                <div class="columna-1-novias-busq">
                                    <div class="imagen-novias-busq"><a href="#">
                                        {item.user.photo_url ?  <img src={item.user.photo_url} alt="" class="img-responsive" /> :
                                        <img src="/images/img amarillis cont.png" alt="" class="img-responsive" />
                        }
                                    </a></div>

                                    <div class="titulo-cliente"><h4 class="azul"><a href={"/empresa/" + item.id + "/perfil"}> {item.business_name}</a></h4></div>




                                    <ReactStars
                                        count={5}
                                        edit={false}
                                        size={20}
                                        half={false}
                                        value={item.rating}
                                        color2={'#ffd700'} />

                                    <div class="letras-pequeñas"> <p >
                                       {item.shortdescription}</p></div>









                                </div>





                            </div>



                        </div>
                    )}
                </ItemsCarousel>
                    */}
            </div>
        )
    }
}

export default carouselPremium