import React, { Component } from 'react';
import $ from 'jquery';
import Switch from 'react-switch';
import profileutils from '../../../utils/profiledata';
import axios from 'axios';
class MenuEdicion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            providerinfo: [],
            checked: undefined
        }
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

        $('#btn-men-lat').click(function () {
            $('.men-lat-emp-res').show(0);
        });
        $('.tab-7 li').click(function () {
            $('.clad + .men-lat-emp-res').hide(0);

        });

        $(document).on('click', '#btn-men-lat', function () {
            $(this).addClass('clad');
        });

        $('.tab-7 li').click(function () {
            $('#btn-men-lat').removeClass('clad');

        });
        this.getproviderinfo();
    }

    getproviderinfo() {
        axios.get('https://serviadvisor.com/checkprovider', { withCredentials: true }).then(res => {

            const data = res.data;
            if (data.error) {

            } else {
                console.log(data);
                this.setState({
                    providerinfo: data,
                    checked: data.registro
                })
                console.log("provider data in page")
            }
        })

    }



    settab = (name) => {
        this.props.onchangetab(name);

    }

    handleChange(checked) {
       
        const data = {
            activateaccount:checked
        }

        axios.post('https://serviadvisor.com/activeprofile', data,{withCredentials:true}).then(res => {
            const data = res.data;

            if(!data.error){
                this.setState({ checked });
            }
        })
    }

    logaout = async () => {


        axios.get('https://serviadvisor.com/logout', { withCredentials: true }).then(res => {
    
    
          profileutils.deleteprofile();
    
    
          window.location.replace("/");
        })
    
      }

    

    render() {
        return (
            <div>
                <div class="col-md-3 blog-ct ">

                    <div class="azul "><h3>Mi Cuenta</h3><hr class="naranja" /></div>



                   



                    <div class="men-lat-emp" id="menu-lat-micuenta">
                        <label>
                            <h4>Perfil Publicado</h4>
                            {this.state.checked !== undefined && (<Switch onChange={this.handleChange} checked={this.state.checked} />) } 
                            
                        </label>


                        <ul class="tab-7">
                            <li id="cerrar"> <i class="fas fa-times"></i> </li>


                            <li id="informacion" onClick={(e) => this.settab("informacion")} class=""><p>Mi perfil</p> <div class="border-bot-gris"></div></li>
                            <li id="businesshours" onClick={(e) => this.settab("businesshours")} class=""><p>Horario de Atención</p> <div class="border-bot-gris"></div></li>
                            <li id="mapa" onClick={(e) => this.settab("mapa")} class=""><p>Localización y Mapa</p> <div class="border-bot-gris"></div></li>
                            <li id="coments" onClick={(e) => this.settab("coments")} class=""><p> Comentarios y calificaciones de los clientes </p> <div class="border-bot-gris"></div></li>
                            <li id="videos" onClick={(e) => this.settab("videos")} class=""><p>Videos</p> <div class="border-bot-gris"></div></li>
                            <li id="galeria" onClick={(e) => this.settab("galeria")} class=""><p>Galería</p> <div class="border-bot-gris"></div></li>
                            <li id="faq" onClick={(e) => this.settab("faq")} class=""><p>Preguntas frecuentes - FAQ</p> <div class="border-bot-gris"></div></li>
                            <li id="account" onClick={(e) => this.settab("account")} class=""><p>Administrar Cuenta</p> <div class="border-bot-gris"></div></li>
                            <li id="chat" onClick={(e) => this.settab("chat")} class=""><p>Chat</p> <div class="border-bot-gris"></div></li>
                            <li id="propuestas" onClick={(e) => this.settab("propuestas")} class=""><p>Propuestas</p> <div class="border-bot-gris"></div></li>
                            <li id="calendario" onClick={(e) => this.settab("calendario")} class=""><p>Calendario</p> <div class="border-bot-gris"></div></li>
                            <li id="pagos" onClick={(e) => this.settab("pagos")} class=""><p>Ganancias </p> <div class="border-bot-gris"></div></li>
                            <li id="notificaciones" onClick={(e) => this.settab("notificaciones")} class=""><p>Notificaciones<div class="circulo-rojo"></div></p> <div class="border-bot-gris"></div></li>
                        </ul>
                        <div class="perfil-publi"><a target="_blank" href={"/empresa/" + this.state.providerinfo.id + "/perfil"}><i class=""></i> Ir a Perfil público</a></div>
                        <div class="cerrar-sesion"><a onClick={this.logaout} href="javascript:void(0)"><i class="fas fa-sign-out-alt"></i> Cerrar Sesión</a>
                        </div>
                    </div>

                    <div id="btn-men-lat" ><i class="fas fa-bars"></i> Menú</div>

                    <div class="men-lat-emp-res" id="menu-lat-micuenta">
                        <ul class="tab-7">
                            <li id="cerrar"> <i class="fas fa-times"></i> </li>

                            {/*       */}

                            <li id="informacion" onClick={(e) => this.settab("informacion")} class=""><p>Mi perfil</p> <div class="border-bot-gris"></div></li>
                            <li id="businesshours" onClick={(e) => this.settab("businesshours")} class=""><p>Horario de Atención</p> <div class="border-bot-gris"></div></li>
                            <li id="mapa" onClick={(e) => this.settab("mapa")} class=""><p>Localización y Mapa</p> <div class="border-bot-gris"></div></li>
                            <li id="coments" onClick={(e) => this.settab("coments")} class=""><p>Comentarios y calificaciones de los clientes</p> <div class="border-bot-gris"></div></li>
                            <li id="videos" onClick={(e) => this.settab("videos")} class=""><p>Videos</p> <div class="border-bot-gris"></div></li>
                            <li id="galeria" onClick={(e) => this.settab("galeria")} class=""><p>Galería</p> <div class="border-bot-gris"></div></li>
                            <li id="faq" onClick={(e) => this.settab("faq")} class=""><p>Preguntas frecuentes - FAQ</p> <div class="border-bot-gris"></div></li>
                            <li id="account" onClick={(e) => this.settab("account")} class=""><p>Administrar Cuenta</p> <div class="border-bot-gris"></div></li>
                            <li id="chat" onClick={(e) => this.settab("chat")} class=""><p>Chat</p> <div class="border-bot-gris"></div></li>
                            <li id="propuestas" onClick={(e) => this.settab("propuestas")} class=""><p>Propuestas</p> <div class="border-bot-gris"></div></li>
                            <li id="calendario" onClick={(e) => this.settab("calendario")} class=""><p>Calendario</p> <div class="border-bot-gris"></div></li>
                            <li id="pagos" onClick={(e) => this.settab("pagos")} class=""><p>Ganancias</p> <div class="border-bot-gris"></div></li>
                            <li id="notificaciones" onClick={(e) => this.settab("notificaciones")} class=""><p>Notificaciones<div class="circulo-rojo"></div></p> <div class="border-bot-gris"></div></li>

                        </ul>
                        <div class="publi-res">
                            <div class="perfil-publi"><a target="_blank" href={"/empresa/" + this.state.providerinfo.id + "/perfil"}><i class=""></i> Ir a Perfil público</a></div>
                            <div class="caj-cerr">
                                <div class="cerrar-sesion"><a  onClick={this.logaout} href="javascript:void(0)"><i class="fas fa-sign-out-alt"></i> Cerrar Sesión</a>
                            </div>
                        </div>
                        </div>
                    </div>




                    <div>
                    </div>




                </div>
            </div>

        );
    }

}

export default MenuEdicion;