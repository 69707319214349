import React,{Component} from 'react';



class Body404 extends Component{
     constructor(props){
         super(props)
     }
    
    render(){
       
      
        return(
            <div>
                            <h1>Pagina No Encontrada</h1>
        

            </div>
        )
    }
}

export default Body404;